import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-verification-container',
  templateUrl: './verification-container.component.html',
  styleUrls: ['./verification-container.component.sass'],
})
export class VerificationContainerComponent implements OnInit {
  @Input() screen: string = '';
  @Output() returnEvent = new EventEmitter<string>();
  type: string = 'recoverPassword';

  constructor(private _nav: NavigationService) {}

  ngOnInit(): void {
    const location = this._nav.getLocationParam();
    if (location) {
      this.type = location;
    } else {
      this.type = this.screen;
    }
  }

  returnScreen(value: string) {
    this.returnEvent.emit(value);
  }

  changeType(value: string) {
    this.type = value;
  }
}
