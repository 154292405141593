import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashBoardServices {
    private readonly baseUrl = environment.apiConfig.baseUrl;
    private readonly dashboardUrl = environment.apiConfig.dashboard.url;
    appVersion:string='';
    constructor(private _httpClient:HttpClient){

    }
    getDashboardData() {
        return this._httpClient
          .get<any>(`${this.baseUrl}${this.dashboardUrl}/data`);
      }
    getFilteredDashboardData(type:string){
      let params = new HttpParams();
      params = params.append("type", type);
      return this._httpClient
          .get<any>(`${this.baseUrl}${this.dashboardUrl}/documents`,{params});
    }

    getAppVersion(){
      return this._httpClient.get<any>(`${this.baseUrl}/appdetails`);
    }
}
