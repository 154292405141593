import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { startWith, map } from 'rxjs';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { UserGroupComponent } from '../user-group/user-group.component';

@Component({
  selector: 'app-add-user-group',
  templateUrl: './add-user-group.component.html',
  styleUrls: [
    '../user-group/user-group.component.sass',
    './add-user-group.component.sass',
  ],
})
export class AddUserGroupComponent
  extends UserGroupComponent
  implements OnInit
{
  memberData: any;
  orginalGroupMembers: any[] = this.groupMembers;
  isValid:boolean = true;
  constructor(
    private _userGroupService: UserGroupService,
    private _usersService: UsersService
  ) {
    super();
    this._usersService.getUsers();
  }

  addUserGroup() {
    const userGroup = this.getUserGroupData();
    if (this.groupName.length > 0) {
      this.isValid=true;
      this._userGroupService
        .createUserGroups(userGroup)
        .subscribe(async (result) => {
          this._userGroupService.getUserGroups().subscribe(({ data }) => {
            this._userGroupService.setUserGroup(
              this._userGroupService.format(data)
            );
          });

          this.closeModal();
        });
    }else{
      this.isValid=false
    }
  }

  ngOnInit(): void {
    this._usersService.getUsers().subscribe((value) => {
      this.users = value.data;
    });
    this.userlist = this.userListControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return this.users.filter((data) =>
          data.email.toLowerCase().includes(value || '')
        ); //this.filter(value as string);
      })
    );
  }
}
