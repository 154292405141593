<div class="bulk-sc-id">
  <button mat-button (click)="updateLocation('bulk-id')" class="back-button">
    <span>
      Back
      <mat-icon inline="true" class="ml-3">arrow_back</mat-icon>
    </span>
  </button>
  <app-table [title]="'ID'" [columns]="columns" [type]="'bulkIdData'" [data]="bulkIdData | async" [paginate]="true"
    [canOpenFile]="true" [rowFunction]="selectedRow">
    <div class="tableSearch">
      <p class="batch-name">
        Batch IDs: <span>{{ title }}</span>
      </p>
      <div class="search-form">
        <mat-form-field floatLabel="never" appearance="fill">
          <input matInput type="text" placeholder="Input Your Text in Here" [(ngModel)]="searchQuery"
            (keyup)="search()" />
          <span matPrefix><img class="search-image" src="../../../assets/images/icons/search.png" alt="" /></span>
        </mat-form-field>
      </div>
    </div>
  </app-table>
  <app-form-builder></app-form-builder>
</div>