<div class="documents padding-top-50">
  <h4 class="title">Documents</h4>
  <mat-tab-group
    class=""
    #tabGroup
    preserveContent
    (selectedTabChange)="changeTab($event)"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/myDoc.svg" />
          <span>My Documents</span>
        </div>
      </ng-template>
      <app-table
        *ngIf="!isPhoneWidth"
        [resultsLength]="myResultsLength"
        [title]="'docs'"
        [columns]="filesCols"
        [data]="myfilesData"
        [paginate]="true"
        [canOpenFile]="true"
        [hideTitle]="true"
        [nameFunction]="openPdf"
      ></app-table>
      <app-tablet-table
        *ngIf="isPhoneWidth"
        [itemTemplate]="my_document_row"
        [title]="''"
        [data]="myfilesData"
      ></app-tablet-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/teamDoc.svg" />
          <span>Group Documents</span>
        </div>
      </ng-template>
      <ng-container *ngIf="!isPhoneWidth">
        <app-table
          *ngIf="isGroupDataLoaded"
          [resultsLength]="groupResultsLength"
          [title]="'docs'"
          [columns]="filesCols"
          [data]="groupfilesData"
          [paginate]="true"
          [canOpenFile]="true"
          [hideTitle]="true"
          [nameFunction]="openPdf"
        ></app-table>
      </ng-container>
      <app-tablet-table
        *ngIf="isPhoneWidth"
        [itemTemplate]="my_document_row"
        [title]="''"
        [data]="groupfilesData"
      ></app-tablet-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/shared-docu.png" />
          <span>Shared Documents</span>
        </div>
      </ng-template>
      <app-table
        *ngIf="!isPhoneWidth"
        [resultsLength]="sharedResultsLength"
        [title]="'docs'"
        [columns]="filesCols"
        [data]="sharedfilesData"
        [paginate]="true"
        [canOpenFile]="true"
        [hideTitle]="true"
        [nameFunction]="openPdf"
      ></app-table>
      <app-tablet-table
        *ngIf="isPhoneWidth"
        [itemTemplate]="my_document_row"
        [title]="''"
        [data]="sharedfilesData"
      ></app-tablet-table>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #my_document_row let-data>
  <div class="recent-document-row" (click)="openPdf(data)">
    <div class="recent-document__content">
      <p class="title">{{ data.title }}</p>
      <p class="content"><a>Updated on: </a>{{ data.date_modified }}</p>
    </div>
    <div class="recent-document__img">
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>
