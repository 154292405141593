<div class="wrapper flexGrid" id="email">
  <h2 class="header">Email sent</h2>
  <h4 class="description" *ngIf="type == 'email-sent'">
    Click the link that was sent to your email to complete the verification
    process. You will only be able to access the dashboard once you are
    verified.
  </h4>
  <h4 class="description" *ngIf="type == 'email-resetpw'">
    We’ve sent you instructions to recover your password in your email.
  </h4>
  <button class="btn-next" (click)="returnScreen('login')">
    <label class="btn-font">Back to log in</label>
  </button>
  <p class="other">Did not receive the email? Check your spam or junk folder</p>
</div>
