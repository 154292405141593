<div class="success-container">
  <div class="title">
    <img [src]="img" alt="" />
    <span>Document verified</span>
  </div>
  <div class="description">
    The document
    <span>”{{ name ?? "File name not found" }}”</span>
    has been verified
  </div>
  <div class="button" (click)="close(true)">
    {{ buttonText }}
  </div>
  <div class="button-no-bg" (click)="close(false)">Back to log in</div>
</div>
