<div class="main-container">
  <div class="container">
    <video #scanner></video>
  </div>

  <!-- <div class="reset" *ngIf="isMobile" (click)="switchCam()">
    <img src="../../../../assets//images/reset.png" alt="" />
    <span>Switch</span>
  </div> -->
  <p>{{ status }}</p>
</div>
<img
  src="../../../../assets/images/template-icons/close.svg"
  alt=""
  class="header__close_img"
  (click)="close()" />
<!-- Loading -->

<!-- start -->
