import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.sass'],
})
export class ColorPickerComponent implements OnInit, AfterContentInit {
  hue: string = '';

  @Input()
  color: string = '';

  @Output('changes')
  colorChange: EventEmitter<string> = new EventEmitter(true);

  opened: boolean = false;
  colorStyle: string = '';
  colorText: string = '';
  constructor(private changeDetectorRef: ChangeDetectorRef, private eRef: ElementRef) {}

  ngAfterContentInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.colorStyle = `--color: ${this.color};`;
    this.colorText = `${this.color}`
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
    this.colorChange.emit(this.color);
  }

  async updateColor(color: any) {
    // await new Promise((resolve) => setTimeout(resolve, 1000))
    this.colorStyle = `--color: ${color};`;
    this.color = color;
    this.colorText = `${color}`
    console.log({color})
    // this.hue = this.hexToHue(color)
    this.colorChange.emit(this.color);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.close()
    }
  }

//   hexToHue(hex: string){
//     let c: any;
//     if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
//         c= hex.substring(1).split('');
//         if(c.length== 3){
//             c= [c[0], c[0], c[1], c[1], c[2], c[2]];
//         }
//         c= '0x'+c.join('');
//         return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
//     }
//     throw new Error('Bad Hex');
// }
}
