<div class="user-groups">
  <app-table
    [title]="'Users'"
    [addButton]="'Add New User'"
    [columns]="userCols"
    [data]="users | async"
    (modal)="showModal($event)"
    [type]="'mUser'"
    [paginate]="true"
    [pageSize]="10"
  >
    <div class="tableSearch">
      <div class="search-form">
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            matInput
            type="text"
            placeholder="Input Your Text in Here"
            [(ngModel)]="searchQuery"
            (keydown.enter)="search()"
          />
          <span matPrefix
            ><img
              class="search-image"
              src="../../../assets/images/icons/search.png"
              alt=""
          /></span>
        </mat-form-field>
        <button type="submit" class="search-button" (click)="search()">
          Search
        </button>
      </div>
    </div>
  </app-table>
</div>
