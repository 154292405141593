<div class="ipad-file-folders">
  <div class="title-container">
    <h4 class="title">Folders and Files</h4>
    <button (click)="showModal.emit({dialog, type, breadcrumb})">
      Create Folder
    </button>
  </div>
  <div class="bread-crumbs" *ngIf="breadcrumb.length !== 0">
    <app-breadcrumb
      [data]="breadcrumb"
      (onBack)="breadcrumbBack($event)"
    ></app-breadcrumb>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="table">
    <ng-container matColumnDef="select"> </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let file" (click)="nameFunction(file)">
        <div class="file-content">
          <p class="file-name">{{ file.title }}</p>
          <p class="content">{{ file.size }} | {{ file.date_modified }}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ocr">
      <th mat-header-cell *matHeaderCellDef>OCR/Index</th>
      <td mat-cell *matCellDef="let file">
        <div class="file-ocr-index">
          <img
            class="ocr"
            [src]="
              file.ocr
                ? '../../../assets/images/icons/ocred.svg'
                : '../../../assets/images/icons/ocr-not.svg'
            "
          />
          <img
            class="index"
            [src]="
              file.indexed
                ? '../../../assets/images/icons/indexed.svg'
                : '../../../assets/images/icons/index-not.svg'
            "
          />
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let file">
        <app-actions
          [actions]="file.actions"
          [type]="type"
          [data]="file"
          [callback]="callback"
          [disable]="file['workflow']"
          (modifyFileEvent)="modifyFileEvent($event)"
        ></app-actions>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      (click)="rowFunction($event, element)"
      *matRowDef="let element; columns: displayedColumns"
    ></tr>
  </table>
</div>
