import { Component, EventEmitter, Inject, OnInit, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { AuthenticationService } from "src/app/@shared/services/authentication.service"
import { environment } from "src/environments/environment"
import { AccountComponent } from "../menu/account/account.component"
import { NavigationService } from "src/app/@shared/services/navigation.service"
import { Subject, Subscription, catchError, filter, of, takeUntil, throwError } from "rxjs"
import { MatDialog } from "@angular/material/dialog"
import { ModalComponent } from "src/app/components/modal/modal.component"
import { FilesService } from "src/app/@shared/services/files.service"
import { SignSecureService } from "src/app/@shared/services/signsecure.service"
import { FileActionService } from "src/app/@shared/services/file-action.service"
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { OtpDialogComponent } from 'src/app/@core/otp-dialog/otp-dialog.component';

import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.sass"],
})
export class SignInComponent implements OnInit, OnDestroy {
  @Output() navigatePage = new EventEmitter<string>()

  readonly regFlag = environment.flag.registration

  loginDisplay = false;

  screen: string = ""
  hide: boolean = true
  verification: boolean = false
  rememberMe: boolean = false
  ADLogin: boolean = false


  loginForm: FormGroup = this.fb.group({
    username: ["", [Validators.required]],
    password: ["", [Validators.required]],
  })
  errorMsg: string = ""
  private readonly unsubscribed = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private fb: FormBuilder,
    private _authService: AuthenticationService,
    private _navigation: NavigationService,
    private _workflow: SignSecureService,
    private _actions: FileActionService,
    public dialog: MatDialog,
    private _safeFormService: SafeformService,
    private _forms: SafeformService

  ) { }

  ngOnInit(): void {
    if (localStorage.length > 0) {
      this.loginForm.controls["username"].setValue(
        localStorage.getItem("username")
      )
      this.loginForm.controls["password"].setValue(
        localStorage.getItem("password")
      )
      this.rememberMe ? localStorage.getItem("remember") === "true" : false
    }


    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        let accessToken = payload.account;
        let fullname = accessToken?.name ?? "",
          username = accessToken?.username ?? ""
        let name = fullname.split(" ", 2)
        this.signInAD(name[0], name[1], username)
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
  }

  ngOnDestroy(): void {
    this.unsubscribed.next(undefined);
    this.unsubscribed.complete();
  }

  login(): void {
    if (this.loginForm.valid) {
      this._authService
        .login(
          this.loginForm.controls["username"].value,
          this.loginForm.controls["password"].value
        )
        .pipe(
          catchError((err) => {
            if (err.status == 401) {
              this.errorMsg = err.error.error
              this.errorMsg = this.errorMsg.slice(15)
            }
            return of()
          })
        )
        .subscribe((i) => {
          const redirect_url = this._navigation.getLocationParam()
          if (i.success == true) {
            this._navigation.lastKnownLocation = "report"
            this._authService.authenticateUser(i.data.accessToken)
            localStorage.setItem("refreshToken", i.data.refreshToken)
            sessionStorage.setItem('added', 'added')
            localStorage.setItem('tabs', '1')
            this._authService.rememberMe(
              this.loginForm.controls["username"].value,
              this.loginForm.controls["password"].value,
              this.rememberMe
            )
            this._authService.setRole(this._authService.userRole)
            localStorage.setItem("sTime", "3600")
            if (redirect_url != null && redirect_url.includes("my-safeforms")) {
              setTimeout(() => {
                const userID = localStorage.getItem("userID")
                window.location.href =
                  redirect_url + `?token=${i.data.accessToken}&userID=${userID}`
              }, 1000)
            }
          }
        })
    }
  }
  async signInAD(fname: string, lname: string, username: string) {
    this._authService
      .loginAzureAD(fname, lname, username, "test", "test")
      .pipe(
        catchError((err) => {
          this.ADLogin = true
          this.logoutAD()
          if (err.status == 401) {
            this.errorMsg = err.error.error
            this.errorMsg = this.errorMsg.slice(15)
          }
          return of()
        })
      )
      .subscribe((i) => {
        if (i.success == true) {
          this._navigation.lastKnownLocation = "report"
          this._authService.authenticateUser(i.data.accessToken)
          sessionStorage.setItem("refreshToken", i.data.refreshToken)
          sessionStorage.setItem('added', 'added')
          localStorage.setItem('tabs', '1')
          this._authService.setRole(this._authService.userRole)
        }
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  async loginAD(): Promise<void> {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logoutAD(): void {
    this.authService.logoutRedirect();
  }

  goTo(data: string): void {
    this.navigatePage.emit(data)
  }

  scanQR() {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: "722px",
      data: {
        action: "scan-qr",
      },
    })

    dialogRef?.afterClosed()?.subscribe((data) => {
      if (!data) return

      let id = null;
      const paramNames = data.paramNames[0];
      let action = '';

      if (paramNames === 'fm' || paramNames === 'ce') {
        id = data._id
        action = 'scan-qr-success';
      } else {
        id = data.id
        if(data?.workflow) {
          action = data?.workflow?.privacyType !== "PUBLIC"  ? "scan-qr-restrict" : "scan-qr-success";
        } else {
          action =  data?.privacyType !== "PUBLIC" ? "scan-qr-restrict" : "scan-qr-success";
        }
      }

      if (id) {
        const dialogRef = this.dialog.open(ModalComponent, {
          width: "512px",
          data: {
            action,
            data,
          },
        })

        dialogRef?.afterClosed()?.subscribe((datum) => {
          if (datum) {
            
            if (paramNames === 'ce') {
              const files = data.filepath;
              this._safeFormService.viewerData = {
                pdfSrc: files[files.length - 1],
                prevLocation: 'Certificate',
                prev: 'certificates-table',
                name: `${data.fullName} - Certificate`,
              };
              this._navigation.updateLocation('formViewer');
            } else if (paramNames === 'fm') {
              this.dialog.open(ModalComponent, {
                width: "512px",
                data: {
                  action: 'otp',
                  data,
                },
              })
            }  else {
              if (!!!data.workflow) {
                if (data?.privacyType === "PUBLIC") {
                  console.log({public: data})
                  this._workflow.setPublicFile(data)
                } else {
                  this._navigation.lastKnownLocation = "report"
                  this._navigation.addParams("f", data?.id)
                  localStorage.setItem('paramNames', 'f')
                  localStorage.setItem('paramId', data?.id)
                  console.log("adding params", data.id)
                }

              } else if (data?.workflow?.privacyType === "PUBLIC" || data?.privacyType === "PUBLIC") {
                console.log({public: data})
                this._workflow.setPublicFile(data)
              } else {
                this._navigation.lastKnownLocation = "report"
                this._navigation.addParams("w", data?.id)
                localStorage.setItem('paramNames', 'w')
                localStorage.setItem('paramId', data?.id)
                
              }
            }
          }
        })
      } else {
        this.dialog.open(ModalComponent, {
          width: "512px",
          data: {
            action: "scan-qr-fails",
          },
        })
      }
    })
  }
}
