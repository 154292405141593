<ng-container>
  <div class="file-share">
    <span class="close-button"><img style="cursor:pointer" src="../../../../assets/images/close.svg" (click)="closeModal()"></span>
    <h4 class="title">
      Share {{ data.fileType || "file" }} "{{ data.title }}"
    </h4>
    <div class="content">
      <h2 class="sub-title">Share to email</h2>
      <div class="input-group">
        <mat-label class="label">Email</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            type="email"
            placeholder="jdoe@email.com"
            matInput
            [(ngModel)]="userEmail"
            [formControl]="userListControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)='addUser($event.option.value)'>
            <mat-option
              *ngFor="let option of userlist | async"
              [value]="option"
              (click)="addUser(option)"
            >
              {{ option.email }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="members">
        <div
          class="member-container"
          *ngFor="let member of groupMembers; let i = index"
        >
          <p class="member">
            {{ member.name }}
            <span class="additional">{{ member.email }}</span>
          </p>
          <mat-select
            placeholder="Viewer"
            panelClass="user-group-select"
            (selectionChange)="updateMemberPermission($event, member)"
            [(value)]="member.role"
          >
            <mat-option [value]="'VIEWER'">Viewer</mat-option>
            <mat-option [value]="'EDITOR'">Editor</mat-option>
            <mat-option [value]="'remove'" (click)="removeUser(i)"
              >Remove Access</mat-option
            >
          </mat-select>
        </div>
      </div>
      <h2 class="sub-title">Share to group</h2>
      <div class="input-group">
        <mat-label class="label">User Group</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <mat-select
            placeholder="Group"
            panelClass="user-group-select"
            (selectionChange)="addUserGroup($event)"
          >
            <mat-option [value]="group" *ngFor="let group of usergrouplist">
              {{ group.group_name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="members">
        <div
          class="member-container"
          *ngFor="let member of userGroups; let i = index"
        >
          <p class="member">
            {{ member.name }}
            <span class="additional"
              >{{ member["list_of_members"] }} members</span
            >
          </p>
          <mat-select
            placeholder="Viewer"
            panelClass="user-group-select"
            (selectionChange)="updateMemberPermission($event, member)"
            [(value)]="member.role"
          >
            <mat-option [value]="'VIEWER'">Viewer</mat-option>
            <mat-option [value]="'EDITOR'">Editor</mat-option>
            <mat-option [value]="'remove'" (click)="removeGroup(i)"
              >Remove Access</mat-option
            >
          </mat-select>
        </div>
      </div>
      <div class="share-checkbox" >
        <mat-checkbox [(ngModel)]="shareToAnyone" (change)="checkPublic()"></mat-checkbox>
        <span >
          <p class="shareHeader">Share document to anyone with a link</p>
          <p class="shareSubHeader">Anyone on the internet with a link can view the file</p>
        </span>
      </div>
      <div class="actions copy {{ data.fileType || 'file' }}">
        <button class="copy-link" (click)="copyLink()">Copy Link</button>
        <button class="create" (click)="save()">Save</button>
      </div>
    </div>
  </div>
</ng-container>
