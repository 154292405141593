import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import {
  forkJoin,
  Subject,
  Subscription,
  from,
  of,
  BehaviorSubject,
} from 'rxjs';
import {
  map,
  mergeMap,
  takeUntil,
  delay,
  bufferCount,
  tap,
  concatMap,
  catchError,
  finalize,
} from 'rxjs/operators';

import moment from 'moment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import {
  Date_FORMAT,
  NAME_FORMAT,
  Roll_FORMAT,
} from 'src/app/@shared/constants/regex-paterns';
import { BulkFormsService } from 'src/app/@shared/services/bulk-forms.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UserResponseModel } from 'src/app/@shared/models/user-response.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
@Component({
  selector: 'app-bulk-certificate-template',
  templateUrl: './bulk-certificate-template.component.html',
  styleUrls: ['./bulk-certificate-template.component.sass'],
})
export class BulkCertificateTemplateComponent implements OnInit {
  columns: string[] = [
    'batch name',
    'name of uploader',
    'no of certificates',
    'upload date',
  ];
  excelToJSON: any = [];
  private bulkCert = new BehaviorSubject<any>([]);
  bulkCert$ = this.bulkCert.asObservable();
  //myFormGroup: FormGroup = null;
  formTemplate: any;
  template: string = ``;
  orientation: string = ``;
  blankFileUrl = '';
  source = '';
  documentTitle = '';
  totalPage: number = 1;
  totalStep: number = 1;
  isLoading = false;
  isFileEmpty: boolean = false;
  isClerkSigEmpty: boolean = false;
  isChiefSigEmpty: boolean = false;
  batchId: any;
  dialogRef?: MatDialogRef<any>;
  public author: any;
  totalDataToUpload = 0;
  inProgress = 0;
  total: number = 0;
  progress = 0;
  uploadFileName: any;
  teamId: any;
  latestTeam: any;
  selectedTeam: any;
  teamOwnerId: any;
  teamStorage: any;
  showMyTeam = false;
  isFile: boolean = true;
  isRoll: boolean = true;
  isRollExist: boolean = true;
  isRollDuplicate: boolean = true;
  isFullname: boolean = true;
  isDateAdmitted: boolean = true;
  isDateAttestation: boolean = true;
  isFileReady: boolean = true;
  isClerkName: boolean = true;
  isChiefName: boolean = true;
  teamSub: any;
  isEmailIndex: any = [];
  isFullnameIndex: any = [];
  isRollIndex: any = [];
  isRollExistIndex: any = [];
  isDateAdmittedIndex: any = [];
  isClerkNameIndex: any = [];
  isChiefNameIndex: any = [];
  content: { [key: string]: any } = {};
  fileHeader: any = ['Roll Number', 'Fullname', 'Chief Name',
    'Clerk Name', 'Date Admitted', 'Date of Attestation'];
  checkFormat: boolean = false;
  fileName: any;
  isSharedToTeam = false;
  documentData = {
    title: '',
    type: 'application/pdf',
    ext: 'pdf',
  };
  BulkCertData: any = [];
  message: string = '';
  userData: any;
  chiefSign: any;
  clerkSign: any;
  RollNumberValidatorMessage: string = ''
  RollNumberDuplicateMessage: string = ''
  private ngUnsubscribe = new Subject<void>();

  @ViewChild('formFile')
  formFile!: ElementRef;

  @ViewChild('clerkSig')
  clerkSig!: ElementRef;

  @ViewChild('chiefSig')
  chiefSig!: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    public bulkFormService: BulkFormsService,
    private authService: AuthenticationService,
    private _navigation: NavigationService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    const token = localStorage.getItem('token');
    let tokenPayload: UserResponseModel;
    if (token != null) {
      tokenPayload = this.authService.getUserDataFromToken(token);
      this.userData = tokenPayload.user;
    }
    this.getBulkCertificate();

    console.log('UserData', this.userData);
  }

  onFileChange(event: any) {
    try {
      this.isRoll = true;
      this.isFullname = true;
      this.isDateAdmitted = true;
      this.isDateAttestation = true;
      this.isFileReady = true;
      this.isChiefName = true;
      this.isClerkName = true;
      this.checkFormat = true;
      this.isRollExist = true;
      this.isRollDuplicate = true;
      this.isRollExistIndex = [];
      const reader: FileReader = new FileReader();
      const target: DataTransfer = <DataTransfer>event.target;
      reader.readAsBinaryString(target.files[0]);
      this.uploadFileName = target.files[0].name;
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        for (var i = 0; i < wb.SheetNames.length; ++i) {
          const wsname: string = wb.SheetNames[i];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { raw: false, defval: "" }); // to get 2d array pass 2nd parameter as object {header: 1}
          this.excelToJSON[`sheet${i + 1}`] = data;
          // console.log("json", this.excelToJSON)
          let fileUploadHeader = Object.keys(this.excelToJSON[`sheet1`][0]);
          this.checkFormat = this.fileHeader.every(function (element: any, index: any) {
            return element === fileUploadHeader[index]
          })
          console.log("this.checkFormat", this.checkFormat)
          this.isFileEmpty = this.checkFormat
          this.isFileReady = this.checkFormat
          if (!this.checkFormat) {
            this.resetInputs()
          }
        }

        console.log('finalOUT', this.excelToJSON);
        this.checkDuplicateRollNumber(this.excelToJSON['sheet1']);
        this.checkRollNumber(this.excelToJSON['sheet1']);
      };
    } catch (error) {
      this.isFileEmpty = false;
    }
  }


  async bulkRegister() {
    this.isFileReady = true;

    // this.firebaseService.getUserTeamLatest().subscribe(res => {
    //   this.teamId = res[0].teamId
    // })
    this.isLoading = true;
    const jsonData = this.excelToJSON['sheet1'];
    this.totalDataToUpload = jsonData.length;
    // this.dialogRef = this.dialog.open(ModalComponent, {
    //   minWidth: 600,
    //   maxWidth: '95vw',
    //   minHeight: 500,
    //   data: {
    //     type: 'creating-document',
    //     user: 'user',//JSON.parse(localStorage.getItem('user')),
    //     totalDataToUpload: this.totalDataToUpload,
    //     inprogressUpload: this.inProgress,
    //   },
    //   autoFocus: false,
    //   disableClose: true
    // });
    this.isEmailIndex = [];
    this.isFullnameIndex = [];
    this.isRollIndex = [];
    this.isDateAdmittedIndex = [];
    this.isClerkNameIndex = [];
    this.isChiefNameIndex = [];
    this.isRollExistIndex = [];
    let errCounter: number = 0;

    let checkData = jsonData.map((validate: any, index: number) => {
      // console.log('roll',validate['Roll Number'])
      // console.log('name',validate['Fullname'])
      // console.log('admitted',validate['Date Admitted'])
      // console.log('attestation',validate['Date of Attestation'])
      // console.log('-------------------------------------------')
      // console.log('roll',Roll_FORMAT.test(validate['Roll Number']))
      // console.log('name',NAME_FORMAT.test(validate['Fullname']))
      // console.log('admitted',Date_FORMAT.test(validate['Date Admitted']))
      // console.log('attestation',Date_FORMAT.test(validate['Date of Attestation']))
      // console.log('-------------------------------------------')
      if (this.isRoll == true) {
        if (Roll_FORMAT.test(validate['Roll Number']) == false) {
          errCounter = index + 2;
          this.isRollIndex.push(errCounter);
          this.RollNumberValidatorMessage = 'a value in Roll Number is invalid';
          this.isRoll = false;
        } else {
          this.isRoll = true;
          this.resetInputs()
        }
      }
      if (this.isFullname == true) {
        if (validate['Fullname'] == undefined) {
          errCounter = index + 2;
          this.isFullnameIndex.push(errCounter);
          this.isFullname = false;
        } else {
          if (NAME_FORMAT.test(validate['Fullname']) == false) {
            errCounter = index + 2;
            this.isFullnameIndex.push(errCounter);
            this.isFullname = false;
          } else {
            this.isFullname = true;
            this.resetInputs()
          }
        }
      }
      if (this.isClerkName == true) {
        if (validate['Clerk Name'] == undefined) {
          errCounter = index + 2;
          this.isClerkNameIndex.push(errCounter);
          this.isClerkName = false;
        } else {
          if (NAME_FORMAT.test(validate['Clerk Name']) == false) {
            errCounter = index + 2;
            this.isClerkNameIndex.push(errCounter);
            this.isClerkName = false;
          } else {
            this.isClerkName = true;
            this.resetInputs()
          }
        }
      }
      if (this.isChiefName == true) {
        if (validate['Chief Name'] == undefined) {
          errCounter = index + 2;
          this.isChiefNameIndex.push(errCounter);
          this.isChiefName = false;
        } else {
          if (NAME_FORMAT.test(validate['Chief Name']) == false) {
            errCounter = index + 2;
            this.isChiefNameIndex.push(errCounter);
            this.isChiefName = false;
          } else {
            this.isChiefName = true;
            this.resetInputs()
          }
        }
      }
      if (this.isDateAdmitted == true) {
        if (validate['Date Admitted'] == undefined) {
          errCounter = index + 2;
          this.isDateAdmittedIndex.push(errCounter);
          this.isDateAdmitted = false;
        } else {
          if (Date_FORMAT.test(validate['Date Admitted']) == false) {
            errCounter = index + 2;

            this.isDateAdmittedIndex.push(errCounter);
            this.isDateAdmitted = false;
          } else {
            this.isDateAdmitted = true;
            this.resetInputs()
          }
        }
      }
      // if(this.isDateAttestation == true){
      //   if (Date_FORMAT.test(validate['Date of Attestation']) == false) {
      //     this.isDateAttestation = false;
      //   } else {
      //     this.isDateAttestation = true;
      //   }
      // }
    });

    if (this.isRoll == false ||
      this.isFullname == false ||
      this.isDateAdmitted == false ||
      this.isDateAttestation == false ||
      this.isChiefName == false ||
      this.isClerkName == false ||
      this.isRollExist == false ||
      this.isRollDuplicate == false
    ) {
      this.isFile = false;
    } else {
      this.isFile = true;
    }

    if (this.isFile) {
      console.log('Content signature ', this.content);

      /// need confirmation from mark
      const dateToday = new Date();
      const dateString = moment(dateToday).format('MM/DD/yyyy').toString();
      const formData = new FormData();

      formData.append(
        `files`,
        new File([this.content['clerkSigUpload']], 'clerkSigUpload.png', {
          type: 'image/png',
        })
      );
      formData.append(
        `files`,
        new File([this.content['chiefSigUpload']], 'chiefSigUpload.png', {
          type: 'image/png',
        })
      );

      jsonData.forEach((data: any, index: number) => {

        formData.append(
          `certificates[${index}][rollNumber]`,
          data['Roll Number']
        );
        formData.append(`certificates[${index}][fullName]`, data['Fullname']);
        formData.append(
          `certificates[${index}][dateAdmitted]`,
          data['Date Admitted']
        );
        formData.append(
          `certificates[${index}][dateOfAttestation]`,
          dateString
        );
        formData.append(`certificates[${index}][clerkSignature]`, '');
        formData.append(`certificates[${index}][chiefSignature]`, '');
        formData.append(
          `certificates[${index}][chiefName]`,
          data['Chief Name']
        );
        formData.append(
          `certificates[${index}][clerkName]`,
          data['Clerk Name']
        );
        formData.append(`certificates[${index}][isFileGenerated]`, 'false');
        formData.append(`certificates[${index}][filepath]`, '');
        formData.append(`certificates[${index}][qrID]`, self.crypto.randomUUID())
      });
      // let data = jsonData.map((x: any) => {
      //   return {
      //     rollNumber: parseInt(x['Roll Number']),
      //     fullName: x['Fullname'],
      //     dateAdmitted: x['Date Admitted'], //moment(x['Date Admitted']).format("Do [day of] MMMM, YYYY").toString(),
      //     dateOfAttestation: moment(dateToday).format('MM/DD/yyyy').toString(), //moment(new Date()).format("Do [day of] MMMM, YYYY").toString(),
      //     clerkSignature: '', //this.content['clerkSigUpload'],
      //     chiefSignature: '', //this.content['chiefSigUpload'],
      //     chiefName: x['Fullname'],
      //     clerkName: x['Fullname'],
      //     // originalDateAdmitted: x['Date Admitted'],
      //     // originalDateAttestation: moment(new Date()).format('yyyy-MM-DD'),
      //     isFileGenerated: false,
      //     filepath: null,
      //   };
      // });
      // console.log(data)
      // let responses = []

      let responses: any = [];

      // console.log('data', data);
      let uuid = self.crypto.randomUUID();
      // const batchInfo = {
      //   batchId: uuid,
      //   batchName: this.uploadFileName,
      //   uploaderName: this.userData.givenName + ' ' + this.userData.lastName,
      //   teamId: '',
      //   certificates: data,
      //   clerkSignature: this.content['clerkSigUpload'],
      //   chiefSignature: this.content['chiefSigUpload'],
      // };

      formData.append('batchId', uuid);
      formData.append('batchName', this.uploadFileName);
      formData.append(
        'uploaderName',
        this.userData.givenName + ' ' + this.userData.lastName
      );
      formData.append('teamId', '');
      formData.append('clerkSignature', '');
      formData.append('chiefSignature', '');

      this.bulkFormService.bulkUploadCertificate(formData).subscribe({
        next: value => console.log(value),
        error: err => console.log(err),
        complete: () => {
          this.isLoading = false;
          console.log('bulkFormService complete ');
          this.getBulkCertificate();

          this.dialog.closeAll();
        },
      });
    } else {
      this.isLoading = false;
      // setTimeout(() => {
      if (this.isRoll == false ||
        this.isFullname == false ||
        this.isDateAdmitted == false ||
        this.isDateAttestation == false ||
        this.isChiefName == false ||
        this.isClerkName == false ||
        this.isRollExist == false ||
        this.isRollDuplicate == false
      ) {
        this.isFileReady = false;
        this.dialog.closeAll();
      } else {
        this.isFileReady = true;
      }
      // }, 3000);
    }

    //this.isLoading = false;

    // console.log(responses)
    // const batchId = this.afs.createId()
    // const batchInfo = {
    //   batchID: batchId,
    //   nameOfUploader: this.author.name,
    //   noOfCertificates: jsonData.length,
    //   uploadDate: new Date()
    // }

    // // await this.formService.saveBatchInfo(batchInfo, environment.batchPath )
    // this.isLoading = false
    // this.router.navigate([`dashboard/certificates`]);
    // console.log('Completed')
  }

  checkRollNumber(data: any) {
    data.map((item: any, index: number) => {
      let rollNumber = item["Roll Number"];
      this.bulkFormService.checkRollNumber(rollNumber).subscribe({
        next: (value) => {
          if (value) {
            this.isRollExist = true;
          } else {
            this.isRollExistIndex.push(index + 1);
            this.RollNumberValidatorMessage = `Roll Number ${rollNumber} already exists`;
            this.isRollExist = false;
            this.isFile = false;
          }
        },
        error: err => { console.log("checkRollNumber err", err) },
      });
    });

  }

  checkDuplicateRollNumber(data: any) {
    const uniqueValues = new Set(data.map((v: any, index: number) => v["Roll Number"]));
    if (uniqueValues.size < data.length) {
      this.RollNumberDuplicateMessage = `Roll Number contains duplicate entries`;
      this.isRollDuplicate = false;
      this.isFile = false;
      console.log('duplicates found');
      this.isFileReady = false;
    }
  }

  setChiefSigUploadSignature(event: any) {
    const signatureValue = event.target.files[0];

    if (signatureValue != undefined || null) {
      this.isChiefSigEmpty = true;
      const file = signatureValue;
      this.content['chiefSigUpload'] = file;
    } else {
      this.isChiefSigEmpty = false;
    }
  }

  setClerkSigUploadSignature(event: any) {
    const signatureValue = event.target.files[0];
    console.log('signatureValue', signatureValue);
    if (signatureValue != undefined || null) {
      this.isClerkSigEmpty = true;
      const file = signatureValue;
      this.content['clerkSigUpload'] = file;
    } else {
      this.isClerkSigEmpty = false;
    }
  }

  formatBulkCertData(data: any) {
    return data
      .map((item: any) => {
        const formattedFile = {
          id: item._id,
          certificates: item.certificates,
          batch_name: item.batchName || '',
          name_of_uploader: item.uploaderName || item.createdBy || '',
          no_of_certificates: item.certificates.length || 0,
          upload_date:
            moment(item.createdAt).format('yyyy-MM-DD HH:mm:ss') || '',
          clerkSignature: item.clerkSignature,
          chiefSignature: item.chiefSignature,
        };

        return formattedFile;
      })
      .reverse();
  }
  getBulkCertificate(wait: boolean = false) {
    this.bulkFormService.getAllBulkCertificate().subscribe((res: any) => {
      if (res) {
        this.setBulkCert(this.formatBulkCertData(res.data));
      }
      this.resetInputs()
    });
  }
  setText(percent: any) {
    this.progress = 0;
  }
  selectedRow(event: any, data: any) {
    if (event.target.className.includes('checkbox')) {
      return;
    }

    console.log('tabledata', data);
    localStorage.setItem('bulkCert', data.id);
    this._navigation.updateLocation('bulk-certificate-table');
  }

  setBulkCert(data: any[]) {
    this.total = data.length || 0;
    this.bulkCert.next(data);
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  bulkCancel() {
    this.router.navigate(['/dashboard/certificates']);
  }
  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }

  resetInputs() {
    this.formFile.nativeElement.value = null
    this.chiefSig.nativeElement.value = null
    this.clerkSig.nativeElement.value = null
  }
}
