import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DomSanitizer,
  SafeHtml,
  SafeValue,
  SafeStyle,
  SafeScript,
  SafeUrl,
} from '@angular/platform-browser';
import moment from 'moment';
import Mustache from 'mustache';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { catchError, lastValueFrom } from 'rxjs';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { environment } from 'src/environments/environment';
import { RollNumberValidator } from 'src/app/@shared/validators/roll-number-checker.validator';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { QrService } from 'src/app/@shared/services/qr.service';

const ZOOM_STEP: number = 10;
const DEFAULT_ZOOM: number = 30;
const DEFAULT_ZOOM_DECIMAL: number = 0.3;
const MAX_ZOOM_IN: number = 150;
const MAX_ZOOM_OUT: number = 30;

@Component({
  selector: 'app-safe-form',
  templateUrl: './safe-form.component.html',
  styleUrls: ['./safe-form.component.sass'],
})
export class SafeFormComponent implements OnInit {
  templateDetails: any;
  isLoading = false;
  progress = 0;
  myFormGroup!: FormGroup;
  formTemplate: any;
  currentStep = 1;
  totalStep = 1;
  currentPage = 1;
  totalPage = 1;
  templateTitle: string = '';
  zoomDefaultValue = 1;
  pageZoomDecimal = 1;
  pageZoom = DEFAULT_ZOOM;
  maxZoom = MAX_ZOOM_IN;
  showMyTeam = false;
  isSharedToTeam = false;
  servicesDescriptionList: any;
  serviceListName: any = [];
  template = ``;
  isSubmitting = false;
  readonly clerkName = this._safeFormService.clerkName;
  readonly chiefName = this._safeFormService.chiefName;
  scLogo = '../../../assets/images/sc-images/sc_logo.png';
  blankSignature = '../../../assets/images/white-bg-signature.jpg'
  scCrown = '../../../assets/images/sc-images/SC_Header.JPG';
  isRollToEdit: boolean = true;
  regexPattern = new RegExp('^(\s+\S+\s*)*(?!\s).*$');

  @ViewChild('formContent', { static: true }) formContent!: ElementRef;

  content: { [key: string]: any } = {
    parties: '456',
    contractDate: 'Hello World!',
    companyName: 'Oh my god!',
    companyLocation: 'Yeah!',
  };

  errors: any = {}

  constructor(
    private _safeFormService: SafeformService,
    private _navigation: NavigationService,
    private _sign: SignSecureService,
    private _load: LoaderService,
    private _qr: QrService
  ) { }

  
  ngOnInit() {
    this.templateTitle = this._safeFormService.formTemplateTitle;

    if(this.templateTitle === this._safeFormService.scId) {
      this.content['logoSrc'] = '../../assets/images/sc_logo.png';
    }

    this._safeFormService.certificateId !== ''
      ? this.editCertificateForm()
      : this.proceedToNormalFillup();
  }

  async editCertificateForm() {
    const qrCodeText = `${window.location.href}?ce=${this._safeFormService.certificateQrID}`;
    const qrCodeDataImage = await this._qr.getQRCode(qrCodeText);
    this.isRollToEdit = false;
    this.templateDetails =
      this._safeFormService.forEditCertificateTemplate.find((template: any) =>
        template?.documentTitle
          ?.toLowerCase()
          .includes(this.templateTitle.toLowerCase())
      );

    let group = {};
    this.formTemplate = this.templateDetails.formTemplate;
    this.totalStep = this.templateDetails.totalStep;
    this.totalPage = this.templateDetails.totalPages;
    this.templateTitle = this.templateDetails.documentTitle;
    this.formTemplate.forEach((input_template: any) => {
      // @ts-ignore
      group[input_template.formControl] = new FormControl('', [
        Validators.required,
        Validators.pattern(this.regexPattern),
      ]);
      this.content[input_template.formControl] = '____________';
      this.errors[input_template.formControl] = {}
    });
    this.content['qrCode'] = qrCodeDataImage;
    this.template = Mustache.render(this.templateDetails.html, this.content);
    this.myFormGroup = new FormGroup(group);

    this.populateCertificateFields();
  }

  populateCertificateFields() {
    this._safeFormService.getCertificate().subscribe(async (res: any) => {
      let certValue = res['data'];

      this.content['certificateName'] = certValue.fullName;
      this.content['rollNumber'] = certValue.rollNumber;
      this.content['dateAdmitted'] = certValue.dateAdmitted
        ? moment(certValue.dateAdmitted)
          .format('Do [day of] MMMM, YYYY')
          .toString()
        : certValue.dateAdmitted;
      this.content['dateAttestation'] = certValue.dateAttestation;
      this.content['clerkName'] = certValue.clerkName;
      this.content['chiefName'] = certValue.chiefName;
      // this.content['clerkSigUpload'] = `./assets${certValue.clerkSignature}`;
      // this.content['chiefSigUpload'] = `./assets${certValue.chiefSignature}`;
      this.content["clerkSigUpload"] = this.blankSignature
      this.content["chiefSigUpload"] = this.blankSignature

      this.myFormGroup.value['certificateName'] =
        this.content['certificateName'];
      this.myFormGroup.value['rollNumber'] = this.content['rollNumber'];
      this.myFormGroup.value['dateAdmitted'] = this.content['dateAdmitted'];
      this.myFormGroup.value['dateAttestation'] =
        this.content['dateAttestation'];
      this.myFormGroup.value['clerkName'] = this.content['clerkName'];
      this.myFormGroup.value['chiefName'] = this.content['chiefName'];

      this.myFormGroup
        .get('certificateName')
        ?.setValue(this.content['certificateName']);
      this.myFormGroup.get('rollNumber')?.setValue(this.content['rollNumber']);
      this.myFormGroup
        .get('dateAdmitted')
        ?.setValue(this.content['dateAdmitted']);
      this.myFormGroup.get('clerkName')?.setValue(this.content['clerkName']);
      this.myFormGroup.get('chiefName')?.setValue(this.content['chiefName']);

      this.template = Mustache.render(this.templateDetails.html, this.content);
    });
  }

  proceedToNormalFillup() {
    const uuid = self.crypto.randomUUID();
    this._safeFormService.getSafeForm().subscribe(async (res: any) => {
      console.log('ress', res)
      let group: any = {};
      this.templateDetails = res['data'];
      this.formTemplate = this.templateDetails.formTemplate;
      this.totalStep = this.templateDetails.totalStep;
      this.totalPage = this.templateDetails.totalPages;
      this.templateTitle = this.templateDetails.documentTitle;
      console.log({ formTemplate: this.templateDetails.html });
      this.formTemplate.forEach((input_template: any) => {
        console.log('input_template', input_template)
        if (input_template.formControl === 'rollNumber') {
          console.log('this.regexPattern', this.regexPattern)
          // @ts-ignore
          group[input_template.formControl] = new FormControl(
            '',
            [Validators.required, Validators.pattern(this.regexPattern)],
            [RollNumberValidator.createValidator(this._safeFormService)]
          );
        } else {
          // @ts-ignore
          if (input_template.formControl === 'clerkName') {
            group[input_template.formControl] = new FormControl(this.clerkName, [
              Validators.required,
              Validators.pattern(this.regexPattern),
            ]);
          } else if (input_template.formControl === 'chiefName') {
            group[input_template.formControl] = new FormControl(this.chiefName, [
              Validators.required,
              Validators.pattern(this.regexPattern),
            ]);
          } else {
            group[input_template.formControl] = new FormControl('', [
              Validators.required,
              Validators.pattern(this.regexPattern),
            ]);
          }
        }

        this.content[input_template.formControl] = '____________';
      });

      // const qrCodeText = `${window.location.href}`;

      const qrCodeText = `${window.location.href}?ce=${uuid}`;
      const qrCodeDataImage = await this._qr.getQRCode(qrCodeText);
      this.content['qrCode'] = qrCodeDataImage
      // this.content['qrID'] = uuid
      this.content["clerkSigUpload"] = this.blankSignature
      this.content["chiefSigUpload"] = this.blankSignature
      this.template = Mustache.render(this.templateDetails.html, this.content);
      group['qrID'] = new FormControl(uuid);
      this.myFormGroup = new FormGroup(group);

      this.templateTitle == this._safeFormService.certificateTitle
        ? this.setClerkAndChiefName()
        : '';
      this.templateTitle == this._safeFormService.travelAuthTitle
        ? this.setTravelAuthPageBreaker()
        : '';
    });
  }

  setTravelAuthPageBreaker() {
    this.content['pageBreakerTravelAuth'] = '';
    this.content['pageBreakerOne'] = '';
    this.content['pageBreakerTwo'] = '';
    this.template = Mustache.render(this.templateDetails.html, this.content);
  }

  setClerkAndChiefName() {
    this.content['clerkName'] = this.clerkName;
    this.content['chiefName'] = this.chiefName;
    this.content['scLogo'] = this.scLogo;
    this.content['scCrown'] = this.scCrown;
    console.log(this.templateDetails.html);
    this.template = Mustache.render(this.templateDetails.html, this.content);
  }

  zoomIn() {
    if (this.zoomDefaultValue == 1.1) {
    } else {
      this.zoomDefaultValue += 0.01;
    }
  }

  zoomOut() {
    if (this.zoomDefaultValue == 0.97) {
    } else {
      this.zoomDefaultValue -= 0.01;
    }
  }

  public setText(e: any) {
    console.log(e);
    // this.content['PositionSupremeCourtId'] = this.content['companyName']
    this.progress = 0;
    for (let index = 0; index < this.totalPage; index++) {
      this.formTemplate.forEach((element: any) => {
        if (element.page === index) {
          console.log({element})
          if (
            element.type !== 'date' &&
            element.type !== 'dateGeneric' &&
            element.type !== 'dateAdmitted' &&
            element.type !== 'dateAttestation' &&
            element.type !== 'clerkSigUpload' &&
            element.type !== 'chiefSigUpload' &&
            element.type !== 'serviceInputFeild' &&
            element.type !== 'pageBreakerTextboxOne' &&
            element.type !== 'pageBreakerTextbox' &&
            element.type !== 'pageBreakerTextboxTwo' &&
            element.type !== 'rollNumber' &&
            element.type !== 'empSCNumber' &&
            !(element.type === 'textBox' && element.label === 'Roll Number')
          ) {
            this.progress =
              this.myFormGroup.value[element.formControl]?.trim() !== ''
                ? this.progress + element.percent
                : this.myFormGroup.value[element.formControl]?.trim().length ===
                  0
                  ? this.progress - 0
                  : this.progress - element.percent;
            this.content[element.formControl] =
              this.myFormGroup.value[element.formControl] !== ''
                ? this.myFormGroup.value[element.formControl]
                : '____________';
          } else if (element.type == 'repeat') {
            let repeatedValue = this.formTemplate.filter((data: any) =>
              element.formControl.includes(data.formControl)
            );
            this.myFormGroup
              .get(element.formControl)
              ?.setValue(this.myFormGroup.value[repeatedValue[0].formControl]);
            this.content[element.formControl] =
              this.myFormGroup.value[repeatedValue[0].formControl];
          } else {
            if (element.type !== 'serviceInputFeild') {
              this.progress =
              this.myFormGroup.value[element.formControl] !== ''
                ? this.progress + element.percent
                : this.myFormGroup.value[element.formControl].length === 0
                  ? this.progress - 0
                  : this.progress - element.percent;
            }

            if (element.type == 'date') {
              const date = moment(this.myFormGroup.value[element.formControl])
                .format('LL')
                .toString();
              this.content[element.formControl] = date;
            } else if (element.type == 'dateGeneric') {
              if (
                this.myFormGroup.get(element.formControl)?.value === '' ||
                this.myFormGroup.get(element.formControl)?.value ===
                '____________'
              ) {
                this.content[element.formControl] = '____________';
              } else {
                const date = moment(this.myFormGroup.value[element.formControl])
                  .format('LL')
                  .toString();
                this.content[element.formControl] = date;
              }
            } else if (element.type == 'dateAdmitted') {
              const dateAdmitted =
                this.myFormGroup.value[element.formControl] instanceof Date
                  ? moment(this.myFormGroup.value[element.formControl])
                    .format('Do [day of] MMMM, YYYY')
                    .toString()
                  : moment(this.myFormGroup.value[element.formControl])
                    .format('Do [day of] MMMM, YYYY')
                    .toString();
              this.content[element.formControl] = dateAdmitted;
            } else if (element.type === 'serviceInputFeild') {
              this.progress = this.progress + element.percent
              if (this.myFormGroup.get(element.formControl)?.value !== '') {
                if (e !== 'removeServiceList') {
                  if (
                    !this.serviceListName.includes(
                      `${this.myFormGroup.value[element.formControl]}` 
                    ) && this.serviceListName.length < 15
                  ) {
                    this.serviceListName.push(
                      `${this.myFormGroup.value[element.formControl]}`
                    );
                    this.servicesDescriptionList = [];
                    let eventNameCharCount =
                      this.myFormGroup.value['reasonOfTravelAuth'].length;
                    let countPersonEntry = this.serviceListName.length;
                    if (eventNameCharCount <= 100) {
                      if (countPersonEntry <= 15) {
                        for (
                          let index = 0;
                          index < this.serviceListName.length;
                          index++
                        ) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                      } else if (countPersonEntry > 15) {
                        for (let index = 0; index <= 14; index++) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                        this.servicesDescriptionList.push(
                          `\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n`
                        );
                        for (
                          let index = 15;
                          index < this.serviceListName.length;
                          index++
                        ) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                      }
                    } else if (
                      eventNameCharCount > 100 &&
                      eventNameCharCount <= 200
                    ) {
                      if (countPersonEntry <= 13) {
                        for (
                          let index = 0;
                          index < this.serviceListName.length;
                          index++
                        ) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                      } else if (countPersonEntry > 13) {
                        for (let index = 0; index <= 12; index++) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                        this.servicesDescriptionList.push(
                          `\n\n\n\n\n\n\n\n\n\n\n\n\n\n`
                        );
                        for (
                          let index = 13;
                          index < this.serviceListName.length;
                          index++
                        ) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                      }
                    } else if (eventNameCharCount > 200) {
                      if (countPersonEntry <= 10) {
                        for (
                          let index = 0;
                          index < this.serviceListName.length;
                          index++
                        ) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                      } else if (countPersonEntry > 10) {
                        for (let index = 0; index <= 9; index++) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                        this.servicesDescriptionList.push(
                          `\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n`
                        );
                        for (
                          let index = 10;
                          index < this.serviceListName.length;
                          index++
                        ) {
                          const element = this.serviceListName[index];
                          this.servicesDescriptionList.push(
                            `\n ${index + 1}. ${element}`
                          );
                        }
                      }
                    }
                    this.content[element.formControl] =
                      this.servicesDescriptionList.join('');
                  }
                } else {
                  for (
                    let index = 0;
                    index < this.serviceListName.length;
                    index++
                  ) {
                    this.servicesDescriptionList.splice(index);
                  }
                  let eventNameCharCount =
                    this.myFormGroup.value['reasonOfTravelAuth'].length;
                  let countPersonEntry = this.serviceListName.length;
                  if (eventNameCharCount <= 100) {
                    if (countPersonEntry <= 15) {
                      for (
                        let index = 0;
                        index < this.serviceListName.length;
                        index++
                      ) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                    } else {
                    }
                    if (countPersonEntry > 15) {
                      for (let index = 0; index <= 14; index++) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                      this.servicesDescriptionList.push(
                        `\n\n\n\n\n\n\n\n\n\n\n\n\n`
                      );
                      for (
                        let index = 15;
                        index < this.serviceListName.length;
                        index++
                      ) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                    }
                  }
                  if (eventNameCharCount > 100 && eventNameCharCount <= 200) {
                    if (countPersonEntry <= 13) {
                      for (
                        let index = 0;
                        index < this.serviceListName.length;
                        index++
                      ) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                    } else if (countPersonEntry > 13) {
                      for (let index = 0; index <= 12; index++) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                      this.servicesDescriptionList.push(
                        `\n\n\n\n\n\n\n\n\n\n\n\n\n\n`
                      );
                      for (
                        let index = 13;
                        index < this.serviceListName.length;
                        index++
                      ) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                    }
                  }
                  if (eventNameCharCount > 200) {
                    if (countPersonEntry <= 10) {
                      for (
                        let index = 0;
                        index < this.serviceListName.length;
                        index++
                      ) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                    } else if (countPersonEntry > 10) {
                      for (let index = 0; index <= 9; index++) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                      this.servicesDescriptionList.push(
                        `\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n`
                      );
                      for (
                        let index = 10;
                        index < this.serviceListName.length;
                        index++
                      ) {
                        const element = this.serviceListName[index];
                        this.servicesDescriptionList.push(
                          `\n ${index + 1}. ${element}`
                        );
                      }
                    }
                  }
                  this.content[
                    element.formControl
                  ] = `${this.servicesDescriptionList.join('')}`;
                }
              }
            } else if (element.type === 'pageBreakerTextboxOne') {
              if (
                this.myFormGroup.get('pageBreakerTravelAuth')?.value ===
                'Page 1'
              ) {
                this.myFormGroup
                  .get(element.formControl)
                  ?.setValue('resetpage');
                this.content[element.formControl] = '';
              } else {
                this.myFormGroup.get(element.formControl)?.setValue('Page 1');
                this.content[element.formControl] = 'Page 1';
              }
            } else if (element.type === 'pageBreakerTextboxTwo') {
              if (
                this.myFormGroup.get('pageBreakerTravelAuth')?.value ===
                'Page 1'
              ) {
                this.myFormGroup.get(element.formControl)?.setValue('Page 2');
                this.content[element.formControl] = 'Page 2';
              } else {
                this.myFormGroup
                  .get(element.formControl)
                  ?.setValue('resetpage');
                this.content[element.formControl] = '';
              }
            } else if (element.type === 'pageBreakerTextbox') {
              if (
                this.myFormGroup.get(element.formControl)?.value !== 'Page 1'
              ) {
                this.myFormGroup.get('pageBreakerTwo')?.setValue('');
                this.content['pageBreakerTwo'] = '';
              }

              let eventNameCharCount =
                this.myFormGroup.value['reasonOfTravelAuth'].length;
              let countPersonEntry = this.serviceListName.length;
              if (eventNameCharCount <= 100) {
                if (countPersonEntry <= 15) {
                  this.myFormGroup
                    .get(element.formControl)
                    ?.setValue('dummy value');
                  this.content[element.formControl] = '';
                } else if (countPersonEntry > 15) {
                  this.myFormGroup.get(element.formControl)?.setValue('Page 1');
                  this.content[element.formControl] = 'Page 1';
                }
              }
              if (eventNameCharCount > 100 && eventNameCharCount <= 200) {
                if (countPersonEntry <= 13) {
                  this.myFormGroup
                    .get(element.formControl)
                    ?.setValue('dummy value');
                  this.content[element.formControl] = '';
                } else if (countPersonEntry > 13) {
                  this.myFormGroup.get(element.formControl)?.setValue('Page 1');
                  this.content[element.formControl] = 'Page 1';
                }
              }
              if (eventNameCharCount > 200) {
                if (countPersonEntry <= 10) {
                  this.myFormGroup
                    .get(element.formControl)
                    ?.setValue('dummy value');
                  this.content[element.formControl] = '';
                } else if (countPersonEntry > 10) {
                  this.myFormGroup.get(element.formControl)?.setValue('Page 1');
                  this.content[element.formControl] = 'Page 1';
                }
              }
            } else if (element.type === 'dateAttestation') {
              const dateAttestation = moment(new Date())
                .format('Do [day of] MMMM, YYYY')
                .toString();
              this.myFormGroup
                .get('dateAttestation')
                ?.setValue(moment(new Date()).format('yyyy-MM-DD'));
              this.content[element.formControl] = dateAttestation;
            } else if (
              element.type === 'rollNumber' ||
              (element.type === 'textBox' && element.label === 'Roll Number')
            ) {
              this.content[element.formControl] =
                this.myFormGroup.value[element.formControl] !== ''
                  ? this.myFormGroup.value[element.formControl]
                  : '____________';
            } else if (element.type === 'empSCNumber') {
              this.content[element.formControl] =
                this.myFormGroup.value[element.formControl] !== ''
                  ? this.myFormGroup.value[element.formControl]
                  : '____________';
            } else {
              if (e.target) {
                if (element.type === 'chiefSigUpload') {
                  if (e.target.dataset.name == 'chief') {
                    // @ts-ignore: Object is possibly 'null'.
                    const file = (e.target as HTMLInputElement).files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      this.content[element.formControl] = reader.result;
                      this.template = Mustache.render(
                        this.templateDetails.html,
                        this.content
                      );
                    };
                  }
                } else if (element.type === 'clerkSigUpload') {
                  if (e.target.dataset.name == 'clerk') {
                    // @ts-ignore: Object is possibly 'null'.
                    const file = (e.target as HTMLInputElement).files[0];
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      this.content[element.formControl] = reader.result;
                      this.template = Mustache.render(
                        this.templateDetails.html,
                        this.content
                      );
                    };
                  }
                }
              }
            }
          }
        }

        this.errors[element.formControl] = this.myFormGroup.get(element.formControl)?.errors ?? {}
      });


    }
    console.log(this.myFormGroup.get('rollNumber'))
    console.log({ content: this.content });
    this.template = Mustache.render(this.templateDetails.html, this.content);
  }

  removeServiceList(serviceDesc: any, event: any) {
    const index = this.serviceListName.indexOf(serviceDesc);
    if (index >= 0) {
      this.serviceListName.splice(index, 1);
      this.servicesDescriptionList.splice(index, 1);
    }

    this.setText(event);

    if (this.serviceListName.length === 0) {
      this.progress = this.progress - 10;
    }  
  }

  shareToTeam(e: any) {
    this.isSharedToTeam = e.checked;
  }

  async submitDocument() {
    this._load.show();
    this._safeFormService.formTemplateData = this.myFormGroup.value;
    const nativeElement = this.formContent.nativeElement;
    this._safeFormService.formTemplate = nativeElement;

    let pdf: any;
    switch (this.templateTitle) {
      case this._safeFormService.certificateTitle:
        nativeElement.classList.add('fullCertSize');
        this._safeFormService.formTemplate = nativeElement;
        this._safeFormService.addCertificate().subscribe(
          async (result) => {
            pdf = await this._safeFormService.generatePDF();
            this._safeFormService.addCertificateFile(result.data._id, pdf).subscribe(() => {
              if (!!result.data) {
                this._safeFormService.certificateId = '';
                this._safeFormService.ScId = '';
                this._load.hide();
                this._navigation.updateLocation('certificates-table');
              }
            })
          },
          error => {
            console.log('error', error);
          }
        );
        break;
      case this._safeFormService.scId:
        (await this._safeFormService.addID()).subscribe(res => {
          if (res.status) {
            this._safeFormService.certificateId = '';
            this._safeFormService.ScId = '';
            this._load.hide();
            this._navigation.updateLocation('sc-id-table');
          }
        });
        break;
      case this._safeFormService.editCertificateTitle:
        nativeElement.classList.add('fullCertSize');
        this._safeFormService.formTemplate = nativeElement;
        pdf = await this._safeFormService.generatePDF();
        this._safeFormService.editCetificate(pdf).subscribe(result => {
          if (!!result.data) {
            this._safeFormService.certificateId = '';
            this._safeFormService.ScId = '';
            this._load.hide();
            this._navigation.updateLocation('certificates-table');
          }
        });
        break;
      case this._safeFormService.travelAuthTitle:
        // generate pdf
        if(this.serviceListName.length > 15) {
          nativeElement.classList.add('travelAuth');
        } else {
          nativeElement.classList.add('travelAuthOnePage');
        }
        
        this._safeFormService.formTemplate = nativeElement;

        await new Promise((resolve) => setTimeout(() => resolve(true), 1000));

        this._navigation.showWorkflow();
        this._sign.clearWorkFlowData();

        // this._sign.setFile(fileObj, '');
        // this._sign.saveFileData(pdf)
        this._sign.setFileInfo('Travel Authority.pdf', '');
        this._sign.saveWorkflowData(this.serviceListName.length);
        this._sign.nextProgress(2);
        this._navigation.updateLocation('templates');
        break;
      default:
        break;
    }
  }

  back() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  next() {
    if (this.currentStep !== this.totalStep) {
      this.currentStep++;
    }
  }

  backPage(location: string) {
    this._safeFormService.certificateId = '';
    this._navigation.updateLocation(location);
  }

  dateFilter = (d: Date | null): boolean => {
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const oneDayBefore = new Date(currentDate.getTime() - oneDay);
    const dateOfEventTravelAuth = new Date(this.content["dateOfEventTravelAuth"])
    const dateOfEventTravelAuthOneDayBefore = new Date(dateOfEventTravelAuth.getTime() - oneDay);

    const minDate = this.content["dateOfEventTravelAuth"] === '____________' ? oneDayBefore : dateOfEventTravelAuthOneDayBefore;

    if (!d) return true;

    return d > minDate;
  }
}
