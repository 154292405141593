<div
  class="flexGrid"
  [ngClass]="isValid ? 'recover-password' : 'recover-password-error'"
>
  <h2 class="headerPw">Recover Password</h2>
  <h4 class="description">
    Enter your email below and we'll send you instructions on how to reset your
    password
  </h4>
  <div class="flex padding-top40">
    <mat-label class="labelPw">Email</mat-label>
  </div>
  <div class="flex">
    <input
      type="email"
      placeholder="Enter your email"
      matInput
      class="textfieldPw"
      [(ngModel)]="email"
      (ngModelChange)="isValid = true"
    />
  </div>
  <span class="error" *ngIf="!isValid">{{ errorMsg }}</span>
  <div class="padding-top40">
    <button class="btn-next" (click)="resetPassword()">
      <label class="btn-font">Reset Password</label>
    </button>
    <div style="display: flex;justify-content: center;">
      <a >Already have an account? <a class="label-clickable" (click)="goTo('login')">Login</a></a>
    </div>
  </div>
</div>
