import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.sass'],
})
export class SpinnerComponent implements OnInit, AfterViewChecked {
  isLoading$ = this._loaderService.isLoading;
  id = 'global';
  diameter = 50;
  top = '50%';
  width = `50px !important`;
  height = `50px !important`;

  @Input()
  loading: boolean = false
  @Output() compLoaded = new EventEmitter<boolean>();
  constructor(private _loaderService: LoaderService) { }

  ngOnInit(): void { }
  ngAfterViewChecked(): void {
    this.compLoaded.emit(true);

  }
}
