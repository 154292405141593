import { Component, Input, OnInit } from '@angular/core';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';

@Component({
  selector: 'app-document-party',
  templateUrl: './document-party.component.html',
  styleUrls: ['./document-party.component.sass']
})
export class DocumentPartyComponent implements OnInit {


  @Input('data')
  party: any
  isPhoneWidth: boolean = false;
  constructor(private _signsecure: SignSecureService) { }

  ngOnInit(): void {
    if (screen.width >= 393 && screen.width <= 430) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false
    }

    this.party.status = this.getStatus(this.party)
  }


  role(): string {
    return this._signsecure.getReadableRole(this.party.role)
  }

  status(): string | null {

    if (this.party.status) {
      return this.party.status
    }

    const role = this.party.role
    const done = !!this.party?.executedFormatted
    switch (role) {
      case 'SIGN': return done ? 'SIGNED' : 'PENDING'
      case 'APPROVE': return done ? 'APPROVED' : 'PENDING'
      case 'COPY': return done ? 'VIEWED' : 'PENDING'
    }

    return null
  }


  getStatus(data: any) {
    data.status = this.status()
    if (data?.viewedFormatted && data?.status === "PENDING") {
      return "VIEWED";
    }

    return data.status
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'PENDING':
        return 'grey'
      case 'VIEWED':
      case 'SIGNED':
      case 'APPROVED':
        return 'green'
      case 'DECLINED':
      case 'REJECTED':
        return 'red'
      default: return 'grey'
    }
  }
}
