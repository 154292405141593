import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WrapperBackgroundComponent } from './@core/wrapper-background/wrapper-background.component';
import { LogoContainerComponent } from './components/logo-container/logo-container.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VerificationContainerComponent } from './components/verification-container/verification-container.component';
import { MatIconModule } from '@angular/material/icon';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { AdminNavbarComponent } from './components/admin-navbar/admin-navbar.component';
import { AdminDashboardWrapperComponent } from './@core/admin-dashboard/admin-dashboard-wrapper/admin-dashboard-wrapper.component';
import { TableComponent } from './@shared/table/table.component';
import { ProgressCardComponent } from './@shared/progress-card/progress-card.component';
import { MatTableModule } from '@angular/material/table';
import { TagsComponent } from './@shared/tags/tags.component';
import { ActionsComponent } from './@shared/actions/actions.component';
import { AdminDashboardContentComponent } from './components/admin-dashboard-content/admin-dashboard-content.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FilterComponent } from './@shared/filter/filter.component';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ModalComponent } from './components/modal/modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FileViewComponent } from './@shared/file-view/file-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { Interceptor } from './@shared/Interceptor/interceptor';
import { MatChipsModule } from '@angular/material/chips';
import { SignInComponent } from './@core/sign-in/sign-in.component';
import { RegistrationComponent } from './@core/registration/registration.component';
import { AdminDashboardReportComponent } from './@core/admin-dashboard/admin-dashboard-report/admin-dashboard-report.component';
import { UserDashboardReportComponent } from './@core/user-dashboard-report/user-dashboard-report.component';
import { AdminDashboardManageUserComponent } from './@core/admin-dashboard/admin-dashboard-manage-user/admin-dashboard-manage-user.component';
import { AdminDashboardManageUserGroupsComponent } from './@core/admin-dashboard/admin-dashboard-manage-user-groups/admin-dashboard-manage-user-groups.component';
import { AdminDashboardAuditLogComponent } from './@core/admin-dashboard/admin-dashboard-audit-log/admin-dashboard-audit-log.component';
import { AdminDashboardFolderFilesComponent } from './@core/admin-dashboard/admin-dashboard-folder-files/admin-dashboard-folder-files.component';
import { AddNewUserComponent } from './@core/user-modal/add-new-user/add-new-user.component';
import { EditUserComponent } from './@core/user-modal/edit-user/edit-user.component';
import { DeleteUserComponent } from './@core/user-modal/delete-user/delete-user.component';
import { AddUserGroupComponent } from './@core/user-group-modal/add-user-group/add-user-group.component';
import { EditUserGroupComponent } from './@core/user-group-modal/edit-user-group/edit-user-group.component';
import { UserGroupComponent } from './@core/user-group-modal/user-group/user-group.component';
import { DeleteUserGroupComponent } from './@core/user-group-modal/delete-user-group/delete-user-group.component';
import { UploadFileComponent } from './@core/file-modal/upload-file/upload-file.component';
import { ShareFileComponent } from './@core/file-modal/share-file/share-file.component';
import { FaqComponent } from './@core/menu/faq/faq.component';
import { AccountComponent } from './@core/menu/account/account.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { SentConfirmationComponent } from './@core/user-modal/sent-confirmation/sent-confirmation.component';
import { DocumentsComponent } from './@core/menu/document/documents.component';
import { InboxComponent } from './@core/menu/inbox/inbox.component';
import { HttpErrorInterceptor } from './@shared/Interceptor/http-error-interceptor.interceptor';
import { SpinnerComponent } from './@shared/spinner/spinner.component';
import { LoaderInterceptor } from './@shared/Interceptor/loader.interceptor';
import { MatTreeModule } from '@angular/material/tree';
import { DocumentTreeComponent } from './@core/menu/document-tree/document-tree.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BreadcrumbComponent } from './@shared/breadcrumb/breadcrumb.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UploadInterceptor } from './@shared/Interceptor/upload.interceptor';
import { SearchResultsComponent } from './@shared/search-results/search-results.component';
import { RecoverPasswordComponent } from './@core/verification-screens/recover-password/recover-password.component';
import { EmailSentComponent } from './@core/verification-screens/email-sent/email-sent.component';
import { VerifitaionFailComponent } from './@core/verification-screens/verifitaion-fail/verifitaion-fail.component';
import { VerificationSuccessComponent } from './@core/verification-screens/verification-success/verification-success.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SupportPageComponent } from './@core/menu/support-page/support-page.component';
import { UploadComponent } from './@core/file-modal/upload/upload.component';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropFileUploadDirective } from './@shared/directives/drag-drop-file-upload.directive';
import { InboxViewComponent } from './@shared/inbox-view/inbox-view.component';
import { SupportUploadComponent } from './@core/file-modal/support-upload/support-upload.component';
import { WorkflowComponent } from './@core/menu/workflow/workflow.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DeleteFileComponent } from './@core/file-modal/delete-file/delete-file.component';
import { OcrPreviewComponent } from './@core/ocr-modal/ocr-preview/ocr-preview.component';
import { CreateFolderComponent } from './@core/file-modal/create-folder/create-folder.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DatePipe } from '@angular/common';
import { SignatureComponent } from './@core/menu/signature/signature.component';
import { AddSignatureComponent } from './@core/signature-modal/add-signature/add-signature.component';
import { RecentDocInfoComponent } from './@core/file-modal/recent-doc-info/recent-doc-info.component';
import { DeleteInboxComponent } from './@core/inbox-modal/delete-inbox/delete-inbox.component';
import { DeleteSignatureComponent } from './@core/signature-modal/delete-signature/delete-signature.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MoveFileComponent } from './@core/file-action-modal/move-file/move-file.component';
import { DuplicateFileComponent } from './@core/file-action-modal/duplicate-file/duplicate-file.component';
import { MembersFileComponent } from './@core/file-action-modal/members-file/members-file.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DATE_FORMAT } from './@shared/constants/date-format.const';
import { ResetPasswordComponent } from './@core/verification-screens/reset-password/reset-password.component';
import { ResetPasswordSuccessComponent } from './@core/verification-screens/reset-password-success/reset-password-success.component';
import { InboxIpadComponent } from './components/inbox-ipad/inbox-ipad.component';
import { FileAndFolderIpadComponent } from './components/file-and-folder-ipad/file-and-folder-ipad.component';
import { AdminDashboardTemplateComponent } from './@core/admin-dashboard/admin-dashboard-template/admin-dashboard-template.component';
import { TemplateNavbarComponent } from './components/admin-template/template-navbar/template-navbar.component';
import { StepComponent } from '../app/components/admin-template/step/step.component';
import { StepsComponent } from '../app/components/admin-template/steps/steps.component';
import { UploadTemplateComponent } from './components/admin-template/progress-screens/upload-template/upload-template.component';
import { TemplateDetailsComponent } from './components/admin-template/progress-screens/template-details/template-details.component';
import { DeleteDocumentComponent } from './@core/file-modal/delete-document/delete-document.component';
import { AddFieldsComponent } from './components/admin-template/progress-screens/add-fields/add-fields.component';
import { TemplatePartiesComponent } from './components/admin-template/progress-screens/template-parties/template-parties.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReviewComponent } from './components/admin-template/progress-screens/review/review.component';
import { PartyListComponent } from './components/admin-template/party-list/party-list.component';
import { PartyComponent } from './components/admin-template/party/party.component';
import { TabletTableComponent } from './components/tablet-table/tablet-table.component';
import { TabletNavbarComponent } from './components/tablet-navbar/tablet-navbar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SignatureFieldPreviewComponent } from './components/admin-template/signature-field-preview/signature-field-preview.component';
import { KonvaModule } from 'ng2-konva';
import { NumberArrayPipe } from './@shared/pipes/number-array.pipe';
import { SignatureFieldComponent } from './components/admin-template/signature-field/signature-field.component';
import { CompleteComponent } from './components/admin-template/progress-screens/complete/complete.component';
import { SigningPageComponent } from './components/signing-page/signing-page.component';
import { SigningPageNavbarComponent } from './components/signing-page-navbar/signing-page-navbar.component';
import { SigningPageContentComponent } from './components/signing-page-content/signing-page-content.component';
import { CreateSignatureComponent } from './@core/signature-modal/create-signature/create-signature.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { AddSignatureComponentComponent } from './@core/signature-modal/add-signature-component/add-signature-component.component';
import { SigningPageDoneComponent } from './components/signing-page-done/signing-page-done.component';
import { CdkMenuModule } from '@angular/cdk/menu';
import { AcceptRejectComponent } from './@core/workflow-modal/accept-reject/accept-reject.component';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { AuditTrailDetailsComponent } from './components/audit-trail/audit-trail-details/audit-trail-details.component';
import { AuditTrailDetailComponent } from './components/audit-trail/audit-trail-detail/audit-trail-detail.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { PortalModule } from '@angular/cdk/portal';
import { DocumentPartiesComponent } from './components/document-details/document-parties/document-parties.component';
import { DocumentPartyComponent } from './components/document-details/document-party/document-party.component';
import { CancelDocumentComponent } from './@core/workflow-modal/cancel-document/cancel-document.component';
import { CancelDocumentSuccessComponent } from './@core/workflow-modal/cancel-document-success/cancel-document-success.component';
import { DeclineDocumentComponent } from './@core/workflow-modal/decline-document/decline-document.component';
import { QrScannerComponent } from './@core/sign-in-modal/qr-scanner/qr-scanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { QrVerificationFailsComponent } from './@core/sign-in-modal/qr-verification-fails/qr-verification-fails.component';
import { QrVerificationSuccessComponent } from './@core/sign-in-modal/qr-verification-success/qr-verification-success.component';
import { CreateInitialsComponent } from './@core/signature-modal/create-initials/create-initials.component';
import { SigningPageCancelComponent } from './components/signing-page-cancel/signing-page-cancel.component';
import { RecentDocumentsComponent } from './@core/menu/recent-documents/recent-documents.component';
import { QrRestrictedComponent } from './@core/sign-in-modal/qr-restricted/qr-restricted.component';
import { PublicViewComponent } from './components/public-view/public-view.component';
import { AutomaticRemindersComponent } from './components/admin-template/automatic-reminders/automatic-reminders.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BulkIdTemplateComponent } from './components/bulk-id-template/bulk-id-template.component';
import { BulkCertificateTemplateComponent } from './components/bulk-certificate-template/bulk-certificate-template.component';
import { BulkIdTableComponent } from './components/bulk-id-template/bulk-id-table/bulk-id-table.component';
import { BulkCertTableComponent } from './components/bulk-certificate-template/bulk-certificate-table/bulk-certificate-table.component';
import { FormTemplateComponent } from './components/form-template/form-template.component';
import { SafeFormComponent } from './components/safe-form/safe-form.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { BreakLinePipe } from './@shared/pipes/break-line.pipe';
import { ParseContentPipe } from './@shared/pipes/parse-content.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { CertificateComponent } from './components/safe-form/table/certificate/certificate.component';
import { ScIdComponent } from './components/safe-form/table/sc-id/sc-id.component';
import { FormViewerComponent } from './components/form-viewer/form-viewer.component';
import { environment } from 'src/environments/environment';
import { DeleteScidComponent } from './@core/forms-modal/delete-scid/delete-scid.component';
import { FormBuilderComponent } from './components/safe-form/form-builder/form-builder.component';
import { OtpDialogComponent } from './@core/otp-dialog/otp-dialog.component';
import { DebounceClickDirective } from './@shared/directives/debounce-click.directive';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ColorSliderComponent } from './components/color-slider/color-slider.component';
import { ColorPaletteComponent } from './components/color-palette/color-palette.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureAD.clientId,
      authority: environment.azureAD.authority,
      redirectUri: environment.azureAD.redirectUri,
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    //  storeAuthStateInCookie: isIE,
    },
    // system: {
    //   allowNativeBroker: false,
    //   loggerOptions: {
    //     loggerCallback,
    //     logLevel: LogLevel.Verbose,
    //     piiLoggingEnabled: false
    //   }
    // }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WrapperBackgroundComponent,
    LogoContainerComponent,
    FormContainerComponent,
    VerificationContainerComponent,
    AdminSidebarComponent,
    AdminNavbarComponent,
    AdminDashboardWrapperComponent,
    TableComponent,
    ProgressCardComponent,
    TagsComponent,
    ActionsComponent,
    AdminDashboardContentComponent,
    FilterComponent,
    ModalComponent,
    FileViewComponent,
    SignInComponent,
    RegistrationComponent,
    AdminDashboardReportComponent,
    UserDashboardReportComponent,
    AdminDashboardManageUserComponent,
    AdminDashboardManageUserGroupsComponent,
    AdminDashboardAuditLogComponent,
    AdminDashboardFolderFilesComponent,
    AddNewUserComponent,
    EditUserComponent,
    DeleteUserComponent,
    AddUserGroupComponent,
    EditUserGroupComponent,
    UserGroupComponent,
    DeleteUserGroupComponent,
    UploadFileComponent,
    ShareFileComponent,
    FaqComponent,
    AccountComponent,
    AdminMenuComponent,
    SentConfirmationComponent,
    DocumentsComponent,
    InboxComponent,
    SpinnerComponent,
    DocumentTreeComponent,
    BreadcrumbComponent,
    SearchResultsComponent,
    RecoverPasswordComponent,
    EmailSentComponent,
    VerifitaionFailComponent,
    VerificationSuccessComponent,
    SupportPageComponent,
    UploadComponent,
    DragDropFileUploadDirective,
    InboxViewComponent,
    SupportUploadComponent,
    WorkflowComponent,
    PdfViewerComponent,
    DeleteFileComponent,
    OcrPreviewComponent,
    CreateFolderComponent,
    SnackbarComponent,
    SignatureComponent,
    AddSignatureComponent,
    RecentDocInfoComponent,
    DeleteInboxComponent,
    DeleteSignatureComponent,
    ImageViewComponent,
    MoveFileComponent,
    DuplicateFileComponent,
    MembersFileComponent,
    ResetPasswordComponent,
    ResetPasswordSuccessComponent,
    InboxIpadComponent,
    FileAndFolderIpadComponent,
    AdminDashboardTemplateComponent,
    TemplateNavbarComponent,
    StepComponent,
    StepsComponent,
    UploadTemplateComponent,
    TemplateDetailsComponent,
    DeleteDocumentComponent,
    AddFieldsComponent,
    TemplatePartiesComponent,
    ReviewComponent,
    PartyListComponent,
    PartyComponent,
    TabletTableComponent,
    TabletNavbarComponent,
    SignatureFieldPreviewComponent,
    NumberArrayPipe,
    SignatureFieldComponent,
    CompleteComponent,
    SigningPageComponent,
    SigningPageNavbarComponent,
    SigningPageContentComponent,
    CreateSignatureComponent,
    AddSignatureComponentComponent,
    SigningPageDoneComponent,
    AcceptRejectComponent,
    AuditTrailComponent,
    AuditTrailDetailsComponent,
    AuditTrailDetailComponent,
    DocumentDetailsComponent,
    DocumentPartiesComponent,
    DocumentPartyComponent,
    CancelDocumentComponent,
    CancelDocumentSuccessComponent,
    DeclineDocumentComponent,
    QrScannerComponent,
    QrVerificationFailsComponent,
    QrVerificationSuccessComponent,
    CreateInitialsComponent,
    SigningPageCancelComponent,
    RecentDocumentsComponent,
    QrRestrictedComponent,
    PublicViewComponent,
    AutomaticRemindersComponent,
    BulkIdTemplateComponent,
    BulkCertificateTemplateComponent,
    BulkIdTableComponent,
    BulkCertTableComponent,
    FormTemplateComponent,
    SafeFormComponent,
    BreakLinePipe,
    ParseContentPipe,
    CertificateComponent,
    ScIdComponent,
    FormViewerComponent,
    DeleteScidComponent,
    FormBuilderComponent,
    OtpDialogComponent,
    DebounceClickDirective,
    ColorPickerComponent,
    ColorSliderComponent,
    ColorPaletteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatListModule,
    MatTabsModule,
    FormsModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatMenuModule,
    FontAwesomeModule,
    HttpClientModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatRadioModule,
    NgxMatIntlTelInputComponent,
    PdfViewerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMomentDateModule,
    MatSlideToggleModule,
    DragDropModule,
    KonvaModule,
    AngularSignaturePadModule,
    CdkMenuModule,
    MatSidenavModule,
    PortalModule,
    MatSidenavModule,
    NgxScannerQrcodeModule,
    MsalModule,
    MatGridListModule,
    MatProgressBarModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UploadInterceptor, multi: true },
    DatePipe,
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
