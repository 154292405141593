<div class="certificates">
  <mat-card>
    <app-table
      [title]="'Certificates'"
      [columns]="columns"
      [type]="'certificates'"
      [data]="certificates | async"
      [paginate]="true"
      [pageSize]="10"
      [rowFunction]="onClickRow">
      <div class="tableSearch">
        <div class="search-form">
          <mat-form-field floatLabel="never" appearance="fill">
            <input
              matInput
              type="text"
              placeholder="Input Your Text in Here"
              [(ngModel)]="searchQuery"
              (keyup)="search()" />
            <span matSuffix
              ><img
                class="search-image"
                src="../../../assets/images/icons/search.png"
                alt=""
            /></span>
          </mat-form-field>
          <button class="search-button" (click)="updateLocation('safe-form')">
            Create Form
          </button>
          <button class="search-button" (click)="updateLocation('bulk-cert')">
            Bulk Create
          </button>
        </div>
      </div>
    </app-table>
  </mat-card>
  <app-form-builder></app-form-builder>
</div>
