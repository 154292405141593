import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';

@Component({
  selector: 'app-accept-reject',
  templateUrl: './accept-reject.component.html',
  styleUrls: ['./accept-reject.component.sass']
})
export class AcceptRejectComponent implements OnInit {


  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  note: string = ''
  constructor(private _signSecure: SignSecureService) { }

  ngOnInit(): void {
    console.log({ id: this.data })
  }


  approve() {
    this._signSecure.pendingSignature({ id: this.data?.data })
    this._signSecure.documentApprove(this.data?.data ?? '', this.note).subscribe((data) => {
      this.close('approve', data?.data)
    })
  }

  decline() {
    this._signSecure.documentReject(this.data?.data ?? '', this.note).subscribe((data) => {
      this.close('reject', data?.data)
    })
  }

  close(action: string, data: any) {
    this.closeModalEvent.emit({ action, note: this.note, data })
  }
}
