<ng-container>
  <form class="delete-user">
    <h4 class="title">
      Are you sure you want to remove this user from your team?
    </h4>
    <div class="content">
      <div class="input-group">
        <!-- <mat-label class="label-text"
          >Are you sure you want to remove this user in your team?</mat-label
        > -->
      </div>
      <div class="actions">
        <button class="cancel" (click)="closeModal()">Cancel</button>
        <button class="create" (click)="deleteUser()">Yes, delete User</button>
      </div>
    </div>
  </form>
</ng-container>
