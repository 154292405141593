import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { catchError, of } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from 'src/app/@shared/services/users.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { ResetPasswordModel } from 'src/app/@shared/models/reset-password.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass'],
})
export class ResetPasswordComponent implements OnInit {
  @Output() resetPassword = new EventEmitter<string>();
  email: string = '';
  errorMsg: any;
  isValid: boolean = true;
  resetPasswordForm: FormGroup = this._fb.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
  });
  required: boolean = false;
  isPasswordHidden: boolean = true;
  isConfirmPasswordHidden: boolean = true;
  constructor(
    private _authService: AuthenticationService,
    private _user: UsersService,
    private _nav: NavigationService,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {}

  areEqual(group: any) {
    const { controls } = group;
    return controls?.password === controls?.confirmPassword
      ? null
      : 'password is not the same';
  }

  getErrorList(errorObject: any) {
    return Object.keys(errorObject);
  }

  checkError(control: string) {
    const formControl = this.resetPasswordForm.controls[control];
    if (
      this.resetPasswordForm.controls['password'].value !==
        this.resetPasswordForm.controls['confirmPassword'].value &&
      this.resetPasswordForm.controls['password'].value !== '' &&
      this.resetPasswordForm.controls['confirmPassword'].value !== ''
    ) {
      return 'Password and Confirm passsword is not matched';
    } else if (formControl.errors !== null && this.required) {
      return `${this.getControlLabel(control)} is required!`;
    } else {
      return '';
    }
  }

  getControlLabel(control: string) {
    switch (control) {
      case 'password':
        return 'Password';
      case 'confirmPassword':
        return 'Confirm Password';
      default:
        return '';
    }
  }

  changePassword() {
    const resetPass: ResetPasswordModel = {
      resetToken: this._nav.getTokenParam(),
      password: this.resetPasswordForm.controls['password'].value,
      confirmPassword: this.resetPasswordForm.controls['confirmPassword'].value,
    };

    this._authService.resetPassword(resetPass).subscribe((data) => {
      this.resetPassword.emit('reset-password-success');
    });

    // this.resetPassword.emit('reset-password-success');
  }
}
