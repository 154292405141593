import {
	AfterContentChecked,
	AfterContentInit,
	AfterViewChecked,
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core"
import Konva from "konva"
import { KonvaComponent } from "ng2-konva"
import { debounceTime, Observable, of, Subject } from "rxjs"
import { Position } from "src/app/@shared/models/fields.model"
import { SignSecureService } from "src/app/@shared/services/signsecure.service"

@Component({
	selector: "app-signature-field",
	templateUrl: "./signature-field.component.html",
	styleUrls: ["./signature-field.component.sass"],
})
export class SignatureFieldComponent
	implements OnInit, AfterViewInit, AfterContentChecked {
	@Input()
	details: any[] = []

	@Input()
	i: number = 0

	@Input()
	config: any

	@Input()
	zoom: number = 1

	@Input()
	qr: any

	@ViewChild("konvaStage")
	stage!: KonvaComponent

	transformer!: Konva.Transformer

	stageConfig: Observable<any> = of({})

	@Input()
	qrPosition: string = "top-right"

	@Input()
	scale: number = 1

	@Output()
	location: EventEmitter<{ x: number; y: number }> = new EventEmitter()

	@Output()
	showControls: EventEmitter<any> = new EventEmitter()

	@Output()
	updateSignatureDetail: EventEmitter<{
		detail: any
		index: number
		page: number
		position: Position
	}> = new EventEmitter()

	@Output()
	isDragged: EventEmitter<any> = new EventEmitter()

	prev: number = -1

	prevConfig: any = null
	constructor(private _sign: SignSecureService) { }

	qrLayer!: Konva.Layer
	prevPosition: string = ""
	prevDetails: any[] = []
	redraw: boolean = false
	redrawCount = 0

	isControlShowing: boolean = false
	isDragging: boolean = false
	ngOnInit(): void {
		this.stageConfig = of(this.config)
		this.redrawCount = 0
	}

	ngAfterContentChecked(): void {
		this.stageConfig = of(this.config)
		
		if (this.stage && this.config !== this.prevConfig && (this.redraw && this.redrawCount < 5)) {
			this.prevConfig = this.config
			const stage: Konva.Stage = this.stage.getStage()
			stage.setSize(this.config)
			stage.scale({ x: this.zoom, y: this.zoom })
			console.log({ config: this.config, prevConfig: this.prevConfig })

			if (this.zoom !== this.prev) {
				this.prev = this.zoom
				this.redrawCount = 0
				stage.children?.forEach((child) => child.destroyChildren())

				this.ngAfterViewInit()
			}
		}

		if (
			!!this.qr &&
			(this.prevPosition !== this.qrPosition ||
				this.zoom !== this.prev ||
				(this.redraw && this.redrawCount < 2))
		) {
			this.redrawCount++
			this.prevPosition = this.qrPosition
			Konva.Image.fromURL(this.qr, (img: Konva.Image) => {
				const qrpos = this.getQRLocation()
				img.filters([Konva.Filters.RGBA])
				img.alpha(0.6)
				img.setAttrs({
					x: qrpos?.x,
					y: qrpos?.y,
					width: 84 * this.scale,
					height: 84 * this.scale,
					listening: false,
				})

				if (this.qrLayer) {
					this.qrLayer.destroy()
				}

				this.qrLayer = new Konva.Layer()
				this.qrLayer.add(img)
				this.stage?.getStage()?.add(this.qrLayer)
			})
		}
	}

	ngAfterViewInit(): void {
		console.log({ updated: "something" })
		this.prev = this.zoom
		this.prevDetails = this.details
		this.redraw = true
		const stage: Konva.Stage = this.stage.getStage()
		stage.clear()
		stage.on("dragmove", (e: any) => this.startDrag(e))
		stage.on("mousemove", (e: any) => this.mouseover(e))
		stage.on("mouseleave", (e: any) => this.mouseover(e))
		stage.on("click tap", (e: any) => this.konvaClick(e))
		stage.on("dragend", (e: any) => this.updateDetail(e))


		this.transformer = new Konva.Transformer({
			borderDash: [4],
			borderStroke: "dash",
			borderStrokeWidth: 2,
			borderEnabled: true,
			flipEnabled: false,
			padding: 8,
			draggable: true,
			resizeEnabled: false,
			rotateEnabled: false,
		})

		this.transformer.on("transformend", (e: any) => this.resize(e));
		this.transformer.on('transform', () => {
			// Update the group's position after resizing
			const newPosition = this.transformer.absolutePosition();
			console.log({ newPosition })
		});

		this.details.forEach((detail, index) => {
			const layer = new Konva.Layer({
				...detail.layerConfig, borderDash: [4],
				x: 0,
				y: 0,
			})
			layer.add(this.transformer)

			let group: any

			if (detail.transform) {
				group = new Konva.Group(detail.transform)
			} else {
				group = new Konva.Group({
					id: detail.trackId,
					index,
					type: detail.type,
				})
			}

			if (detail.type === "signature") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "signature-name") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.nameConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "signature-date") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.dateConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "signature-name-designation") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.nameConfig)))
				group.add(
					new Konva.Text(this.getZoomedSettings(detail.desginationConfig))
				)
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "initials") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.documentConfig)))
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			} else if (detail.type === "name") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.nameConfig)))
			} else if (detail.type === "designation") {
				group.add(
					new Konva.Text(this.getZoomedSettings(detail.desginationConfig))
				)
			} else if (detail.type === "date-time") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.dateConfig)))
			} else if (detail.type === "textbox") {
				group.add(new Konva.Text(this.getZoomedSettings(detail.textConfig)))
				group.add(new Konva.Rect(this.getZoomedSettings(detail.config)))
			}



			layer.dragBoundFunc((pos) => {
				console.log('magic', pos)

				const detail = { ...this.details[index] }
				var stageWidth = stage.width();
				var stageHeight = stage.height();

				const shapes = group.children

				// console.log({ metadata, shapes, details: this.details })
				let mappedShapes: { [key: string]: any } = {}
				shapes.forEach((shape: any) => {
					mappedShapes[shape.attrs.id] = shape
				})

				const attr = this.getAttributes(detail, mappedShapes)
				const groupX = detail?.transform?.scaleX ?? 1
				const groupY = detail?.transform?.scaleY ?? 1

				console.log({ detail })
				let newX = attr.x + pos.x
				let newY = attr.y + pos.y

				if (newX < 0) {
					newX = 0 - attr.x
				} else if (newX + (attr.width * groupX) > stageWidth) {
					newX = stageWidth - attr.x - (attr.width * groupX)
				} else {
					newX = pos.x
				}

				if (newY < 0) {
					newY = 0 - attr.y
				} else if (newY + (attr.height * groupY) > stageHeight) {
					newY = stageHeight - attr.y - (attr.height * groupY)
				} else {
					newY = pos.y
				}

				console.log('magic', { newX, newY, originX: attr.x, originY: attr.y, stageWidth })
				return {
					x: newX,
					y: newY
				};
			})
			this.transformer.forceUpdate()
			stage.add(layer)
			layer.add(group)

			layer.clearBeforeDraw()
			stage.batchDraw()
		})
	}

	konvaClick(e: any): void {
		if (!e) return

		const stage = this.stage.getStage()
		if (e.target === stage) {
			this.isControlShowing = false
			this.showControls.emit("hide")
			this.transformer.nodes([])
			return
		}

		this.isControlShowing = true
		const target: Konva.Group = e.target.parent ?? e.target
		this.transformer.nodes([target])
		const width = this.transformer.getWidth()
		const attr = target.getAttrs()

		const { type } = attr
		let space
		console.log({ type })
		switch (type) {
			case "designation":
			case "name":
			case "date-time":
				space = 50
				break
			default:
				space = 20
		}

		const x = this.transformer.getX() + width + 20
		const y = this.transformer.getY() - space
		this.showControls.emit({
			x,
			y,
			target,
			transformer: this.transformer,
			index: target.attrs.index,
			page: this.i,
		})
	}

	mouseover(event: any) {
		if (event?.type === "mousemove") {
			const mEvent = event.evt
			this.location.emit({ x: mEvent.offsetX, y: mEvent.offsetY })
		} else if (event?.type === "mouseleave") {
			this.location.emit({ x: -1, y: -1 })
		}
	}

	updateDetail(event: any) {
		console.log({ things: event.target.children, target: event.target })
		const group = event.target.children.find(
			(child: any) => !(child instanceof Konva.Transformer)
		)

		const metadata = group.attrs
		const shapes = group.children
		const detail = this.details[metadata.index]

		let mappedShapes: { [key: string]: any } = {}
		shapes.forEach((shape: any) => {
			mappedShapes[shape.attrs.id] = shape
		})

		const attr = this.getAttributes(detail, mappedShapes)
		const lastPos = event.target._lastPos

		const position = {
			x: attr.x + lastPos.x,
			y: attr.y + lastPos.y,
		}



		this.updateSignatureDetail.emit({
			detail,
			index: metadata.index,
			page: this.i,
			position,
		})

		this.isDragged.emit(false)
	}

	resize(event: any) {
		const group = event.target

		const metadata = group.attrs
		const shapes = group.children

		const detail = { ...this.details[metadata.index] }

		let mappedShapes: { [key: string]: any } = {}
		shapes.forEach((shape: any) => {
			mappedShapes[shape.attrs.id] = shape
		})

		let x = 0
		let y = 0
		if (metadata.type === "signature") {
			detail.documentConfig = mappedShapes[detail.documentConfig.id].attrs
			detail.textConfig = mappedShapes[detail.textConfig.id].attrs
			detail.config = mappedShapes[detail.config.id].attrs

			const rect = event.target.children.find(
				(child: any) => child instanceof Konva.Rect
			)

			const position = this.transformer.getAbsolutePosition()
			x = +position.x.toFixed()
			y = +position.y.toFixed()
		} else {
			const element = event.target.children[0]
			x = element.x()
			y = element.y()
		}

		const position = { x, y }
		detail.transform = metadata

		this.updateSignatureDetail.emit({
			detail,
			index: metadata.index,
			page: this.i,
			position,
		})

		const width = this.transformer.getWidth()
		const attrs = group.getAttrs()

		const { type } = attrs
		let space
		console.log({ type })
		switch (type) {
			case "designation":
			case "name":
			case "date-time":
				space = 50
				break
			default:
				space = 20
		}

		const transformX = this.transformer.getX() + width + 20
		const transformY = this.transformer.getY() - space
		this.showControls.emit({
			transformX,
			transformY,
			group,
			transformer: this.transformer,
		})
	}

	startDrag(event: any) {
		// event.cancelBubble = true;
		console.log({ dragevent: event })

		const target = event.target
		const group = event.target.children.find(
			(child: any) => !(child instanceof Konva.Transformer)
		)

		const node = this.transformer.getNode()
		// console.log({ node })
		const metadata = group.attrs
		const shapes = group.children
		const detail = this.details[metadata.index]

		// console.log({ metadata, shapes, details: this.details })
		let mappedShapes: { [key: string]: any } = {}
		shapes.forEach((shape: any) => {
			mappedShapes[shape.attrs.id] = shape
		})

		console.log({ mappedShapes, shapes, detail })

		const attr = this.getAttributes(detail, mappedShapes)


		// get the something
		const stage = this.stage.getStage()

		var stagePos = stage.position();
		var stageWidth = stage.width();
		var stageHeight = stage.height();


		const lastPos = event.target._lastPos

		const position = { x: attr.x + lastPos.x, y: attr.y + lastPos.y }

		const width = this.transformer.getWidth()

		let newX = position.x + width + 20
		let newY = this.transformer.getY() - 20


		if (newX < stagePos.x) {
			newX = stagePos.x;
		} else if (newX + attr.width + width > stagePos.x + stageWidth) {
			newX = stagePos.x + stageWidth - attr.width - width + 20;
		}

		if (newY < stagePos.y) {
			newY = stagePos.y;
		} else if (newY + attr.height > stagePos.y + stageHeight) {
			newY = stagePos.y + stageHeight - attr.height - 20;
		}



		this.isDragged.emit({ x: newX, y: newY })
		console.log({ stagePos, group, stageWidth, stageHeight, attr, ...{ x: newX, y: newY } })
	}

	getAttributes(detail: any, shapes: any) {
		let attr
		switch (detail.type) {
			case "name":
				attr = shapes[detail.nameConfig.id].attrs
				break
			case "date-time":
				attr = shapes[detail.dateConfig.id].attrs
				break
			case "designation":
				attr = shapes[detail.desginationConfig.id].attrs
				break
			default:
				attr = shapes[detail.config.id].attrs
		}
		return attr
	}

	getShape(detail: any, shapes: any) {
		let shape
		switch (detail.type) {
			case "name":
				shape = shapes[detail.nameConfig.id]
				break
			case "date-time":
				shape = shapes[detail.dateConfig.id]
				break
			case "designation":
				shape = shapes[detail.desginationConfig.id]
				break
			default:
				shape = shapes[detail.config.id]
		}
		return shape
	}

	getZoomedSettings(config: any) {
		const newConfig = { ...config }

		return newConfig
	}

	getQRLocation() {
		switch (this.qrPosition) {
			case "top-right":
				return { x: this.config.width - 124 * this.scale, y: 35 * this.scale }
			case "bottom-right":
				return {
					x: this.config.width - 124 * this.scale,
					y: this.config.height - 119 * this.scale,
				}
			case "bottom-left":
				return { x: 40 * this.scale, y: this.config.height - 119 * this.scale }
			case "top-left":
				return { x: 40 * this.scale, y: 35 * this.scale }
			case "top-center":
				return { x: this.config.width / 2 - (42 * this.scale), y: 35 * this.scale }
			case "bottom-center":
				return {
					x: this.config.width / 2 - (42 * this.scale),
					y: this.config.height - (119 * this.scale),
				}
			default:
				return { x: 40 * this.scale, y: 35 * this.scale }
		}
	}
}
