import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { BulkFormsService } from 'src/app/@shared/services/bulk-forms.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SafeformService } from 'src/app/@shared/services/safeform.service';

@Component({
  selector: 'app-bulk-id-table',
  templateUrl: './bulk-id-table.component.html',
  styleUrls: ['./bulk-id-table.component.sass'],
})
export class BulkIdTableComponent implements OnInit {
  columns: string[] = [
    'name',
    'employee number',
    'branch station',
    'position',
    'date created',
    'actions',
  ];
  bulkId: any;
  title: string = '';
  bulkIdData = this.bulkFormService.bulkIdData$;
  data = this.bulkFormService.bulkIdData$;
  searchQuery: string = '';
  constructor(
    private bulkFormService: BulkFormsService,
    private _navigation: NavigationService,
    private _safeFormService: SafeformService,
  ) { }

  ngOnInit(): void {
    this.bulkId = localStorage.getItem('bulkId');
    console.log('this._navigation.getParams ', this.bulkId);
    this.getBulkId(this.bulkId);
  }

  getBulkId(id: string) {
    this.bulkFormService.getBulkSCID(id).subscribe((res: any) => {
      console.log('getBulkSCID', res);
      if (res) {
        this.title = res.data.batchName;
        this.bulkFormService.setBulkIdRow(
          this.bulkFormService.formatBulkScId(res.data.scId)
        );
      }
    });
  }

  selectedRow(event: any, data: any) {
    if (event.target.className.includes('checkbox')) {
      return;
    }

    if (event.target.localName == 'img') {
      return;
    }

    console.log({ data });

    const file = data.actions.scid_download.filepath;

    if (!!file?.length) {
      this._safeFormService.viewerData = {
        ...data.scid_download,
        pdfSrc: { pdfPath: file },
        prevLocation: 'SC ID',
        prev: 'sc-id-table',
        name: `${data.actions.scid_download.fullName} - SC ID`,
      };
      this._navigation.updateLocation('formViewer');
    } else {
      const value = data.actions.scid_download;

      console.log({ value });
      const content = {
        PositionSupremeCourtId: value?.position ?? '',
        allergiesSupremeCourt: value?.allergies ?? '',
        branchStationSCid: value?.branchStation ?? '',
        comorbiditySupremeCourt: value?.comorbidity ?? '',
        companyLocation: value?.branchStation ?? '',
        companyName: value?.personToContact ?? '',
        contactNumberEmergency: value?.emergencyContactNumber ?? '',
        contractDate: value?.dateOfBirth ?? '',
        courtDropDown: value?.Court ?? '',
        dateGeneric: value?.dateOfBirth ?? '',
        emailAddressSupremeCourt: value?.emailAddress ?? '',
        employeeNumberSCid: value?.employeeNumber ?? '',
        gsisSupremeCourtId: value?.gsis ?? '',
        heightSupremeCourtId: value?.height ?? '',
        nameSupremeCourtId: value?.fullName ?? '',
        pagIbigSupremeCourtId: value?.pagibig ?? '',
        parties: value?.allergies ?? '',
        personToContactInEmergency: value?.personToContact ?? '',
        philhealthSupremeCourtId: value?.philHealth ?? '',
        selectBloodType: value?.bloodType ?? '',
        tinNoSupremeCourtId: value?.tinNumber ?? '',
        weightSupremeCourtId: value?.weight ?? '',
        logoSrc: '../../assets/images/sc_logo.png',
      };

      this._safeFormService.ScId = data.actions.scid_download._id;
      this._safeFormService.generateMissingPDF(content);
    }
  }

  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }

  search() {
    this.bulkIdData = this.data.pipe(
      map((tableData: any) => {
        console.log({ tableData, search: this.searchQuery });
        return tableData.filter((tableDatum: any) => {
          return tableDatum?.owner
            ?.toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      })
    );
  }
}
