import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { SignSecureProgressModel } from 'src/app/@shared/models/sign-secure-progress.model';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { SnackbarComponent } from '../../snackbar/snackbar.component';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.sass'],
})
export class StepsComponent implements OnInit, OnDestroy {
  steps: SignSecureProgressModel[] = [
    {
      inactive: '../../../assets/images/steps/upload.svg',
      active: '../../../assets/images/steps/upload-active.svg',
      done: '../../../assets/images/steps/upload-done.svg',
      label: 'Upload',
      status: 'active',
    },
    {
      inactive: '../../../assets/images/steps/details.svg',
      active: '../../../assets/images/steps/details-active.svg',
      done: '../../../assets/images/steps/details-done.svg',
      label: 'Details',
      status: 'inactive',
    },
    {
      inactive: '../../../assets/images/steps/parties.svg',
      active: '../../../assets/images/steps/parties-active.svg',
      done: '../../../assets/images/steps/parties-done.svg',
      label: 'Parties',
      status: 'inactive',
    },
    {
      inactive: '../../../assets/images/steps/fields.svg',
      active: '../../../assets/images/steps/fields-active.svg',
      done: '../../../assets/images/steps/fields-done.svg',
      label: 'Fields',
      status: 'inactive',
    },
    {
      inactive: '../../../assets/images/steps/review.svg',
      active: '../../../assets/images/steps/review-active.svg',
      done: '../../../assets/images/steps/review-done.svg',
      label: 'Review',
      status: 'inactive',
    },
    {
      inactive: '',
      active: '',
      done: '',
      label: 'Done',
      status: 'inactive',
    },
  ];

  subs = new Subscription();
  workflow: any
  constructor(private _stepsNav: SignSecureService, private _snackBar: MatSnackBar) { }


  ngOnInit(): void {
    this.subs.add(
      this._stepsNav.signSecureProgress$.subscribe((max) => {
        for (let i = 0; i < this.steps.length; i++) {
          if (i < +max) {
            this.steps[i].status = 'done';
          } else {
            this.steps[i].status = 'inactive';
          }

          if (i === +max - 1) this.steps[i].status = 'active'
        }
      })
    );

    this.subs.add(

    )
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  move(page: number, data: any) {
    console.log({ page, data })
    const workflow = data.workflow
    if (page >= 4 && workflow) {
      const parties = workflow.parties
      if (!!!parties.some((party: any) => party.role === 'SIGN')) {
        this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: {
            type: "error",
            text: "Please add signatory",
          },
        })
        return
      }
    }

    this._stepsNav.prevPage(page)
  }
}
