import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { UserGroupModel } from 'src/app/@shared/models/user-group.model';
import { UserModel } from 'src/app/@shared/models/user.model';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})
export class ModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {}

  ngOnInit(): void {}

  closeModal(data: any) {
    console.log({data})
    if (this.data.action === 'share-permission') {
      this.dialogRef.close({ ...data, modalAction: this.data.action });
    } else if (this.data.action === 'mUser' && data) {

      this.data.action='user-created';
      this.data.data=data;
    } else {
      this.dialogRef.close(data);
    }
  }
}
