import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { UserResponseModel } from 'src/app/@shared/models/user-response.model';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.sass'],
})
export class DocumentDetailsComponent implements OnInit {
  @Input()
  data: any;

  @Input()
  show: boolean = true;

  @Output()
  close: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  email: any;
  parties: any[] = [];
  reminders: any
  constructor() { }

  ngOnInit(): void {
    const workflow = this.data.workflow;
    this.email = workflow.emailContent;
    this.parties = workflow.parties;
    this.reminders = workflow.reminders

    if (this.reminders) {
      this.reminders.expiration = moment(this.reminders.expiration).format('MMMM DD, YYYY')
    } else {
      this.reminders = { expiration: 'None' }
    }

    this.reminders.reminderInXDays = !!this.reminders.reminderInXDays ? `${this.reminders.reminderInXDays} day/s` : 'None'
    this.reminders.reminderEveryXDays = !!this.reminders.reminderEveryXDays ? `${this.reminders.reminderEveryXDays} day/s` : 'None'
    this.reminders.reminderBeforeXDays = !!this.reminders.reminderBeforeXDays ? `${this.reminders.reminderBeforeXDays} day/s` : 'None'

  }

  ngOnChanges() {
    this.parties = this.data.workflow.parties;
  }

  //     Pending - grey
  // Viewed - green
  // Signed - green
  // Approved - green
  // Declined - red
  // Rejected - red
  getStatusColor(status: string) {
    switch (status) {
      case 'PENDING':
        return 'grey';
      case 'VIEWED':
      case 'SIGNED':
      case 'APPROVED':
        return 'green';
      case 'DECLINED':
      case 'REJECTED':
        return 'red';
      default:
        return 'grey';
    }
  }
}
