<div class="small-navi" (click)="backMenu()">< Back to {{ prevPosition }}</div>
<div class="header-container">
  <h4 class="title">{{ count }} results for "{{ keyword }}"</h4>
</div>
<!-- <div class="sub-title">Filter</div>
<div class="checkbox-group">
  <mat-checkbox class="checkbox">All</mat-checkbox>
  <mat-checkbox class="checkbox">File name</mat-checkbox>
  <mat-checkbox class="checkbox">Folder name</mat-checkbox>
  <mat-checkbox class="checkbox">Metadata</mat-checkbox>
  <mat-checkbox class="checkbox">Details</mat-checkbox>
</div> -->
<div class="search-table">
  <app-table
    [title]="'Folders and Files'"
    [isSearch]="isSearch"
    [hasCheckBox]="hasCheckBox"
    [columns]="filesCols"
    [data]="filesData"
    [type]="'search-result'"
    [nameFunction]="openPdf"
  ></app-table>
</div>
