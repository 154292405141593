<div class="inbox" *ngIf="!isPhoneWidth; else mobileTable">
  <ng-container>
    <app-table
      *ngIf="!(viewMsg | async)"
      [title]="'Inbox'"
      [hasCheckBox]="hasCheckBox"
      [columns]="columns"
      [data]="data | async"
      [type]="'inbox'"
      [rowFunction]="onClickRow"
      [isFolderStructure]="false"
      [selection]="selection"
    >
      <div class="extra-buttons">
        <button class="extra-button" (click)="deleteMessages()">
          Delete selected messages
        </button>
      </div>
    </app-table>
  </ng-container>
  <app-inbox-ipad
    *ngIf="!(viewMsg | async)"
    [data]="data | async"
    [rowFunction]="onClickRow"
  ></app-inbox-ipad>
  <app-inbox-view
    *ngIf="viewMsg | async"
    (goBackEvent)="goback($event)"
  ></app-inbox-view>
</div>

<ng-template #mobileTable>
  <div class="mobile-table">
    <app-tablet-table
      *ngIf="!(viewMsg | async)"
      [itemTemplate]="inbox_row"
      [title]="'Inbox'"
      [data]="data | async"
    ></app-tablet-table>
  </div>
</ng-template>

<ng-template #inbox_row let-data>
  <div class="inbox-row" (click)="(onClickRow)">
    <div class="inbox-content">
      <p class="title">{{ data.name }}</p>
      <p class="subtitle">{{ data.subject }}</p>
      <p class="content">{{ data.message }}</p>
    </div>
    <div class="inbox-date">
      <p class="content">{{ convertDate(data.date) }}</p>
    </div>
  </div>
</ng-template>
