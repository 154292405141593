<div class="container backCertificate">
  <button mat-button (click)="updateLocation('certificates-table')">
    <span class="back-button">
      Back to Certificates
      <mat-icon inline="true" class="ml-3">arrow_back</mat-icon>
    </span>
  </button>
</div>
<div class="container" *ngIf="!isFileReady">
  <div class="alert alert-danger mt-3" role="alert">
    <ul style="margin-bottom: 0 !important">
      <li *ngIf="!isRollExist">
        [row {{ isRollExistIndex }}] {{RollNumberValidatorMessage}}.
      </li>
      <li *ngIf="!isRollDuplicate">
       {{RollNumberDuplicateMessage}}.
      </li>
      <li *ngIf="!isRoll">
        [row {{ isRollIndex }}] a value in Roll Number is invalid.
      </li>
      <li *ngIf="!isFullname">
        [row {{ isFullnameIndex }}] a value in Fullname is invalid.
      </li>
      <li *ngIf="!isChiefName">
        [row {{ isChiefNameIndex }}] a value in Chief Name is invalid.
      </li>
      <li *ngIf="!isClerkName">
        [row {{ isChiefNameIndex }}] a value in Clerk Name is invalid.
      </li>
      <li *ngIf="!isDateAdmitted">
        [row {{ isDateAdmittedIndex }}] a value in Date Admitted is invalid.
      </li>
      <li *ngIf="!isDateAttestation">
        a value in Date of Attestation is invalid.
      </li>
      <li *ngIf="!checkFormat">
        Invalid value. Please ensure that field names are correct and required
        fields are not blank.
      </li>
    </ul>
  </div>
</div>
<div class="container d-flex justify-content-center py-2" style="margin-bottom: 2.5rem">
  <mat-card class="card-table">
    <div class="col-12 sign-in-container">
      <mat-card-title>
        <div class="title-row">
          <div class="col-9">Bulk Certificate Creation</div>
          <div class="col-3">
            <button mat-raised-button class="button-auth" color="primary" type="submit" [disabled]="
                !isFileEmpty ||
                !isClerkSigEmpty ||
                !isChiefSigEmpty ||
                isLoading
              " (click)="bulkRegister()">
              <span *ngIf="isLoading">
                <i class="fa fa-spinner fa-spin fa-fw"></i> Creating
              </span>
              <span *ngIf="!isLoading"> Create </span>
            </button>
          </div>
        </div>
      </mat-card-title>
      <!-- <mat-error *ngIf="message">
                {{message}}
            </mat-error> -->
      <!-- <div class="spinner-container mb-3" *ngIf="isFormLoading">
                <mat-progress-spinner mode="indeterminate">
                </mat-progress-spinner>
            </div> -->
      <div class="form-data">
        <label for="formFile" class="form-label mb-4">Upload the needed documents</label>
        <input class="form-control" type="file" id="formFile"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="onFileChange($event)" #formFile />
      </div>
      <div class="form-data">
        <label class="form-label" for="customFileClerk">Clerk of Court's Signature</label>
        <input type="file" class="form-control" id="customFileClerk" data-name="clerk"
          (change)="setClerkSigUploadSignature($event)" accept="image/png, image/jpg, image/gif, image/jpeg"
          #clerkSig />
      </div>
      <div class="form-data">
        <label class="form-label" for="customFileChief">Chief Justice's Signature</label>
        <input type="file" class="form-control" id="customFileChief" data-name="chief"
          (change)="setChiefSigUploadSignature($event)" accept="image/png, image/jpg, image/gif, image/jpeg"
          #chiefSig />
      </div>
    </div>
  </mat-card>
</div>

<div class="container d-flex justify-content-center py-2" style="margin-top: 2.5rem; margin-bottom: 2.5rem">
  <mat-card class="card-table" style="padding: 3rem 2rem">
    <app-table [title]="'Uploaded Bulk Certificates'" [columns]="columns" [type]="'bulkId$'" [data]="bulkCert$ | async"
      [paginate]="true" [canOpenFile]="true" [rowFunction]="selectedRow"></app-table>
  </mat-card>
</div>