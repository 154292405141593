import { Component, OnInit } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.sass'],
})
export class FormViewerComponent implements OnInit {
  pdfSrc: string = './assets/dms-documents/pdf-test.pdf';
  zoom: number = 1;
  prevLocation: string = 'Certificates';
  data: any;
  pdf!: PDFDocumentProxy;
  hasToken: boolean = false
  constructor(
    private _form: SafeformService,
    private _files: FilesService,
    private _nav: NavigationService
  ) {}

  ngOnInit(): void {
    this.data = this._form.viewerData;

    console.log({ data: this.data });
    if (!environment.local) {
       this.pdfSrc = `assets/${this.data.pdfSrc.pdfPath}`;
    }

    this.prevLocation = this.data.prevLocation;

    const token = localStorage.getItem('token');
    this.hasToken = !!token
    
  }

  afterLoadComplete(event: PDFDocumentProxy) {
    this.pdf = event;
  }

  zoomIn() {
    if (this.zoom < 3) this.zoom += 0.1;
  }

  zoomOut() {
    if (this.zoom > 0.75) this.zoom -= 0.1;
  }

  download() {
    this._files.downloadFile(this.data.name, this.pdfSrc);
  }

  back() {
    this._nav.updateLocation(this.data.prev);
  }

  print() {
    try {
      this.pdf.getData().then((u8: any) => {
        let blob = new Blob([u8.buffer], {
          type: 'application/pdf',
        });

        const blobUrl = window.URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        document.body.appendChild(iframe);
        iframe?.contentWindow?.print();
      });
    } catch (e) {
      console.log('ERROR', e);
      window.print();
    }
  }
}
