import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { UserGroupModel } from "src/app/@shared/models/user-group.model";
import { UserGroupService } from "src/app/@shared/services/user-group.service";
import { ModalComponent } from "src/app/components/modal/modal.component";

@Component({
  selector: "app-admin-dashboard-manage-user-groups",
  templateUrl: "./admin-dashboard-manage-user-groups.component.html",
  styleUrls: ["./admin-dashboard-manage-user-groups.component.sass"],
})
export class AdminDashboardManageUserGroupsComponent
  implements OnInit, OnDestroy
{
  userGroups = this._manageUserGroups.userGroups$;
  userGroupsCols = this._manageUserGroups.columns;
  subs = new Subscription();
  searchQuery: string = "";
  userGroupData: any[] = [];
  userGroupDataOrig: any[] = [];
  constructor(private _manageUserGroups: UserGroupService) {
     
  }

  ngOnInit(): void {
    this.subs = this._manageUserGroups.getUserGroups().subscribe(({ data }) => {
      this.userGroupDataOrig = this._manageUserGroups.format(data);
      this.userGroupData = this.userGroupDataOrig;
      this._manageUserGroups.setUserGroup(this.userGroupDataOrig);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  showModal(data: any) {
    const { dialog, type: action } = data;

    const dialogRef = dialog?.open(ModalComponent, {
      width: "636px",
      data: { action },
    });
  }

  search() {
    if (this.searchQuery.trim().length == 0) {
      this.userGroupData = this.userGroupDataOrig;
    } else {
      this.userGroupData = this.userGroupDataOrig.filter((userGroup) => {
        if (
          userGroup.group_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          return userGroup;
        } else {
          return;
        }
      });
    }
    this._manageUserGroups.setUserGroup(this.userGroupData);
  }
}
