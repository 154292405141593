<div *ngIf="verification == false">
  <app-sign-in
    *ngIf="initialLoad || screen == 'login'"
    (navigatePage)="goTo($event)"
  ></app-sign-in>
  <app-registration
    *ngIf="(initialLoad || screen == 'registration') && regFlag"
    (verificationScreen)="verify($event)"
    (navigatePage)="goTo($event)"
  ></app-registration>
</div>

<app-verification-container
  *ngIf="verification"
  [screen]="verificationType"
  (returnEvent)="goTo($event)"
></app-verification-container>
