import { Component, Input, OnInit } from '@angular/core';
import { Party } from 'src/app/@shared/models/party.model';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';



@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.sass']
})
export class PartyComponent implements OnInit {

  @Input()
  party: Party = {
    number: 1,
    name: 'John Doe',
    email: 'johndoe@email.com',
    designation: 'Court Attorney',
    role: 'Needs to Sign'
  }

  @Input()
  index: number = 1
  constructor(private _signsecure: SignSecureService) { }

  ngOnInit(): void {
  }

  role(): string {
    console.log({ role: this.party.role })
    return this._signsecure.getReadableRole(this.party.role)
  }

}
