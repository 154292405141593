import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observer, Subscription } from "rxjs";
import { UserGroupModel } from "src/app/@shared/models/user-group.model";
import { UserGroupResponseModel } from "src/app/@shared/models/user-groups-response.model";
import { UserTableModel } from "src/app/@shared/models/user-table.model";
import { UserModel } from "src/app/@shared/models/user.model";
import { UsersResponseModel } from "src/app/@shared/models/users-response.model";
import { AuthenticationService } from "src/app/@shared/services/authentication.service";
import { FileActionService } from "src/app/@shared/services/file-action.service";
import { FilesService } from "src/app/@shared/services/files.service";
import { LoaderService } from "src/app/@shared/services/loader.service";
import { NavigationService } from "src/app/@shared/services/navigation.service";
import { UserGroupService } from "src/app/@shared/services/user-group.service";
import { UsersService } from "src/app/@shared/services/users.service";
import { environment } from "src/environments/environment";
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: "app-admin-dashboard-content",
  templateUrl: "./admin-dashboard-content.component.html",
  styleUrls: ["./admin-dashboard-content.component.sass"],
})
export class AdminDashboardContentComponent
  implements OnInit, OnDestroy, AfterContentInit,OnChanges
{
  menuType: string = "";
  userType: string = "";
  recentDocmentTitle: string = "Recent Documents";
  recentUsersTitle: string = "Recent Users";
  usersData = this._userService.users$;
  isSearch: boolean = false;
  modifyFile: boolean = false;
  @Input()
  viewPdf: boolean = false;
  subs: Subscription = new Subscription();
  dialog: MatDialog;
  spinnerLoaded: boolean = false;
  readonly signaturesFlag = environment.flag.signatureMenu;
  isLoading = this._loaderService.isLoading;

  constructor(
    dialog: MatDialog,
    private _userService: UsersService,
    private _userGroupService: UserGroupService,
    private _navigation: NavigationService,
    private _fileActionService: FileActionService,
    private _fileService: FilesService,
    private _loaderService: LoaderService,
    private _authService: AuthenticationService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.dialog = dialog;
  }
  ngOnChanges(){
    this.changeDetector.detectChanges();
  }
  ngAfterContentInit(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this.menuType = this._navigation.initLocation;

    this.subs.add(
      this._userService.getUsers().subscribe((users) => {
        this._userService.setUsers(this._userService.formatUsers(users.data));
      })
    );
    this.subs.add(
      this._fileActionService.isModify$.subscribe((i) => {
        this.modifyFile = i;
      })
    );

    this.subs.add(
      this._fileActionService.isViewFile$.subscribe((i) => {
        if (!i) this.spinnerLoaded = i;
      })
    );

    this.subs.add(
      this._navigation.location$.subscribe((location) => {
        this.menuType = location;
        if (this._navigation.redirectSet.has(location)) {
          this._loaderService.show();
          const token = localStorage.getItem("token");
          this._navigation.redirectToSignSecureSite(
            location,
            token == null ? "" : token
          );

          this._navigation.updateLocation("report");
          this._loaderService.hide();
        }
      })
    );

    this.subs.add(
      this._navigation.serchView$.subscribe((i) => {
        this.isSearch = i;
        if (i) this._fileActionService.setIsModify(false);
      })
    );
  }

  modifyFileStatusUpdate(status: boolean) {
    if (this.isSearch) {
      this._fileService.searchFiles().subscribe((data) => {
        this._fileService.setSearchResults({
          searchQuery: this._fileService.searchQuery,
          data: this._fileService.formatSearchResults(data.data),
        });
      });
    }

    this._fileActionService.setIsModify(status);
  }

  ngOnDestroy() {
    this._navigation.initLocation = this.menuType;
    this.subs.unsubscribe();
  }
}
