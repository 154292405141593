<div class="main-wrapper">
  <ng-container
    *ngIf="!viewPdf && !workflow && !sign && !public && !formViewer">
    <app-wrapper-background
      *ngIf="userType.length == 0 && (isSessionActive$ | async)">
      <app-logo-container></app-logo-container>
      <app-form-container></app-form-container>
    </app-wrapper-background>

    <app-admin-dashboard-wrapper
      *ngIf="userType == 'admin' || userType == 'user'"
      [viewPdf]="viewPdf">
    </app-admin-dashboard-wrapper>
  </ng-container>

  <ng-container *ngIf="viewPdf">
    <app-pdf-viewer
      *ngIf="fileType == 'pdf'"
      [pdfSrc]="pdfSrc"></app-pdf-viewer>
    <app-image-view
      *ngIf="fileType != 'pdf'"
      [pdfSrc]="pdfSrc"></app-image-view>
  </ng-container>

  <ng-container *ngIf="workflow">
    <app-admin-dashboard-template></app-admin-dashboard-template>
  </ng-container>

  <ng-container *ngIf="public">
    <app-public-view></app-public-view>
  </ng-container>

  <ng-container *ngIf="sign">
    <app-signing-page></app-signing-page>
  </ng-container>

  <ng-container *ngIf="formViewer">
    <app-form-viewer></app-form-viewer>
  </ng-container>
</div>
