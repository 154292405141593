<div class="public-viewer-container">
  <div class="pdf-viewer-nav" *ngIf="!isPhoneWidth">
    <div class="left">
      <button (click)="goBack()" class="center-text-button">
        <img
          src="../../../assets/images/icons/back.svg"
          alt=""
          class="back-button"
        />
        Back to log in
      </button>
    </div>
    <div class="center">
      <button (click)="zoomLevel('in')">
        <img src="../../../assets//images/icons/zoom-in.svg" alt="" />
      </button>
      <button (click)="zoomLevel('out')">
        <img src="../../../assets//images/icons/zoom-out.svg" alt="" />
      </button>
      <select name="" id="" [(ngModel)]="zoom">
        <option value="0.25">25%</option>
        <option value="0.5">50%</option>
        <option value="0.75">75%</option>
        <option value="1">100%</option>
        <option value="1.25">125%</option>
        <option value="1.5">150%</option>
        <option value="1.75">175%</option>
        <option value="2">200%</option>
        <option value="2.25">225%</option>
        <option value="2.5">250%</option>
        <option value="2.75">275%</option>
        <option value="3">300%</option>
      </select>
      <div class="divider"></div>
      <button (click)="pageNavi('up')">
        <img
          src="../../../assets//images/icons/up.svg"
          alt=""
          class="back-button"
        />
      </button>
      <div class="page-text">{{ pageNumber }} of {{ totalPages }}</div>
      <button (click)="pageNavi('down')">
        <img
          src="../../../assets//images/icons/down.svg"
          alt=""
          class="back-button"
        />
      </button>
    </div>
    <div class="right">
      <button class="cta-buttons" (click)="download()">
        <img src="../../../assets/images/icons/download-white.svg" alt="" />
        <p>Download</p>
      </button>
    </div>
  </div>
  <div class="phone-nav" *ngIf="isPhoneWidth">
    <div class="left">
      <button (click)="goBack()">
        <img src="../../../assets/images/iphone-assets/back.svg" alt="" />
      </button>
    </div>
    <div class="right">
      <button (click)="download()">
        <img src="../../../assets/images/iphone-assets/download.svg" alt="" />
      </button>
    </div>
  </div>
  <pdf-viewer
    *ngIf="!isPhoneWidth"
    [src]="pdfSrc"
    [render-text]="true"
    [original-size]="true"
    [fit-to-page]="true"
    [zoom]="zoom"
    [(page)]="pageNumber"
    (after-load-complete)="afterLoadComplete($event)"
    class="pdf"
  ></pdf-viewer>
  <pdf-viewer
    *ngIf="isPhoneWidth"
    [src]="pdfSrc"
    [fit-to-page]="true"
    [zoom]="zoom"
    [(page)]="pageNumber"
    [show-borders]="true"
    (after-load-complete)="afterLoadComplete($event)"
    class="pdf"
  ></pdf-viewer>
  <div class="page-nav" *ngIf="isPhoneWidth">
    <button (click)="pageNavi('up')">
      <img src="../../../assets/images/iphone-assets/page-up.svg" />
    </button>
    <div class="page-number">{{ pageNumber }} of {{ totalPages }}</div>
    <button (click)="pageNavi('down')">
      <img src="../../../assets/images/iphone-assets/page-down.svg" />
    </button>
  </div>
</div>
