import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { MatSnackBar } from "@angular/material/snack-bar"
import { catchError, of } from "rxjs"
import { SignSecureService } from "src/app/@shared/services/signsecure.service"
import { SnackbarComponent } from "src/app/components/snackbar/snackbar.component"

@Component({
  selector: "app-decline-document",
  templateUrl: "./decline-document.component.html",
  styleUrls: ["./decline-document.component.sass"],
})
export class DeclineDocumentComponent implements OnInit {
  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  note: string = ""
  constructor(private _signSecure: SignSecureService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    console.log({ id: this.data })
  }

  approve() {
    this._signSecure
      .documentDecline(this.data?.data ?? "", this.note)
      .pipe(catchError((err, caught) => {
        this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: {
            type: "error",
            text: 'This document has been completed already. Please refresh your browser for the latest document updates and status.',
          },
        })
        return of()
      }))
      .subscribe(() => {
        this.close("document-declined")
      })
  }

  decline() {
    this.close("")
  }

  close(action: string) {
    this.closeModalEvent.emit({ action, note: this.note })
  }
}
