import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { catchError, of } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.sass'],
})
export class RecoverPasswordComponent implements OnInit {
  @Output() emailSentEvent = new EventEmitter<string>();
  @Output() navigatePage = new EventEmitter<string>();
  email: string = '';
  errorMsg: any;
  isValid:boolean = true;
  constructor(private _authService: AuthenticationService) {}

  ngOnInit(): void {}

  resetPassword() {
    this._authService
      .forgotPassowrd(this.email)
      .pipe(
        catchError((err) => {
          
          if (err.status == 401 || err.status == 404) {
            this.errorMsg = err.error.error;
            this.errorMsg = this.errorMsg.slice(15);
            this.isValid=false;
          }
          return of();
        })
      )
      .subscribe((data) => {
        this.isValid = true;
        this.emailSentEvent.emit('email-resetpw');
      });
  }
  checkValidity(){
    if(this.errorMsg.length>0){

    }

  }
  goTo(data: string): void {
    this.navigatePage.emit(data);
  }
}
