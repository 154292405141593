import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, catchError, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BulkFormsService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly formsUrl = environment.apiConfig.forms.url;
  private bulkIdData = new BehaviorSubject<any>([]);
  private bulkCertData = new BehaviorSubject<any>([]);
  bulkIdData$ = this.bulkIdData.asObservable();
  bulkCertData$ = this.bulkCertData.asObservable();
  total: number = 0;
  constructor(private _httpClient: HttpClient) {}

  getAllSCID() {
    //const encodedUserID = encodeURIComponent(userId)
    return this._httpClient.get(`${this.baseUrl}${this.formsUrl}/scid`);
  }
  
  getScIdById(id: string) {
    // const encodedUserID = encodeURIComponent(userId)
    return this._httpClient.get<any>(`${this.baseUrl}${this.formsUrl}/scid/${id}`);
  }

  getAllBulkSCID() {
    //const encodedUserID = encodeURIComponent(userId)
    return this._httpClient.get(`${this.baseUrl}${this.formsUrl}/bulk-id`);
  }

  getBulkSCID(id: string) {
    //const encodedUserID = encodeURIComponent(userId)
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/bulk-id/${id}`
    );
  }
  bulkUploadSCID(scid: any) {
    return this._httpClient.post<any>(
      `${this.baseUrl}${this.formsUrl}/bulk-id`,
      scid
    );
  }

  getAllBulkCertificate() {
    //const encodedUserID = encodeURIComponent(userId)
    return this._httpClient.get(
      `${this.baseUrl}${this.formsUrl}/bulk-certificate`
    );
  }

  getBulkCertificate(id: any) {
    //const encodedUserID = encodeURIComponent(userId)
    return this._httpClient.get(
      `${this.baseUrl}${this.formsUrl}/bulk-certificate/${id}`
    );
  }

  bulkUploadCertificate(bulkcert: any) {
    return this._httpClient.post(
      `${this.baseUrl}${this.formsUrl}/bulk-certificate`,
      bulkcert
    );
  }

  checkRollNumber(rollnumber: number) {
    return this._httpClient
      .get<any>(
        `${this.baseUrl}${this.formsUrl}/certificate/rollnumber/${rollnumber}`
      )
      .pipe(
        tap(data => data.data),
        catchError(() => of(false))
      );
  }

  setBulkIdRow(data: any[]) {
    this.total = data.length || 0;
    this.bulkIdData.next(data);
  }

  formatBulkScId(ids: any) {
    console.log('ids', ids);
    return ids
      .map((scId: any) => {
        const formattedFile = {
          employee_number: scId.employeeNumber || '',
          name: scId.fullName || '',
          position: scId.position || '',
          branch_station: scId.branchStation || '',
          date_created:
            moment(scId.createdAt).format('yyyy-MM-DD HH:mm:ss') || '',
          actions: {
            scid_qr: scId,
            scid_download: scId,
            scid_delete: scId,
          },
        };

        return formattedFile;
      })
      .reverse();
  }
  setBulkCertRow(data: any[]) {
    this.total = data.length || 0;
    this.bulkCertData.next(data);
  }
  formatBulkCertificate(ids: any, _id: string) {
    return ids
      .map((data: any) => {
        const formattedFile = {
          owner: data.fullName || '',
          roll_number: data.rollNumber || 0,
          clerk_name: data.clerkName || '',
          chief_name: data.chiefName || '',
          date_admitted: moment(data.dateAdmitted).format('DD MMM yyyy') || '',
          created: moment(data.createdAt).format('DD MMM yyyy') || '',
          chiefSignature: data.chiefSignature || '',
          clerkSignature: data.clerkSignature || '',
          date_created: moment(data.createdAt).format('MM/DD/YYYY'),
          actions: {
            // edit_certificate: { _id, ...data },
            form_download: { _id, ...data },
          },
        };

        return formattedFile;
      })
      .reverse();
  }
}
