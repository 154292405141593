<div class="inbox-table">
  <h4 class="title">Inbox</h4>

  <table mat-table [dataSource]="dataSource" matSort class="table">
    <ng-container matColumnDef="select"> </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let email">
        <div class="email-content">
          <h5 class="sender">{{ email.sender }}</h5>
          <h6 class="title">{{ email.subject }}</h6>
          <span class="content">{{ email.message }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date and Time</th>
      <td mat-cell *matCellDef="let email">
        {{ email.date_modified | date : "d MMM y | h:mm" }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      (click)="rowFunction($event, element)"
      *matRowDef="let element; columns: displayedColumns"
      class="{{ toLowercase(element.status) }}"
    ></tr>
  </table>
</div>
