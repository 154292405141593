import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription, catchError, of, switchMap } from "rxjs";
import { ModalData } from "src/app/@shared/models/Modal.model";
import { UsersService } from "src/app/@shared/services/users.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.sass"],
})
export class EditUserComponent implements OnInit, OnDestroy {
  @Input() data: ModalData = {
    type: "",
    action: "",
  };
  @Output() closeModalEvent = new EventEmitter();
  updateError: boolean = false;
  errMsg: string = '';
  private _subs = new Subscription();
  editUserForm: FormGroup = this.fb.group({
    givenName: [
      this.data.data?.givenName ? this.data.data.givenName : "",
      [Validators.required],
    ],
    lastName: [
      this.data.data?.lastName ? this.data.data.lastName : "",
      [Validators.required],
    ],
    email: [
      { value: this.data.data?.email ? this.data.data.email : "", disabled: true },
      [
        Validators.required,
        Validators.pattern(
          "^[a-z0-9._%+-]+@(test.gov.ph|dfa.gov.ph|unawa.asia|judiciary.gov.ph|outlook.com)$"
        ),
      ],
    ],
    position: [this.data.data?.position ? this.data.data.position : "", []],
    role: [
      this.data.data?.role ? this.data.data.role : "",
      [Validators.required],
    ],
  });

  constructor(private fb: FormBuilder, private _usersService: UsersService) { }

  ngOnInit(): void {
    this.editUserForm.controls["givenName"].setValue(this.data.data?.givenName);
    this.editUserForm.controls["lastName"].setValue(this.data.data?.lastName);
    this.editUserForm.controls["email"].setValue(this.data.data?.email);
    this.editUserForm.controls["role"].setValue(this.data.data?.role);
    this.editUserForm.controls["position"].setValue(this.data.data?.position);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  updateUser() {
    const user = this.getUserData();

    if (this.editUserForm.valid) {
      this._subs = this._usersService
        .udpateUser(user)
        .pipe(
          catchError((err) => {
            this.updateError = true;
            this.errMsg = err.error.error;
            console.log(err);
            return of();
          }),
          switchMap((i) => {
            return this._usersService.getUsers();
          })
        )

        .subscribe((result) => {
          this._usersService.setUsers(
            this._usersService.formatUsers(result.data)
          );
          this.closeModal();
        });
    }
  }
  closeModal(): void {
    this.closeModalEvent.emit();
  }

  private getUserData() {
    const { givenName, lastName, email, role, position } =
      this.editUserForm.controls;
    const user = { ...this.data.data };
    user["givenName"] = givenName.value;
    user["lastName"] = lastName.value;
    user["email"] = email.value;
    user["role"] = role.value;
    user["position"] = position.value;

    return user;
  }
}
