import { SelectionModel } from "@angular/cdk/collections";
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
	combineLatest,
	lastValueFrom,
	map,
	Subscription,
	take,
	zip,
} from "rxjs";
import { Breadcrumb } from "src/app/@shared/models/breadcrumb.model";
import { BreadcrumbsService } from "src/app/@shared/services/breadcrumbs.service";
import { FileActionService } from "src/app/@shared/services/file-action.service";
import { FilesService } from "src/app/@shared/services/files.service";
import { LoaderService } from "src/app/@shared/services/loader.service";
import { ModalComponent } from "src/app/components/modal/modal.component";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-admin-dashboard-folder-files",
	templateUrl: "./admin-dashboard-folder-files.component.html",
	styleUrls: ["./admin-dashboard-folder-files.component.sass"],
})
export class AdminDashboardFolderFilesComponent implements OnInit, OnDestroy {
	filesCols: string[] = [
		" ",
		"title",
		"Date Modified",
		"Size",
		"OCR",
		"Indexed",
		"actions",
	];
	filesData: any = this._fileService.filesAndFolderAsync$;
	hasCheckBox: boolean = environment.flag.multiFileSelect;
	modifyFile = this._fileActionService.isModify$;
	folderFunctions = {
		openFile: this.openFile,
	};

	selection: SelectionModel<any> = new SelectionModel<any>(true, []);

	subs = new Subscription();
	// to keep track  statuses
	viewFile: boolean = false;
	modify: boolean = false;
	readonly multipleFileActionsFlag = environment.flag.multipleFileActions;
	constructor(
		private _fileService: FilesService,
		private _fileActionService: FileActionService,
		private _breadcrumbs: BreadcrumbsService,
		private _loader: LoaderService,
		public dialog: MatDialog
	) { }

	ngOnDestroy(): void {
		this.subs.unsubscribe();
		if (!this.viewFile && !this.modify) {
			this._breadcrumbs.emptyBreadcrumb();
		}
	}

	ngOnInit(): void {
		this.subs.add(this.getFilesAndFolderData());

		this.subs.add(
			this._fileActionService.isViewFile$.subscribe((i) => {
				this.viewFile = i;
			})
		);
		this.subs.add(
			this._fileActionService.isModify$.subscribe((i) => {
				this.modify = i;
			})
		);
	}

	createFolder(data: any) {
		const { dialog, type: action, breadcrumb } = data;
		const dialogRef = dialog?.open(ModalComponent, {
			data: { action, data: breadcrumb },
		});

		dialogRef.afterClosed()?.subscribe(async () => {
			this.selection.clear();
		});
	}

	openFile(file: any): void {
		this._fileActionService.file = file;
		this._fileActionService.setIsModify(true);
	}

	donwloadSelected() {
		this.selection.selected.forEach((data) => {
			if (data.file_type == "file") {
				this._fileService.downloadFile(
					data.title,
					`assets/${data.actions?.choices?.download?.path}`
				);
			} else {
				this._fileService.downloadFolder(data.id).subscribe((response: any) => {
					const blob = new Blob([response], { type: "application/zip" });
					const blobUrl = URL.createObjectURL(blob);
					const link = document.createElement("a");
					link.href = blobUrl;
					link.setAttribute("download", `${data.title}.zip`);
					document.body.appendChild(link);
					link.click();
					link.remove();
				});
			}
		});
	}

	deleteSelected() {
		const dialogRef = this.dialog.open(ModalComponent, {
			width: "512px",
			data: {
				action: "delete-selected-files",
				data: this.selection.selected,
			},
		});

		dialogRef.afterClosed()?.subscribe(() => { });
	}

	openFolder(event: any, data: any) {
		if (event.target.className.includes("checkbox")) {
			return;
		}

		const { title, file_type, id } = data;
		console.log({folderData: data})
		if (file_type === "folder") {
			this._breadcrumbs.setBreadcrumbsData({ title, id, file: data });
			if (id === "signsecure") {
				this._fileService.getWorkflowData(99999).subscribe(({ data }) => {
					const format = this._fileService.formatFilesAndFolderData(data, true);
					this._fileService.setFilesAndFolderAsync(format);
				});
			} else {
				console.log({ id })
				this._fileService.getAllFolderFiles(id).subscribe(({ data }) => {
					const format = this._fileService.formatFilesAndFolderData(data);
					this._fileService.setFilesAndFolderAsync(format);
				});
			}
		} else if (file_type === "back") {
			const breadcrumb = this._breadcrumbs.removeBreadcrumbData();

			if (breadcrumb.length === 0) {
				this._fileService.getAllFilesAndFolder().subscribe((data) => {
					const format = this._fileService.formatFilesAndFolderData(data.data);
					this._fileService.setFilesAndFolderAsync(format);
				});
			} else {
				this._fileService
					.getAllFolderFiles(breadcrumb[breadcrumb.length - 1].id)
					.subscribe(({ data }) => {
						const format = this._fileService.formatFilesAndFolderData(data);
						this._fileService.setFilesAndFolderAsync(format);
					});
			}
		}
	}

	onFolderBack(data: Breadcrumb) {
		this._fileActionService.setIsModify(false);
		if (data.id === "") {
			this._breadcrumbs.emptyBreadcrumb();
			this._fileService.getAllFolder().subscribe(({ data }) => {
				this._fileService.setFolderAsync(this._fileService.formatFolder(data));
			});
			this._fileService.getAllFilesAndFolder().subscribe(({ data }) => {
				const format = this._fileService.formatFilesAndFolderData(data);
				const signSecure = {
					id: "signsecure",
					title: "SignSecure workflow documents",
					file_type: "folder",
					status: "",
				};
				this._fileService.setFilesAndFolderAsync([signSecure, ...format]);

			});
		} else {
			const loc = this._breadcrumbs.breadcrumbsData.findIndex(
				(datum) => datum.id === data.id
			);
			this._breadcrumbs.backBreadcrumb(loc);

			if (data.id === 'signsecure') {
				this._fileService.getWorkflowData(99999).subscribe(({ data }) => {
					const format = this._fileService.formatFilesAndFolderData(data, true);
					this._fileService.setFilesAndFolderAsync(format);
				});
			} else {
				this._fileService.getAllFolderFiles(data.id).subscribe(({ data }) => {
					const format = this._fileService.formatFilesAndFolderData(data);
					this._fileService.setFilesAndFolderAsync(format);
				});
			}


		}
	}

	openPdf(data: any) {
		if (data.file_type === "folder" || data.file_type === "back") return;

		let url =
			data?.action?.choices?.download?.path ||
			data?.actions?.choices?.download?.path ||
			data?.action?.download?.path ||
			data?.actions?.download?.path;

		if (
			data.fileExtension == "docx" ||
			data.fileExtension == "doc" ||
			data.fileExtension == "xls" ||
			data.fileExtension == "xlsx" ||
			data.fileExtension == "ppt" ||
			data.fileExtension == "pptx"
		) {
			let office = "";
			if (data.fileExtension == "docx" || data.fileExtension == "doc") {
				office = "ms-word";
			}
			if (data.fileExtension == "xls" || data.fileExtension == "xlsx") {
				office = "ms-excel";
			}
			if (data.fileExtension == "ppt" || data.fileExtension == "pptx") {
				office = "ms-powerpoint";
			}

			window.location.assign(
				office + ":ofv|u|" + window.location.origin + "/assets/" + url
			);
			return;
		}
		url = `../../../assets/${url}`;

		this._fileActionService.pdfSrc = url;
		this._fileActionService.file = data;
		this._fileActionService.setIsViewFile(true);
	}

	getFilesAndFolderData() {
		const temp =
			this._breadcrumbs.breadcrumbsData[
			this._breadcrumbs.breadcrumbsData.length - 1
			];
		let breadcrumb: any;
		if (temp?.id === "") {
			breadcrumb = this._breadcrumbs.removeBreadcrumbData();
		} else {
			breadcrumb = [temp];
		}
		console.log({ breadcrumb })
		if (!!!temp || breadcrumb.length === 0) {

			return this._fileService.getAllFilesAndFolder().subscribe((data) => {
				const format = this._fileService.formatFilesAndFolderData(data.data);

				const signSecure = {
					id: "signsecure",
					title: "SignSecure workflow documents",
					file_type: "folder",
					status: "",
				};
				this._fileService.setFilesAndFolderAsync([signSecure, ...format]);

			});
		} else if (breadcrumb[breadcrumb.length - 1].id === "signsecure") {
			return this._fileService.getWorkflowData(99999).subscribe(({ data }) => {
				const format = this._fileService.formatFilesAndFolderData(data, true);
				this._fileService.setFilesAndFolderAsync(format);
			});
		} else {
			return this._fileService
				.getAllFolderFiles(breadcrumb[breadcrumb.length - 1].id)
				.subscribe(({ data }) => {
					const format = this._fileService.formatFilesAndFolderData(data);
					this._fileService.setFilesAndFolderAsync(format);
				});
		}
	}

	modalCloses(data: any) {
		if (data?.modalAction == "share-permission") {
			this.getFilesAndFolderData();
		}
	}
}
