import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, debounceTime, lastValueFrom } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';
import { Breadcrumb } from '../models/breadcrumb.model';
import { FileDataModel } from '../models/file-data.model';
import { AuthenticationService } from '../services/authentication.service';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { InboxService } from '../services/inbox.service';
import { NavigationService } from '../services/navigation.service';
import { SignSecureService } from '../services/signsecure.service';
import { SafeformService } from '../services/safeform.service';
import { QrService } from '../services/qr.service';
import { LoaderService } from '../services/loader.service';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @Input('columns')
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  @Input() isSearch: boolean = false;
  @Input() resultsLength: number = 0;
  @Input('data')
  data: any;
  isInitialLoad: boolean = true;
  @Input()
  title: string = 'Recent Documents';

  @Input()
  addButton: string = '';

  @Input()
  addButtonIcon: string = '';

  @Input() hasCheckBox: boolean = false;

  @Input()
  showFilter: boolean = false;

  @Output('modal')
  showModal: EventEmitter<any> = new EventEmitter<any>();

  @Input() type: string = '';

  @Output('modalCloses') callback: EventEmitter<any> = new EventEmitter<any>();

  @Output('clickedRow') clicked: EventEmitter<any> = new EventEmitter<any>();

  checkBool: boolean = false;
  dataSource = new MatTableDataSource();

  @Input()
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);
  selectionLength: number = 0;

  file: any;

  @Input()
  canOpenFile: boolean = false;

  @Input()
  breadcrumb: Breadcrumb[] = [];

  @Input('paginate')
  isPaginate: boolean = false;

  @Input()
  pageSize: number = 5;

  @Input()
  showBreadcrumbs: boolean = false;

  @Input()
  isFolderStructure: boolean = true;

  @Input()
  folderFunctions: any;

  @Input()
  modifyFile: boolean | null = false;

  @Input()
  hideTitle: boolean = false;

  @Input()
  nameFunction: Function = (element: any) => { };

  @Input()
  rowFunction: Function = (event: any, element: any) => { };

  @Input()
  breadcrumbBack: Function = (event: any) => { };

  readonly workflow = environment.flag.useLocalSign;
  public delete = { delete: true };

  debounceClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  // don't remove the unused services they are used in the different components
  constructor(
    public dialog: MatDialog,
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _breadcrumbs: BreadcrumbsService,
    private _inboxService: InboxService,
    private _navigation: NavigationService,
    private _signSecureService: SignSecureService,
    private _safeFormService: SafeformService,
    private _qr: QrService,
    private _loader: LoaderService
  ) { }
  ngAfterViewInit() {
    this.selection.changed.subscribe(s => { });
    this._breadcrumbs.breadcrumbs$.subscribe(i => {
      this.breadcrumb = i;
    });
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: any): void {
    this.dataSource.data = this.data;
    console.log({ tableData: this.data });
  }

  ngOnInit(): void { 
    this.debounceClick.pipe(debounceTime(1000)).subscribe(data => this.debounceClick.next(!data))
  }

  transformToSnakecase(val: string): string {
    return val.replaceAll(' ', '_').toLowerCase();
  }

  checkSelectAll(value: any): boolean {
    let checkBoxStatus;
    if (this.isInitialLoad) {
      checkBoxStatus = value;
    } else {
      checkBoxStatus = false;
    }
    return checkBoxStatus;
  }

  checkAllChanges({ checked }: any) {
    if (checked) {
      this.checkAll();
    } else {
      this.uncheckAll();
    }
  }

  checkAll(): void {
    this.selection.select();
    this.selection.select(...this.dataSource.data);
  }

  uncheckAll(): void {
    this.selection.clear();
  }

  getClassTh(column: string): string {
    return this.hasCheckBox && column === ' '
      ? 'header checkbox-container'
      : 'header';
  }
  getClassTd(column: string): string {
    return this.hasCheckBox && column === ' ' ? 'checkbox-container' : '';
  }

  getObjectImage(fileType: string) {
    switch (fileType) {
      case 'file':
        return '../../../assets/images/icons/file.png';
      case 'folder':
        return '../../../assets/images/icons/folder.png';
      default:
        return '../../../assets/images/icons/file.png';
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  hasSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  select(event: any, element: any) {
    if (event.checked) {
      this.selection.select(element);
    } else {
      this.selection.deselect(element);
    }
  }

  openFile(data: any): void {
    this.modifyFile = true;
  }

  modifyFileEvent(data: any): void {
    this._fileActionService.file = data;
    this._fileActionService.breadCrumbs;
    this.modifyFile = !this.modifyFile;
    this._fileActionService.setIsModify(this.modifyFile);
  }

  async generateBreadcrumbs(fileDatas: any[], file: any) {
    if (file.parent) {
      const { data } = await this.getFile(file.parent);
      fileDatas.push(data);
      await this.generateBreadcrumbs(fileDatas, data);
    }
  }

  async getFile(fileId: string) {
    return await lastValueFrom(this._fileService.getFile(fileId));
  }

  getStatusColor(status: string) {
    switch (status) {
      case 'PENDING':
        return 'grey';
      case 'VIEWED':
      case 'SIGNED':
      case 'APPROVED':
      case 'COMPLETED':
        return 'green';
      case 'DECLINED':
      case 'REJECTED':
        return 'red';
      default:
        return 'grey';
    }
  }

  createForm() {
    this._navigation.updateLocation('templates');
  }

  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }
}
