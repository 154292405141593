<mat-card class="container-reg">
  <div class="flexGrid">
    <h2 class="heading2">Create Your Account</h2>
    <h4 class="heading4">Enter your account details below</h4>
  </div>
  <form name="signUpForm" [formGroup]="signUpForm">
    <div class="flex">
      <mat-label class="labelReg">Email</mat-label>
    </div>
    <div class="flex padding-left150">
      <mat-form-field class="form" floatLabel="never" appearance="fill">
        <input
          type="email"
          placeholder="Enter your email"
          matInput
          formControlName="email"
          class="textfieldReg"
          [ngClass]="{
            'textfieldreg-error':
              signUpForm.controls['email'].invalid &&
              signUpForm.controls['email'].touched
          }"
          required
        />
        <mat-error
          class="padding-top20"
          *ngIf="signUpForm.controls['email'].invalid"
        >
          This email is not existing within this organization
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-label class="labelReg">Fullname</mat-label>
    </div>
    <div class="flex padding-left150">
      <mat-form-field class="form" floatLabel="never" appearance="fill">
        <input
          formControlName="name"
          placeholder="First and Last name"
          matInput
          class="textfieldReg"
        />
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-label class="labelReg">Phone Number</mat-label>
    </div>
    <div class="flex padding-left150">
      <mat-form-field class="form" floatLabel="never" appearance="fill">
        <input
          placeholder="+63917 123 4567"
          matInput
          class="textfieldReg"
          formControlName="phoneNumber"
        />
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-label class="labelReg">Password</mat-label>
    </div>
    <div class="flex padding-left150">
      <mat-form-field class="form" floatLabel="never" appearance="fill">
        <input
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          matInput
          class="textfieldReg"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
    </div>
    <div class="flex">
      <mat-label class="labelReg">Confirm Password</mat-label>
    </div>
    <div class="flex padding-left150">
      <mat-form-field class="form" floatLabel="never" appearance="fill">
        <input
          [type]="hideConfirm ? 'password' : 'text'"
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          matInput
          class="textfieldReg"
        />
        <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
          hideConfirm ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
    </div>
  </form>
  <div class="flexCenter padding-top40">
    <mat-checkbox class="padding-left20 col"
      ><img src="{{ regConfirmation }}"
    /></mat-checkbox>
  </div>

  <div class="flex padding-left150 padding-top20">
    <button class="reg-btn" (click)="verify('email-sent')">
      <label class="btn-font">Verify your account</label>
    </button>
  </div>

  <div class="flexCenter padding-top40">
    <label>Already have an account? </label>
    <label class="label-clickable" (click)="goTo('login')">Login</label>
  </div>
</mat-card>
