<app-spinner></app-spinner>
<div class="container">
  <div class="header-container">
    <h4 class="title">My Account</h4>
  </div>
  <div class="account-container">
    <div class="account-title">Personal Info</div>
    <div class="account-sub-title">Update your personal information here</div>
    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>First Name</mat-label>
        <mat-form-field
          class="input-form-pair"
          floatLabel="never"
          appearance="fill"
        >
          <input
            type="text"
            matInput
            class="textfieldReg"
            [(ngModel)]="givenName"
          />
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label>Last Name</mat-label>
        <mat-form-field
          class="input-form-pair"
          floatLabel="never"
          appearance="fill"
        >
          <input [(ngModel)]="lastName" matInput />
        </mat-form-field>
      </div>
    </div>

    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>Email</mat-label>
        <mat-form-field
          class="input-form-pair"
          floatLabel="never"
          appearance="fill"
        >
          <input
            type="email"
            [disabled]="true"
            [(ngModel)]="email"
            matInput
            class="textfieldReg"
          />
        </mat-form-field>
      </div>
      <form [formGroup]="editAccountForm">
        <div class="input-group">
          <mat-label>Mobile Number</mat-label>
          <mat-form-field
            class="input-form-pair"
            floatLabel="never"
            appearance="fill"
          >
            <ngx-mat-intl-tel-input
              [preferredCountries]="['ph']"
              formControlName="phoneNumber"
              #phone
            ></ngx-mat-intl-tel-input>

            <!--<input type="text" matInput class="textfieldReg" />!-->
          </mat-form-field>
        </div>
      </form>
    </div>
    <div class="btnGrp">
      <!--<button class="cancel" (click)="close()">Cancel</button>!-->
      <button class="create" (click)="updateProfile()">
        Update personal info
      </button>
    </div>

    <div class="account-title">Change Password</div>
    <div class="account-sub-title">Update your password here</div>
    <div class="flex">
      <mat-label class="label">Current Password</mat-label>
    </div>
    <div class="flex">
      <mat-form-field
        class="input-form-pair label"
        floatLabel="never"
        appearance="fill"
      >
        <input
          [type]="hideOldPw ? 'password' : 'text'"
          [(ngModel)]="oldPassword"
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          matInput
          class="textfieldReg"
        />
        <mat-icon matSuffix (click)="hideOldPw = !hideOldPw">{{
          hideOldPw ? "visibility_off" : "visibility"
        }}</mat-icon>
      </mat-form-field>
    </div>
    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>New Password</mat-label>
        <mat-form-field
          class="input-form-pair"
          floatLabel="never"
          appearance="fill"
        >
          <input
            [type]="hideNewPw ? 'password' : 'text'"
            [(ngModel)]="newPassword"
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
            matInput
          />
          <mat-icon matSuffix (click)="hideNewPw = !hideNewPw">{{
            hideNewPw ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label>Confirm Password</mat-label>
        <mat-form-field
          class="input-form-pair"
          floatLabel="never"
          appearance="fill"
        >
          <input
            [type]="hideConfirm ? 'password' : 'text'"
            [(ngModel)]="confirmPassword"
            placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
            matInput
          />
          <mat-icon matSuffix (click)="hideConfirm = !hideConfirm">{{
            hideConfirm ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
      </div>
    </div>

    <div class="btnGrp">
      <!--<button class="cancel" (click)="close()">Cancel</button>!-->
      <button class="create" (click)="updatePassword()">Change Password</button>
    </div>
  </div>
</div>
