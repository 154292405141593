<div style="overflow: auto; max-height: 800px">
  <!-- START: Add New User -->
  <app-add-new-user
    *ngIf="data.action === 'mUser'"
    (closeModalEvent)="closeModal($event)"></app-add-new-user>
  <!-- END: Add New User -->

  <!-- START: edit User -->
  <app-edit-user
    *ngIf="data.action === 'mUser-editUser'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-edit-user>
  <!-- END: edit User -->

  <!-- START: delete User -->
  <app-delete-user
    *ngIf="data.action === 'mUser-deleteUser'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-user>
  <!-- END: delete User -->

  <!-- START: send user verification -->
  <app-sent-confirmation
    *ngIf="data.action === 'mUser-verification'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-sent-confirmation>
  <!-- END: send user verification -->

  <!-- START: Add New User Group-->
  <app-add-user-group
    *ngIf="data.action === 'mUserGrp'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-add-user-group>
  <!-- END: Add New User Group -->

  <!-- START: edit User Group -->
  <app-edit-user-group
    *ngIf="data.action === 'mUserGrp-editUser'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-edit-user-group>
  <!-- END: edit User Group-->

  <!-- START: delete User Group -->
  <app-delete-user-group
    *ngIf="data.action === 'mUserGrp-deleteUser'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-user-group>
  <!-- END: delete User Group -->

  <!-- START: Upload Files -->
  <app-upload-file
    *ngIf="data.action === 'upload-documents'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-upload-file>
  <!-- END: Upload Files -->

  <!-- START: Share Files -->
  <app-share-file
    *ngIf="data.action === 'share-permission'"
    (closeModalEvent)="closeModal($event)"
    [data]="data"></app-share-file>
  <!-- End: Share Files -->

  <!-- START: Drag and Drop Upload -->
  <app-upload
    *ngIf="data.action === 'drag-drop-upload'"
    (closeModalEvent)="closeModal($event)"></app-upload>
  <!-- End: Drag and Drop Upload -->

  <app-support-upload
    *ngIf="data.action === 'upload-attachment'"
    (closeModalEvent)="closeModal($event)">
  </app-support-upload>

  <!-- START: delete Recent Documents -->
  <app-delete-user
    *ngIf="data.action === 'recent-documents-deleteUser'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-user>
  <!-- END: delete ecent Documents -->

  <!-- START: OCR modal-->
  <app-ocr-preview
    *ngIf="data.action === 'run-ocr' || data.action === 'edit-ocr'"
    [ocrAction]="data.action"
    (closeModalEvent)="closeModal($event)">
  </app-ocr-preview>
  <!-- END: OCR modal-->

  <app-create-folder
    *ngIf="data.action === 'create-folder'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-create-folder>

  <!-- START: delete files and document -->
  <app-delete-file
    *ngIf="data.action === 'delete-selected-files'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-file>
  <!-- END: delete files and document -->

  <!-- START: delete document -->
  <app-delete-document
    *ngIf="data.action === 'recent-documents-deleteDocument'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-document>
  <!-- END: delete document -->

  <!-- START: add signatures -->
  <app-add-signature
    *ngIf="data.action === 'signatures'"
    (closeModalEvent)="closeModal($event)"></app-add-signature>
  <!-- END: add signatures -->

  <!-- START: recent doc info -->
  <app-recent-doc-info
    *ngIf="
      data.action === 'recent-documents-recent-doc-info' ||
      data.action === '-recent-doc-info'
    "
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-recent-doc-info>
  <!-- END: recent doc info -->

  <app-delete-inbox
    *ngIf="data.action === 'delete-message'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-inbox>

  <app-delete-signature
    *ngIf="data.action === 'signatures-deleteUser'"
    (closeModalEvent)="closeModal($event)"
    [data]="data"></app-delete-signature>

  <app-members-file
    *ngIf="data.action == 'viewMembers'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-members-file>

  <app-move-file
    *ngIf="data.action == 'move-file'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-move-file>

  <app-sent-confirmation
    *ngIf="data.action == 'user-created'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-sent-confirmation>

  <app-create-signature
    *ngIf="data.action == 'create-signature'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-create-signature>

  <app-create-initials
    *ngIf="data.action == 'create-initial'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-create-initials>

  <app-accept-reject
    *ngIf="data.action == 'workflow-reject-accept'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-accept-reject>

  <app-cancel-document
    *ngIf="data.action == 'cancel-document'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-cancel-document>

  <app-cancel-document-success
    *ngIf="data.action == 'document-cancelled'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-cancel-document-success>

  <app-decline-document
    *ngIf="data.action == 'decline-document'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-decline-document>

  <app-qr-scanner
    *ngIf="data.action == 'scan-qr'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-qr-scanner>

  <app-qr-verification-success
    *ngIf="data.action == 'scan-qr-success'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-qr-verification-success>

  <app-qr-verification-fails
    *ngIf="data.action == 'scan-qr-fails'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-qr-verification-fails>

  <app-qr-restricted
    *ngIf="data.action == 'scan-qr-restrict'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-qr-restricted>

  <app-delete-scid
    *ngIf="data.action == 'sc_ID-deleteScid'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-scid>

    <app-delete-scid
    *ngIf="data.action == 'bulkIdData-deleteScid'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-delete-scid>

    
  <app-otp-dialog
    *ngIf="data.action == 'otp'"
    [data]="data"
    (closeModalEvent)="closeModal($event)"></app-otp-dialog>
</div>
