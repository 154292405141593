<div class="party" *ngIf="!isPhoneWidth">
  <p class="party__number" [matTooltip]="party.number || party.order">
    {{ party.number || party.order }}
  </p>
  <p class="party__name" [matTooltip]="party.name">{{ party.name }}</p>
  <p class="party__email" [matTooltip]="party.email">{{ party.email }}</p>
  <p class="party__role" [matTooltip]="role()">{{ role() }}</p>
  <p
    class="party__status {{ getStatusColor(getStatus(party)) }}"
    [matTooltip]="getStatus(party)"
  >
    {{ getStatus(party) }}
  </p>
</div>

<div class="party-phone" *ngIf="isPhoneWidth">
  <div class="number-container">
    <p class="party__number">{{ party.number || party.order }}</p>
    <span class="line"></span>
  </div>
  <div class="party-details">
    <p class="party__name">{{ party.name }}</p>
    <p class="party__email">{{ party.email }}</p>
    <p class="party__role">{{ role() }}</p>
    <p
      class="party__status {{ getStatusColor(party.status) }}"
      [matTooltip]="party.status || 'PENDING'"
    >
      {{ party.status || "PENDING" }}
    </p>
  </div>
</div>
