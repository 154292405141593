import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.sass'],
})
export class ProgressCardComponent implements OnInit {
  @Input()
  image: string = '../../../assets/images/icons/duplicate-icon.png';

  @Input()
  title: string = 'Duplicate';

  @Input()
  number: string = '14';

  @Input()
  location: string = '';

  @Input()
  isPhoneWidth: boolean = false;

  @Input()
  actionData: any;

  @Input()
  textColor: string = '';

  @Output() filterEvent = new EventEmitter<string>();

  @Input()
  selected?: string = '';

  constructor(private _navigationService: NavigationService) {}

  ngOnInit(): void {}

  filterData(filter?: string) {
    this.selected = filter;
    this.filterEvent.emit(filter);
  }
  navigateTo(loc: string) {
    this._navigationService.setLocation(loc);
  }
}
