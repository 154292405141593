<div class="snackbar-container">
  <ng-container *ngIf="data.type == 'FolderCreated'">
    <img class="icon" src="../../../assets/images/icons/success.svg" />
    <p class="text">Folder Created</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="data.type == 'UserUpdated'">
    <img class="icon" src="../../../assets/images/icons/success.svg" />
    <p class="text">User Updated</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="data.type == 'TicketCreated'">
    <img class="icon" src="../../../assets/images/icons/success.svg" />
    <p class="text">Concern Submitted</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="data.type == 'DirectoryMoved'">
    <img class="icon" src="../../../assets/images/icons/success.svg" />
    <p class="text">{{ data.dataType }} moved</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="data.type == 'CopyLink'">
    <img class="icon" src="../../../assets/images/icons/success.svg" />
    <p class="text">Link Copied</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="data.type == 'error'">
    <img class="icon" src="../../../assets/images/icons/error.png" />
    <p class="text">{{ data.text }}</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>

  <ng-container *ngIf="data.type == 'publish'">
    <img class="icon" src="../../../assets/images/icons/success.svg" />
    <p class="text">{{ data.text }}</p>
    <button class="close" (click)="closeSnackbar()">
      <img src="../../../assets/images/icons/snack-bar-close.svg" />
    </button>
  </ng-container>
</div>

