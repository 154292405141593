import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, map } from 'rxjs';
import { BulkFormsService } from 'src/app/@shared/services/bulk-forms.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { QrService } from 'src/app/@shared/services/qr.service';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bulk-certificate-table',
  templateUrl: './bulk-certificate-table.component.html',
  styleUrls: ['./bulk-certificate-table.component.sass'],
})
export class BulkCertTableComponent implements OnInit {
  columns: string[] = [
    'owner',
    'roll number',
    'date admitted',
    'created',
    'actions',
  ];
  bulkCert: any;
  title: string = '';
  bulkCertData = this.bulkFormService.bulkCertData$;
  data: any = this.bulkFormService.bulkCertData$;
  searchQuery: string = '';

  constructor(
    private bulkFormService: BulkFormsService,
    private _navigation: NavigationService,
    private _safeFormService: SafeformService,
    private _loader: LoaderService,
    private _qr: QrService
  ) { }

  ngOnInit(): void {
    this.bulkCert = localStorage.getItem('bulkCert');
    console.log('this._navigation.getParams ', this.bulkCert);
    this.getBulkCertificate(this.bulkCert);
  }

  getBulkCertificate(id: string) {
    this.bulkFormService.getBulkCertificate(id).subscribe((res: any) => {
      console.log('getBulkCertificate', res);
      if (res) {
        this.title = res.data.batchName;
        this.bulkFormService.setBulkCertRow(
          this.bulkFormService.formatBulkCertificate(
            res.data.certificates,
            res.data._id
          )
        );
      }
      this._loader.hideNow()
    });
  }

  async selectedRow(event: any, data: any) {
    if (event.target.className.includes('checkbox')) {
      return;
    }

    const files = data.actions.form_download.filepath;

    if (!!files.length) {
      this._safeFormService.viewerData = {
        ...data.actions.form_download,
        pdfSrc: files[files.length - 1],
        prevLocation: 'Certificate',
        prev: 'bulk-certificate-table',
        name: `${data.actions.form_download.fullName} - Certificate`,
      };
      this._navigation.updateLocation('formViewer');
    } else {
      let certValue = data.actions.form_download;
      console.log({ certValue });
      const fileData: any = {};
      fileData['certificateName'] = certValue.fullName;
      fileData['rollNumber'] = certValue.rollNumber;
      fileData['dateAdmitted'] = certValue.dateAdmitted
        ? moment(certValue.dateAdmitted)
          .format('Do [day of] MMMM, YYYY')
          .toString()
        : certValue.dateAdmitted;
      fileData['dateAttestation'] = moment(certValue.dateOfAttestation)
        .format('Do [day of] MMMM, YYYY')
        .toString();
      fileData['clerkName'] = certValue.clerkName;
      fileData['chiefName'] = certValue.chiefName;
      fileData['prev'] = 'bulk-certificate-table';
      const qrCodeText = `${window.location.href}?ce=${certValue.qrID}`;
      const qrCodeDataImage = await this._qr.getQRCode(qrCodeText);
      fileData['qrCode'] = qrCodeDataImage
      if (environment.local) {
      } else {
        fileData['clerkSigUpload'] = `./assets/${certValue.clerkSignature}`;
        fileData['chiefSigUpload'] = `./assets/${certValue.chiefSignature}`;
      }

      console.log({ fileData })
      this._safeFormService.certificateId = data.actions.form_download._id;
      this._safeFormService.generateMissingPDF(fileData);
    }
  }

  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }

  search() {
    this.bulkCertData = this.data.pipe(
      map((tableData: any) => {
        console.log({ tableData, search: this.searchQuery });
        return tableData.filter((tableDatum: any) => {
          return tableDatum?.owner
            ?.toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      })
    );
  }
}
