<form class="reset-password" [formGroup]="resetPasswordForm">
  <h2 class="header">Reset your password</h2>
  <h4 class="subheader">
    Please input a new password that you haven't used before.
  </h4>

  <div class="flex flex-center padding-top40">
    <mat-label class="textfield-label flex">
      <span class="flex-self-left">New Password</span></mat-label
    >
    <mat-form-field floatLabel="never" appearance="fill">
      <input
        [type]="isPasswordHidden ? 'password' : 'text'"
        type="email"
        placeholder="Enter your email"
        matInput
        class="textfieldPw {{ checkError('password') === '' ? '' : 'error' }}"
        formControlName="password"
        placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
      />
      <mat-icon matSuffix (click)="isPasswordHidden = !isPasswordHidden">{{
        isPasswordHidden ? "visibility_off" : "visibility"
      }}</mat-icon>
    </mat-form-field>
    <div
      class="error textfield-label flex"
      *ngIf="checkError('password') !== ''"
    >
      <p class="margin-0">
        {{ checkError("password") }}
      </p>
    </div>
  </div>

  <div class="flex flex-center padding-top40">
    <mat-label class="textfield-label flex">
      <span class="flex-self-left">Confirm Password</span></mat-label
    >
    <mat-form-field floatLabel="never" appearance="fill">
      <input
        [type]="isConfirmPasswordHidden ? 'password' : 'text'"
        type="email"
        placeholder="Enter your email"
        matInput
        class="textfieldPw {{
          checkError('confirmPassword') === '' ? '' : 'error'
        }}"
        formControlName="confirmPassword"
        placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
      />
      <mat-icon
        matSuffix
        (click)="isConfirmPasswordHidden = !isConfirmPasswordHidden"
        >{{
          isConfirmPasswordHidden ? "visibility_off" : "visibility"
        }}</mat-icon
      >
    </mat-form-field>

    <div
      class="error textfield-label flex"
      *ngIf="checkError('confirmPassword') !== ''"
    >
      <p class="margin-0">
        {{ checkError("confirmPassword") }}
      </p>
    </div>
  </div>
  <div class="flex padding-top40 flex-center">
    <button class="btn-next" (click)="changePassword()">
      <label class="btn-font">Reset Password</label>
    </button>
  </div>
</form>
