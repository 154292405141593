import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, of } from 'rxjs';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-cancel-document',
  templateUrl: './cancel-document.component.html',
  styleUrls: ['./cancel-document.component.sass']
})
export class CancelDocumentComponent implements OnInit {


  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()


  constructor(private _signSecure: SignSecureService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    console.log({ data: this.data })
  }

  approve() {
    this._signSecure.documentCancel(this.data.data)
      .pipe(catchError((err, caught) => {
        this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: {
            type: "error",
            text: 'This document has been completed already. Please refresh your browser for the latest document updates and status.',
          },
        })
        return of()
      }))
      .subscribe((data) => {
        this.closeModalEvent.emit({ action: 'document-cancelled' })
      })
  }

  decline() {
    this.closeModalEvent.emit(false)
  }



  close(action: string) {
    this.closeModalEvent.emit({ action })
  }

}
