<div class="list-folder">
  <app-table
    [title]="'Folders and Files'"
    [hasCheckBox]="hasCheckBox"
    [columns]="filesCols"
    [canOpenFile]="true"
    (modal)="createFolder($event)"
    [addButton]="'Create Folder'"
    [type]="'create-folder'"
    [paginate]="true"
    [pageSize]="50"
    [showBreadcrumbs]="true"
    [data]="filesData | async"
    [folderFunctions]="folderFunctions"
    [selection]="selection"
    [breadcrumbBack]="onFolderBack"
    [rowFunction]="openFolder"
    [nameFunction]="openPdf"
    (modalCloses)="modalCloses($event)"
  >
    <div class="extra-buttons" *ngIf="multipleFileActionsFlag">
      <button class="extra-button" (click)="donwloadSelected()">
        Download
      </button>
      <button class="extra-button" (click)="deleteSelected()">Delete</button>
      <button class="extra-button">Move</button>
      <button class="extra-button">Duplicate</button>
    </div>
  </app-table>
  <app-file-and-folder-ipad
    [data]="filesData | async"
    [breadcrumbBack]="onFolderBack"
    [type]="'create-folder'"
    [rowFunction]="openFolder"
    [nameFunction]="openPdf"
    (modal)="createFolder($event)"
    (modalCloses)="modalCloses($event)"
  ></app-file-and-folder-ipad>
</div>
