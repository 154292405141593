import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of, switchMap, throwError } from 'rxjs';
import { UserModel } from 'src/app/@shared/models/user.model';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrls: ['./add-new-user.component.sass'],
})
export class AddNewUserComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  fieldValid: boolean = true;
  addNewUserForm: FormGroup = this.fb.group({
    givenName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[a-z0-9._%+-]+@(unawa.asia|judiciary.gov.ph|outlook.com)$'
        ),
      ],
    ],
    position: ['', [Validators.required]],
    role: ['', [Validators.required]],
    office: ['', [Validators.required]],
    newPassword: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
  });
  hide: boolean = true;
  confirmHide: boolean = true;
  newPassword: string = '';
  confirmPassword: string = '';
  errorMsg: string | string[] = '';
  pwordErrorMsg: string | string[] = '';
  isPwordMatch: boolean = true;
  constructor(private fb: FormBuilder, private _usersService: UsersService) { }

  ngOnInit(): void { }

  createUser() {
    const {
      givenName,
      lastName,
      email,
      position,
      role,
      newPassword,
      confirmPassword,
      office,
    } = this.addNewUserForm.controls;


    const user: UserModel = {
      givenName: givenName.value,
      lastName: lastName.value,
      email: email.value,
      position: position.value,
      role: role.value,
      office: office.value,
      displayPhoto: '',
      password: '0vvtjv6F8m', // TODO :: generate random string here
    };

    if (
      email.valid &&
      givenName.valid &&
      lastName.valid
    ) {
      this._usersService
        .createUser(user)
        .pipe(
          catchError((err) => {
            confirmPassword.reset();
            newPassword.reset();
            this.isPwordMatch = true;
            this.errorMsg = err.error.error;

            if (typeof this.errorMsg == 'string') {
              this.errorMsg = this.errorMsg.split(':').pop() || '';
            }
            if (this.errorMsg[0].includes('password')) {
              this.pwordErrorMsg = this.errorMsg;
              this.errorMsg = '';
              this.isPwordMatch = false;
            }
            return of();
          }),
          switchMap((user) => {
            return this._usersService.createSignSecureUser(user);
          })
        )
        .subscribe((result: any) => {
          this.closeModal(user.email);
        });
    }
  }
  checkPassword() {
    if (
      this.addNewUserForm.controls['newPassword'].dirty &&
      this.addNewUserForm.controls['confirmPassword'].dirty
    ) {
      if (
        this.addNewUserForm.controls['newPassword'].value !=
        this.addNewUserForm.controls['confirmPassword'].value
      ) {
        this.pwordErrorMsg = 'The password does not match';
        this.isPwordMatch = false;
      } else if (
        !this.addNewUserForm.controls['newPassword'].valid ||
        !this.addNewUserForm.controls['confirmPassword'].valid
      ) {
        this.pwordErrorMsg = 'Password should have 6 characters';
        this.isPwordMatch = false;
      } else {
        this.pwordErrorMsg = '';
        this.isPwordMatch = true;
      }
    }
  }
  closeModal(email?: string): void {
    this.closeModalEvent.emit(email);
  }
}
