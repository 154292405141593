<app-table
*ngIf="!isPhoneWidth;else mobileTable"
[title]="recentDocmentTitle"
[data]="recentDocumentData | async"
[columns]="recentDocuemtCols"
[canOpenFile]="true"
[type]="'recent-documents'"
(modalCloses)="recentDocumentModal($event)"
[nameFunction]="openPdf"
[paginate]="true"
class="recents"
></app-table>
<ng-template #mobileTable>

<app-tablet-table 
      [itemTemplate]="recent_document_row"
      [title]="recentDocmentTitle"
      [data]="recentDocumentData | async"
    ></app-tablet-table>
</ng-template>

<ng-template #recent_document_row let-data>
    <div class="recent-document-row" (click)="openPdf(data)">
      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <p class="subtitle"><app-tags [tags]="data.tags"></app-tags></p>
        <p class="content">{{ data.date }}</p>
      </div>
      <div class="recent-document__img">
        <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
      </div>
    </div>
  </ng-template>