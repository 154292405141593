import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.sass'],
})
export class ResetPasswordSuccessComponent implements OnInit {
  @Output() returnEvent = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  returnScreen(value: string) {
    this.returnEvent.emit(value);
  }
}
