import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InboxService } from 'src/app/@shared/services/inbox.service';

@Component({
  selector: 'app-inbox-ipad',
  templateUrl: './inbox-ipad.component.html',
  styleUrls: ['./inbox-ipad.component.sass'],
})
export class InboxIpadComponent implements OnInit {
  @Input('data')
  data: any;

  @Input()
  rowFunction: Function = (event: any, element: any) => {};

  dataSource = new MatTableDataSource();
  displayedColumns = ['email', 'date'];

  constructor(private _inboxService: InboxService) {}

  ngOnChanges() {
    this.dataSource.data = this.data;
  }

  toLowercase(value: string) {
    return value.toLowerCase();
  }

  ngOnInit(): void {}
}
