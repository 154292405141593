import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.sass']
})
export class CompleteComponent implements OnInit {


  constructor(private _navService: NavigationService, private _signsecure: SignSecureService) { }

  ngOnInit(): void {
  }

  goBack() {
    this._signsecure.clearWorkFlowData()
    this._navService.hideWorkFlow();
  }

}
