<div class="audit-logs">
  <app-table
    [title]="'Audit Log'"
    [addButton]="'Download Log'"
    [addButtonIcon]="'../../../assets/images/icons/plus.png'"
    (modal)="downloadLog()"
    [columns]="auditCols"
    [showFilter]="true"
    [data]="auditData | async"
    [paginate]="true"
    [pageSize]="10"
  ></app-table>
</div>
