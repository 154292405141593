<div class="wrapper flexGrid">
  <img
    class="logo"
    src="../../../../assets/images/verification-success.png"
    alt="logo"
  />
  <div class="text-container flexGrid">
    <h2 class="header">Account verified</h2>
    <h4 class="description">
      Your email has been verified. Start accessing your documents using your
      account.
    </h4>
  </div>
  <button class="btn-next">
    <label class="btn-font">Go to dashboard</label>
  </button>
</div>
