import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.sass'],
})
export class AdminMenuComponent implements OnInit {
  selected: string = '';
  @Output() toggleSideNavEvent = new EventEmitter<string>();
  constructor(private _navigation: NavigationService,private _fileActionService:FileActionService) {
    this._navigation.location$.subscribe(
      (location) => (this.selected = location)
    );
  }

  ngOnInit(): void {
  }

  updateLocation(location: string) {
    this.toggleSideNavEvent.emit();
    this._navigation.updateLocation(location);
    this._fileActionService.setIsModify(false);
  }
}
