import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-admin-dashboard-wrapper',
  templateUrl: './admin-dashboard-wrapper.component.html',
  styleUrls: ['./admin-dashboard-wrapper.component.sass'],
})
export class AdminDashboardWrapperComponent implements OnInit {
  isViewAccount: boolean = false;
  toggle=true;
  public menuType = '';
  @Input()
  viewPdf: boolean = false;
  isLoading = this._loader.isLoading;
  
  constructor(private _navigationService: NavigationService, private _loader: LoaderService) {}

  ngOnInit(): void {
    window.scrollTo(0,0);
    this._navigationService.accountView$.subscribe((i) => {
      this.isViewAccount = i;
    });

    this.menuType = this._navigationService.initLocation;

    this._navigationService.location$.subscribe((location) => {
      this.menuType = location;
    });
  }

  toggleSideNav(toggle:any){
    this.toggle=toggle;
  }

  viewAccount(value: boolean) {
    this._navigationService.isViewAccount = value;
    this.isViewAccount = value;
  }
}
