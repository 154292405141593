<form class="add-new-user" [formGroup]="addNewUserForm">
  <h4 class="title">Add a new user</h4>
  <div class="header-align">
    <div class="header">Personal Info</div>
    <div class="sub-header">Update the users personal information</div>
  </div>
  <div class="content">
    <div class="form-field-pair">
      <div class="input-group">
        <mat-label class="label">First Name</mat-label>
        <mat-form-field [ngClass]="
            (addNewUserForm.controls['givenName'].dirty &&
            addNewUserForm.controls['givenName'].value.length == 0) || !fieldValid
              ? 'form-field-error'
              : 'form-field'
          " floatLabel="never" appearance="fill">
          <input type="name" matInput placeholder="Juan" formControlName="givenName" />
        </mat-form-field>
        <span class="error-code" *ngIf="
            addNewUserForm.controls['givenName'].dirty &&
            addNewUserForm.controls['givenName'].value.length == 0
          ">First name is required</span>
      </div>
      <div class="input-group">
        <mat-label class="label">Last Name</mat-label>
        <mat-form-field [ngClass]="
            (addNewUserForm.controls['lastName'].dirty &&
            addNewUserForm.controls['lastName'].value.length == 0) || !fieldValid
              ? 'form-field-error'
              : 'form-field'
          " floatLabel="never" appearance="fill">
          <input type="name" matInput placeholder="Dela Cruz" formControlName="lastName" />
        </mat-form-field>
        <span class="error-code" *ngIf="
            addNewUserForm.controls['lastName'].dirty &&
            addNewUserForm.controls['lastName'].value.length == 0
          ">Last name is required</span>
      </div>
    </div>
    <div class="input-group">
      <mat-label class="label">Email</mat-label>
      <mat-form-field [ngClass]="
          (addNewUserForm.controls['email'].dirty &&
            (errorMsg.length > 0 ||
              (addNewUserForm.controls['email'].invalid &&
                addNewUserForm.controls['email'].value?.length > 0))) ||
          (addNewUserForm.controls['email'].dirty &&
            addNewUserForm.controls['email'].value.length == 0)  || !fieldValid
            ? 'form-field-error'
            : 'form-field'
        " floatLabel="never" appearance="fill">
        <input type="email" matInput placeholder="juandelacruz@email.com" formControlName="email"
          (ngModelChange)="errorMsg = ''" />
      </mat-form-field>
      <span class="error-code" *ngIf="addNewUserForm.controls['email'].dirty && errorMsg.length > 0">{{ errorMsg
        }}</span>
      <span class="error-code" *ngIf="
          addNewUserForm.controls['email'].dirty &&
          addNewUserForm.controls['email'].value.length == 0
        ">Email is required</span>
      <span class="error-code" *ngIf="
          addNewUserForm.controls['email'].dirty &&
          addNewUserForm.controls['email'].invalid &&
          addNewUserForm.controls['email'].value?.length > 0
        ">Email is invalid</span>
    </div>
    <div class="input-group">
      <mat-label class="label">Position</mat-label>
      <mat-form-field class="form-field" floatLabel="never" appearance="fill">
        <input type="name" matInput placeholder="Court Attorney" formControlName="position" />
      </mat-form-field>
    </div>
    <div class="input-group-role">
      <mat-label class="label-role">Role</mat-label>
      <mat-form-field class="form-field" floatLabel="never" appearance="fill">
        <mat-select placeholder="Admin" formControlName="role" panelClass="role-select">
          <mat-option value="admin"> Admin</mat-option>
          <mat-option value="user"> User</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="input-group">
      <mat-label class="label">Office</mat-label>
      <mat-form-field class="form-field" floatLabel="never" appearance="fill">
        <input type="text" matInput placeholder="Supreme Court" formControlName="office" />
      </mat-form-field>
    </div>
    <div class="header-align" *ngIf="false">
      <div class="header">Password</div>
      <div class="sub-header">Set the users password for their account</div>
    </div>

    <div class="input-group" *ngIf="false">
      <mat-label>New Password</mat-label>
      <mat-form-field [ngClass]="isPwordMatch ? 'form-field' : 'form-field-error'" floatLabel="never" appearance="fill">
        <input [type]="hide ? 'password' : 'text'" formControlName="newPassword" placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          (ngModelChange)="checkPassword()" matInput />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
      </mat-form-field>
      <span class="error-code" *ngIf="!isPwordMatch">{{ pwordErrorMsg }}</span>
    </div>
    <div class="input-group" *ngIf="false">
      <mat-label>Confirm Password</mat-label>
      <mat-form-field class="form-field" floatLabel="never" appearance="fill">
        <input [type]="confirmHide ? 'password' : 'text'" formControlName="confirmPassword" placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
          (ngModelChange)="checkPassword()" matInput />
        <mat-icon matSuffix (click)="confirmHide = !confirmHide">{{
          confirmHide ? 'visibility_off' : 'visibility'
          }}</mat-icon>
      </mat-form-field>
    </div>

    <div class="actions">
      <button class="cancel" (click)="closeModal()">Cancel</button>
      <button class="create" (click)="createUser()">Create User</button>
    </div>
  </div>
</form>