<app-spinner (compLoaded)="spinnerLoaded = $event"></app-spinner>

<div
  [ngClass]="{ container: menuType !== 'safe-form' }"
  *ngIf="menuType != 'listMenu' && menuType != 'form-viewer' && spinnerLoaded"
  [hidden]="isSearch || modifyFile || viewPdf"
  [style.overflow]="(isLoading| async)? 'hidden' : 'auto'"
  >
  <!--START:  Dashboard and Report  -->
  <app-admin-dashboard-report
    *ngIf="menuType == 'report' && userType !== 'user'">
  </app-admin-dashboard-report>
  <!--END:  Dashboard and Report  -->

  <!--START:  Manage User -->
  <ng-container *ngIf="menuType == 'mUser'">
    <app-admin-dashboard-manage-user
      [users]="usersData"></app-admin-dashboard-manage-user>
  </ng-container>
  <!--END:  Manage User   -->
  <!--START:  Manage User Groups  -->
  <ng-container *ngIf="menuType == 'mUserGrp'">
    <app-admin-dashboard-manage-user-groups></app-admin-dashboard-manage-user-groups>
  </ng-container>
  <!--END:  Manage User Groups   -->
  <!--START:  Audit Logs  -->
  <ng-container *ngIf="menuType == 'audit'">
    <app-admin-dashboard-audit-log></app-admin-dashboard-audit-log>
  </ng-container>
  <!--END:  Audit Logs  -->

  <!--START: Documents -->
  <ng-container *ngIf="menuType == 'docs' && !modifyFile">
    <app-documents></app-documents>
  </ng-container>
  <!--END: Documents -->

  <!--START: Documents -->
  <ng-container *ngIf="menuType == 'recent-docs' && !modifyFile">
    <app-recent-documents></app-recent-documents>
  </ng-container>
  <!--END: Documents -->

  <!-- START: Form Templates -->
  <ng-container *ngIf="menuType === 'templates'">
    <app-form-template></app-form-template>
  </ng-container>
  <!-- END: Form Templates -->

  <!-- START: Safe Form -->
  <ng-container *ngIf="menuType === 'safe-form'">
    <app-safe-form></app-safe-form>
  </ng-container>
  <!-- END: Safe Form -->

  <!-- START: Certificate Table -->
  <ng-container *ngIf="menuType === 'certificates-table'">
    <app-certificate></app-certificate>
  </ng-container>
  <!-- END: Certificate Table -->

  <!-- START: SC ID Table -->
  <ng-container *ngIf="menuType === 'sc-id-table'">
    <app-sc-id [style.overflow]="(isLoading| async)? 'hidden' : 'auto'"></app-sc-id>
  </ng-container>
  <!-- END: SC ID Table -->

  <!--START:  Workflow -->
  <ng-container *ngIf="menuType == 'workflow'">
    <app-workflow></app-workflow>
  </ng-container>
  <!--END:  Workflow  -->
  <!-- START: bulk Templates -->
  <ng-container *ngIf="menuType === 'bulk-id'">
    <app-bulk-id-template></app-bulk-id-template>
  </ng-container>
  <!-- START: bulk Templates -->
  <ng-container *ngIf="menuType === 'bulk-id-table'">
    <app-bulk-id-table></app-bulk-id-table>
  </ng-container>

  <ng-container *ngIf="menuType === 'bulk-cert'">
    <app-bulk-certificate-template></app-bulk-certificate-template>
  </ng-container>

  <ng-container *ngIf="menuType === 'bulk-certificate-table'">
    <app-bulk-certificate-table></app-bulk-certificate-table>
  </ng-container>
  <!-- END: bulk Templates -->
  <!--START:  Inbox -->
  <ng-container *ngIf="menuType == 'inbox'">
    <app-inbox></app-inbox>
  </ng-container>
  <!--END:  Inbox  -->

  <!--START:  Support Page -->
  <ng-container *ngIf="menuType == 'support'">
    <app-support-page></app-support-page>
  </ng-container>
  <!--END:  Support Page  -->
  <!--START:  FAQ Page -->
  <ng-container *ngIf="menuType == 'faq'">
    <app-faq></app-faq>
  </ng-container>
  <!--END:  FAQ Page  -->

  <!--START:  Support Page -->
  <ng-container *ngIf="menuType == 'signatures' && signaturesFlag">
    <app-signature></app-signature>
  </ng-container>
  <!--END:  Support Page  -->
</div>

<!--START:  List of Files and Folders -->
<ng-container
  *ngIf="
    menuType == 'listMenu' &&
    !isSearch &&
    !modifyFile &&
    !viewPdf &&
    spinnerLoaded
  ">
  <div class="list-wrapper">
    <app-document-tree></app-document-tree>
    <app-admin-dashboard-folder-files></app-admin-dashboard-folder-files>
  </div>
</ng-container>
<!--END:  List of Files and Folders  -->

<div class="container" *ngIf="isSearch && !modifyFile">
  <ng-container>
    <app-search-results [isSearch]="isSearch"></app-search-results>
  </ng-container>
</div>

<div class="container" *ngIf="modifyFile">
  <app-file-view (goBackEvent)="modifyFileStatusUpdate($event)"></app-file-view>
</div>

<div class="container" *ngIf="menuType == 'form-viewer'">
  <app-form-viewer></app-form-viewer>
</div>

<input type="file" style="display: none" id="tempFile" />
