import { Component, OnInit } from '@angular/core';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';

@Component({
  selector: 'app-admin-dashboard-template',
  templateUrl: './admin-dashboard-template.component.html',
  styleUrls: ['./admin-dashboard-template.component.sass'],
})
export class AdminDashboardTemplateComponent implements OnInit {
  documentProgress = this.signSecureService.signSecurePage$;
  options = this.signSecureService.signatureOptions;

  data = this.signSecureService.worflowData$

  signatureData: any

  pdfDetails: any

  addedAll: any = false

  qr: string = 'bottom-right'
  scale: any = 1;
  
  constructor(private signSecureService: SignSecureService) { }

  ngOnInit(): void {
    this.signSecureService.clearWorkFlowData()
  }

  review(value: boolean) {
    this.signSecureService.setSignatures(this.signatureData ?? [], this.qr)
    this.signSecureService.nextProgress(5)
  }

  setSignatureData(data: any) {
    this.signatureData = data
    this.signSecureService.setSignatures(data, this.qr)
  }

  setPdfDetails(data: any) {
    this.pdfDetails = data
  }

  addedAllSignatories(status: any) {
    this.addedAll = status
  }

  updateQRPosition(qrposition: string) {
    this.qr = qrposition
  }
}
