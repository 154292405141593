import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qr-verification-success',
  templateUrl: './qr-verification-success.component.html',
  styleUrls: ['./qr-verification-success.component.sass']
})
export class QrVerificationSuccessComponent implements OnInit {

  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()

  img: string = "../../../../assets/images/success-verified.svg"
  name: string = ''

  buttonText: string = "Please login to view the document"
  constructor() { }

  ngOnInit(): void {
    console.log({ data: this.data })

    const paramNames = this.data.data.paramNames[0]

    if (paramNames === 'fm') {
      this.buttonText = 'Send OTP'
      this.name = this.data?.data?.fullName
    } else if (paramNames === 'ce') {
      this.buttonText = 'View document'
      this.name = this.data?.data?.fullName
    } else {
      if (this.data?.data?.workflow?.privacyType === 'PUBLIC' || this.data?.data?.privacyType === 'PUBLIC') {
        this.buttonText = 'View document'
      }
      this.name = this.data?.data?.name
    }
  }

  close(result: boolean) {
    this.closeModalEvent.emit(result)
  }

}
