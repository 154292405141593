<div class="navbar-content">
  <img
    src="../../../assets/images/hamburger.svg"
    alt=""
    srcset=""
    class="hamburger-button"
  />
  <img
    src="../../../assets/images/side-logo.svg"
    alt=""
    srcset=""
    class="logo-navbar"
  />
</div>
