<div class="forms-navbar-container">
  <div class="left">
    <button (click)="backPage('templates')">
      <img src="../../../assets/images/icons/back-gold.svg" alt="" class="back" />
      Back
    </button>
  </div>
  <div class="right">
    <img src="../../../assets/images/template-icons/sign-logo.svg" alt="" class="icon" (click)="backPage('reports')" />
  </div>
</div>
<div class="progress-bar-bg">
  <ng-container>
    <div class="progress-container">
      <mat-progress-bar mode="determinate" color="primary" [value]="progress"></mat-progress-bar>
      <span style="position: absolute; top: 0.5em" [ngStyle]="{ left: progress + '%' }">{{ progress }}%</span>
    </div>
  </ng-container>
</div>
<div class="safeform-gridlist">
  <mat-grid-list cols="5">
    <mat-grid-tile colspan="1">
      <ng-container>
        <div class="drag-drop-sidebar" #sideBar>
          <div>
            <form [formGroup]="myFormGroup" *ngIf="myFormGroup">
              <div *ngFor="let form_elem of formTemplate">
                <div [ngSwitch]="form_elem.type">
                  <!-- Travel Auth -->
                  <ng-container *ngSwitchCase="'dateGeneric'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input *ngIf="form_elem.formControl === 'eventEndDateTravelAuth'; else elseBlock" (dateChange)="setText(form_elem.percent)" readonly matInput [matDatepicker]="picker"
                          formControlName="{{ form_elem.formControl }}" [matDatepickerFilter]="dateFilter" /><ng-template #elseBlock>
                          <input (dateChange)="setText(form_elem.percent)" readonly matInput [matDatepicker]="picker"
                            formControlName="{{ form_elem.formControl }}" /></ng-template>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'courtDropDown'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select placeholder="Others" matInput formControlName="{{ form_elem.formControl }}"
                          (selectionChange)="setText(form_elem.percent)">
                          <mat-option value="" disabled>--Select Court--</mat-option>
                          <mat-option value="Metropolitan Trial Court">Metropolitan Trial Court
                          </mat-option>
                          <mat-option value="Municipal Trial Court">Municipal Trial Court
                          </mat-option>
                          <mat-option value="Municipal Trial Court in Cities">Municipal Trial Court in
                            Cities</mat-option>
                          <mat-option value="Municipal Circuit Trial Court">Municipal Circuit Trial Court</mat-option>
                          <mat-option value="Shari'a District Court">Shari'a District Court
                          </mat-option>
                          <mat-option value="Shari'a Circuit Court">Shari'a Circuit Court
                          </mat-option>
                          <mat-option value="Regional Trial Court">Regional Trial Court
                          </mat-option>
                          <mat-option value="Family Court">Family Court</mat-option>
                          <mat-option value="Halls of Justice">Halls of Justice</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'textBox'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" placeholder="{{ form_elem.label }}" [readonly]="
                            !isRollToEdit && form_elem.label === 'Roll Number'
                          " [ngStyle]="{
                            color:
                              !isRollToEdit && form_elem.label === 'Roll Number'
                                ? 'lightgray'
                                : ''
                          }" />

                      </mat-form-field>
                      <mat-error *ngIf="!!errors[form_elem.formControl]?.rollNumberExists">This
                        roll
                        number is already in use</mat-error>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'rollNumber'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input type="number" (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" placeholder="{{ form_elem.label }}"
                          [readonly]="!isRollToEdit" [ngStyle]="{
                            color: !isRollToEdit ? 'lightgray' : ''
                          }" onkeydown="return event.keyCode !== 69" />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'empSCNumber'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input type="number" (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" placeholder="{{ form_elem.label }}"
                          onkeydown="return event.keyCode !== 69" />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'selectPosition'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select placeholder="Others" matInput formControlName="{{ form_elem.formControl }}"
                          (selectionChange)="setText(form_elem.percent)">
                          <mat-option value="" disabled>--Select Position--</mat-option>
                          <mat-option value="Judge">Judge</mat-option>
                          <mat-option value="Clerk of Court">Clerk of Court</mat-option>
                          <mat-option value="Other Personnel">Other Personnel</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'selectBloodType'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <mat-select placeholder="Others" matInput formControlName="{{ form_elem.formControl }}"
                          (selectionChange)="setText(form_elem.percent)">
                          <mat-option value="" disabled>--Select Blood Type--</mat-option>
                          <mat-option value="A+">A+</mat-option>
                          <mat-option value="A-">A-</mat-option>
                          <mat-option value="B+">B+</mat-option>
                          <mat-option value="B-">B-</mat-option>
                          <mat-option value="AB+">AB+</mat-option>
                          <mat-option value="AB-">AB-</mat-option>
                          <mat-option value="O+">O+</mat-option>
                          <mat-option value="O-">O-</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'personToContactInEmergency'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label class="personInContact">Person to Contact incase of Emergency </mat-label><br /><br />
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'dateAdmitted'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input (dateChange)="setText(form_elem.percent)" readonly matInput [matDatepicker]="picker"
                          placeholder="Choose a date" formControlName="{{ form_elem.formControl }}" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <div *ngSwitchCase="'dateAttestation'" hidden>
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input (dateChange)="setText(form_elem.percent)" readonly matInput [matDatepicker]="picker"
                          placeholder="Choose a date" formControlName="{{ form_elem.formControl }}" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </ng-container>
                  </div>
                  <ng-container *ngSwitchCase="'clerkSigUpload'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <label class="form-label" for="customFileClerk">{{
                        form_elem.label
                        }}</label>
                      <input type="file" class="form-control" id="customFileClerk" data-name="clerk"
                        (change)="setText($event)" formControlName="{{ form_elem.formControl }}"
                        accept="image/png, image/jpg, image/gif, image/jpeg" />
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'chiefSigUpload'" class="my-4">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <label class="form-label" for="customFileChief">{{
                        form_elem.label
                        }}</label>
                      <input type="file" class="form-control" id="customFileChief" data-name="chief"
                        (change)="setText($event)" formControlName="{{ form_elem.formControl }}"
                        accept="image/png, image/jpg, image/gif, image/jpeg" />
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'eventTextbox'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="fill">
                        <input (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" />
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'serviceInputFeild'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline" class="bsServiceTextbox">
                        <input matInput formControlName="{{ form_elem.formControl }}" />
                      </mat-form-field>
                      <button type="button" class="bsServiceBtnAdd" color="primary" (click)="setText($event)" [disabled]="myFormGroup.value['dataInputDynamicTravelAuth'] === '' || serviceListName.length >= 15">
                        Add
                      </button>
                      <div class="scroolVertical">
                        <ng-container *ngFor="let serviceDesc of serviceListName">
                          <mat-form-field appearance="outline" class="bsListMarginTop">
                            <input matInput disabled value="{{ serviceDesc }}" class="bsServiceTextbox" />
                            <button class="bsServiceBtnRemove" (click)="
                                removeServiceList(
                                  serviceDesc,
                                  'removeServiceList'
                                )
                              ">
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-form-field>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'assignatoryTravelAuth'">
                    <ng-container *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline" class="travelAuthAuthorized">
                        <mat-select formControlName="{{ form_elem.formControl }}"
                          (selectionChange)="setText(form_elem.percent)">
                          <mat-option value="" disabled>--Select Signatory/Authorizing officer--
                          </mat-option>
                          <mat-option value="Chief Justice">Chief Justice</mat-option>
                          <mat-option value="Associate Justice">Associate Justice</mat-option>
                          <mat-option value="Clerk of Court">Clerk of Court</mat-option>
                          <mat-option value="Chief of Office">Chief of Office</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                  </ng-container>
                  <div *ngSwitchCase="'pageBreakerTextboxOne'" hidden>
                    <div *ngIf="currentStep === form_elem.step">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ form_elem.label }}</mat-label>
                        <input (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div *ngSwitchCase="'pageBreakerTextboxTwo'" hidden>
                    <div *ngIf="currentStep === form_elem.step">
                      <mat-form-field appearance="outline">
                        <mat-label>{{ form_elem.label }}</mat-label>
                        <input (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" />
                      </mat-form-field>
                    </div>
                  </div>
                  <div *ngSwitchCase="'pageBreakerTextbox'" hidden>
                    <div *ngIf="currentStep === form_elem.step">
                      <mat-label>{{ form_elem.label }}</mat-label>
                      <mat-form-field appearance="outline">
                        <input (change)="setText(form_elem.percent)" matInput
                          formControlName="{{ form_elem.formControl }}" />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Checkbox Share with group-->
              <!-- <div *ngIf="currentStep == totalStep">
                <div>
                  <mat-checkbox
                    (change)="shareToTeam($event)"
                    matTooltip="Everyone on your group
                                    will be able to view this document.">
                    <span
                      class="share-team"
                      matTooltip="Everyone on your group
                                        will be able to view this document."
                      >Share with group</span
                    >
                  </mat-checkbox>
                </div>
              </div> -->

              <!-- Button Next and Back -->
              <mat-grid-list cols="2" gutterSize="10px" class="backNextBtnContainer">
                <mat-grid-tile colspan="1">
                  <div *ngIf="currentStep != 1" class="backBtnContainer">
                    <button mat-raised-button class="button-auth" color="primary" autofocus="false" (click)="back()">
                      <span> Back </span>
                    </button>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                  <div class="nextBtnContainer">
                    <button *ngIf="currentStep != totalStep" mat-raised-button class="button-auth" color="primary"
                      type="button" autofocus="false" (click)="next()">
                      <span> Next </span>
                    </button>
                    <button *ngIf="currentStep == totalStep" mat-raised-button [ngClass]="
                        myFormGroup?.invalid || isSubmitting
                          ? 'button-auth btnExtension disabled'
                          : 'button-auth btnExtension'
                      " color="primary" autofocus="false" (click)="submitDocument()"
                      [disabled]="!(progress >= 100) || isSubmitting" [ngStyle]="
                        isSubmitting
                          ? {
                              'font-size': '14px',
                              'padding-left': '12px',
                              'padding-right': '12px',
                              'margin-left': '-18px'
                            }
                          : {
                              'padding-left': '0',
                              'padding-right': '0',
                              'margin-left': '',
                              color: '#FFFFFF'
                            }
                      ">
                      <span *ngIf="isSubmitting">
                        <i class="fa fa-spinner fa-spin fa-fw"></i>
                        Sending
                      </span>
                      <span *ngIf="!isSubmitting"> Finish </span>
                    </button>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </form>
          </div>
        </div>
      </ng-container>
    </mat-grid-tile>
    <mat-grid-tile colspan="4" class="pdf-container">
      <div id="templateDiv" [style.transform]="'scale(' + zoomDefaultValue + ')'">
        <div #formContent class="pdf-canvas" [innerHTML]="template"></div>
      </div>
    </mat-grid-tile>
    <div class="zoom-container">
      <div>
        <button class="d-block mb-2 side-button" mat-raised-button mat-icon-button aria-label="Zoom in" isRoundButton
          (click)="zoomIn()" [disabled]="pageZoomDecimal === maxZoom">
          <mat-icon>zoom_in</mat-icon>
        </button>
      </div>
      <div>
        <button class="d-block mb-2 side-button" mat-raised-button mat-icon-button aria-label="Zoom out" isRoundButton
          (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
    </div>
  </mat-grid-list>
</div>