import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observer, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserGroupModel } from '../models/user-group.model';
import { UserGroupResponseModel } from '../models/user-groups-response.model';
import { UserTableModel } from '../models/user-table.model';
import { UserModel } from '../models/user.model';
import { UsersResponseModel } from '../models/users-response.model';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly userGroup = environment.apiConfig.userGroup.url;
  private readonly _userGroupUrl = `${this.baseUrl}${this.userGroup}`;
  constructor(private _httpClient: HttpClient) {}

  private userGroups = new BehaviorSubject<UserGroupModel[]>([]);
  userGroups$ = this.userGroups.asObservable();
  columns = ['Group Name', 'No. of Members', 'Date Added', 'Actions'];

  getUserGroups(sort: string = 'asc') {
    return this._httpClient.get<any>(`${this._userGroupUrl}/all`);
  }

  getUserGroup(id: string) {
    return this._httpClient.get<any>(`${this._userGroupUrl}/${id}`);
  }

  createUserGroups(data: UserGroupModel) {
    return this._httpClient.post<any>(`${this._userGroupUrl}/create`, data);
  }

  updateUserGroups(userGroup: any) {
    return this._httpClient.put<any>(`${this._userGroupUrl}/update`, userGroup);
  }

  deleteUserGroups(id: string) {
    return this._httpClient.delete<any>(`${this._userGroupUrl}/${id}`);
  }

  async addUserToGroups(user: any) {
    return await lastValueFrom(
      this._httpClient.post<any>(`${this._userGroupUrl}/add/user`, user)
    );
  }

  async removeUserFromGroups(usergroupId: string, userId: string) {
    return await lastValueFrom(
      this._httpClient.delete<any>(
        `${this._userGroupUrl}/remove/${usergroupId}/${userId}`
      )
    );
  }

  setUserGroup(data: any) {
    this.userGroups.next(data);
  }

  format(groups: UserGroupModel[]) {
    return groups.map((group: UserGroupModel) => {
      const { id, groupName, membersCount, createdAtFormatted } = group;
      const formattedUser: any = {};

      formattedUser['id'] = id;
      formattedUser['group_name'] = groupName;
      formattedUser['no._of_members'] = membersCount;
      formattedUser['date_added'] = createdAtFormatted?.split(',')[0];
      formattedUser['actions'] = { edit: group, delete: id };
      formattedUser['membersCount'] = membersCount;
      return formattedUser;
    });
  }
}
