<ng-container>
  <form class="edit-user" [formGroup]="editUserForm">
    <h4 class="title">Edit User</h4>
    <div class="content">
      <div class="form-field-pair">
        <div class="input-group">
          <mat-label class="label">First Name</mat-label>
          <mat-form-field floatLabel="never" appearance="fill">
            <input
              type="name"
              matInput
              placeholder="Juan"
              formControlName="givenName"
              class="textfieldReg width" />
          </mat-form-field>
        </div>
        <div class="input-group">
          <mat-label class="label">Last Name</mat-label>
          <mat-form-field floatLabel="never" appearance="fill">
            <input
              type="name"
              matInput
              placeholder="Dela Cruz"
              formControlName="lastName"
              class="textfieldReg width" />
          </mat-form-field>
        </div>
      </div>
      <div class="input-group">
        <mat-label class="label">Email</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            type="email"
            matInput
            placeholder="juandelacruz@email.com"
            [disabled]="true"
            formControlName="email"
            class="textfieldReg"
            (ngModelChange)="updateError = false; errMsg = ''"
            [ngClass]="{
              'textfieldreg-error':
                (editUserForm.controls['email'].invalid &&
                  editUserForm.controls['email'].touched) ||
                updateError
            }" />
        </mat-form-field>
        <mat-error
          class="padding-top20"
          *ngIf="editUserForm.controls['email'].invalid">
          This email is not valid
        </mat-error>
        <mat-error
          class="padding-top20"
          *ngIf="
            updateError && editUserForm.controls['email'].value?.length > 0
          ">
          {{ errMsg }}
        </mat-error>
      </div>
      <div class="input-group">
        <mat-label class="label">Position</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <input
            type="text"
            matInput
            placeholder="Court Attorney"
            formControlName="position"
            class="textfieldReg" />
        </mat-form-field>
      </div>
      <div class="input-group-role">
        <mat-label class="label">Role</mat-label>
        <mat-form-field floatLabel="never" appearance="fill">
          <mat-select
            placeholder="Admin"
            panelClass="role-select"
            formControlName="role"
            class="textfieldReg-select">
            <mat-option value="admin"> Admin</mat-option>
            <mat-option value="user"> User</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="actions">
        <button class="cancel" (click)="closeModal()">Cancel</button>
        <button class="create" (click)="updateUser()">Save</button>
      </div>
    </div>
  </form>
</ng-container>
