<div class="signatures">
  <app-table
    [title]="'Signatures'"
    [hasCheckBox]="hasCheckBox"
    [columns]="columns"
    [type]="'signatures'"
    [data]="signatures | async"
    [addButton]="'Upload Signature'"
    (modal)="addSignature($event)"
  ></app-table>
</div>
