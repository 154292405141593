import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';

@Component({
  selector: 'app-signing-page-done',
  templateUrl: './signing-page-done.component.html',
  styleUrls: ['./signing-page-done.component.sass']
})
export class SigningPageDoneComponent implements OnInit {

  @Input()
  data: any

  constructor(private _sign: SignSecureService, private _nav: NavigationService, private _files: FilesService) { }

  ngOnInit(): void {
  }

  async download() {
    await lastValueFrom(this._files.downloadDirectory(this.data?.id))
    var blob = new Blob([this._sign.signPdfData], { type: "application/pdf" });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = this._sign.signPdfName;
    link.download = fileName;
    link.click();
  }

  return() {
    this._nav.updateSigningPage(false)
  }


}
