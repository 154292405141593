import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  catchError,
  last,
  lastValueFrom,
  of,
  tap,
} from 'rxjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { formatDate } from '@angular/common';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class SafeformService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly formsUrl = environment.apiConfig.forms.url;
  appVersion: string = '';

  private certificates = new BehaviorSubject<any>([]);
  private supremeId = new BehaviorSubject<any>([]);
  certificates$ = this.certificates.asObservable();
  supremeId$ = this.supremeId.asObservable();
  total: number = 0;
  formId: string = '123';
  formTemplateData: any;
  formTemplate: any;
  formTemplateTitle: any;
  readonly certificateTitle = 'Certificate of Admission to the Bar';
  readonly travelAuthTitle = 'Travel Authority Form';
  readonly editCertificateTitle = 'Edit Certificate of Admission to the Bar';
  readonly scId = 'Supreme Court ID';
  readonly clerkName = 'Marife M. Lomibao-Cuevas';
  readonly chiefName = 'Alexander G. Gesmundo';
  certificateId: string = '';
  ScId: string = '';
  forEditCertificateTemplate: any;
  templates: any;

  viewerData: any;

  private generateCert = new BehaviorSubject<any>(null);
  generateCert$ = this.generateCert.asObservable();
  qrID: any;
  certificateQrID: any;

  constructor(private _httpClient: HttpClient, private _load: LoaderService) { }

  getSafeForms() {
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/form-templates`
    );
  }

  getSafeForm() {
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/form-template?id=${this.formId}`
    );
  }

  getCertificates() {
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/certificate`
    );
  }

  getCertificate(id: any = this.certificateId) {
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/certificate/${id}`
    );
  }

  getCertificateQr(id: any) {
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/certificate/qr/${id}`
    );
  }


  getScId() {
    return this._httpClient.get<any>(`${this.baseUrl}${this.formsUrl}/scid`);
  }

  setCertificates(data: any[]) {
    this.total = data.length || 0;
    this.certificates.next(data);
  }

  setScId(data: any[]) {
    this.total = data.length || 0;
    this.supremeId.next(data);
  }

  formatCertificates(certificates: any) {
    return certificates
      .map((cert: any) => {
        const formattedFile = {
          owner: cert.fullName || '',
          roll_number: cert.rollNumber || 0,
          clerk_name: cert.clerkName || '',
          chief_name: cert.chiefName || '',
          date_admitted: moment(cert.dateAdmitted).format('DD MMM yyyy') || '',
          created: moment(cert.createdAt).format('DD MMM yyyy') || '',
          actions: {
            edit_certificate: cert,
            form_download: cert,
            form_version: cert,
          },
        };

        return formattedFile;
      })
      .reverse();
  }

  formatScId(ids: any) {
    return ids
      .map((scId: any) => {
        const formattedFile = {
          employee_number: scId.employeeNumber || '',
          name: scId.fullName || '',
          position: scId.position || '',
          branch_station: scId.branchStation || '',
          date_created:
            moment(scId.createdAt).format('yyyy-MM-DD HH:mm:ss') || '',
          actions: {
            scid_qr: scId,
            scid_download: scId,
            scid_delete: scId,
          },
        };

        return formattedFile;
      })
      .reverse();
  }

  addCertificate() {
    const formData = new FormData();
    // formData.append('file', pdfData, 'cert.pdf');
    formData.append('fullName', this.formTemplateData.certificateName);
    formData.append('rollNumber', this.formTemplateData.rollNumber);
    formData.append('dateAdmitted', this.formTemplateData.dateAdmitted);
    formData.append('dateOfAttestation', this.formTemplateData.dateAttestation);
    formData.append('clerkName', this.formTemplateData.clerkName);
    formData.append('chiefName', this.formTemplateData.chiefName);
    formData.append('clerkSignature', this.clerkName);
    formData.append('chiefSignature', this.chiefName);
    formData.append('isFileGenerated', 'true');
    formData.append('batchId', 'singleCreateForm');
    formData.append('qrID', this.formTemplateData.qrID);

    return this._httpClient.post<any>(
      `${this.baseUrl}${this.formsUrl}/certificate`,
      formData
    );
  }

  editCetificate(pdfData: Blob) {
    const formData = new FormData();
    formData.append('file', pdfData, 'cert.pdf');
    formData.append('fullName', this.formTemplateData.certificateName);
    formData.append('dateAdmitted', this.formTemplateData.dateAdmitted);
    formData.append('dateOfAttestation', this.formTemplateData.dateAttestation);
    formData.append('clerkName', this.formTemplateData.clerkName);
    formData.append('chiefName', this.formTemplateData.chiefName);
    formData.append('clerkSignature', this.formTemplateData.clerkName);
    formData.append('chiefSignature', this.formTemplateData.chiefName);
    formData.append('batchId', 'singleCreateForm');

    return this._httpClient.put<any>(
      `${this.baseUrl}${this.formsUrl}/certificate/${this.certificateId}`,
      formData
    );
  }

  addCertificateFile(id: string, pdfData: any, otherInfo: any = {}) {
    const formData = new FormData();
    formData.append('file', pdfData, 'cert.pdf');
    formData.append('clerkName', otherInfo?.clerkName ?? this.formTemplateData?.clerkName);
    formData.append('chiefName', otherInfo?.chiefName ?? this.formTemplateData.chiefName);

    return this._httpClient.put<any>(
      `${this.baseUrl}${this.formsUrl}/certificate/${id}`,
      formData
    );
  }

  addTravelAuthority(pdfData: File) {
    const formData = new FormData();
    formData.append('file', pdfData, 'travel-auth.pdf');
    formData.append('fullName', '');
    formData.append('documentTitle', this.formTemplateData.rollNumber);
    formData.append('description', this.formTemplateData.dateAdmitted);
    formData.append('emailNotif', this.formTemplateData.dateAttestation);

    return this._httpClient.post<any>(
      `${this.baseUrl}${this.formsUrl}/travel-authority`,
      formData
    );
  }

  async addID() {
    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: [8.5, 13],
      compress: true,
    });

    const canvas = await html2canvas(this.formTemplate, {
      scale: 1, // Increase the scale for better resolution
      allowTaint: true,
      useCORS: true,
    });

    const imageData = canvas.toDataURL('image/png', 0.6);

    const img = new Image();
    img.src = imageData;

    const width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();
    // height = (img.height / img.width) * width;
    // Add the image to the PDF with the calculated dimensions
    pdf.addImage(imageData, 'PNG', 0, 0, width, height);

    // to render image
    let pdfData = pdf.output('blob'); // Get the PDF data as a Blob
    pdfData = pdf.output('blob'); // Get the PDF data as a Blob
    // pdf.save('example.pdf'); // Save the PDF for local testing
    // return of();

    // Send the PDF data to the server
    const formData = new FormData();
    formData.append('file', pdfData, 'scid.pdf');
    formData.append('batchId', 'single create');
    formData.append('fullName', this.formTemplateData.nameSupremeCourtId);
    formData.append('employeeNumber', this.formTemplateData.employeeNumberSCid);
    formData.append('branchStation', this.formTemplateData.branchStationSCid);
    formData.append('position', this.formTemplateData.PositionSupremeCourtId);
    formData.append(
      'emailAddress',
      this.formTemplateData.emailAddressSupremeCourt
    );
    formData.append(
      'comorbidity',
      this.formTemplateData.comorbiditySupremeCourt
    );
    formData.append('allergies', this.formTemplateData.allergiesSupremeCourt);
    formData.append('bloodType', this.formTemplateData.selectBloodType);
    formData.append(
      'emergencyContactNumber',
      this.formTemplateData.contactNumberEmergency
    );
    formData.append(
      'personToContact',
      this.formTemplateData.personToContactInEmergency
    );
    formData.append('height', this.formTemplateData.heightSupremeCourtId);
    formData.append('weight', this.formTemplateData.weightSupremeCourtId);
    formData.append('dateOfBirth', this.formTemplateData.dateGeneric);
    formData.append('gsis', this.formTemplateData.gsisSupremeCourtId);
    formData.append('tinNumber', this.formTemplateData.tinNoSupremeCourtId);
    formData.append(
      'philHealth',
      this.formTemplateData.philhealthSupremeCourtId
    );
    formData.append(
      'pagibig',
      this.formTemplateData.pagIbigSupremeCourtId
    );

    formData.append('QR', location.host);
    formData.append('isFileGenerated', '');

    const payload = {
      batchId: 'single create',
      fullName: this.formTemplateData.nameSupremeCourtId,
      employeeNumber: this.formTemplateData.employeeNumberSCid,
      branchStation: this.formTemplateData.branchStationSCid,
      position: this.formTemplateData.PositionSupremeCourtId,
      emailAddress: this.formTemplateData.emailAddressSupremeCourt,
      comorbidity: this.formTemplateData.comorbiditySupremeCourt,
      allergies: this.formTemplateData.allergiesSupremeCourt,
      bloodType: this.formTemplateData.selectBloodType,
      emergencyContactNumber: this.formTemplateData.contactNumberEmergency,
      personToContact: this.formTemplateData.personToContactInEmergency,
      height: this.formTemplateData.heightSupremeCourtId,
      weight: this.formTemplateData.weightSupremeCourtId,
      dateOfBirth: this.formTemplateData.dateGeneric,
      gsis: this.formTemplateData.gsisSupremeCourtId,
      tinNumber: this.formTemplateData.tinNoSupremeCourtId,
      philHealth: this.formTemplateData.philhealthSupremeCourtId,
      QR: 'qr value should be domain point to the pdf view',
      isFileGenerated: 'test',
    };

    return this._httpClient.post<any>(
      `${this.baseUrl}${this.formsUrl}/scid`,
      formData
    );
  }

  editScID(pdfData: Blob, id: string) {
    const formData = new FormData();
    formData.append('file', pdfData, 'scid.pdf');

    return this._httpClient.put<any>(
      `${this.baseUrl}${this.formsUrl}/scid/${id}`,
      formData
    );
  }

  getFormUrlEncoded(toConvert: any) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  async generatePDF() {
    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
      compress: true
    });

    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    // const nativeElement = this.formTemplate.nativeElement;
    // nativeElement.classList.add('fullCertSize');

    const canvas = await html2canvas(this.formTemplate, {
      scale: 2.5, // Increase the scale for better resolution
      imageTimeout: 50000,
      allowTaint: true,
      useCORS: true,
    });

    const imageData = canvas.toDataURL('image/png', 0.6);

    const img = new Image();
    img.src = imageData;

    pdf.addImage(imageData, 'PNG', 0, 0, width, img.height);
    // pdf.save('example.pdf'); // Save the PDF for local testing
    // return
    return pdf.output('blob');
  }
  
  async generateTravelAuthPdf(partiesLength: number = 1) {
    // Create a new jsPDF instance
    const PAGE_HEIGHT = 650;
    const PAGE_WIDTH = 468
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [PAGE_WIDTH, PAGE_HEIGHT],
      compress: true,
    });

    const canvas = await html2canvas(this.formTemplate, {
      scale: 2, // Increase the scale for better resolution
      imageTimeout: 50000,
      allowTaint: true,
      useCORS: true,
    });


    const imageData = canvas.toDataURL('image/png', 0.6);

    const img = new Image();
    img.src = imageData;

    let imgWidth = PAGE_WIDTH;
    let pageHeight = PAGE_HEIGHT;
    let imgHeight =
          ((canvas.height * imgWidth) / 1920)*1.24;
    var heightLeft = imgHeight;
    let position =  0;
    heightLeft -= pageHeight;

    pdf.addImage(imageData, "PNG", 0, position, imgWidth, partiesLength > 15 ?pageHeight - (pageHeight - imgHeight) : img.height);

    if (heightLeft > 0 && partiesLength > 15) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imageData, "PNG", 0, position, imgWidth, imgHeight);
    }
    
    // pdf.save('example.pdf'); // Save the PDF for local testing
    return pdf.output('arraybuffer');
  }

  getScIdById(id: string) {
    return this._httpClient.get<any>(
      `${this.baseUrl}${this.formsUrl}/scid/${id}/`
    );
  }

  deleteScId(id: string) {
    return this._httpClient.delete<any>(
      `${this.baseUrl}${this.formsUrl}/scid/${id}`
    );
  }

  checkRollNumber(rollNumber: number) {
    return this._httpClient
      .get<any>(
        `${this.baseUrl}${this.formsUrl}/certificate/rollnumber/${rollNumber}`
      )
      .pipe(
        tap(data => data.data),
        catchError(() => of(false))
      );
  }

  generateMissingPDF(certData: any) {
    console.log({ certData });
    this.generateCert.next(certData);
  }

  sendOtp(email: string) {
    return this._httpClient.post(`${this.baseUrl}${this.formsUrl}/otp`, {
      email,
    });
  }

  downloadFile(id: string) {
    return this._httpClient.get(`${this.baseUrl}${this.formsUrl}/scid/download/${id}`, {
      responseType: 'blob' as 'json',
    });
  }
}
