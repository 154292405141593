import {
  SignaturePadComponent,
  NgSignaturePadOptions,
} from "@almothafar/angular-signature-pad";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef
} from "@angular/core";
import { of } from "rxjs";
import { NavigationService } from "src/app/@shared/services/navigation.service";
import { SignatureService } from "src/app/@shared/services/signature.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-create-signature",
  templateUrl: "./create-signature.component.html",
  styleUrls: ["./create-signature.component.sass"],
})
export class CreateSignatureComponent implements OnInit {
  @Input()
  data: any;

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild("signature")
  public signaturePad!: SignaturePadComponent;

  @ViewChild("phoneSignature")
  public signaturePhonePad!: SignaturePadComponent;

  @ViewChild('signaturePreviewElement') signaturePreviewElement: ElementRef | undefined;

  @ViewChild('signatureCanvas') canvasRef!: ElementRef;

  inputSignature: string = '';

  selectedFont: string | undefined;

  fonts: string[] = [
    'Lora',
    'PlayfairDisplay',
    'Redressed',
    'Yellowtail',
  ];

  signWithFont () {
    this.drawSignature();
    this.setSignatureFontDataURL();
  }

  selectFont(font: string) {
    this.selectedFont = font;
    this.drawSignature();
  }

  drawSignature() {
    this._detech.detectChanges()

    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    const ctx = canvas.getContext('2d');
  
    if (!ctx) {
      console.error('Canvas context is not available.');
      return;
    }
  
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
  
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
  
    const fontSize = 45;
    ctx.font = `${fontSize}px ${this.selectedFont}`;
    ctx.fillStyle = 'black';
  
    const text = this.inputSignature;
  
    const textMetrics = ctx.measureText(text);
    const textWidth = textMetrics.width;
    const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
  
    const textX = (canvasWidth - textWidth) / 2;
    const textY = (canvasHeight - textHeight) / 2 + 20;
  
    ctx.fillText(text, textX, textY);
  }
  
  setSignatureFontDataURL() {
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;
    const dataURL = canvas.toDataURL();
    this.signatureData = dataURL;

    var link = document.createElement('a');
    link.href = dataURL;
    
    this.close();
  }

  clearSelectedFont() {
    this.selectedFont = undefined;
  }

  public signaturePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 462,
    canvasHeight: 134,
  };

  public signaturePhonePadOptions: NgSignaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    minWidth: 5,
    canvasWidth: 340,
    canvasHeight: 300,
  };

  signatureData: any = null;

  resetUpload: boolean = false;
  status: string = "";

  signatureList: any[] = [];

  selectedSignature: any = null;

  config = of({
    width: 462,
    height: 134,
  });

  typedSignature: string = "";
  constructor(
    private _signatures: SignatureService,
    private _nav: NavigationService,
    private _detech: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._signatures.getSignatures().subscribe((data: any) => {
      console.table(data.data);
      this.signatureList = data?.data ?? [];
      if (environment.local) {
        this.signatureList = this.signatureList.map((signature: any) => ({
          ...signature,
          path: "../../../assets/images/signature.png",
        }));
      } else {
        this.signatureList = this.signatureList.map((signature: any) => ({
          ...signature,
          path: `./assets/${signature.path}`,
        }));
      }
    });
  }


  drawComplete(event: MouseEvent | Touch, view: string) {
    // will be notified of szimek/signature_pad's onEnd event

    console.log({ view });
    console.log({
      normalData: this.signaturePad.toDataURL(),
      mobileData: this.signaturePhonePad.toDataURL(),
    });
    if (view === "Desktop" || view === "Tablet") {
      this.signatureData = this.signaturePad.toDataURL();
    } else if (view === "Mobile") {
      this.signatureData = this.signaturePhonePad.toDataURL();
    }

    console.log({ signatureData: this.signatureData });
  }

  drawStart(event: MouseEvent | Touch) {
    // will be notified of szimek/signature_pad's onBegin event
  }

  close() {
    console.log('this.signatureData1', this.signatureData)
    if (this.signatureData) {
      this.closeModalEvent.emit(this.signatureData);
    } else {
      this.closeModalEvent.emit(null);
    }
  }

  doneUpload(event: any) {
    console.log("doneUpload", event);
    if (!environment.local) {
      this.signatureData = `./assets/${event.data.path}`;
    } else {
      this.signatureData = `../../../assets/images/signature.svg`;
    }

    this.close();
  }

  uploadStatus(event: any) {
    this.status = event;
    if (event === "start") {
      this.resetUpload = false;
    }
  }

  reset() {
    this.resetUpload = true;
    this.clearSelected()
    this._detech.detectChanges()
  }

  selectSignature(signature: any) {
    this.signatureData = signature.path;
  }

  clearSelected() {
    this.signatureData = null;
  }

  clearDrawing() {
    this.signaturePad.clear();
    this.signaturePhonePad.clear();
    this.clearSelected();
  }
}
