<div class="otp-container">
  <h2 align="center" class="title">OTP is required to access this document</h2>
    <ng-container>
      <p class="otp-confirmation">
        One Time Password(OTP) has been sent to the ID owner's email address.
        <ng-container *ngIf="time>0;else otpExpired">It will expire in {{time}} seconds.</ng-container>
      </p>
      <!-- <div class="otp-input-container">
        <div class="input-container"> -->
          <input
            type="text"
            class="otp"
            placeholder="Enter OTP"
            (blur)="inputOtp($event)"
          />
          <p *ngIf="otpError !== ''" class="error-text">{{ otpError }}</p>
        <!-- </div> -->

        <button
          [class]="otp === '' ? 'otp-button' : 'otp-button active'"
          (click)="verifyOtp()"
        >
          Verify OTP
        </button>

        <button
          *ngIf="isExpired"
          class="otp-button active"
          (click)="sendOtp()"
        >
          Resend OTP
        </button>
      <!-- </div> -->
    </ng-container>
</div>
<ng-template #otpExpired><a style="color: red">OTP has expired</a></ng-template>