import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Subject,
  catchError,
  filter,
  firstValueFrom,
  of,
  takeUntil,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { DashboardCardModel } from './@shared/models/dasboard.model';
import { AuthenticationService } from './@shared/services/authentication.service';
import { BreadcrumbsService } from './@shared/services/breadcrumbs.service';
import { DashBoardServices } from './@shared/services/dashboard.service';
import { FileActionService } from './@shared/services/file-action.service';
import { FilesService } from './@shared/services/files.service';
import { LoaderService } from './@shared/services/loader.service';
import { NavigationService } from './@shared/services/navigation.service';
import { SignSecureService } from './@shared/services/signsecure.service';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'unawa-dms-UI';
  initialLoad: boolean = true;
  userType: string = '';
  viewPdf: boolean = false;
  viewImage: boolean = false;
  pdfSrc: string = '';
  fileType: string = '';
  sessionTimer: any;
  workflow = false;
  sign = false;
  isSessionActive$ = this._authService.isSessionActive$;
  public = false;
  formViewer = false;

  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private _authService: AuthenticationService,
    private _fileActionService: FileActionService,
    private _dashboardService: DashBoardServices,
    private _fileService: FilesService,
    private _nav: NavigationService,
    private _snackBar: MatSnackBar,
    private _loader: LoaderService,
    private _signSecureService: SignSecureService,
    private idle: Idle
  ) {
    const sessionTimer: number = +(localStorage.getItem('sTime') || 0);
    this.sessionTimer = sessionTimer;
    idle.setIdle(30);
    idle.setTimeout(1800);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.logout();
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    let tab = localStorage.getItem('tabs');
    let numTabs = tab ?? '0';

    if (!!!numTabs) {
      localStorage.removeItem('tabs');
    } else {
      localStorage.setItem('tabs', (+numTabs - 1).toFixed(0));
    }

    return true;
  }

  @HostListener('window:storage', ['$event'])
  storageListner(event: any) {
    const token = localStorage.getItem('token');
    if (token) {
      if (!!!this._authService.userRole) {
        this._authService.authenticateUser(token);
      }
    } else {
      this._authService.userRole = '';
      this._authService.setRole(this._authService.userRole);
    }
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    let tab = localStorage.getItem('tabs');
    let numTabs = tab ?? '0';
    const added = sessionStorage.getItem('added');

    console.log({
      numTabs,
      token: localStorage.getItem('token') !== null,
      added,
    });
    if (+numTabs <= 0 && localStorage.getItem('token') && !added) {
      localStorage.clear();
    }

    if (!added) {
      sessionStorage.setItem('added', 'added');
      localStorage.setItem('tabs', (+numTabs + 1).toFixed(0));
    }

    this._dashboardService.getAppVersion().subscribe(i => {
      this._dashboardService.appVersion = i.data.version;
    });
    this._fileActionService.isViewFile$.subscribe(i => {
      if (this._fileActionService.file)
        this.fileType = this._fileActionService.file.fileExtension;
      if (!environment.local) {
        this.pdfSrc = this._fileActionService.pdfSrc;
      } else {
        if (this.fileType == 'pdf') {
          this.pdfSrc = './assets/dms-documents/pdf-test.pdf';
        } else {
          this.pdfSrc = './assets/dms-documents/FullPage.jpg';
        }
      }

      this.viewPdf = i;
      //TODO: prevent nested subscriptions, use RXJS opertors as needed.
      if (i) {
        this._fileService
          .viewDirectory(
            this._fileActionService.file.id ||
              this._fileActionService.file.actions.info.data.id
          )
          .subscribe(() => {});
      }
    });

    this._signSecureService.isPublicView$.subscribe(async i => {
      console.log({i})
      this.public = i;
    });

    this._authService.role$.subscribe(async i => {
      this.userType = i;
      this.idle.watch();
      const fileId = this._nav.sharedView();
      console.log({ fileId });
      if (fileId) {
        this._fileService
          .verifySharedFile(fileId)
          .pipe(
            catchError((error: any) => {
              if (
                !error?.error?.error?.split(':')[1]?.includes('Unauthorized') ??
                false
              ) {
                this._snackBar.openFromComponent(SnackbarComponent, {
                  duration: 3000,
                  data: {
                    type: 'error',
                    dataType: this.fileType,
                    text: error.error.error.split(':')[1],
                  },
                });
              }
              this._nav.clearParams(true, true);
              return of();
            })
          )
          .subscribe((data: any) => {
            console.log({ data });
            if (data.data) {
              const document = data.data;

              this._nav.clearParams(false, true);
              if (!document.workflow) {
                this._fileActionService.pdfSrc = `./assets/${
                  document.path || ''
                }`;

                this._fileActionService.file = data.data;
                this._fileActionService.file.img_properties =
                  data.data.properties;
                this._fileActionService.setIsViewFile(true);
              } else {
                this._signSecureService.setWorkFlowData(document);
                this._nav.updateSigningPage(true);
              }
            }
          });
      }

      const workflowId = this._nav.workflowSharedView();
      console.log({ workflowId });
      if (workflowId) {
        const data = await firstValueFrom(
          this._fileService.verifyFile(workflowId).pipe(
            catchError(() => {
              this._nav.clearParams(true, true);
              return of();
            })
          )
        );

        if (
          data && (data?.data?.workflow?.privacyType === 'RESTRICTED'||
          data?.data?.privacyType === 'RESTRICTED')
        ) {
          console.log({ restricted: data, i });
          if (!!i) {
            this._fileService
              .verifyFile(workflowId)
              .pipe(
                catchError((error: any) => {
                  this._snackBar.openFromComponent(SnackbarComponent, {
                    duration: 3000,
                    data: {
                      type: 'error',
                      dataType: this.fileType,
                      text: error.error.error.split(':')[1],
                    },
                  });
                  this._nav.clearParams(true, true);
                  return of();
                })
              )
              .subscribe((data: any) => {
                if (data.data) {
                  const document = data.data;

                  this._nav.clearParams(false, true);
                  if (!document.workflow) {
                    this._fileActionService.pdfSrc = `./assets/${
                      document.path || ''
                    }`;

                    this._fileActionService.file = data.data;
                    this._fileActionService.file.img_properties =
                      data.data.properties;
                    this._fileActionService.setIsViewFile(true);
                  } else {
                    this._signSecureService.setWorkFlowData(document);
                    this._nav.updateSigningPage(true);
                  }
                }
              });
          } else {
            // this._nav.clearParams(true)
          }
        } else if (
          data &&
          (data?.data?.workflow?.privacyType === 'CONFIDENTIAL' ||
            data?.data?.privacyType === 'CONFIDENTIAL')
        ) {
          this._fileService
            .getFile(workflowId)
            .pipe(
              catchError((error: any) => {
                this._snackBar.openFromComponent(SnackbarComponent, {
                  duration: 3000,
                  data: {
                    type: 'error',
                    dataType: this.fileType,
                    text: error.error.error.split(':')[1],
                  },
                });
                this._nav.clearParams(true, true);
                return of();
              })
            )
            .subscribe((data: any) => {
              if (data.data) {
                const document = data.data;

                this._nav.clearParams(false, true);
                if (!document.workflow) {
                  this._fileActionService.pdfSrc = `./assets/${
                    document.path || ''
                  }`;

                  this._fileActionService.file = data.data;
                  this._fileActionService.file.img_properties =
                    data.data.properties;
                  this._fileActionService.setIsViewFile(true);
                } else {
                  this._signSecureService.setWorkFlowData(document);
                  this._nav.updateSigningPage(true);
                }
              }
            });
        } else if (
          data &&
          (data?.data?.workflow?.privacyType === 'PUBLIC' ||
            data?.data?.privacyType === 'PUBLIC')
        ) {
          this._fileService
            .verifyUserFile(workflowId)
            .pipe(
              catchError((error: any) => {
                this._snackBar.openFromComponent(SnackbarComponent, {
                  duration: 3000,
                  data: {
                    type: 'error',
                    dataType: this.fileType,
                    text: error.error.error.split(':')[1],
                  },
                });
                this._nav.clearParams(true, true);
                return of();
              })
            )
            .subscribe((data: any) => {
              if (data.data) {
                const document = data.data;

                this._nav.clearParams(false, true);
                if (!document.workflow) {
                  this._fileActionService.pdfSrc = `./assets/${
                    document.path || ''
                  }`;

                  this._fileActionService.file = data.data;
                  this._fileActionService.file.img_properties =
                    data.data.properties;
                  this._fileActionService.setIsViewFile(true);
                } else {
                  this._signSecureService.setWorkFlowData(document);
                  this._nav.updateSigningPage(true);
                }
              }
            });
        }
      }
    });

    this._authService.isAuthorized().subscribe(({ data }) => {
      if (data) {
        this._authService.isLogin();
        this.idle.watch();
      }
      this.isSessionActive$ = of(true);
    });

    this._nav.showWorkFlow$.subscribe(i => (this.workflow = i));
    this._nav.showSigningPage$.subscribe(i => (this.sign = i));

    this._nav.location$.subscribe(i => {
      if (i === 'formViewer') {
        this.formViewer = true;
      } else {
        this.formViewer = false;
      }
    });

    const token = localStorage.getItem('token');

    if (token) {
      const decoded = this._authService.getUserDataFromToken(token);
      if (decoded.exp < Date.now() / 1000) {
        this.logout();
      }
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logoutMSAL() {
    // if (popup) {
    //   this.authService.logoutPopup({
    //     mainWindowRedirectUri: "/"
    //   });
    // } else {
    //   this.authService.logoutRedirect();
    // }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  checkFileType(file: string) {
    const regex = new RegExp('.*.pdf');
    if (regex.test(file)) {
      return 'pdf';
    } else {
      return 'img';
    }
  }

  private logout() {
    localStorage.clear();
    sessionStorage.clear();
    this._authService.userRole = '';
    this._authService.setRole(this._authService.userRole);
    this._nav.emptyParams();

    if (this.loginDisplay) {
      const account = this.authService.instance.getActiveAccount();
      this.authService.logoutRedirect({
        account: account,
      });

      // this.authService.logoutRedirect();
    }
  }
}
