import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { OcrService } from 'src/app/@shared/services/ocr.service';

@Component({
  selector: 'app-ocr-preview',
  templateUrl: './ocr-preview.component.html',
  styleUrls: ['./ocr-preview.component.sass'],
})
export class OcrPreviewComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() ocrAction: string = '';
  title: string = 'Sample';
  ocrResult: string = '';
  constructor(
    private _ocrService: OcrService,
    private _fileActionService: FileActionService
  ) {
    this.title = this._fileActionService.file.name;
  }

  ngOnInit(): void {
    if (this.ocrAction == 'run-ocr') {
      this._ocrService
        .runOcr(this._fileActionService.file.id)
        .subscribe((i) => {
          console.log({ i })
          this.ocrResult = i.data.ocrText;
          this._fileActionService.file.tags = i.data.tags
        });
    } else {
      this.ocrResult = this._fileActionService.file.ocr;
    }
  }
  saveOcr() {
    this._ocrService
      .updateOcr(this._fileActionService.file.id, this.ocrResult)
      .subscribe((i) => {
        this._fileActionService.file.ocr = this.ocrResult;
        this.closeModalEvent.emit({ text: this.ocrResult, file: i.data });
      });
  }
  closeModal() {
    this.closeModalEvent.emit();
  }
}
