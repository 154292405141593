import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { lastValueFrom, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportData } from '../models/support.model';

@Injectable({
    providedIn: 'root',
  })
  export class SupportService{
    private readonly baseUrl = environment.apiConfig.baseUrl;
    private readonly supportUrl = environment.apiConfig.support.url;
    constructor(private _httpClient: HttpClient) {}

    createSupportMsg(msg:any) {
        return this._httpClient.post(`${this.baseUrl}${this.supportUrl}/create`, msg);
      }
  }