import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';

@Component({
  selector: 'app-cancel-document-success',
  templateUrl: './cancel-document-success.component.html',
  styleUrls: ['./cancel-document-success.component.sass']
})
export class CancelDocumentSuccessComponent implements OnInit {


  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()


  constructor(private _signSecure: SignSecureService) { }

  ngOnInit(): void {
    console.log({ data: this.data })
  }

  approve() {
    this.closeModalEvent.emit({ action: 'dashboard' })
  }

  decline() {
    this.closeModalEvent.emit(false)
  }



  close(action: string) {
    this.closeModalEvent.emit({ action })
  }

}
