import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { combineLatest, switchMap } from "rxjs";
import { ModalData } from "src/app/@shared/models/Modal.model";
import { BreadcrumbsService } from "src/app/@shared/services/breadcrumbs.service";
import { FilesService } from "src/app/@shared/services/files.service";

@Component({
	selector: "app-delete-file",
	templateUrl: "./delete-file.component.html",
	styleUrls: ["./delete-file.component.sass"],
})
export class DeleteFileComponent implements OnInit {
	@Output() closeModalEvent = new EventEmitter();
	@Input() data: ModalData = {
		type: "",
		action: "",
	};

	constructor(
		private _fileService: FilesService,
		private _breadcrumb: BreadcrumbsService
	) {}

	ngOnInit(): void {}

	async deleteFile() {
		for (let i = 0; i < this.data.data.length; i++) {
			await this._fileService.deleteFile(this.data.data[i].id);
		}

		if (this._breadcrumb.breadcrumbsData.length > 1) {
			this._fileService
				.getAllFolderFiles(
					this._breadcrumb.breadcrumbsData[
						this._breadcrumb.breadcrumbsData.length - 1
					]?.id ?? ""
				)
				.subscribe((i) => {
					this._fileService.setFilesAndFolderAsync(
						this._fileService.formatFilesAndFolderData(i.data)
					);
				});
		} else {
			this._fileService.getAllFilesAndFolder().subscribe(({ data }) => {
				const formattedFiles = this._fileService.formatFilesAndFolderData(data);
				const signSecure = {
					id: "signsecure",
					title: " ‌SignSecure workflow documents",
					file_type: "folder",
					status: "",
				};
				this._fileService.setFilesAndFolderAsync([
					signSecure,
					...formattedFiles,
				]);
			});
		}

		this._fileService.getAllFolder().subscribe(({ data }) => {
			this._fileService.folders = data;
			const format = this._fileService.formatFolder(data);
			this._fileService.setFolderAsync(format);
		});

		this.closeModal(true);
	}
	closeModal(confirm?: boolean) {
		this.closeModalEvent.emit(confirm);
	}
}
