import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ITabletTable } from 'src/app/@shared/models/tablet-table.model';
@Component({
  selector: 'app-tablet-table',
  templateUrl: './tablet-table.component.html',
  styleUrls: ['./tablet-table.component.sass'],
})
export class TabletTableComponent implements OnInit, AfterViewInit, OnChanges {
  paginator!: MatPaginator;
  @Input()
  title: string = '';

  isPaginate: boolean = false;

  @Input() pageSize: number = 5;

  @Input() resultsLength: number = 0;

  @Input()
  data: any = [];

  @Input()
  itemTemplate!: TemplateRef<any>;

  @Input()
  rowFunction: Function = (event: any, element: any) => {};
  pageData: [] = [];
  constructor() {}

  ngAfterViewInit(): void {}
  
  ngOnChanges() {

    if (this.data?.length > 5) {
      this.resultsLength = this.data.length;
      this.isPaginate = true;
      this.pageData = this.data.slice(0, this.pageSize);
    }else{
      this.pageData=this.data;
    }
    
  }

  changePage(e:any) {
    this.pageData = this.data.slice(
      e.pageIndex * e.pageSize,
      e.pageIndex * e.pageSize + e.pageSize
    );
  }
  ngOnInit(): void {}
}
