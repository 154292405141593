import { Pipe, PipeTransform } from '@angular/core';
import Mustache from 'mustache';

@Pipe({
  name: 'parseContent'
})
export class ParseContentPipe implements PipeTransform {
  transform(template: string, content: any): string {
    const result = Mustache.render(template,content)
    console.log('From Mustache template', template)
    console.log('From Mustache content', content)
    return result;
  }
}
