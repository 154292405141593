<div class="view-members">
  <button class="btn-close" (click)="closeModal()">
    <img src="../../../../assets/images/close.svg" />
  </button>
  <div class="header">
    <h1 class="title">File Members</h1>
  </div>

  <div *ngIf="users.length > 0" class="members">
    <div class="member-container" *ngFor="let user of users; let i = index">
      <p class="member">
        {{ user.name }}
        <span class="additional">{{ user.email }}</span>
      </p>
      <mat-select
        placeholder="Viewer"
        panelClass="user-group-select"
        [(value)]="user.role"
        disabled="true">
        <mat-option [value]="'VIEWER'">Viewer</mat-option>
        <mat-option [value]="'EDITOR'">Editor</mat-option>
      </mat-select>
    </div>
  </div>

  <div *ngIf="userGroups.length > 0" class="members">
    <div
      class="member-container"
      *ngFor="let userGroup of userGroups; let i = index">
      <p class="member">
        {{ userGroup.name }}
        <span class="additional">{{ userGroup.name }}</span>
      </p>
      <mat-select
        placeholder="Viewer"
        panelClass="user-group-select"
        [(value)]="userGroup.role"
        disabled="true">
        <mat-option [value]="'VIEWER'">Viewer</mat-option>
        <mat-option [value]="'EDITOR'">Editor</mat-option>
      </mat-select>
    </div>
  </div>

  <div *ngIf="parties.length > 0" class="members">
    <div class="member-container" *ngFor="let party of parties; let i = index">
      <p class="member">
        {{ party.name }}
        <span class="additional">{{ party.email }}</span>
      </p>
    </div>
  </div>

  <div
    *ngIf="users.length == 0 && userGroups.length == 0 && parties.length == 0"
    class="no-members">
    <img
      class="icn-no-members"
      src="../../../../assets/images/file-member.png"
      alt="" />
    <span class="txt-no-members">There are currently no members.</span>
  </div>
</div>
