<div class="container">
  <div
    class="action"
    *ngIf="actions?.download"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="downloadMenu">
    <img src="../../../assets/images/icons/download-line.svg" alt="" />

    <mat-menu #downloadMenu="matMenu">
      <button
        mat-menu-item
        (click)="download(actions.download, 'documentPath')"
        *ngIf="!!actions?.download?.documentPath">
        Document only
      </button>
      <button
        mat-menu-item
        (click)="download(actions.download, 'manifestPath')"
        *ngIf="!!actions?.download?.manifestPath">
        Manifest only
      </button>
      <button mat-menu-item (click)="download(actions.download, 'path')">
        Document with Manifest
      </button>
    </mat-menu>
  </div>
  <div
    class="action"
    *ngIf="actions?.download_normal"
    (click)="download(actions.download_normal, 'path')">
    <img src="../../../assets/images/icons/download-line.svg" alt="" />
  </div>
  <div
    class="action"
    *ngIf="actions?.form_download"
    (click)="downloadSafeForm(actions.form_download)">
    <img src="../../../assets/images/icons/cert-download.svg" alt="" />
  </div>

  <div class="action" *ngIf="actions?.undue">
    <img src="../../../assets/images/icons/warning.png" alt="" />
  </div>
  <div
    class="action"
    *ngIf="actions?.info"
    (click)="showModal(actions.info?.action, '818px', actions.info?.data)">
    <img src="../../../assets/images/icons/info.svg" alt="" />
  </div>
  <div
    class="action edit"
    *ngIf="actions?.edit"
    (click)="showModal('editUser', '636px', actions.edit)">
    <img src="../../../assets/images/icons/edit.png" alt="" />
  </div>
  <div
    class="action edit"
    *ngIf="actions?.edit_certificate"
    (click)="editCertificate(actions.edit_certificate)">
    <img src="../../../assets/images/icons/cert-edit.svg" alt="" />
  </div>
  <div
    class="action edit"
    *ngIf="actions?.edit_scid"
    (click)="editCertificate(actions.edit_scid)">
    <img src="../../../assets/images/icons/edit.png" alt="" />
  </div>
  <div
    class="action delete"
    *ngIf="actions?.delete"
    (click)="showModal('deleteUser', '588px', actions.delete)">
    <img src="../../../assets/images/icons/delete.png" alt="" />
  </div>
  <div
    class="action delete"
    *ngIf="actions?.delete_doc"
    (click)="showModal('deleteDocument', '588px', data)">
    <img src="../../../assets/images/icons/delete.png" alt="" />
  </div>
  <div
    class="action"
    *ngIf="actions?.scid_qr"
    (click)="
      download_ScIDQR(actions.scid_qr)
    ">
    <img src="../../../assets/images/icons/cert-qr.svg" alt="" />
  </div>

  <div
    class="action"
    *ngIf="actions?.scid_download"
    (click)="
      downloadScId(
        actions.scid_download,
        'filepath',
        actions.scid_download?.fullName
      )
    ">
    <img src="../../../assets/images/icons/cert-download.svg" alt="" />
  </div>
  <div
    class="action"
    *ngIf="actions?.scid_delete"
    (click)="showModal('deleteScid', '588px', actions?.scid_delete?.id || actions?.scid_delete?._id)">
    <img src="../../../assets/images/icons/cert-delete.svg" alt="" />
  </div>
  <div
    class="action"
    *ngIf="actions?.form_version?.filepath?.length > 1"
    (click)="$event.stopPropagation()"
    [matMenuTriggerFor]="formVersion">
    <img src="../../../assets/images/icons/version.svg" alt="" />

    <mat-menu #formVersion="matMenu">
      <button
        *ngFor="let path of actions.form_version?.filepath; let i = index"
        (click)="showForm(path, actions.form_version?.fullName)"
        mat-menu-item>
        <img src="../../../assets/images/icons/version.svg" alt="" />
        Version {{ i + 1 }}
      </button>
    </mat-menu>
  </div>
  <div
    class="action choice {{ disable ? 'disable' : '' }}"
    *ngIf="actions?.choices">
    <button
      class="btn-actions {{ disable ? 'disable' : '' }}"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="disable ? null : moreActions">
      Actions
      <img src="../../../assets/images/icons/gear.png" alt="" />
    </button>
    <mat-menu #moreActions="matMenu">
      <button
        mat-menu-item
        (click)="shareFile(actions.choices?.share)"
        *ngIf="actions?.choices?.share">
        <img src="../../../assets/images/icons/share.svg" alt="" />
        Share
      </button>
      <button
        mat-menu-item
        (click)="modifyFile(actions.choices?.modify)"
        *ngIf="actions?.choices?.modify">
        <img src="../../../assets/images/icons/modify.svg" alt="" />
        Modify
      </button>
      <button
        mat-menu-item
        (click)="modifyFile(actions.choices?.view_file)"
        *ngIf="actions?.choices?.view_file">
        <img src="../../../assets/images/icons/view_info.svg" alt="" />
        View Info
      </button>

      <button
        mat-menu-item
        *ngIf="actions?.choices?.move"
        (click)="moveFile(actions.choices?.move)">
        <img src="../../../assets/images/icons/move.svg" alt="" />
        Move
      </button>

      <!-- Hidden for now
        <button
        mat-menu-item
        *ngIf="actions?.choices?.duplicate"
        (click)="createDuplicateDirectory()"
      >
        <img src="../../../assets/images/icons/duplicate.svg" alt="" />
        Duplicate
      </button> -->
      <button
        mat-menu-item
        *ngIf="actions?.choices?.download"
        (click)="download(actions.choices?.download)">
        <img src="../../../assets/images/icons/download.svg" alt="" />
        Download
      </button>
      <button
        mat-menu-item
        *ngIf="actions?.choices?.view_members"
        (click)="
          fileActionModal('viewMembers', '520px', actions.choices?.view_members)
        ">
        <img src="../../../assets/images/icons/view-team-members.svg" alt="" />
        View Members
      </button>
      <button
        mat-menu-item
        *ngIf="actions?.choices?.delete"
        (click)="
          fileActionModal(
            'delete-selected-files',
            '588px',
            actions.choices?.delete
          )
        ">
        <img src="../../../assets/images/icons/delete.svg" alt="" />
        Delete
      </button>
    </mat-menu>

    <mat-menu #share="matMenu">
      <button mat-menu-item>
        <fa-icon [icon]="faUser"></fa-icon>
        Share to user
      </button>

      <button mat-menu-item>
        <fa-icon [icon]="faUsers"></fa-icon>
        Share to group
      </button>
      <button mat-menu-item>
        <fa-icon [icon]="faLock"></fa-icon>
        Set Share access
      </button>
      <button mat-menu-item>
        <fa-icon [icon]="faCalendarCheck"></fa-icon>
        Share until
      </button>
    </mat-menu>

    <mat-menu #export="matMenu">
      <button mat-menu-item>PDF</button>
      <button mat-menu-item>Excel</button>
      <button mat-menu-item>Word Document</button>
    </mat-menu>
  </div>
</div>
