import { AfterContentInit, AfterViewInit, ViewChild } from "@angular/core";
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  SimpleChanges,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { AuthenticationService } from "src/app/@shared/services/authentication.service";
import { DashBoardServices } from "src/app/@shared/services/dashboard.service";
import { FileActionService } from "src/app/@shared/services/file-action.service";
import { NavigationService } from "src/app/@shared/services/navigation.service";
import { UsersService } from "src/app/@shared/services/users.service";
import { environment } from "src/environments/environment";
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: "app-admin-sidebar",
  templateUrl: "./admin-sidebar.component.html",
  styleUrls: ["./admin-sidebar.component.sass"],
})
export class AdminSidebarComponent implements OnInit, AfterContentInit {
  version: string = "";
  userType: string = "";
  readonly unawaLogoFlag = environment.flag.unawaLogo;
  selected: string = this._navigation.initLocation;
  dashRep: boolean = true;
  manageUser: boolean = false;
  manageUserGrp: boolean = false;
  auditLog: boolean = false;
  docMenu: boolean = false;
  listMenu: boolean = false;
  docDetailMenu: boolean = false;
  name: string = "";
  email: string = "";
  isPhoneWidth: boolean = false;
  @Input() sideNavToggle: boolean = true;
  @ViewChild("ref1") sideNav!: MatSidenav;
  @ViewChild("ref2") phoneSideNav!: MatSidenav;
  readonly signaturesMenuFlag = environment.flag.signatureMenu;
  loginDisplay = false;
  isMobile: boolean = false;

  constructor(
    private authService: MsalService, 
    private msalBroadcastService: MsalBroadcastService,
    private _navigation: NavigationService,
    private _authService: AuthenticationService,
    private _fileActionService: FileActionService,
    private _dashboardService: DashBoardServices,
    private _userService: UsersService
  ) {
    this._navigation.location$.subscribe(
      (location) => (this.selected = location)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }
    if (this.isPhoneWidth) {
      if(this.phoneSideNav){
      this.phoneSideNav.toggle();
      }
    } else {
      if (this.sideNav) this.sideNav.toggle();
    }

  }

  ngAfterContentInit(): void {
  }

  ngOnInit(): void {
    if (screen.width >= 393 && screen.width <= 430) {
      this.isMobile = true;
    }

    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }
    this.version = this._dashboardService.appVersion;
    this._navigation.initLocation = this.selected;
    this.userType = this._authService.userRole;
    this._userService.getCurrentUserDetails().subscribe((i) => {
      this.name = i.data.givenName + " " + i.data.lastName;
      this.email = i.data.email;
    });

    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })

  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout() {
    this._authService.logout();
    sessionStorage.removeItem('token');
    this._navigation.setSearchView(false);
    this._authService.userRole = '';
    this._authService.setRole(this._authService.userRole);

    
    if (this.loginDisplay) {
      this.authService.logoutRedirect();
    }
  }
  closeSideNav(){
    if(this.isPhoneWidth){
      this.phoneSideNav.close();
    }
  }
  updateLocation(location: string) {
    if(this.isPhoneWidth){
      this.phoneSideNav.close();
    }
    this._navigation.updateLocation(location);
   
    if (location !== "docs") {
      this._fileActionService.selectedTab = 0;
    }

    this._fileActionService.setIsModify(false);
  }
}
