import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core"
import { SafeHtml, SafeResourceUrl } from "@angular/platform-browser"
import { InboxService } from "../services/inbox.service"
import { fromEvent, skip } from "rxjs"

@Component({
  selector: "app-inbox-view",
  templateUrl: "./inbox-view.component.html",
  styleUrls: ["./inbox-view.component.sass"],
})
export class InboxViewComponent implements OnInit, AfterViewInit {
  @Output() goBackEvent = new EventEmitter<boolean>()
  message: any
  html!: SafeResourceUrl
  clickUrl: any

  @ViewChild("iframe") iframe!: ElementRef

  constructor(private _inboxService: InboxService) { }

  ngOnInit(): void {
    this._inboxService.message$.subscribe((data) => {
      console.log({ data })
      this.clickUrl = data.clickUrl['changingThisBreaksApplicationSecurity']?.replace('&#x3D;', '=')
      this.message = data
      this.html = data.html
    })
  }
  goBack() {
    this.goBackEvent.emit(false)
  }

  moved(event: any, frame: any) {
    console.log({ event, frame })
  }

  ngAfterViewInit(): void {
    fromEvent(this.iframe.nativeElement, "load")
      .pipe(skip(1))
      .subscribe((data) => {
        window.location.href = this.clickUrl
      })
  }
}
