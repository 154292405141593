import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: [
    '../../../components/verification-container/verification-container.component.sass',
  ],
})
export class EmailSentComponent implements OnInit {
  @Input() type: string = '';
  @Output() returnEvent = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}
  returnScreen(value: string) {
    this.returnEvent.emit(value);
  }
}
