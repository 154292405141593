<div class="form-viewer">
  <div class="nav">
    <div class="left">
      <button (click)="back()">
        <img
          src="../../../assets/images/icons/back-gold.svg"
          alt=""
          class="back" />
        Back to {{ hasToken ? prevLocation : 'Log in' }}
      </button>
    </div>
    <div class="right">
      <img
        src="../../../assets/images/template-icons/sign-logo.svg"
        alt=""
        class="icon" />
    </div>
  </div>
  <div class="bar"></div>
  <div class="content">
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [original-size]="true"
      [fit-to-page]="true"
      [zoom]="zoom"
      (after-load-complete)="afterLoadComplete($event)"
      class="pdf"></pdf-viewer>
    <div class="actions">
      <button class="action" (click)="zoomIn()">
        <mat-icon class="custom-icon">zoom_in</mat-icon>
      </button>
      <button class="action" (click)="zoomOut()">
        <mat-icon class="custom-icon">zoom_out</mat-icon>
      </button>
      <button class="action" (click)="download()">
        <img
          src="../../../assets/images/icons/download-line.svg"
          alt=""
          srcset="" />
      </button>
      <button class="action" (click)="print()">
        <img
          src="../../../assets/images/template-icons/printer.svg"
          alt=""
          srcset="" />
      </button>
    </div>
  </div>
</div>
