import { DatePipe, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { Breadcrumb } from '../models/breadcrumb.model';
import { FileDataModel } from '../models/file-data.model';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { UserGroupService } from '../services/user-group.service';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.sass'],
  providers: [TitleCasePipe]
})
export class FileViewComponent implements OnInit, OnDestroy {
  @Output() goBackEvent = new EventEmitter<boolean>();
  loc1: string = 'Legal';
  loc2: string = 'Documnents';
  loc3: string = 'Santiago v. Commission on Elections';
  tags: any[] = ['testing@gmail.com'];
  userGroups: any[] = [];
  fileData: FileDataModel = { id: '', src: '', title: '', size: 0 };
  ocrResult: string = '';
  file: any = { name: '' };

  name: string = '';
  userGroupList: any[] = [];
  userList: any[] = [];
  breadcrumbs: Breadcrumb[] = [];
  dateVal: Date = new Date();
  dueDateVal: Date = new Date();
  documentNumber: string = '';
  docDetails: string = '';
  isPublished: boolean = false;
  fileId: string = '';
  isPublishable: boolean = true;
  title: string = ''
  todayDate: Date = new Date();


  constructor(
    public dialog: MatDialog,
    private _ngZone: NgZone,
    private _fileService: FilesService,
    private _userGroupService: UserGroupService,
    private _fileActionService: FileActionService,
    private _breadcrumbsService: BreadcrumbsService,
    private dp: DatePipe,
    private _snackBar: MatSnackBar,
    private changeDetection: ChangeDetectorRef
  ) {
    this.file = this._fileActionService.file;
    this.isPublished = this.file.isPublished;
    this.fileId = this.file.id;
    this.ocrResult = this.file.ocr;
  }
  ngOnDestroy(): void {
    this._breadcrumbsService.removeBreadcrumbData();
  }

  ngOnInit(): void {

    const titleCase = new TitleCasePipe()

    this.title = `Modify ${titleCase.transform(this.file.type || "file")}`

    console.log('file', this.file)
    if (!!this.file.workflow) {
      this.title = `File Information`
    }

    this.tags = this.file?.tags || [];
    this._breadcrumbsService.setBreadcrumbsData({
      title: this.file.name,
      id: '',
    });
    this._breadcrumbsService.breadcrumbs$.subscribe((i) => {
      this.breadcrumbs = i;
    });
    this.tags?.forEach(i => {
      if (i.name.toLowerCase() == 'signsecure') {
        this.isPublishable = false;
      }
    });
    this.userGroups = this.file.userGroups;
    this.userList = this.file.users;
    this.name = this.file.name;
    this.documentNumber = this.file.directoryNumber || this.file?.id;
    this.docDetails = this.file.details || this.file?.description;
    console.log(this.file);
    this.dateVal = new Date(this.file.createdAtFormatted.split(',')[0]);
    this.dueDateVal = new Date(this.file?.workflow?.reminders?.expiration || this.file.dueDateFormatted.split(',')[0]);
  }

  showModal() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: this.name,
        fileId: this.file.id,
        data: [...this.userGroups, ...this.userList] || [],
      },
    });

    dialogRef?.afterClosed()?.subscribe((result) => {
      if (result) {
        if (!result.cancelled) {
          this.userGroups = result.userGroups;
          this.userList = result.users;
        }
      }
    });
  }

  addTags(tag: any) {
    const { value } = tag;
    if (tag.value !== '') {
      if (!this.tags.some((tag) => tag === value)) {
        this.tags.push(value);
      }
      tag.value = '';
    }
  }

  tagUpdate(event: any) { }

  getObjectImage(fileType: string): string {
    switch (fileType) {
      case 'file':
        return '../../../assets/images/icons/file.png';
      case 'folder':
        return '../../../assets/images/icons/folder.png';
      default:
        return '../../../assets/images/icons/file.png';
    }
  }
  goBack() {
    this.goBackEvent.emit(false);
  }

  async updateDirectory() {
    const updatedFile = { ...this.file };
    updatedFile.name = this.name;
    updatedFile.userGroups = this.userGroups.map((groups) => groups.id);
    updatedFile.tags = this.tags?.map((tag) => {
      return tag.name ? tag : { name: tag, type: 'normal' };
    });
    updatedFile.directoryNumber = this.documentNumber;
    updatedFile.details = this.docDetails;
    updatedFile.size = '0 KB'
    console.log(this.dateVal.valueOf() > 0, this.dueDateVal.valueOf() > 0);
    if (this.dateVal.valueOf() > 0 && this.dueDateVal.valueOf() > 0) {
      const date = this.dp.transform(
        new Date(this.dateVal),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      const dueDate = this.dp.transform(
        new Date(this.dueDateVal),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      updatedFile.date = date;
      updatedFile.dueDate = dueDate;
    }
    await this._fileService.updateDirectory(updatedFile).catch((err) => { });
    this.goBack();
  }

  runOcr(ocrAction: string) {
    if (this.isPublished) return;

    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '1072',
      data: {
        action: ocrAction,
        fileId: this.file.id,
        fileName: this.file.name,
      },
    });

    dialogRef?.afterClosed()?.subscribe((result) => {
      this.tags = this._fileActionService.file.tags
      this.changeDetection.detectChanges()
      if (result) {
        this.ocrResult = result.text;
        this.file = result.file
        this.tags = this.file?.tags || [];
      }
    });
  }

  onBreadcrumbBack(data: any) {
    if (data.id === '') {
      this.breadcrumbs = [];
      this._breadcrumbsService.emptyBreadcrumb();
    } else {
      const loc = this.breadcrumbs.findIndex((datum) => datum.id === data.id);
      this._breadcrumbsService.backBreadcrumb(loc);
      this._fileService.getAllFolderFiles(data.id).subscribe(({ data }) => {
        const format = this._fileService.formatFilesAndFolderData(data);
        this._fileService.setFilesAndFolderAsync(format);
      });
    }

    this.goBack();
  }

  publishFile() {
    this.isPublished = !this.isPublished;
    const data = {
      type: 'publish',
      text: '',
    };

    this._fileService
      .publishFile(this.fileId, this.isPublished)
      .subscribe((i) => {
        if (this.isPublished) {
          data['text'] = 'File published';
          this.goBack();
        } else {
          data['text'] = 'File unpublished';
        }
        if (this.isPublished == false) {
          this.tags = this.tags.filter((i) => {
            return i.name != 'Published';
          });
        }
        this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 800,
          data: data,
        });
      });
  }
}
