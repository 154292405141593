<div class="safeform-container">
  <mat-card>
    <mat-card-title>Form Templates</mat-card-title>
    <mat-card-content style="width: 87%;">
      <mat-grid-list cols="3" >
          <mat-grid-tile colspan="1" *ngFor="let template of templates">
            <pdf-viewer
              (click)="
                useForm(template.documentTitle, template._id, template.html)
              "
              [show-all]="false"
              [src]="template.fileUrl"
              [fit-to-page]="true"
              [render-text]="false"
              [page]="1"
              [fit-to-page]="true"
              [show-borders]="true">
            </pdf-viewer>
            <mat-grid-tile-footer>
              {{ template.documentTitle }}
            </mat-grid-tile-footer>
          </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
</div>
