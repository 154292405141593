<div class="signing-page__content" #content>
  <div class="signing-page__navigation" *ngIf="isPhoneWidth">
    <button (click)="pageNavi('up')">
      <img
        src="../../../assets/images/template-icons/previous-button.svg"
        alt="" />
    </button>
    <p>Page {{ pageNumber }} / {{ totalPages }}</p>
    <button (click)="pageNavi('down')">
      <img src="../../../assets/images/template-icons/next-button.svg" alt="" />
    </button>
  </div>
  <div
    class="signing-page__navigation"
    *ngIf="signingNow && status !== 'COMPLETED' && !isPhoneWidth">
    <button (click)="pageNavi('up')">
      <img
        src="../../../assets/images/template-icons/previous-button.svg"
        alt="" />
    </button>
    <p>Page {{ pageNumber }} / {{ totalPages }}</p>
    <button (click)="pageNavi('down')">
      <img src="../../../assets/images/template-icons/next-button.svg" alt="" />
    </button>
  </div>

  <div
    class="signing-page__done_navigation"
    *ngIf="(status === 'COMPLETED' || !signingNow) && !isPhoneWidth">
    <button (click)="print()">
      <img src="../../../assets//images/template-icons/printer.svg" alt="" />
    </button>
    <button (click)="zoomLevel('in')">
      <img src="../../../assets//images/icons/zoom-in.svg" alt="" />
    </button>
    <button (click)="zoomLevel('out')">
      <img src="../../../assets//images/icons/zoom-out.svg" alt="" />
    </button>
    <select [(ngModel)]="zoom">
      <option value="0.25">25%</option>
      <option value="0.5">50%</option>
      <option value="0.75">75%</option>
      <option value="1" selected>100%</option>
      <option value="1.25">125%</option>
      <option value="1.5">150%</option>
      <option value="1.75">175%</option>
      <option value="2">200%</option>
      <option value="2.25">225%</option>
      <option value="2.5">250%</option>
      <option value="2.75">275%</option>
      <option value="3">300%</option>
    </select>
    <div class="divider"></div>
    <button (click)="pageNavi('up')">
      <img
        src="../../../assets//images/icons/up.svg"
        alt=""
        class="back-button" />
    </button>
    <div class="page-text">{{ pageNumber }} of {{ totalPages }}</div>
    <button (click)="pageNavi('down')">
      <img
        src="../../../assets//images/icons/down.svg"
        alt=""
        class="back-button" />
    </button>
  </div>

  <div class="pdf" #viewer>
    <div
      *ngIf="!(status === 'COMPLETED' ||
        status === 'CANCELLED' ||
        status === 'DECLINED' ||
        status === 'DONE' ||
        status === 'EXPIRED' ||
        status === 'REJECTED' ||
        !signingNow)"
      class="signing"
      [ngStyle]="{
        position: 'absolute',
        left: positionLeft,
        top: '10px',
        marginLeft: 'auto',
        marginRight: 'auto'
      }">
      <ko-stage [config]="stageConfig" #stage></ko-stage>
    </div>
    <pdf-viewer
      [src]="pdfSrc"
      [fit-to-page]="true"
      [zoom]="zoom"
      [(page)]="pageNumber"
      [show-borders]="true"
      [show-all]="false"
      (after-load-complete)="afterLoadComplete($event)"
      (page-rendered)="test($event)">
    </pdf-viewer>
  </div>
</div>
