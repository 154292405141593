import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Mustache from 'mustache';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { Subscription, async, lastValueFrom } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.sass'],
})
export class FormBuilderComponent implements OnInit {
  template: any;

  content: any;

  hidden: boolean = true;
  @ViewChild('formContent', { static: true }) formContent!: ElementRef;

  running: boolean = false;
  subs: Subscription = new Subscription();
  display: string = 'block';
  constructor(
    private _forms: SafeformService,
    private _loader: LoaderService,
    private _navigation: NavigationService,
    private _file: FilesService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this._forms.generateCert$.subscribe(async data => {
        if (!!!data && !this.running) return;
        this._loader.show();
        this._loader.show();
        this._loader.show();
        this._loader.show();
        this._loader.show();
        this.running = true;
        this.content = data;
        this.display = 'block';
        await new Promise(r => setTimeout(r, 1000));
        this.template = Mustache.render(this._forms.formTemplate, this.content);
        await new Promise(r => setTimeout(r, 2000));
        await this.generate();
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async generate() {
    if (!!!this.content && !this.running) return;

    let pdf;
    switch (this._forms.formTemplateTitle) {
      case this._forms.certificateTitle:
        console.log('entering');
        pdf = await this.generateCertificate();
        this._forms
          .addCertificateFile(this._forms.certificateId, pdf, this.content)
          .subscribe(res => {
            const data = res.data;
            const files = res.data.filepath;

            if (this.content.action === 'download') {
              this._file.downloadFile(
                this._file.changeExtension(data.fullName, 'pdf'),
                `../../../assets/${files[files.length - 1].pdfPath}`
              );
            } else {
              this._forms.viewerData = {
                ...res?.data,
                pdfSrc: files[files.length - 1],
                prevLocation: 'Certificate',
                prev: this.content?.prev ?? 'certificates-table',
                name: `${res.data.fullName} - Certificate`,
              };
              if (!this.content.noRedirection) {
                this._navigation.updateLocation('formViewer');
              } else {
                this._loader.hideNow();
              }
            }
            this._forms.certificateId = '';
            this.content = null;
            this.running = false;
            this.display = 'none';
            this._forms.generateMissingPDF(null);
          });
        break;
      case this._forms.scId:
        pdf = await this.generateScid();
        this._forms.editScID(pdf, this._forms.ScId).subscribe(res => {
          const data = res.data;
          const files = res.data.filepath;

          if (this.content.action === 'download') {
            this.downloadScId(data);
          } else {
            this._forms.viewerData = {
              ...res?.data,
              pdfSrc: { pdfPath: files },
              prevLocation: 'Supreme Court ID',
              prev: 'sc-id-table',
              name: `${res.data.fullName} - ID`,
            };
            if (!this.content.noRedirection) {
              this._navigation.updateLocation('formViewer');
            } else {
              this._loader.hideNow();
            }
          }

          this._forms.ScId = '';
          this.content = null;
          this.running = false;
          this.display = 'none';
          this._forms.generateMissingPDF(null);
        });
        break;
      default:
        return;
    }
  }

  async downloadScId(data: any) {
    let pdfSrc: any;
    await lastValueFrom(this._forms.downloadFile(data.id || data?._id)).then(
      (pdfSrcData: any) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
        };
        fileReader.onloadend = () => {
          this._loader.hideNow();
          this._file.downloadFileFromBytes(data?.fullName, pdfSrc);
        };
        fileReader.readAsArrayBuffer(pdfSrcData);
      }
    );
  }

  async generateCertificate() {
    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    const nativeElement = this.formContent.nativeElement;
    nativeElement.classList.add('fullCertSize');

    const canvas = await html2canvas(nativeElement, {
      scale: 2.5, // Increase the scale for better resolution
      imageTimeout: 50000,
      allowTaint: true,
      useCORS: true,
    });

    const imageData = canvas.toDataURL('image/png', 0.6);

    const img = new Image();
    img.src = imageData;

    pdf.addImage(imageData, 'PNG', 0, 0, width, img.height);
    // pdf.save('example.pdf'); // Save the PDF for local testing
    return pdf.output('blob');
  }

  async generateScid() {
    // Create a new jsPDF instance
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: [8.5, 13],
      compress: true,
    });

    const nativeElement = this.formContent.nativeElement;
    nativeElement.classList.add('scidSize');
    const canvas = await html2canvas(nativeElement, {
      scale: 1, // Increase the scale for better resolution
      allowTaint: true,
      useCORS: true,
      logging: true,
    });

    const imageData = canvas.toDataURL('image/png', 0.6);

    const img = new Image();
    img.src = imageData;

    const width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();
    // height = (img.height / img.width) * width;
    // Add the image to the PDF with the calculated dimensions
    pdf.addImage(imageData, 'PNG', 0, 0, width, img.height);

    // to render image
    let pdfData = pdf.output('blob'); // Get the PDF data as a Blob
    // pdf.save('example.pdf')
    return (pdfData = pdf.output('blob')); // Get the PDF data as a Blob
  }
}
