import { DOCUMENT, Location } from "@angular/common";
import {
	EventEmitter,
	Inject,
	Injectable,
	OnInit,
	Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, connect } from "rxjs";
import { environment } from "src/environments/environment";
import { UserGroupModel } from "../models/user-group.model";
import { AuthenticationService } from "./authentication.service";
import { FileActionService } from "./file-action.service";

export enum Locations {
	DASHBAORD = "report",
	INBOX = "inbox",
	DOCUMENTS = "docs",
	FOLDER_AND_FILES = "listMenu",
	WORKFLOW = "workflow",
	SIGNATURES = "signatures",
	FAQ = "faq",
	SUPPORT = "support",
	MANAGE_USERS = "mUser",
	MANAGE_GROUPS = "mUserGrp",
	AUDIT_LOGS = "audit",
	REPORTS = "reports",
	RESET_PASSWORD = "reset-password",
	TEMPLATES = "templates",
	BULK_ID = 'bulk-id',
	BULK_CERTIFICATE = 'bulk-cert',
	BULK_ID_TABLE = 'bulk-id-table',
	BULK_CERTIFICATE_TABLE = 'bulk-certificate-table',
	SAFE_FORM = 'safe-form',
	CERTIFICATES_TABLE = 'certificates-table',
	SUPREME_COURT_ID_TABLE = 'sc-id-table',
}

@Injectable({
	providedIn: "root",
})
export class NavigationService {
	isViewAccount: boolean = false;
	initLocation: string = this.getInitLocation();

	private location = new BehaviorSubject(this.initLocation);
	private accountView = new BehaviorSubject<boolean>(false);
	private searchView = new BehaviorSubject<boolean>(false);
	private showWorkFlow = new BehaviorSubject<boolean>(false);
	private showSigningPage = new BehaviorSubject<boolean>(false);
	lastKnownLocation: string = "report";
	location$ = this.location.asObservable();
	accountView$ = this.accountView.asObservable();
	serchView$ = this.searchView.asObservable();
	showWorkFlow$ = this.showWorkFlow.asObservable();
	showSigningPage$ = this.showSigningPage.asObservable();
	params: any;
	localParams: any;

	redirectSet: Map<string, string> = new Map([
		// ["templates", environment.integration.formTemplatesURL],
	]);

	constructor(
		private _location: Location,
		@Inject(DOCUMENT) private _document: Document,
		private _fileActionService: FileActionService
	) { }

	setAccountView(data: boolean) {
		this.accountView.next(data);
	}
	setSearchView(data: boolean) {
		this.searchView.next(data);
	}
	setLocation(data: string) {
		this.lastKnownLocation = data;
		this.location.next(data);
	}
	updateLocation(location: string) {
		// this._location.go('/', `l=${location}`);
		console.log("location", location);
		this.setSearchView(false);
		this.initLocation = location;
		this.setLocation(location);
		if (this.isViewAccount) {
			this.closeView();
		}
	}

	getLastLocation() {
		switch (this.lastKnownLocation) {
			case "report":
				return "Dashboard";
			case "inbox":
				return "Inbox";
			case "docs":
				return "Documents";
			case "listMenu":
				return "Folders and Files";
			case "workflow":
				return "Workflow";
			case "signatures":
				return "Signatures";
			case "faq":
				return "Faq";
			case "support":
				return "Support";
			case "mUser":
				return "Mange Users";
			case "mUserGrp":
				return "Mange Groups";
			case "audit":
				return "Audit Log";
			case "reports":
				return "Reports";
			case "templates":
				return "Templates";
			case "bulk-id-table":
				return "Bulk ID Table";
			case "bulk-certificate-table":
				return "Bulk Certificate Table";
			case 'safe-form':
				return 'Safe Form';
			case 'certificates-table':
				return 'Certificates Table';
			case 'sc-id-table':
				return 'ID Table';
			default:
				return "Dashboard";
		}
	}

	closeView() {
		this.isViewAccount = false;
		this.setAccountView(this.isViewAccount);
	}

	ngOnInit() {
		// let params = new URL(this._document.location.toString()).searchParams;
		// let name = params.get('l');
		// this.initLocation = name || 'report';
		//this.updateLocation(this.initLocation);
	}

	goTologinPage() {
		this._location.go("/");
	}

	sharedView() {
		const params: any = this.getParams();
		let f = null
		if(localStorage.getItem('paramNames') === 'f') {
			f = localStorage.getItem('paramId')
		}

		return params?.f || this.params?.f || f;
	}

	workflowSharedView() {
		const params: any = this.getParams();
		let w = null
		if(localStorage.getItem('paramNames') === 'w') {
			w = localStorage.getItem('paramId')
		}

		return params?.w || this.params?.w || w;
	}

	getTokenParam() {
		const params: any = this.getParams();
		return params?.token || this.params?.token || null;
	}

	getLocationParam() {
		const params: any = this.getParams();
		return params?.q || this.params?.q || null;
	}

	getInitLocation() {
		const loc = this.getLocationParam();
		this.clearParams();
		return this.getValidLocation(loc);
	}

	getVerificationStatus() {
		switch (this.initLocation) {
			case "reset-password":
				return true;
			default:
				return false;
		}
	}

	getParams(param: string = "") {
		return new Proxy(
			new URLSearchParams(`${!!window.location.search ? `${window.location.search}&${param}` : `?${param}`}`),
			{
				get: (searchParams, prop: string) => searchParams.get(prop),
			}
		);
	}

	saveParams(file: boolean = false) {
		if (!!!this.params || JSON.parse(JSON.stringify(this.params)).length === 0) {
			const name = localStorage.getItem('paramNames');
			const id = localStorage.getItem('paramId');
			console.log({file, id, name})
			if(name && id) {
				this.params = this.getParams(`${name}=${id}`);
			} else {
				this.params = this.getParams();
			}
			
			localStorage.setItem('params', JSON.stringify(this.params))

			if (file) {

				localStorage.removeItem('paramNames');
				localStorage.removeItem('paramId');
			}
		}
	}

	addParams(param: string, value: string) {
		



		this.params = this.getParams(`${param}=${value}`);

		console.log({ params: { param, value } })
	}

	clearParams(error: boolean = false, file = false) {
		this.saveParams(file);
		

		if (error) {
			window.history.replaceState({}, document.title, "/");
		} else {
			window.history.pushState({}, document.title, "/");
		}
	}

	getValidLocation(location: string) {
		switch (location) {
			case Locations.DASHBAORD:
			case Locations.INBOX:
			case Locations.DOCUMENTS:
			case Locations.FOLDER_AND_FILES:
			case Locations.WORKFLOW:
			case Locations.FOLDER_AND_FILES:
			case Locations.SIGNATURES:
			case Locations.FAQ:
			case Locations.SUPPORT:
			case Locations.MANAGE_USERS:
			case Locations.MANAGE_GROUPS:
			case Locations.AUDIT_LOGS:
			case Locations.RESET_PASSWORD:
			case Locations.TEMPLATES:
			case Locations.BULK_ID_TABLE:
			case Locations.BULK_CERTIFICATE_TABLE:
			case Locations.SAFE_FORM:
			case Locations.CERTIFICATES_TABLE:
				return location;
			default:
				return Locations.DASHBAORD;
		}
	}

	redirectToSignSecureSite(location: string, token: string) {
		const userid = localStorage.getItem("userID");
		const mLocation = `${this.redirectSet.get(
			location
		)}?token=${token}&site-id=DMS&userID=${userid}`;
		window.location.href = mLocation || window.location.href;
	}

	showWorkflow() {
		this.showWorkFlow.next(true);
	}

	hideWorkFlow() {
		this.showWorkFlow.next(false);
	}

	updateSigningPage(status: boolean) {
		this.showSigningPage.next(status);
	}

	getCurrentView() {
		const w = window.innerWidth;
		console.log({ w });
		if (w > 961) {
			return "Desktop";
		} else if (w <= 412) {
			return "Mobile";
		} else {
			return "Tablet";
		}
	}

	async sleep(msec: number) {
		return new Promise(resolve => setTimeout(resolve, msec));
	}

	emptyParams() {
		this.params = null
	}
}
