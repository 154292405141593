import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { Subject } from "rxjs";
import { Breadcrumb } from "src/app/@shared/models/breadcrumb.model";
import { FileDataModel } from "src/app/@shared/models/file-data.model";
import { ModalData } from "src/app/@shared/models/Modal.model";
import { BreadcrumbsService } from "src/app/@shared/services/breadcrumbs.service";
import { FilesService } from "src/app/@shared/services/files.service";
import { NavigationService } from "src/app/@shared/services/navigation.service";

@Component({
	selector: "app-upload-file",
	templateUrl: "./upload-file.component.html",
	styleUrls: ["./upload-file.component.sass"],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class UploadFileComponent implements OnInit {
	@Input() data: ModalData = {
		type: "",
		action: "",
	};

	@Output() closeModalEvent = new EventEmitter();

	files: any = [];
	ref: any;
	ongoing = this._fileService.doneUpload$;
	close = this._fileService.closeModal$;

	constructor(
		private _fileService: FilesService,
		private _navigation: NavigationService,
		private _breadcrumb: BreadcrumbsService
	) {}

	ngOnInit(): void {
		this._fileService.files$.subscribe((files) => {
			if (files.length <= 0) {
				this.uploadMore();
			}
			this.files = files.map((file) => {
				return { ...file, size: this._fileService.formatBytes(file.size) };
			});
		});
		this._fileService.pushData();

		this.close.subscribe((data) => {
			console.log({resultData: data})
			if(data) {
				this.closeModal()
			}
		})
	}

	closeModal() {
		if (this._navigation.lastKnownLocation === "docs") {
			this._fileService.getGroupFiles();
			this._fileService.getUserFiles();
		} else if (this._navigation.lastKnownLocation === "listMenu") {
			// Gets all the files inside of the folder
			if (this._breadcrumb.breadcrumbsData.length > 1) {
				this._fileService
					.getAllFolderFiles(
						this._breadcrumb.breadcrumbsData[
							this._breadcrumb.breadcrumbsData.length - 1
						]?.id ?? ""
					)
					.subscribe((i) => {
						this._fileService.setFilesAndFolderAsync(
							this._fileService.formatFilesAndFolderData(i.data)
						);
					});
			} else {
				this._fileService.getAllFilesAndFolder().subscribe(({ data }) => {
					const formattedFiles =
						this._fileService.formatFilesAndFolderData(data);
					const signSecure = {
						id: "signsecure",
						title: " ‌SignSecure workflow documents",
						file_type: "folder",
						status: "",
					};
					this._fileService.setFilesAndFolderAsync([
						signSecure,
						...formattedFiles,
					]);
				});
			}

			this._fileService
				.getAllFolder()
				.subscribe((i) =>
					this._fileService.setFolderAsync(
						this._fileService.formatFolder(i.data)
					)
				);
		} else if (this._navigation.lastKnownLocation === "report") {
			this._fileService.getRecentFiles().subscribe(({ data }) => {
				this._fileService.setRecentFiles(
					this._fileService.formatRecentFile(data)
				);
			});
		}
		this.closeModalEvent.emit();
	}

	uploadMore() {
		this.closeModalEvent.emit("more");
	}
}
