import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserTableModel } from '../models/user-table.model';
import { UserModel } from '../models/user.model';
import recentUsers from '../../../dummy-data/recent-users.json';
import { UserUpdateModel } from '../models/user-update.model';
import { UserInterceptorInterceptor } from '../Interceptor/user-interceptor.interceptor';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly signSecureCreateUserURL = environment.integration.createUser;
  private readonly user = environment.apiConfig.user.url;
  private readonly _usersUrl = `${this.baseUrl}${this.user}`;

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService
  ) {}
  private users = new BehaviorSubject<UserTableModel[]>([]);
  private userModel = new BehaviorSubject<UserModel[]>([]);
  users$ = this.users.asObservable();
  userModel$ = this.userModel.asObservable();

  userTableColumns = ['Name', 'Email', 'Position', 'Role', 'Actions'];
  userRecentTableColumns = ['Name', 'Email', 'Date Added'];

  setUsers(data: UserTableModel[]) {
    this.users.next(data);
  }

  setUserModel(data: UserModel[]) {
    this.userModel.next(data);
  }

  getUsers(sort: string = 'asc') {
    return this._httpClient.get<any>(`${this._usersUrl}/all?sort=${sort}`);
  }

  getCurrentUserDetails() {
    return this._httpClient.get<any>(
      `${this._usersUrl}/` + this._authService.userId
    );
  }

  createUser(user: UserModel) {
    return this._httpClient.post<any>(`${this._usersUrl}/create`, user);
  }

  createSignSecureUser(user: UserModel) {
    return this._httpClient.post<any>(`${this.signSecureCreateUserURL}`, user);
  }

  udpateUser(user: UserUpdateModel) {
    return this._httpClient.patch<any>(`${this._usersUrl}/${user.id}`, user);
  }

  deleteUser(id: string) {
    return this._httpClient.delete<any>(`${this._usersUrl}/${id}`);
  }

  getUserInfo(id: string = this._authService.userId) {
    return this._httpClient.get<any>(`${this._usersUrl}/${id}`);
  }

  formatUsers(users: UserModel[]): UserTableModel[] {
    return users.map((user: UserModel) => {
      const { id, givenName, lastName, email, position, role } = user;
      const formattedUser: any = {};
      formattedUser['name'] = `${givenName} ${lastName}`;
      formattedUser['email'] = email;
      formattedUser['position'] = position;
      formattedUser['role'] = role;
      formattedUser['actions'] = { edit: user, delete: id };
      return formattedUser;
    });
  }

  getRecentUsers() {
    return recentUsers;
  }

  getUserCurrentInfo() {
    return this.getUserInfo();
  }

  generateOTP() {
    return this._httpClient.post<any>(`${this._usersUrl}/generate-otp`, {});
  }

  validateOTP(otp: string) {
    return this._httpClient.get<any>(
      `${this._usersUrl}/validate-otp?otp=${otp}`
    );
  }
}
