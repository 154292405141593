<ng-container>
  <form class="add-new-user">
    <h4 class="title">Are you sure you want to remove this group?</h4>
    <div class="content">
      <div class="input-group">
        <!-- <mat-label class="label-text"
            >Are you sure you want to remove this group?</mat-label
          > -->
      </div>
      <div class="actions">
        <button class="cancel" (click)="closeModal()">Cancel</button>
        <button class="create" (click)="deleteUserGroup()">
          Yes, delete Group
        </button>
      </div>
    </div>
  </form>
</ng-container>
