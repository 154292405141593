import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SafeformService } from 'src/app/@shared/services/safeform.service';

@Component({
  selector: 'app-sc-id',
  templateUrl: './sc-id.component.html',
  styleUrls: ['./sc-id.component.sass'],
})
export class ScIdComponent implements OnInit {
  columns: string[] = [
    'name',
    'employee number',
    'branch station',
    'position',
    'date created',
    'actions',
  ];
  data: any = this._safeFormService.supremeId$;
  sc_ID: any = this._safeFormService.supremeId$;
  searchQuery: string = '';

  constructor(
    private _safeFormService: SafeformService,
    private _navigation: NavigationService,
    private _loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.getSupremeId();
  }

  getSupremeId(wait: boolean = false) {
    this._safeFormService.getScId().subscribe(({ data }) => {
      this._safeFormService.setScId(this._safeFormService.formatScId(data));
      this._loader.hideNow();
    });
  }

  search() {
    this.sc_ID = this.data.pipe(
      map((tableData: any) => {
        console.log({ tableData, search: this.searchQuery });
        return tableData.filter((tableDatum: any) => {
          return (
            tableDatum?.employee_number
              ?.toString()
              .includes(this.searchQuery.toLowerCase()) ||
            tableDatum?.name
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            tableDatum?.position
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            tableDatum?.branch_station
              ?.toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          );
        });
      })
    );
  }

  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }

  onClickRow(event: any, data: any) {
    if (event.target.localName == 'img') {
      return;
    }

    console.log({ data });

    const file = data.actions.scid_download.filepath;

    if (!!file?.length) {
      this._safeFormService.viewerData = {
        ...data.scid_download,
        pdfSrc: { pdfPath: file },
        prevLocation: 'SC ID',
        prev: 'sc-id-table',
        name: `${data.actions.scid_download.fullName} - SC ID`,
      };
      this._navigation.updateLocation('formViewer');
    } else {
      const value = data.actions.scid_download;

      this._loader.show();
      const content = {
        PositionSupremeCourtId: value?.position ?? '',
        allergiesSupremeCourt: value?.allergies ?? '',
        branchStationSCid: value?.branchStation ?? '',
        comorbiditySupremeCourt: value?.comorbidity ?? '',
        companyLocation: value?.branchStation ?? '',
        companyName: value?.personToContact ?? '',
        contactNumberEmergency: value?.emergencyContactNumber ?? '',
        contractDate: value?.dateOfBirth ?? '',
        courtDropDown: value?.Court ?? '',
        dateGeneric: value?.dateOfBirth ?? '',
        emailAddressSupremeCourt: value?.emailAddress ?? '',
        employeeNumberSCid: value?.employeeNumber ?? '',
        gsisSupremeCourtId: value?.gsis ?? '',
        heightSupremeCourtId: value?.height ?? '',
        nameSupremeCourtId: value?.fullName ?? '',
        pagIbigSupremeCourtId: value?.pagibig ?? '',
        parties: value?.allergies ?? '',
        personToContactInEmergency: value?.personToContact ?? '',
        philhealthSupremeCourtId: value?.philHealth ?? '',
        selectBloodType: value?.bloodType ?? '',
        tinNoSupremeCourtId: value?.tinNumber ?? '',
        weightSupremeCourtId: value?.weight ?? '',
        logoSrc: '../../assets/images/sc_logo.png',
      };

      this._safeFormService.ScId =
        data.actions.scid_download.id || data.actions.scid_download._id;
      this._safeFormService.generateMissingPDF(content);
    }
  }
}
