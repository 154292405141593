import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faUser, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faUsers, faLock } from '@fortawesome/free-solid-svg-icons';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Actions } from '../models/Actions.model';
import { FilesService } from '../services/files.service';
import { SafeformService } from '../services/safeform.service';
import { NavigationService } from '../services/navigation.service';
import { QrService } from '../services/qr.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.sass'],
})
export class ActionsComponent implements OnInit {
  @Input()
  actions: Actions = {};
  @Input() type: string = '';
  @Input() callback: any = () => {};
  @Input() data: any;
  @Input() disable: boolean = false;
  @Output() modifyFileEvent = new EventEmitter();

  faDownload = faDownload;
  faUsers = faUsers;
  faUser = faUser;
  faLock = faLock;
  faCalendarCheck = faCalendarCheck;

  constructor(
    public dialog: MatDialog,
    private _fileService: FilesService,
    private _safeformService: SafeformService,
    private _navigation: NavigationService,
    private _qr: QrService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.dialog = dialog;
  }

  ngOnInit(): void {}

  showModal(action: string, size: string, data?: any) {
    console.log(this.type);
    action = this.type + '-' + action;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: size,
      data: { action, data },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }

  fileActionModal(action: string, size: string, data?: any) {
    action = action;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: size,
      data: { action, data },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }

  modifyFile(data: any) {
    this._fileService.title = this.data.name;
    this._fileService.imgSrc = this.data.fileType;
    this.modifyFileEvent.emit(data);
  }

  /**
   * TODO ::: Need to request for duplicate directory endpoint
   */
  createDuplicateDirectory() {
    const newDirectory = { ...this.data };
    newDirectory.name = newDirectory.title + ' - Copy';
    newDirectory.type = newDirectory.file_type;
    newDirectory.file = {};
    delete newDirectory.actions;
    delete newDirectory.file_type;
    this._fileService.duplicateDirectory(newDirectory);
  }

  async download(data: any, path: string = 'path', name: string = 'file') {
    await lastValueFrom(this._fileService.downloadDirectory(data?.id));

    if (data?.type === 'folder') {
      console.log('folder');
      this._fileService.downloadFolder(data.id).subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/zip' });
        const blobUrl = URL.createObjectURL(blob);
        const link = this._document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${data.name}.zip`);
        this._document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } else if (!!data?.workflow) {
      console.log('workflow');
      this._fileService.downloadFile(
        this._fileService.changeExtension(data.name, 'pdf'),
        `../../../assets/${data[path]}`
      );
    } else if (data?.name?.split('.').length > 1) {
      console.log('name');
      this._fileService.downloadFile(
        data.name,
        `../../../assets/${data[path]}`
      );
    } else {
      console.log('else');
      const extension = data[path].split('\\').pop().split('.')[1];
      console.log({ extension });
      this._fileService.downloadFile(
        `${data?.name ?? name}.${extension}`,
        `../../../assets/${data[path]}`
      );
    }
    console.warn('logsss');
  }

  shareFile(data: any) {
    const file = Array.isArray(data) ? data[0] : data;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: file.name,
        fileId: file.id,
        fileType: file.type,
        data: [...file.userGroups, ...file.users],
      },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }

  moveFile(data: any) {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'move-file',
        title: data?.name || data?.title,
        fileId: data.id,
        data: [data],
      },
    });
  }

  editCertificate(certificate: any) {
    if (!!certificate.filepath.length) {
      console.log('this._safeformServddddice', this._safeformService);
      console.log('this._safeformServddddice', certificate);
      this._safeformService.certificateId = certificate._id;
      this._safeformService.certificateQrID = certificate.qrID;
      this._navigation.updateLocation('safe-form');
    } else {
      const fileData: any = {};
      fileData['certificateName'] = certificate.fullName;
      fileData['rollNumber'] = certificate.rollNumber;
      fileData['dateAdmitted'] = certificate.dateAdmitted
        ? moment(certificate.dateAdmitted)
            .format('Do [day of] MMMM, YYYY')
            .toString()
        : certificate.dateAdmitted;
      fileData['dateAttestation'] = certificate.dateAttestation;
      fileData['clerkName'] = certificate.clerkName;
      fileData['chiefName'] = certificate.chiefName;

      if (environment.local) {
      } else {
        fileData['clerkSigUpload'] = `./assets${certificate.clerkSignature}`;
        fileData['chiefSigUpload'] = `./assets${certificate.chiefSignature}`;
      }

      this._safeformService.certificateId = certificate._id;
      this._safeformService.generateMissingPDF(fileData);
    }
  }

  editSCId(scId: any) {
    this._safeformService.ScId = scId._id;
    this._navigation.updateLocation('safe-form');
  }

  downloadSafeForm(data: any) {
    console.log({ data });

    let filepath = data.filepath;

    if (!!filepath.length) {
      if (typeof data.filepath !== 'string') {
        const filePaths = data.filepath?.filter((file: any) => !!file.isActive);
        filepath = filePaths[filePaths.length - 1]?.pdfPath;
      }

      this._fileService.downloadFile(
        this._fileService.changeExtension(data.fullName, 'pdf'),
        `../../../assets/${filepath}`
      );
    } else {
      let certValue = data;

      console.log({ certValue });
      const fileData: any = {};
      fileData['certificateName'] = certValue.fullName;
      fileData['rollNumber'] = certValue.rollNumber;
      fileData['dateAdmitted'] = certValue.dateAdmitted
        ? moment(certValue.dateAdmitted)
            .format('Do [day of] MMMM, YYYY')
            .toString()
        : certValue.dateAdmitted;
      fileData['dateAttestation'] = moment(certValue.dateOfAttestation)
        .format('Do [day of] MMMM, YYYY')
        .toString();
      fileData['clerkName'] = certValue.clerkName;
      fileData['chiefName'] = certValue.chiefName;

      if (environment.local) {
      } else {
        fileData['clerkSigUpload'] = `./assets/${certValue.clerkSignature}`;
        fileData['chiefSigUpload'] = `./assets/${certValue.chiefSignature}`;
      }
      fileData['action'] = 'download';

      this._safeformService.certificateId = certValue._id;
      this._safeformService.generateMissingPDF(fileData);
    }
  }

  async downloadQR(text: string, ext: string, name: string = 'qr_code') {
    const link = `${window.location.href}?fm=${text}`;
    const blob = await this._qr.getQRCode(link);
    this._fileService.downloadFile(`${name}.${ext}`, blob);
  }

  async download_ScIDQR(data: any) {
    let text: string = data.id || data?._id,
      ext: string = 'tif',
      name: string = data.employeeNumber;

    let filepath = data.filepath;

    if (!filepath) {
      const content = {
        PositionSupremeCourtId: data?.position ?? '',
        allergiesSupremeCourt: data?.allergies ?? '',
        branchStationSCid: data?.branchStation ?? '',
        comorbiditySupremeCourt: data?.comorbidity ?? '',
        companyLocation: data?.branchStation ?? '',
        companyName: data?.personToContact ?? '',
        contactNumberEmergency: data?.emergencyContactNumber ?? '',
        contractDate: data?.dateOfBirth ?? '',
        courtDropDown: data?.Court ?? '',
        dateGeneric: data?.dateOfBirth ?? '',
        emailAddressSupremeCourt: data?.emailAddress ?? '',
        employeeNumberSCid: data?.employeeNumber ?? '',
        gsisSupremeCourtId: data?.gsis ?? '',
        heightSupremeCourtId: data?.height ?? '',
        nameSupremeCourtId: data?.fullName ?? '',
        pagIbigSupremeCourtId: data?.pagibig ?? '',
        parties: data?.allergies ?? '',
        personToContactInEmergency: data?.personToContact ?? '',
        philhealthSupremeCourtId: data?.philHealth ?? '',
        selectBloodType: data?.bloodType ?? '',
        tinNoSupremeCourtId: data?.tinNumber ?? '',
        weightSupremeCourtId: data?.weight ?? '',
        logoSrc: '../../assets/images/sc_logo.png',
        noRedirection: true,
      };
      this._safeformService.ScId = data.id || data?._id;
      this._safeformService.generateMissingPDF(content);
      console.log('download_ScIDQR', data);
      const link = `${window.location.href}?fm=${text}`;
      const blob = await this._qr.getQRCode(link);
      this._fileService.downloadFile(`${name}.${ext}`, blob);
    } else {
      console.log('download_ScIDQR', data);
      const link = `${window.location.href}?fm=${text}`;
      const blob = await this._qr.getQRCode(link);
      this._fileService.downloadFile(`${name}.${ext}`, blob);
    }
  }

  showForm(path: any, name: string) {
    this._safeformService.viewerData = {
      ...path?.data,
      pdfSrc: path,
      prevLocation: 'Certificate',
      prev: 'certificates-table',
      name: `${name} - Certificate`,
    };
    this._navigation.updateLocation('formViewer');
  }

  async downloadScId(data: any, path: string = 'path', name: string = 'file') {
    let filepath = data.filepath;
    if (!filepath) {
      const content = {
        PositionSupremeCourtId: data?.position ?? '',
        allergiesSupremeCourt: data?.allergies ?? '',
        branchStationSCid: data?.branchStation ?? '',
        comorbiditySupremeCourt: data?.comorbidity ?? '',
        companyLocation: data?.branchStation ?? '',
        companyName: data?.personToContact ?? '',
        contactNumberEmergency: data?.emergencyContactNumber ?? '',
        contractDate: data?.dateOfBirth ?? '',
        courtDropDown: data?.Court ?? '',
        dateGeneric: data?.dateOfBirth ?? '',
        emailAddressSupremeCourt: data?.emailAddress ?? '',
        employeeNumberSCid: data?.employeeNumber ?? '',
        gsisSupremeCourtId: data?.gsis ?? '',
        heightSupremeCourtId: data?.height ?? '',
        nameSupremeCourtId: data?.fullName ?? '',
        pagIbigSupremeCourtId: data?.pagibig ?? '',
        parties: data?.allergies ?? '',
        personToContactInEmergency: data?.personToContact ?? '',
        philhealthSupremeCourtId: data?.philHealth ?? '',
        selectBloodType: data?.bloodType ?? '',
        tinNoSupremeCourtId: data?.tinNumber ?? '',
        weightSupremeCourtId: data?.weight ?? '',
        logoSrc: '../../assets/images/sc_logo.png',
        noRedirection: true,
        action: 'download',
      };
      this._safeformService.ScId = data.id || data?._id;
      this._safeformService.generateMissingPDF(content);
    } else {
      let pdfSrc: any;
      await lastValueFrom(
        this._safeformService.downloadFile(data.id || data?._id)
      ).then((pdfSrcData: any) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
        };
        fileReader.onloadend = () => {
          this._fileService.downloadFileFromBytes(data?.fullName, pdfSrc);
        };
        fileReader.readAsArrayBuffer(pdfSrcData);
      });
    }
  }
}
