import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, lastValueFrom } from 'rxjs';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.sass'],
})
export class TemplateDetailsComponent implements OnInit, OnDestroy {

  name: string = ''
  description: string = ''


  sub: Subscription = new Subscription()
  constructor(private _signSecureService: SignSecureService, private _snack: MatSnackBar) {
    this.sub.add(this._signSecureService.worflowData$.subscribe(data => {
      console.log({ data })
      this.name = data.name ?? ''
      this.description = data.description ?? ''
    }))
  }

  async ngOnInit() {
    const data = await this._signSecureService.getSavedWorkflowData()
    if (!!data) {

      try {
        if (typeof data.fileData === 'object') {
          data.fileData = Uint8Array.from(Object.values(data.fileData)).buffer
        } else {
          if (data.fileData) {
            data.fileData = await fetch(data.fileData).then(res => res.arrayBuffer());
          }
        }
      } catch {

      }


      if (data.travelAuth) {
        data.fileData = data.travelAuth
      }

      data.file = this._signSecureService.bytesToFile(data.fileData, data.name)
      this._signSecureService.setWorkFlowData(data)
      this._signSecureService.clearSavedData()
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  back() {
    this._signSecureService.nextProgress(1);
  }

  next() {

    if (!!!this.name) {
      this._snack.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: { type: 'error', text: `Please enter a document title` },
      });
      return
    }

    this._signSecureService.setFileInfo(this.name, this.description)
    this._signSecureService.nextProgress(3);
  }
}
