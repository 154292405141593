<div class="container" [style.overflow]="(isLoading | async) ? 'hidden' : 'auto' ">
  <app-admin-sidebar
    [sideNavToggle]="toggle"
    *ngIf="this.menuType !== 'safe-form'"></app-admin-sidebar>
  <div class="content-wrapper" [style.overflow]="(isLoading | async) ? 'hidden' : 'auto' ">
    <app-admin-navbar
      (viewAccountEvent)="viewAccount($event)"
      (toggleSideNavEvent)="toggleSideNav($event)"
      *ngIf="this.menuType !== 'safe-form'"></app-admin-navbar>
    <app-tablet-navbar></app-tablet-navbar>
    <div class="content-separate">
      <app-admin-sidebar [sideNavToggle]="toggle"></app-admin-sidebar>
      <app-admin-dashboard-content
        *ngIf="!isViewAccount"
        [viewPdf]="viewPdf" 
        [style.overflow]="(isLoading | async) ? 'hidden' : 'auto' "
        ></app-admin-dashboard-content>
    </div>
    <app-account *ngIf="isViewAccount"></app-account>
  </div>
</div>
