import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileDataModel } from '../models/file-data.model';
import { FileTableModel } from '../models/file-table.model';
import { UserTableModel } from '../models/user-table.model';
import FileDataJson from '../../../dummy-data/files.json';
import { AuthenticationService } from './authentication.service';
import { SearchResults } from '../models/search-results.model';
import { NavigationService } from './navigation.service';
import { FolderModel } from '../models/folder.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { Actions } from '../models/Actions.model';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly folder = environment.apiConfig.folder.url;
  private readonly file = environment.apiConfig.file.url;
  private readonly directory = environment.apiConfig.directory.url;
  private readonly _fileUrl = `${this.baseUrl}${this.file}`;
  private readonly _folderUrl = `${this.baseUrl}${this.folder}`;
  private readonly _directoryUrl = `${this.baseUrl}${this.directory}`;
  private readonly _publishUrl = `${this.baseUrl}${this.directory}${environment.apiConfig.publish.url}`;
  imgSrc: string = '';
  title: string = '';

  addButton: string = 'add sample';

  filesCols: string[] = [
    ' ',
    'title',
    'tags',
    'Date Modified',
    'Date Created',
    'actions',
  ];

  filesData: Object[] = FileDataJson;
  files1Data: FileTableModel[] = [];
  fileData: FileDataModel[] = [];
  private searchResults = new BehaviorSubject<SearchResults>({
    searchQuery: '',
    data: [],
  });
  searchResults$ = this.searchResults.asObservable();
  searchQuery: string = '';

  file1Data: FileDataModel[] = [];
  filesAysnc = new Subject<any>();
  private files = new BehaviorSubject<FileDataModel[]>([]);
  files$ = this.files.asObservable();
  resultsLength: number = 0;

  private recentfiles = new BehaviorSubject<any[]>([]);
  recentfiles$ = this.recentfiles.asObservable();
  prevRecentFiles: any[] = [];

  // Used for Files and Folders
  private filesAndFolderAsync = new BehaviorSubject<any[]>([]);
  filesAndFolderAsync$ = this.filesAndFolderAsync.asObservable();
  filesAndFolder: any[] = this.filesAndFolderAsync.value;
  // Used for Folders
  folders: any[] = [];
  private foldersAsync = new BehaviorSubject<any[]>([]);
  folderAsync$ = this.foldersAsync.asObservable();
  node: any;

  private doneUploadAsync = new BehaviorSubject<boolean>(false);
  doneUpload$ = this.doneUploadAsync.asObservable();

  private closeModalAsync = new BehaviorSubject<boolean>(false);
  closeModal$ = this.closeModalAsync.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService,
    private _breadcrumbs: BreadcrumbsService,
    private _snackBar: MatSnackBar
  ) {}

  async uploadFiles(files: FileList) {
    this.formatFiles(files);
    this.pushData();
    this.doneUploadAsync.next(true);
    const length = this.fileData.length;
    const parent =
      this._breadcrumbs.breadcrumbsData.length > 1
        ? this._breadcrumbs.breadcrumbsData[
            this._breadcrumbs.breadcrumbsData.length - 1
          ].id
        : '';

    const file = this._breadcrumbs.breadcrumbsData[
      this._breadcrumbs.breadcrumbsData.length - 1
    ] ?? null

    if(!!file?.file && !this.checkIfUserViewer(file.file)) {
      this.closeModalAsync.next(true)
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: {
          type: 'error',
          text: "Doesn't have an access to this folder",
        },
      });
      return; 
    }
    

    for (let i = 0; i < length; i++) {
      try {
        if (this.fileData[i].type == 'folder') {
          const children = this.fileData[i].child ?? [];
          const percentile = 100 / children.length ?? 1;
          this.fileData[i].parent = parent;
          await this.uploadFolderData(this.fileData[i], i, percentile);
        } else {
          this.fileData[i].level = 1;
          await this.uploadDirectory(this.fileData[i], 'file', i);
        }
      } catch (e) {}
    }

    this.doneUploadAsync.next(false);
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formatFiles(files: any) {
    let fileDatum: any = {};

    if (files[0]?.webkitRelativePath !== '') {
      fileDatum.child = [];
      let childDatum: any = {};
      const folders: any = {};
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const paths = file?.webkitRelativePath.split('/') || [];

        childDatum = {};
        childDatum.name = file?.name;
        childDatum.type = 'file';
        childDatum.size = `${file.size || 0}`;
        childDatum.progress = 0;
        childDatum.file = file;
        childDatum.parent = paths.length >= 2 ? paths[paths.length - 2] : null;

        let folder: any = {};
        if (folders[paths[paths.length - 2]]) {
          folders[paths[paths.length - 2]].size += file?.size || 0;
          folders[paths[paths.length - 2]].child.push(childDatum);
        } else {
          folder.name = paths[paths.length - 2];
          folder.type = 'folder';
          folder.numErrors = 0;
          folder.progress = 0;
          folder.child = [];
          folder.parent = paths.length >= 3 ? paths[paths.length - 3] : null;
          folder.size = file?.size || 0;
          folder.child.push(childDatum);
          folders[folder.name] = { ...folder };
        }
      }

      const formattedData: any = {};
      const folderKeys = Object.keys(folders);
      folderKeys.forEach(key => {
        const folder = folders[key];
        if (folder.parent) {
          if (!formattedData[folder.parent]?.name) {
            formattedData[folder.parent] = { ...folder };
            formattedData[folder.parent].child = [];
            formattedData[folder.parent].name = folder.parent;
            formattedData[folder.parent].size = 0;
            formattedData[folder.parent].progress = 0;
            delete formattedData[folder.parent].parent;
          }

          formattedData[folder.parent].child.push({ ...folder });
          formattedData[folder.parent].size += folder.size;

          if (formattedData[key]) {
            delete formattedData[key];
          }
        } else if (!formattedData[key]) {
          formattedData[key] = { ...folder };
        }
      });
      Object?.keys(formattedData).forEach(key => {
        formattedData[key].size = `${formattedData[key].size}`;
        this.fileData.push(formattedData[key]);
      });
    } else {
      for (let i = 0; i < files.length; i++) {
        fileDatum = {};
        if (this._breadcrumbs.breadcrumbsData.length > 1) {
          fileDatum.parent =
            this._breadcrumbs.breadcrumbsData[
              this._breadcrumbs.breadcrumbsData.length - 1
            ].id;
        }
        fileDatum.name = files[i]?.name;
        fileDatum.type = 'file';
        fileDatum.size = `${files[i]?.size || 0}`;
        fileDatum.progress = 0;
        fileDatum.file = files[i];

        this.fileData.push(fileDatum);
      }
    }
  }

  setFiles(data: FileDataModel[]) {
    this.files.next(data);
  }

  setSearchResults(data: SearchResults) {
    this.searchQuery = data.searchQuery;
    this.searchResults.next(data);
  }

  pushData() {
    this.setFiles(this.fileData);
    this.filesAysnc.next(this.fileData);
  }

  async uploadFile(file: any, index: number) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._fileUrl}/create`,
        this.formatFileDTO(file, index, 'file'),
        {
          reportProgress: true,
          observe: 'events',
        }
      )
    );
  }

  async uploadFolder(file: any, index: number) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._folderUrl}/create`,
        this.formatFolderDTO(file, index, 'folder'),
        {
          reportProgress: true,
          observe: 'events',
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }
      )
    );
  }

  async uploadDirectory(data: any, type: string, index: number) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._directoryUrl}/user/create/${this._authService.userId}`,
        this.formatDirectoryDTO(data, index, type),
        {
          reportProgress: true,
          observe: 'events',
        }
      )
    );
  }

  formatDirectoryDTO(data: any, index: number, type: string) {
    const formData = new FormData();
    if (data.file) {
      formData.append('file', data.file);
    }
    formData.append('name', data.name);
    formData.append('description', data.name);
    if (data.parent) {
      formData.append('parent', data.parent);
    }
    if (typeof data.size === 'number') {
      formData.append('size', `${data.size}`);
    } else {
      formData.append('size', data.size);
    }
    formData.append('index', index + '');
    formData.append('type', type);
    formData.append('progress', '0');
    return formData;
  }

  formatFolderDTO(file: any, index: number, type: string = 'folder') {
    return {
      index: index,
      type: type,
      folderName: file.name,
      createdBy: 'string',
      updatedBy: 'string',
      description: file.name,
      parent: file.type === 'file' ? '' : file.parent || '',
      level: file.type === 'file' ? 1 : file.level || 1,
      tags: [],
      userGroup: '',
      createdAt: 0,
      updatedAt: 0,
    };
  }

  // For future use....
  formatFileDTOFormData(file: any, index: number, type: string = 'file') {
    const formData = new FormData();
    formData.append('title', file.name);
    formData.append('description', file.name);
    formData.append(
      'parent',
      file.type === 'file' ? undefined : file.parent || undefined
    );
    formData.append('level', file.type === 'file' ? 1 : file.level || 1);
    formData.append('index', index + '');
    formData.append('size', file.size);
    formData.append('type', type);
    formData.append('file', file.file);
    return formData;
  }

  // For current version only
  formatFileDTO(file: any, index: number, type: string = 'file') {
    return {
      index: index,
      type: type,
      name: file.name,
      createdBy: 'string',
      updatedBy: 'string',
      description: '',
      parent: file.type === 'file' ? '' : file.parent || '',
      level: file.type === 'file' ? 1 : file.level || 1,
      tags: [],
      userGroup: '',
      createdAt: 0,
      updatedAt: 0,
    };
  }

  reset() {
    this.fileData = [];
  }

  updateProgress(index: number, progress: number, errorMessage: string = '') {
    if (index === -1) {
      const loc = this.fileData.findIndex(data => {
        let progress = data.progress ?? 10;
        return progress !== 100 && progress >= 0;
      });
      let location = loc === -1 ? this.fileData.length - 1 : loc;
      if (this.fileData[location]?.type === 'file') {
        this.fileData[location].progress = progress;
        this.fileData[location].error = errorMessage;
      } else {
        this.fileData[location].numErrors =
          (this.fileData[location].numErrors ?? 0) + 1;
        this.fileData[location].progress = progress;
        this.fileData[
          location
        ].error = `${errorMessage} (${this.fileData[location].numErrors})`;
      }
    } else {
      this.fileData[index].progress = progress;
    }

    this.pushData();
  }

  async uploadFolderData(file: any, index: number, percentile: number) {
    const { child } = file;
    const folderData: any = await this.uploadDirectory(file, 'folder', index);
    for (let i = 0; i < child.length ?? [].length; i++) {
      child[i].parent = folderData.body.data.id;
      try {
        if (child[i].type == 'folder') {
          await this.uploadFolderData(
            child[i],
            index,
            percentile / child.length
          );
        } else {
          await this.uploadDirectory(child[i], 'file', index);
        }
      } catch {}

      this.updateProgress(index, percentile * (i + 1));
    }
    return '';
  }

  formatFile(
    files: FileDataModel[],
    filter: boolean = true,
    isShared?: boolean
  ) {
    //TODO: add filter for indexed files
    return files
      .filter((file: FileDataModel) => {
        if (!filter) {
          return true;
        }
        return file.createdBy == this._authService.userId;
      })
      .map((file: FileDataModel) => {
        const choicesPublished: Actions = {
          modify: file,
          duplicate: file,
          download: file,
          view_members: file,
          img_view: file.properties,
        };
        const choicesUnpublished: Actions = {
          modify: file,
          share: file,
          move: file,
          duplicate: file,
          download: file,
          view_members: file,
          img_view: file.properties,
        };
        const viewerOnly: Actions = {
          share: file,
          download: file,
          view_members: file,
        };
        const sharedPublished: Actions = {
          duplicate: file,
          download: file,
          view_members: file,
          img_view: file.properties,
        };
        const formattedFile: FileTableModel = {
          id: file.id || '',
          title: file.name || '',
          date_created: file.createdAtFormatted?.split(',')[0],
          date_modified: file.updatedAtFormatted?.split(',')[0],
          file_type: 'file',
          tags: file.tags,
          fileExtension: file.fileExtension,
          createdBy: file.createdBy,
          actions: {
            choices: choicesUnpublished,
          },
        };

        if (file.tags) {
          file.tags.some(tag => {
            if (tag.name?.toUpperCase() == 'PUBLISHED') {
              formattedFile['actions'] = { choices: choicesPublished };
            }
          });
        }
        if (isShared) {
          if (this.checkIfUserViewer(file)) {
            formattedFile['actions'] = {
              choices: { ...viewerOnly, modify: file },
            };
          } else {
            formattedFile['actions'] = {
              choices: viewerOnly,
            };
          }
            
          if (file.tags) {
            file.tags.some(tag => {
              if (tag.name?.toUpperCase() == 'PUBLISHED') {
                formattedFile['actions'] = { choices: sharedPublished };
              }
            });
          }
        }

        if (file?.workflow?.status === 'COMPLETED') {
          const choices = formattedFile.actions?.choices;
          delete choices['modify'];
          choices['view_file'] = file;
        }

        return formattedFile;
      });
  }

  formatSearchResults(files: FileDataModel[]) {
    console.log({ searchFiles: files });
    return files.map((file: FileDataModel) => {
      const createdDate = new Date(file.createdAt as string);
      const updatedAt = new Date(file.updatedAt as string);
      const formattedFile: FileTableModel = {
        id: file.id || '',
        title: file.name || '',
        date_created: createdDate.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
        date_modified: updatedAt.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }),
        file_type: 'file',
        fileExtension: file.fileExtension,
        createdBy: file.createdBy,
        tags: file.tags,
        actions: {
          choices: {
            modify: file,
            share: file,
            move: file,
            duplicate: file,
            download: file,
            view_members: file,
            img_view: file.properties,
          },
        },
      };

      if (!!file.workflow || file.isPublished) {
        delete formattedFile.actions?.choices?.share;
        delete formattedFile.actions?.choices?.move;
      }
      return formattedFile;
    });
  }

  formatRecentFile(files: FileDataModel[]) {
    return files.map((file: any) => {
      const formattedFile: any = {
        id: file.id || '',
        name: file.name || 'Testing',
        date: file.createdAtFormatted?.split(',')[0],
        tags: file.tags || [],
        fileExtension: file.fileExtension,
        img_properties: file.properties,
        createdBy: file.createdBy,
        isViewerOnly: this.checkIfUserViewer(file),
        isQRAdded: file.isQRAdded,
        isPublished: file.isPublished,
        isWatermarkAdded: file.isWatermarkAdded,
        users: file.users,
        groups: file.userGroups,
        actions: {
          download_normal: file,
          info: {
            data: { ...file, isViewerOnly: this.checkIfUserViewer(file) },
            action: 'recent-doc-info',
          },
        },
      };

      return formattedFile;
    });
  }

  publishFile(fileId: string, isPublished: boolean) {
    return this._httpClient.post<any>(this._publishUrl + '/' + fileId, {
      isPublished: isPublished,
    });
  }

  getAllFiles() {
    let params = new HttpParams();
    params = params.append('skip', 0);
    return this._httpClient.get<any>(this._fileUrl + '/all', { params });
  }

  getFile(fileId: string) {
    return this._httpClient.get<any>(`${this._directoryUrl}/${fileId}`);
  }

  getWorkflowFile(fileId: string) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/workflow/${fileId}`
    );
  }

  formatFilesAndFolderData(data: any[], workflow: boolean = false) {
    return data
      .filter(file => (workflow ? !!file.workflow : !!!file.workflow))
      .map(datum => {
        const choices: Actions = {
          duplicate: datum,
          download: datum,
          view_members: datum,
        };

        if (!datum.isPublished) {
          choices.delete = [datum];
        }

        if (this.checkIfUserViewer(datum)) {
          choices['modify'] = datum;
          choices['move'] = datum;
          choices['share'] = [datum];
        }

        if (datum.isPublished) {
          delete choices['share'];
          delete choices['move'];
        }

        return {
          id: datum.id,
          title: datum.name,
          date_modified: datum.updatedAtFormatted?.split(',')[0],
          file_type: datum.type,
          size: datum.size || '0 KB',
          tag: datum.tags,
          groups: datum.userGroups,
          indexed: workflow ? (datum.ocr ? true : false) : datum.indexed,
          ocr: datum.ocr ? true : false,
          fileExtension: datum.fileExtension,
          img_properties: datum.properties,
          isPublished: datum.isPublished,
          createdBy: datum.createdBy,
          actions: {
            choices: choices,
          },
          users: datum.users,
          sort: datum.updatedAt,
          workflow: !!datum.workflow,
          isQRAdded: datum.isQRAdded,
          isWatermarkAdded: datum.isWatermarkAdded,
        };
      });
  }

  setFilesAndFolderAsync(data: any[]) {
    this.filesAndFolderAsync.next(data);
  }

  getAllFilesAndFolder(includeParent: boolean = true) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all?sort=0&limit=0&includeParent=${includeParent}`
    );
  }

  formatFolder(folders: any[], child = false) {
    const format = folders.reduce((array, folder) => {
      if (folder.type === 'file') return array;
      const data: any = {
        id: folder.id,
        name: folder.name,
      };
      if (!folder.parent || child) {
        array.push(data);
      }
      return array;
    }, []);

    return format;
  }

  setFolderAsync(data: any[]) {
    this.foldersAsync.next(data);
  }

  getAllFolder(includeParent: boolean = true) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all?type=folder&limit=0&includeParent=${includeParent}`
    );
  }
  getRecentFiles() {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all?type=file&includeParent=false&limit=7&sort=0`
    );
  }

  searchFiles(query: string = this.searchQuery) {
    return this._httpClient.get<any>(
      `${
        this._directoryUrl
      }/all?type=file&includeParent=false&query=${encodeURI(query)}`
    );
  }

  getUserFiles() {
    return this._httpClient.get<any>(
      this._directoryUrl +
        '/all/user?type=file&hasTags=true&fileExtension=pdf&includeParent=false&userId=' +
        this._authService.userId
    );
  }

  getGroupFiles() {
    let userGroupIds: string[] = [];
    this._authService.userGroups.forEach(i => {
      userGroupIds.push(i.userGroupId);
    });

    let params = new HttpParams();
    params = params.append('userGroupIds', userGroupIds.join(','));

    return this._httpClient.get<any>(
      this._directoryUrl + '/all/user-group?includeParent=false&type=file',
      {
        params: params,
      }
    );
  }

  getGroupData() {
    let userGroupIds: string[] = [];
    this._authService.userGroups.forEach(i => {
      userGroupIds.push(i.userGroupId);
    });

    let params = new HttpParams();
    params = params.append('userGroupIds', userGroupIds.join(','));

    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user-group?includeParent=false`,
      {
        params: params,
      }
    );
  }

  getAllFolderFiles(id: string) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user?parent=${id}&userId=${this._authService.userId}&&limit=0`
    );
  }

  openFolderOnTree(folder: any) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/user?type=folder&limit=0&parent=${folder.id}&userId=${this._authService.userId}`
    );
  }

  formatNode(folders: any[], node: any, subchild: any[], level = 0) {
    const length = folders.length;

    for (let i = 0; i < length; i++) {
      if (level === 0) {
        this._breadcrumbs.emptyBreadcrumb();
      } else if (i !== 0) {
        this._breadcrumbs.removeBreadcrumbData();
      }

      this._breadcrumbs.setBreadcrumbsData({
        title: folders[i].name,
        id: folders[i].id,
        file: folders[i]
      });

      if (folders[i].id === node.id) {
        folders[i] = { ...folders[i], children: subchild };
        break;
      }

      if (folders[i].children && folders[i].children.length !== 0) {
        folders[i].children = this.formatNode(
          folders[i].children,
          node,
          subchild,
          level + 1
        );
        break;
      }
    }

    return folders;
  }

  async addUserGroupToDirectory(id: string, userGroupId: string) {
    return await lastValueFrom(
      this._httpClient.post<any>(
        `${this._directoryUrl}/add/user-group/${id}/${userGroupId}`,
        {}
      )
    );
  }

  async updateDirectory(directory: any) {
    return await lastValueFrom(
      this._httpClient.put<any>(`${this._directoryUrl}/update`, directory)
    );
  }

  async duplicateDirectory(directory: any) {
    await lastValueFrom(
      this._httpClient.post<any>(`${this._directoryUrl}/create`, directory)
    );
  }

  downloadFile(name: string, url: string) {
    console.log({ name, url });
    var link = document.createElement('a');
    link.setAttribute('download', name);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  fileExists(url: string): Observable<boolean> {
    return this._httpClient.get(url).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  async deleteFile(id: string) {
    await lastValueFrom(
      this._httpClient.delete<any>(`${this._directoryUrl}/${id}`).pipe(
        catchError(() => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: {
              type: 'error',
              text: 'This folder contains published document that can’t be deleted',
            },
          });

          return of();
        })
      )
    );
    this.getRecentFiles();
  }

  createFolder(folder: FolderModel) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/user/create/${this._authService.userId}`,
      folder
    );
  }

  updateSharingPermission(id: string, data: any) {
    return this._httpClient.put<any>(
      `${this._directoryUrl}/update/share-permission/${id}`,
      data
    );
  }

  moveDirectory(id: string, parent: string) {
    return this._httpClient.post<any>(`${this._directoryUrl}/move/${id}`, {
      parent,
    });
  }

  checkIfUserViewer(data: any, condition: Function =  ((data: any) =>{return  data.role === 'EDITOR'})): boolean {
    const groups = this._authService.userGroups;
    const userId = this._authService.userId;



    const { userGroups, users, createdBy } = data;

    if (userId === createdBy) {
      return true;
    }

    const byGroup =
      userGroups?.filter(function (e: any) {
        return groups.some((group: any) => group.userGroupId === e.id);
      }) || [];

    const byEmail = users?.filter((i: any) => i.id === userId) || [];

    return [...byGroup, ...byEmail].some(data => condition(data));
  }

  viewDirectory(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/view/${fileId}`,
      {}
    );
  }

  downloadFolder(id: string) {
    const headers = new HttpHeaders({
      Accept: 'application/zip',
    });
    return this._httpClient.get<any>(
      `${this._directoryUrl}/download/folder/${id}`,
      {
        responseType: 'arraybuffer' as 'json',
      }
    );
  }

  saveImageState(id: string, rotate: string, hFlip: boolean, vFlip: boolean) {
    const body = { rotate: rotate, horizontalFlip: hFlip, verticalFlip: vFlip };
    return this._httpClient.put<any>(
      `${this._directoryUrl}/update/properties/${id}`,
      body
    );
  }

  getSharedDocuments(fileExtension: string = 'pdf') {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/all/shared-documents?limit=0&fileExtension=${fileExtension}&sort=0&includeParent=false`
    );
  }

  getWorkflowData(limit: number = 10) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/recent-workflow?${
        limit !== 0 ? `limit=${limit}` : ''
      }`
    );
  }

  setRecentFiles(recentFiles: any[]) {
    this.prevRecentFiles = recentFiles;
    this.recentfiles.next(recentFiles);
  }

  deleteDocument(data: any) {
    return this._httpClient.delete(environment.integration.deleteDocument, {
      body: data,
    });
  }

  formatWorkflowData(data: any) {
    const datePipe = new DatePipe('en-US');
    return data
      .filter((datum: any) => datum.workflow)
      .map((datum: any) => {
        const actions = { delete_doc: undefined, download: undefined };
        let dateCreated, dateFormatted;
        if (
          (datum?.workflow?.status === 'CANCELLED' ||
            datum?.workflow?.status === 'DECLINED' ||
            datum?.workflow?.status === 'REJECTED' ||
            datum?.workflow?.status === 'EXPIRED') &&
          datum.createdBy === this._authService.userId
        ) {
          actions['delete_doc'] = datum.id;
        } else {
          delete actions.delete_doc;
        }

        if (datum?.workflow?.status === 'COMPLETED') {
          actions['download'] = datum;
        } else {
          delete actions.download;
        }

        if (datum?.createdAtFormatted) {
          dateCreated = datum.createdAtFormatted.split(',')[0];
        } else {
          dateCreated = datePipe.transform(
            new Date(datum?.createdAt),
            'dd MMM YYYY'
          );
        }

        if (datum?.updatedAtFormatted) {
          dateFormatted = datum.updatedAtFormatted.split(',')[0];
        } else {
          dateFormatted = datePipe.transform(
            new Date(datum?.updatedAt),
            'dd MMM YYYY'
          );
        }

        let status = datum.workflow.status;
        const party = datum.workflow.parties.find(
          (party: any) => party.id === this._authService.userId
        );
        if (party?.viewedFormatted && status === 'PENDING') {
          status = 'VIEWED';
        }

        return {
          ...datum,
          title: datum.name,
          status: status,
          date_created: dateCreated,
          last_updated: dateFormatted,
          workflow: datum.workflow,
          actions,
        };
      });
  }

  verifyFile(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/signsecure/verify/${fileId}`,
      {}
    );
  }

  verifyUserFile(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/signsecure/verify-user/${fileId}`,
      {}
    );
  }

  verifySharedFile(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/verify-user/${fileId}`,
      {}
    );
  }

  changeExtension(name: string, extension: string) {
    const pos = name.lastIndexOf('.');
    const newName =
      name.substr(0, pos < 0 ? name.length : pos) + '.' + extension;
    return newName;
  } 

  checkIfShared(file: any) {
    console.log({ file });
    return file?.shared ?? false;
  }

  updateFile(file: File, id: string) {
    let formData = new FormData();
    formData.append('file', file);
    return this._httpClient.put<any>(
      `${this._directoryUrl}/update/file/${id}`,
      formData
    );
  }

  updateQRAdded(id: string, isQRAdded: boolean = true, privacyType = 'CONFIDENTIAL') {
    return this._httpClient.put<any>(`${this._directoryUrl}/update`, {
      id,
      isQRAdded,
      privacyType
    });
  }

  checkIfUserHaveAccess(file: any) {
    console.log({file})
  }

  printDirectory(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/print/${fileId}`,
      {}
    );
  }

  downloadDirectory(fileId: string) {
    return this._httpClient.get<any>(
      `${this._directoryUrl}/download/${fileId}`,
      {
        responseType: 'arraybuffer' as 'json',
      }
    );
  }

  watermarkDirectory(fileId: string) {
    return this._httpClient.post<any>(
      `${this._directoryUrl}/watermark/${fileId}`,
      {}
    );
  }

  downloadFileFromBytes(name: string, blob: any) {
      const newBlob = new Blob([blob], { type: 'application/pdf' });
      const downloadLink = document.createElement('a');
      downloadLink.target = '_self';
      downloadLink.href = window.URL.createObjectURL(newBlob);
      downloadLink.download = name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
}
}
