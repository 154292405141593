import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper-background',
  templateUrl: './wrapper-background.component.html',
  styleUrls: ['./wrapper-background.component.sass'],
})
export class WrapperBackgroundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
