import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserModel } from 'src/app/@shared/models/user.model';
import { UsersService } from 'src/app/@shared/services/users.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass'],
})
export class RegistrationComponent implements OnInit {
  @Output() verificationScreen = new EventEmitter<string>();
  @Output() navigatePage = new EventEmitter<string>();
  hide: boolean = true;
  hideConfirm: boolean = true;
  regFormData: UserModel = {
    lastName: '',
    givenName: '',
    email: '',
    role: 'user',
  };
  regConfirmation: string = '../../../assets/images/regLabel.png';
  signUpForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(5)]],
    email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+(@test.gov.ph)$'),
      ],
    ],
    phoneNumber: [
      '',
      [
        Validators.required,
        Validators.pattern('^[+63]*[0-9]{10,11}$'),
        Validators.minLength(11),
      ],
    ],
    password: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(private fb: FormBuilder, private _userService: UsersService) {}

  ngOnInit(): void {
    this.signUpForm.controls['email'].invalid == false;
  }
  verify(data: string): void {
    let formData = this.signUpForm.getRawValue();

    this.regFormData = {
      givenName: formData.name.split(' ')[0],
      lastName: formData.name.split(' ')[0],
      email: formData.email,
      role: 'user',
    };
    this._userService.createUser(this.regFormData).subscribe(() => {
      this.verificationScreen.emit(data);
    });
  }
  goTo(data: string) {
    this.navigatePage.emit(data);
  }
}
