import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import QRCode from 'qrcode'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrService {

  readonly key = environment.qrCodeKey ?? 'thisissorandom'
  constructor() { }

  encrypt(value: string): string {
    console.log({ something: this.key })
    return CryptoJS.AES.encrypt(value, this.key.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    console.log({ something: textToDecrypt })
    return CryptoJS.AES.decrypt(textToDecrypt.replaceAll(' ', ''), this.key.trim()).toString(CryptoJS.enc.Utf8);
  }

  async getQRCode(text: string) {
    return await QRCode.toDataURL(text)
  }


  getQRCodeConfig(position: string, height: number, width: number) {
    switch (position) {
      case 'top-right': return { x: width - 68 - 28, y: height - (35 + 55) }
      case 'bottom-right': return { x: width - 68 - 28, y: 35 }
      case 'bottom-left': return { x: 68, y: 35 }
      case 'top-left': return { x: 68, y: height - (35 + 55) }
      case 'top-center': return { x: (width / 2) - 28, y: height - (35 + 55) }
      case 'bottom-center': return { x: (width / 2) - 28, y: 35 }
      default: return { x: 68, y: 35 }
    }
  }
}
