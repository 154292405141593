import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { catchError, delay, lastValueFrom, of } from 'rxjs';
import { FilesService } from 'src/app/@shared/services/files.service';
import { QrService } from 'src/app/@shared/services/qr.service';
import QrScanner from 'qr-scanner';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { BulkFormsService } from 'src/app/@shared/services/bulk-forms.service';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.sass'],
})
export class QrScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  data: any;

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter();

  isRearCam: boolean = true;

  isMobile: boolean = false;

  @ViewChild('scanner')
  qrScanner!: ElementRef;

  scan!: QrScanner;
  verifData: any;

  status: string = '';
  constructor(
    private _qrService: QrService,
    private _files: FilesService,
    private detectRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalComponent>,
    private _forms: SafeformService,
    public bulkFormService: BulkFormsService,
  ) { }
  ngOnDestroy(): void {
    this.scan.destroy();
  }

  ngDoCheck(): void {
    if (this.verifData) {
      this.closeModalEvent.emit(this.verifData);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.reading.bind(this);
    const cameras = await QrScanner.listCameras(true);

    if (cameras.length === 1) {
      this.isRearCam = false;
    }

    this.scan = new QrScanner(
      this.qrScanner.nativeElement,
      async (result: any) => {
        if (!!!this.verifData) {
          await this.reading(result);
        } else {
          this.dialogRef.backdropClick();
        }
      },
      {
        returnDetailedScanResult: true,
        calculateScanRegion: (video: HTMLVideoElement) => ({
          x: 0,
          y: 0,
          width: video.width,
          height: video.height,
        }),
        preferredCamera: 'environment',
      }
    );


    let backCamera = cameras.findIndex(camera => camera.label.toLowerCase().includes('back'))

    if (backCamera === -1) {
      backCamera = 0;
    }

    await this.scan.setCamera(cameras[backCamera].id);
    await this.scan.start();
  }

  ngOnInit(): void {
    if (screen.width >= 393 && screen.width <= 430) {
      this.isMobile = true;
    }
  }

  reading(result: any) {
    if (result?.data === null) return;


    let data = result?.data
    try {
      data = new URL(data)

      const urlParams = new URLSearchParams(data);
      const paramNames = [];
      for (const paramName of data.searchParams.keys()) {
        paramNames.push(paramName);
      }

      const params = data.searchParams
      data = params.get(paramNames)

      if (!!!data) {
        this.closeModalEvent.emit({});
        this.dialogRef.close({});
        this.scan.$video.pause();
      }

      this.doAsyncTasks(data, paramNames);
    } catch {
      try {
        const fileId = this._qrService.decrypt(result?.data);
        this.doAsyncTasks(fileId, 'f');
      } catch (e: any) {
        this.closeModalEvent.emit({});
        this.dialogRef.close({});
        this.scan.$video.pause();
      }
    }
  }

  async doAsyncTasks(fileId: string, paramNames: any) {
    this.status = 'Verification in progress...';
    this.detectRef.detectChanges();

    if (paramNames == 'fm') {
      this._forms
        .getScIdById(fileId)
        .pipe(catchError((err: any) => {
          console.log({ err })
          this.closeModalEvent.emit({});
          this.dialogRef.close({});
          this.scan.$video.pause();
          return of({ data: {} })
        }))
        .subscribe(data => {
          this.scan.$video.pause();
          data.data.paramNames = paramNames
          this.verifData = data.data;
          this.closeModalEvent.emit(this.verifData);
          this.dialogRef.close(this.verifData);
          this.detectRef.detectChanges();
        })
    } else if (paramNames == 'ce') {
      this._forms.certificateId = fileId
      this._forms
        .getCertificateQr(fileId)
        .pipe(catchError((err: any) => {
          console.log({ err })
          this.closeModalEvent.emit({});
          this.dialogRef.close({});
          this.scan.$video.pause();
          return of({ data: {} })
        }))
        .subscribe(data => {
          this.scan.$video.pause();
          data.data.paramNames = paramNames
          this.verifData = data.data;
          this.closeModalEvent.emit(this.verifData);
          this.dialogRef.close(this.verifData);
          this.detectRef.detectChanges();
        })
    } else {
      this._files
        .verifyFile(fileId)
        .pipe(catchError((err: any) => {
          this.closeModalEvent.emit({});
          this.dialogRef.close({});
          this.scan.$video.pause();
          return of({ data: {} })
        }))
        .subscribe(data => {
          this.scan.$video.pause();
          data.data.paramNames = paramNames
          this.verifData = data.data;
          this.closeModalEvent.emit(this.verifData);
          this.dialogRef.close(this.verifData);
          this.detectRef.detectChanges();
        })
    }
  }

  async switchCam() {
    this.isRearCam = !this.isRearCam;
    const cameras = await QrScanner.listCameras(true);
    await this.scan.setCamera(cameras[+this.isRearCam].id);
  }

  close() {
    this.closeModalEvent.emit(null);
  }
}
