<div class="navbar-container">
  <img src="../../../assets/images/logo-small.png" alt="" class="small-logo" />

  <div class="search-container {{ isForm ? 'form-view' : '' }}">
    <img
      src="../../../assets/images/side-logo-black.svg"
      alt=""
      class="logo"
      (click)="updateLocation('report')"
      *ngIf="isForm" />
    <button class="upload" (click)="showUploadDragAndDropModal()">
      Upload Document <img src="../../../assets/images/icons/plus.png" alt="" />
    </button>
    <div class="search-form">
      <mat-form-field floatLabel="never" appearance="fill">
        <input
          matInput
          type="text"
          placeholder="Input Your Text in Here"
          [(ngModel)]="searchQuery"
          (keydown.enter)="search()" />
        <span matPrefix
          ><img
            class="search-image"
            src="../../../assets/images/icons/search.png"
            alt=""
        /></span>
      </mat-form-field>
      <button type="submit" class="search-button" (click)="search()">
        Search
      </button>
    </div>
  </div>
  <div class="right-side">
    <button class="upload" (click)="showUploadDragAndDropModal()">
      Upload Document <img src="../../../assets/images/icons/plus.png" alt="" />
    </button>
    <mat-menu #uploadMenu="matMenu" xPosition="before" class="upload-menu">
      <button mat-menu-item class="menu-label" (click)="upload('file')">
        <img src="../../../assets/images/icons/file-icon.png" alt="" />
        Files
        <input
          type="file"
          id="fileUpload"
          name="fileUploads"
          style="display: none"
          (change)="onFileSelected($event)"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
          multiple />
      </button>
      <button mat-menu-item class="menu-label" (click)="upload('folder')">
        <img src="../../../assets/images/icons/folder.png" alt="" />
        Folder
        <input
          type="file"
          id="folderUpload"
          name="folderUploads"
          style="display: none"
          (change)="onFileSelected($event)"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
          webkitdirectory
          directory />
      </button>
    </mat-menu>
    <mat-form-field
      floatLabel="never"
      appearance="fill"
      class="right-side-search">
      <input
        matInput
        type="text"
        placeholder="Input Your Text in Here"
        [(ngModel)]="searchQuery"
        (keydown.enter)="search()" />
      <span matPrefix
        ><img
          class="search-image"
          src="../../../assets/images/icons/search.png"
          alt=""
      /></span>
    </mat-form-field>
    <button class="avatar-container" [matMenuTriggerFor]="accountMenu">
      <img src="../../../assets/images/avatar.png" alt="Avatar" />
    </button>
    <mat-menu #accountMenu="matMenu" xPosition="before">
      <button mat-menu-item class="menu-label" (click)="viewAccount()">
        <img
          src="../../../assets/images/icons/accountAvatar.png"
          alt="smallAvatar" />
        View Account
      </button>
      <button mat-menu-item class="menu-label" (click)="logout()">
        <img src="../../../assets/images/icons/logout.png" alt="logout" /> Log
        out
      </button>
    </mat-menu>
  </div>
</div>

<div class="phone-navbar-container">
  <div class="top-content">
    <img
      src="../../../assets/images/icons/nav-hamburger.svg"
      class="clickable"
      (click)="toggleSideNav()" />
    <img
      src="../../../assets//images/iphone-assets/iphone-navbar-logo.svg"
      alt=""
      class="sc-logo" />
  </div>
  <div class="phone-search">
    <div class="search">
      <mat-form-field
        floatLabel="never"
        appearance="fill"
        class="width-adjustment"
        [ngClass]="isPro ? 'pro-width' : isProMax ? 'promax-width' : ''">
        <input
          matInput
          type="text"
          placeholder="Search"
          [(ngModel)]="searchQuery"
          (keydown.enter)="search()" />
        <span matPrefix
          ><img
            class="search-image"
            src="../../../assets/images/icons/search.png"
            alt=""
        /></span>
      </mat-form-field>
    </div>
    <div class="button-container">
      <button type="submit" class="phone-upload" (click)="search()">+</button>
    </div>
  </div>
</div>
