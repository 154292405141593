import { Component, Input, OnInit } from '@angular/core';
import { Party } from 'src/app/@shared/models/party.model';

@Component({
  selector: 'app-party-list',
  templateUrl: './party-list.component.html',
  styleUrls: ['./party-list.component.sass']
})
export class PartyListComponent implements OnInit {

  @Input()
  parties: Party[] = [{
    number: 1,
    name: 'John Doe',
    email: 'johndoe@email.com',
    designation: 'Court Attorney',
    role: 'Needs to Sign'
  }, {
    number: 2,
    name: 'Kallie Caballero',
    email: 'johndoe@email.com',
    designation: 'Court Attorney',
    role: 'Needs to Sign'
  },]



  constructor() { }

  ngOnInit(): void {
  }

}
