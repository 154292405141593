import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { FileDataModel } from 'src/app/@shared/models/file-data.model';
import { FileTableModel } from 'src/app/@shared/models/file-table.model';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.sass'],
})
export class DocumentsComponent implements OnInit, AfterViewInit {
  myfilesData: FileTableModel[] = [];
  groupfilesData: FileTableModel[] = [];
  sharedfilesData: FileTableModel[] = [];
  isPhoneWidth:boolean = false;
  filesCols: string[] = [];
  hasCheckBox: boolean = environment.flag.multiFileSelect;
  myResultsLength: number = 0;
  groupResultsLength: number = 0;
  sharedResultsLength: number = 0;
  isGroupDataLoaded: boolean = false;
  @ViewChild('tabGroup') tabGroup!: MatTabGroup;
  subs: Subscription = new Subscription();
  constructor(
    private _filesService: FilesService,
    private _fileActionService: FileActionService
  ) {}

  ngAfterViewInit() {
    this.tabGroup.selectedIndex = this._fileActionService.selectedTab;
    this.tabGroup.realignInkBar();
  }

  changeTab(data: any) {
    this._fileActionService.selectedTab = data.index;
    switch (data.index) {
      case 0:
        this.loadMyDocs();
        break;

      case 1:
        this.loadGroupDocs();
        break;

      case 2:
        this.loadShareedDocs();
        break;

      default:
        break;
    }
  }

  ngOnInit(): void {
    if(screen.width >= 393 && screen.width<=490)
    {
      this.isPhoneWidth =true;
    }else{
      this.isPhoneWidth = false;
    }
    this.filesCols = [...this._filesService.filesCols];
    this.filesCols.shift();

    this.loadMyDocs();
  }

  openPdf(data: FileTableModel) {
    console.log('this is the',data);
    if (data.file_type === 'folder' || data.file_type === 'back') return;

    let url =
      data?.actions?.choices?.download?.path || data?.actions?.download?.path;

    url = `../../../assets/${url}`;
    this._fileActionService.pdfSrc = url;
    this._fileActionService.file = data;
    this._fileActionService.setIsViewFile(true);
  }
  loadMyDocs() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = this._filesService.getUserFiles().subscribe((data) => {
      const formattedData = this._filesService.formatFile(data.data);
      this.myfilesData = formattedData;
      this.myResultsLength = formattedData.length;
    });
  }
  loadGroupDocs() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = this._filesService.getGroupFiles().subscribe((data) => {
      const formattedData = this._filesService.formatFile(data.data, false,true);
      this.groupfilesData = formattedData;
      this.groupResultsLength = formattedData.length;
      this.isGroupDataLoaded = true;
    });
  }
  loadShareedDocs() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = this._filesService
      .getSharedDocuments()
      .subscribe(({ data }) => {
        const formattedData = this._filesService.formatFile(data, false, true);
        console.log(data);
        this.sharedfilesData = formattedData;
        this.sharedResultsLength = formattedData.length;
      });
  }
}
