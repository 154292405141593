import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserUpdateModel } from 'src/app/@shared/models/user-update.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.sass'],
})
export class AccountComponent implements OnInit {
  hideOldPw: boolean = true;
  hideNewPw: boolean = true;
  hideConfirm: boolean = true;
  phone: any;
  editAccountForm: FormGroup = this.fb.group({
    phoneNumber: ['', [Validators.required]],
  });
  givenName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  oldPassword: string | undefined;
  newPassword: string | undefined;
  confirmPassword: string | undefined;
  email: string | undefined;

  constructor(
    private _navigationService: NavigationService,
    private _authService: AuthenticationService,
    private _userService: UsersService,
    private fb: FormBuilder,
    private sb: MatSnackBar
  ) { }

  ngOnInit(): void {
    this._userService.getCurrentUserDetails().subscribe((i) => {
      this.givenName = i.data.givenName;
      this.lastName = i.data.lastName;
      this.email = i.data.email;
      this.editAccountForm.controls['phoneNumber'].setValue(i.data.phoneNumber);
    });
  }
  close() {
    this._navigationService.closeView();
  }
  updateProfile() {
    const user: UserUpdateModel = {
      id: this._authService.userId,
    };
    if (this.givenName) {
      user.givenName = this.givenName;
    }
    if (this.lastName) {
      user.lastName = this.lastName;
    }
    if (this.email) {
      user.email = this.email;
    }
    if (this.editAccountForm.controls['phoneNumber'].valid) {
      user.phoneNumber = this.editAccountForm.controls['phoneNumber'].value;
    }

    this._authService.name = `${this.givenName} ${this.lastName}`
    this._userService.udpateUser(user).subscribe((i) => {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'UserUpdated' },
      });
    });
  }
  updatePassword() {
    if (!this.oldPassword) {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'error', text: 'Old Password should not be empty' },
      });
      return;
    }
  
    if (this.newPassword && this.newPassword.length < 6) {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'error', text: 'New Password should be at least 6 characters long' },
      });
      return;
    }
  
    if (!this.newPassword) {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'error', text: 'New Password should not be empty' },
      });
      return;
    }
  
    if (!this.confirmPassword) {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'error', text: 'Confirm Password should not be empty' },
      });
      return;
    }
  
    if (this.newPassword !== this.confirmPassword) {
      this.sb.openFromComponent(SnackbarComponent, {
        duration: 1000,
        data: { type: 'error', text: 'New Passwords do not match' },
      });
      return;
    }
  
    // Proceed with the request if all validations pass
    const user: UserUpdateModel = {
      id: this._authService.userId,
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmPassword,
    };
  
    this._authService.updateUserPassword(user).subscribe(
      (response) => {
        this.sb.openFromComponent(SnackbarComponent, {
          duration: 1000,
          data: { type: 'UserUpdated' },
        });
      },
      (error: HttpErrorResponse) => {
        if (error.error.error === 'HttpException: Old password does not match') {
          this.sb.openFromComponent(SnackbarComponent, {
            duration: 1000,
            data: { type: 'error', text: 'Old Password does not match' },
          });
          return;
        }
        // Handle the error response here
        console.error('Error:', error);
        console.error('Error Status:', error.status);
        console.error('Error Message:', error.message);
        console.error('Error Body:', error.error);
      }
    );
  }  
  
}
