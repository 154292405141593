import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, debounceTime, filter, map } from 'rxjs';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { QrService } from 'src/app/@shared/services/qr.service';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.sass'],
})
export class CertificateComponent implements OnInit {
  columns: string[] = [
    'owner',
    'roll number',
    'date admitted',
    'created',
    'actions',
  ];
  data: any = this._safeFormService.certificates$;
  certificates: any = this._safeFormService.certificates$;
  searchQuery: string = '';

  generateFile: boolean = false;
  fileData: any;
  debounceClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  dataRetrieved: boolean = false

  constructor(
    private _safeFormService: SafeformService,
    private _navigation: NavigationService,
    private _loader: LoaderService,
    private _qr: QrService
  ) { }

  ngOnInit(): void {
    this.getCertificates();
  }

  getCertificates(wait: boolean = false) {
    if (this.dataRetrieved) return ;
    this._safeFormService.getCertificates().subscribe(({ data }) => {
      this._safeFormService.setCertificates(
        this._safeFormService.formatCertificates(data)
      );
      this.dataRetrieved = true
      this._loader.hideNow()
    });
  }

  search() {
    this.certificates = this.data.pipe(
      map((tableData: any) => {
        console.log({ tableData, search: this.searchQuery });
        return tableData.filter((tableDatum: any) => {
          return tableDatum?.owner
            ?.toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      })
    );
  }

  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }

  async onClickRow(event: any, data: any) {
    if (event.target.localName == 'img') {
      return;
    }

    const files = data.actions.form_download.filepath;
    
    if (!!files.length) {
      this._safeFormService.viewerData = {
        ...data.actions.form_download,
        pdfSrc: files[files.length - 1],
        prevLocation: 'Certificate',
        prev: 'certificates-table',
        name: `${data.actions.form_download.fullName} - Certificate`,
      };
      this._navigation.updateLocation('formViewer');
    } else {
      this._loader.show()
      let certValue = data.actions.form_download;
      console.log("certValue-",{ certValue });
      const fileData: any = {};
      fileData['certificateName'] = certValue.fullName;
      fileData['rollNumber'] = certValue.rollNumber;
      fileData['dateAdmitted'] = certValue.dateAdmitted
        ? moment(certValue.dateAdmitted)
          .format('Do [day of] MMMM, YYYY')
          .toString()
        : certValue.dateAdmitted;
      fileData['dateAttestation'] = moment(certValue.dateOfAttestation)
        .format('Do [day of] MMMM, YYYY')
        .toString();
      fileData['clerkName'] = certValue.clerkName;
      fileData['chiefName'] = certValue.chiefName;
      const qrCodeText = `${window.location.href}?ce=${certValue.qrID}`;
      const qrCodeDataImage = await this._qr.getQRCode(qrCodeText);
      fileData['qrCode'] = qrCodeDataImage
      if (environment.local) {
      } else {
        fileData['clerkSigUpload'] = `./assets/${certValue.clerkSignature}`;
        fileData['chiefSigUpload'] = `./assets/${certValue.chiefSignature}`;
      }

      this._safeFormService.certificateId = data.actions.form_download._id;
      this._safeFormService.generateMissingPDF(fileData);
    }
  }
}
