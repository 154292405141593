import { Component, OnInit } from '@angular/core';
import { AuditRequestModel } from 'src/app/@shared/models/audit-request.model';
import { AuditLogService } from 'src/app/@shared/services/audit-log.service';

@Component({
  selector: 'app-admin-dashboard-audit-log',
  templateUrl: './admin-dashboard-audit-log.component.html',
  styleUrls: ['./admin-dashboard-audit-log.component.sass'],
})
export class AdminDashboardAuditLogComponent implements OnInit {
  auditCols: string[] = [
    'Date and Time',
    'Email Address',
    'Type',
    'Changes made',
    // 'Action',
  ];
  auditData = this._auditLogService.auditResult$;
  constructor(private _auditLogService: AuditLogService) {}

  ngOnInit(): void {}

  downloadLog() {
    this._auditLogService.downloadLog().subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/csv' });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `audit log.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
}
