<div class="container" *ngIf="!modifyFile">
  <div *ngIf="!hideTitle && !isSearch" class="header-container">
    <h4 class="title">{{ title }}</h4>
    <button class="button-header" *ngIf="addButton !== ''"
      (click)="addButton == 'Create Form' ? createForm() : showModal.emit({dialog, type, breadcrumb})">
      <ng-container *ngIf="addButton == 'Use SignSecure'; else title">Create Document</ng-container>
      <ng-template #title>{{ addButton | titlecase }}</ng-template>
      <img *ngIf="addButtonIcon !== ''" [src]="addButtonIcon" alt="" />
    </button>
    <ng-content select=".extra-buttons" *ngIf="selection.selected.length > 0 && data.length !== 0"></ng-content>
    <ng-content select=".tableSearch"></ng-content>
  </div>
  <div class="bread-crumbs" *ngIf="breadcrumb.length !== 0 && showBreadcrumbs">
    <app-breadcrumb [data]="breadcrumb" (onBack)="breadcrumbBack($event)"></app-breadcrumb>
  </div>

  <app-filter *ngIf="showFilter"></app-filter>

  <table mat-table [dataSource]="dataSource" matSort class="table">
    <ng-container matColumnDef="select"> </ng-container>
    <ng-container *ngFor="let displayedColumn of displayedColumns" matColumnDef="{{ displayedColumn }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [class]="getClassTh(displayedColumn)">
        <ng-container *ngIf="hasCheckBox && displayedColumn === ' '">
          <mat-checkbox (change)="checkAllChanges($event)"></mat-checkbox>
        </ng-container>

        <ng-container *ngIf="displayedColumn !== ' '">
          {{ displayedColumn | titlecase }}
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let element" [class]="getClassTd(displayedColumn)">
        <ng-container *ngIf="displayedColumn == ' ' && element['file_type'] !== 'back'">
          <mat-checkbox *ngIf="hasCheckBox" (change)="select($event, element)"
            [checked]="selection.isSelected(element)"></mat-checkbox>
        </ng-container>
        <ng-container *ngIf="displayedColumn == 'signature'">
          <img class="signature-image" [src]="element[transformToSnakecase(displayedColumn)]" alt="" srcset="" />
        </ng-container>
        <ng-container *ngIf="displayedColumn == 'tags' || displayedColumn == 'Tags'">
          <div class="tags-container">
            <app-tags [tags]="element[transformToSnakecase(displayedColumn)]">
            </app-tags>
          </div>
        </ng-container>

        <ng-container *ngIf="
            displayedColumn == 'actions' ||
            displayedColumn == 'Actions' ||
            displayedColumn == 'action' ||
            displayedColumn == 'Action'
          ">
          <app-actions [actions]="element[transformToSnakecase(displayedColumn)]" [type]="type" [data]="element"
            [callback]="callback" [disable]="element['workflow']"
            (modifyFileEvent)="modifyFileEvent($event)"></app-actions>
        </ng-container>
        <ng-container *ngIf="
            transformToSnakecase(displayedColumn) == 'name' ||
            transformToSnakecase(displayedColumn) == 'title'
          ">
          <ng-container *ngIf="element[transformToSnakecase(displayedColumn)]?.length >= 30">
            <div class="name-container" (click)="nameFunction(element)"
              [matTooltip]="element[transformToSnakecase(displayedColumn)]">
              <img *ngIf="element['file_type'] && element['file_type'] !== 'back'"
                [src]="getObjectImage(element['file_type'])" alt="" />
              {{ element[transformToSnakecase(displayedColumn)] }}
            </div>
          </ng-container>
          <ng-container *ngIf="element[transformToSnakecase(displayedColumn)].length < 30">
            <div class="name-container" (click)="nameFunction(element)">
              <img *ngIf="element['file_type'] && element['file_type'] !== 'back'"
                [src]="getObjectImage(element['file_type'])" alt="" />
              {{ element[transformToSnakecase(displayedColumn)] }}
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="transformToSnakecase(displayedColumn) === 'indexed'">
          <p *ngIf="element[transformToSnakecase(displayedColumn)] == true" class="indexed">
            Yes
          </p>
          <p *ngIf="element[transformToSnakecase(displayedColumn)] === false" class="not-indexed">
            No
          </p>
        </ng-container>
        <ng-container *ngIf="transformToSnakecase(displayedColumn) === 'ocr'">
          <img *ngIf="element[transformToSnakecase(displayedColumn)] == true" class="indexed"
            src="../../../assets/images/ocr.png" />
        </ng-container>
        <ng-container *ngIf="transformToSnakecase(displayedColumn) === 'status'">
          <p [class]="
              getStatusColor(element[transformToSnakecase(displayedColumn)])
            ">
            {{ element[transformToSnakecase(displayedColumn)] }}
          </p>
        </ng-container>
        <ng-container *ngIf="
            displayedColumn != 'actions' &&
            displayedColumn != 'Actions' &&
            displayedColumn != 'tags' &&
            displayedColumn != 'Tags' &&
            displayedColumn != 'action' &&
            displayedColumn != 'Action' &&
            displayedColumn != 'indexed' &&
            displayedColumn != 'Indexed' &&
            displayedColumn != 'OCR' &&
            transformToSnakecase(displayedColumn) !== 'title' &&
            transformToSnakecase(displayedColumn) !== 'name' &&
            displayedColumn !== 'signature' &&
            displayedColumn !== 'status' &&
            displayedColumn !== 'Status'
          ">
          {{ element[transformToSnakecase(displayedColumn)] }}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row (click)="rowFunction($event, element)"
      *matRowDef="let element; columns: displayedColumns"
      [ngClass]="element?.status ? transformToSnakecase(element['status']) : ''"></tr>
  </table>
  <mat-paginator *ngIf="isPaginate" [length]="resultsLength" [pageSize]="pageSize"
    [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>