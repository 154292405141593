import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.sass'],
})
export class SnackbarComponent implements OnInit {
  constructor(private _snackbar: MatSnackBar,@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
    
  }

  closeSnackbar() {
    this._snackbar.dismiss();
  }
}
