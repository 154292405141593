import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.sass'],
})
export class FormContainerComponent implements OnInit {
  readonly regFlag = environment.flag.registration;
  initialLoad: boolean = false;
  screen: string = '';
  verificationType: string = 'reset-password';
  verification: boolean = this._nav.getVerificationStatus();
  rememberMe: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _authService: AuthenticationService,
    private _nav: NavigationService
  ) {}

  ngOnInit(): void {
    const loc = this._nav.initLocation;
    if (loc === 'reset-password') {
      this.verificationType = loc;
      this.verification = true;
    } else {
      this.screen = 'login';
    }
  }

  goTo(navigate: string): void {
    if (navigate != 'recoverPassword') {
      this.screen = navigate;
      this.verification = false;
    } else {
      this.verificationType = navigate;
      this.verification = true;
    }
  }
  verify(verifyType: string): void {
    this.verificationType = verifyType;
    this.verification = true;
  }
  logging() {}
}
