import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, map } from 'rxjs';
import { Breadcrumb } from 'src/app/@shared/models/breadcrumb.model';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

interface MoveBreadcrumb extends Breadcrumb {
  data: any;
}
@Component({
  selector: 'app-move-file',
  templateUrl: './move-file.component.html',
  styleUrls: ['./move-file.component.sass'],
})
export class MoveFileComponent implements OnInit {
  @Output()
  closeModalEvent = new EventEmitter();

  @Input()
  data: any;

  numberOfFiles: number = 0;
  fileType: string = 'folder';
  directory: any[] = [];
  selected: string = '';
  openedDirectory: any[] = [];

  breadcrumbs: MoveBreadcrumb[] = [];
  disabled = false

  fileId: string = '';
  constructor(
    private _fileServices: FilesService,
    private _breadcrumbService: BreadcrumbsService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.emptyBreadcrumbs();

    const data = this.data.data;
    this.disabled = !data[0]?.parent && this.selected === ''
    this.numberOfFiles = data.length;
    this.fileType = data.length === 1 ? data[0].type : 'items';
    this._fileServices.getAllFolder(false).subscribe((data: any) => {
      this.directory = this.formatDirectory(data.data);
      this.openedDirectory = this.directory;
    });
  }

  getIconSrc(fileType: string) {
    switch (fileType) {
      case 'file':
        return '../../../assets/images/icons/file.png';
      case 'folder':
        return '../../../assets/images/icons/folder.png';
      default:
        return '../../../assets/images/icons/file.png';
    }
  }

  formatDirectory(files: any[]) {
    this.fileId =
      this.data.data.length === 1 ? this.data.data[0].id : this.data.data.id;
    const format = files
      .map((file: any) => {
        let children: any[] = [...files].filter(
          (f: any) => f.parent === file.id && f.id !== this.fileId
        );
        children = this.getChildren(files, children);
        return { ...file, children: children };
      })
      .filter((file) => !file.parent && file.id !== this.fileId);
    return format;
  }

  getChildren(files: any[], children: any[]) {
    return children.map((child: any) => {
      let children = [...files].filter(
        (f: any) => f.parent === child.id && f.id !== this.fileId
      );
      return { ...child, children };
    });
  }

  openDirectory(file: any) {
    if (file.children && file.children.length !== 0) {
      this.breadcrumbs.push({ id: file.id, title: file.name, data: file });
      this.openedDirectory = file.children;
      this.selected = '';
      this.disabled = false;
    } else {
      this.selected = file.id;
      this.disabled = false;
    }
  }

  back(data: any) {
    if (this.breadcrumbs.length === 1 && !data.data) {
      this.close();
    } else if (!data.data) {
      this.openedDirectory = this.directory;
      this.emptyBreadcrumbs();
    } else if (data.id !== this.breadcrumbs[this.breadcrumbs.length - 1].id) {
      this.openedDirectory = data.data.children;
      this.removeBreadcrumbs(data.data);
    }
  }

  removeBreadcrumbs(file: any) {
    const location = this.breadcrumbs.findIndex(
      (datum) => datum.id === file.id
    );
    this.breadcrumbs = this.breadcrumbs.slice(0, location + 1);
  }

  emptyBreadcrumbs() {
    this.breadcrumbs = [{ id: '', title: 'All Folder and Files', data: null }];
  }

  moveDirectory() {
    if (this.disabled) return

    const fileId = this.data.fileId;
    let fileParent: any =
      this.selected !== ''
        ? this.selected
        : this.breadcrumbs[this.breadcrumbs.length - 1].id;

    fileParent = fileParent !== '' ? fileParent : null;
    this._fileServices
      .moveDirectory(fileId, fileParent)
      .subscribe((data: any) => {
        console.log({ fileParent })
        if (!!fileParent) {
          this._fileServices
            .getAllFolderFiles(fileParent ?? '')
            .subscribe(({ data }) => {
              const format = this._fileServices.formatFilesAndFolderData(data);

              this._fileServices.setFilesAndFolderAsync([...format]);

              this.updateFolderAndFilesBreadcrumbs();
              this.showNotif();
              this.close(data);
            });
        } else {
          this._fileServices.getAllFilesAndFolder().subscribe(({ data }) => {
            const format = this._fileServices.formatFilesAndFolderData(data);

            const signSecure = {
              id: "signsecure",
              title: " ‌SignSecure workflow documents",
              file_type: "folder",
              status: "",
            };

            this._fileServices.setFilesAndFolderAsync([signSecure, ...format]);

            this.updateFolderAndFilesBreadcrumbs();
            this.showNotif();
            this.close(data);
          })
        }

      });
  }

  showNotif() {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 3000,
      data: { type: 'DirectoryMoved', dataType: this.fileType },
    });
  }

  updateFolderAndFilesBreadcrumbs() {
    this._breadcrumbService.emptyBreadcrumb();
    for (let i = 1; i < this.breadcrumbs.length; i++) {
      this._breadcrumbService.setBreadcrumbsData(this.breadcrumbs[i]);
    }

    if (this.selected !== '') {

      const loc = this.openedDirectory.findIndex(
        (datum) => datum.id === this.selected
      );

      this._breadcrumbService.setBreadcrumbsData({
        id: this.selected,
        title: this.openedDirectory[loc].name,
        file: this.openedDirectory[loc],
      });
    }
  }

  close(data: any = { cancelled: true }) {

    this.closeModalEvent.emit(data);
  }
}
