<app-spinner></app-spinner>
<div class="container">
  <app-signing-page-navbar
    [data]="navdata"
    [done]="done"
    [role]="role"
    [createdByMe]="createdByMe"
    [status]="status"
    [documentStatus]="documentStatus"
    [id]="data.id"
    [signing]="signNow"
    [userData]="currentUser"
    [cancel]="cancel"
    [documentTitle]="data.name"
    (generate)="generateNewPdfFile()"
    (action)="doAction($event)"
  ></app-signing-page-navbar>
  <mat-sidenav-container *ngIf="!done && !cancel" hasBackdrop="true">
    <mat-sidenav-content>
      <app-signing-page-content
        [data]="data"
        [status]="documentStatus"
        (getUpdateData)="updateData($event)"
      ></app-signing-page-content>
    </mat-sidenav-content>
    <mat-sidenav
      class="sidebar-content"
      mode="over"
      position="end"
      [(opened)]="openDrawer"
      #sidenav
    >
      <ng-container [cdkPortalOutlet]="panelPortal | async"> </ng-container>
    </mat-sidenav>
  </mat-sidenav-container>

  <app-signing-page-done *ngIf="done" [data]="doneData"></app-signing-page-done>
  <app-signing-page-cancel
    *ngIf="cancel"
    [expired]="expired"
  ></app-signing-page-cancel>
</div>

<ng-template #Default></ng-template>
<ng-template #AuditTrail>
  <app-audit-trail
    [data]="auditTrail"
    (close)="closeSlider()"
  ></app-audit-trail>
</ng-template>
<ng-template #DocumentDetails>
  <app-document-details
    [data]="data"
    (close)="closeSlider()"
  ></app-document-details>
</ng-template>
