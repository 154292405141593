import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, catchError, interval, of } from 'rxjs';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { NavigationService } from "src/app/@shared/services/navigation.service"

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.sass']
})
export class OtpDialogComponent implements OnInit {
  @Input()
  data: any

  @Output()
  closeModalEvent: EventEmitter<any> = new EventEmitter()
  timer = new Subscription();
  time = 100;
  otp: string = '';
  otpRequest: string = ''
  otpError: string = '';
  isExpired: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<OtpDialogComponent>,
    private _safeFormService: SafeformService,
    private _navigation: NavigationService,
    private _forms: SafeformService
  ) {}

  ngOnInit() {
    this.sendOtp()
    this.startTimer()
  }

  verifyOtp() {
    if (this.otpRequest == this.otp && this.otp !== '' && this.otpRequest !== '') {
      this._safeFormService.viewerData = {
        // ...data.scid_download,
        pdfSrc: { pdfPath: this.data.data.filepath },
        prevLocation: 'SC ID',
        prev: 'sc-id-table',
        name: `${this.data.data.fullName} - SC ID`,
      };
      this._navigation.updateLocation('formViewer');
      this.dialogRef.close();
    } else {
      this.otpError = 'Invalid OTP';
    }
  }

  sendOtp () {
    this._forms
    .sendOtp(this.data.data.emailAddress)
    .pipe(
      catchError((error: any) => {
        return of();
      })
    )
    .subscribe((otpResponse: any) => {
      this.otpRequest = otpResponse.data.otp
      this.otpError = ''
      this.isExpired = false
      this.time = 100;
      this.startTimer()
    });
  }

  inputOtp(data: any) {
    this.otp = data?.target?.value || '';
  }

  startTimer() {
    this.timer = interval(1000).subscribe(() => {
      if (this.time > 0) {
        this.time -= 1;
      } else {
        this.otpRequest = ''
        this.isExpired = true
        this.timer.unsubscribe();
      }
    });
  }
}
