import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  from,
  lastValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignSecureProgressModel } from '../models/sign-secure-progress.model';
import { PDFDocument, PDFFont, PDFPage, PageSizes, rgb } from 'pdf-lib';
import { QrService } from './qr.service';
import fontkit from '@pdf-lib/fontkit';
import { UsersService } from './users.service';
import moment from 'moment';
import { FilesService } from './files.service';
import { SafeformService } from './safeform.service';
import { LoaderService } from './loader.service';

interface WorkFlow {
  signOrder?: boolean;
  parties?:
    | {
        name?: string;
        email?: string;
        id?: string;
        role?: string;
      }[]
    | any[];
  emailContent?: {
    subject?: string;
    message?: string;
  };
  signatures?: any[];
  reminders?: {
    expiration?: string;
    reminderInXDays?: number;
    reminderEveryXDays?: number;
    reminderBeforeXDays?: number;
  };
  status?: string;
  auditTrail?: any[];
  qrPosition?: string;
  currentParty?: string;
  currentOrder?: number;
}
interface WorkFlowDTO {
  id?: any;
  file?: any;
  fileData?: any;
  name?: string;
  type?: string;
  description?: string;
  workflow?: WorkFlow;
  createdBy?: string;
  scale?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SignSecureService {
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly directory = environment.apiConfig.directory.url;
  private readonly signsecureUrl = `${this.baseUrl}${this.directory}/signsecure`;

  private signSecureProgress = new BehaviorSubject<Number>(4);
  signSecureProgress$ = this.signSecureProgress.asObservable();

  private signSecurePage = new BehaviorSubject<Number>(4);
  signSecurePage$ = this.signSecurePage.asObservable();

  private worflowData = new BehaviorSubject<WorkFlowDTO>({});
  worflowData$ = this.worflowData.asObservable();
  workflowDataValue = this.worflowData.getValue();

  publicFile: any;
  private isPublicView = new BehaviorSubject<boolean>(false);
  isPublicView$ = this.isPublicView.asObservable();

  signatureOptions = [
    {
      name: 'Signature',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature',
    },
    {
      name: 'Signature & Full Name',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature-name',
    },
    {
      name: 'Signature & Date',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature-date',
    },
    {
      name: 'Signature, Full Name & Designation',
      image: '../../../../../assets/images/template-icons/signature.svg',
      type: 'signature-name-designation',
    },
    {
      name: 'Full Name',
      image: '../../../../../assets/images/template-icons/text.svg',
      type: 'name',
    },
    {
      name: 'Initial',
      image: '../../../../../assets/images/template-icons/pen.svg',
      type: 'initials',
    },
    {
      name: 'Designation',
      image: '../../../../../assets/images/template-icons/designation.svg',
      type: 'designation',
    },
    {
      name: 'Date & Time',
      image: '../../../../../assets/images/template-icons/calendar.svg',
      type: 'date-time',
    },
    // {
    //   name: 'Textbox',
    //   image: '../../../../../assets/images/template-icons/box.svg',
    //   type: 'textbox',
    // },
  ];

  signPdfData: any;
  signPdfName: string = '';

  private addFieldPdfPageNumber = new BehaviorSubject<Number>(1);
  addFieldPdfPageNumber$ = this.addFieldPdfPageNumber.asObservable();

  private addFieldPdfZoom = new BehaviorSubject<number>(1);
  addFieldPdfZoom$ = this.addFieldPdfZoom.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _safe: SafeformService,
    private _loader: LoaderService
  ) {}

  nextProgress(progress: number) {
    this.signSecureProgress.next(progress);
    this.signSecurePage.next(progress);
  }

  prevPage(progress: number) {
    this.signSecurePage.next(progress);
  }

  setInitialData(value: any, fileData: any) {
    this.worflowData.next({ ...value, fileData });
  }

  setId(id: string) {
    const value = this.worflowData.value;
    this.worflowData.next({ ...value, id });
  }

  setFile(file: any, fileData: any, name = undefined) {
    const value = this.worflowData.value;
    console.log({ workflowV: value });
    this.worflowData.next({
      ...value,
      file,
      fileData,
      type: 'file',
      name: name || (file?.name ?? ''),
    });
  }

  setFileInfo(name: string, description: string) {
    const value = this.worflowData.value;
    console.log({ value, name, description });
    this.worflowData.next({ ...value, name, description });
  }

  isFileUploaded() {
    const value = this.worflowData.value;
    return !!value.file;
  }

  setPartiesData(parties: any, signOrder: boolean) {
    const value = this.worflowData.value;
    const formattedparties = parties.map((party: any, index: number) => ({
      ...party,
      order: index,
    }));
    console.log({ value, formattedparties });
    const data = {
      ...value,
      workflow: { ...value.workflow, parties: formattedparties, signOrder },
    };
    this.worflowData.next(data);
    this.checkSignatureWithCurrentParty(data);
  }

  clearWorkFlowData() {
    this.worflowData.next({});
  }

  setSignatures(signatures: any, qrPosition: any) {
    const value = this.worflowData.value;
    this.worflowData.next({
      ...value,
      workflow: { ...value.workflow, signatures, qrPosition },
    });
  }

  setScale(scale: any) {
    const value = this.worflowData.value;
    this.worflowData.next({
      ...value,
      scale,
    });
  }

  checkSignatureWithCurrentParty(currentData: any) {
    const { workflow } = currentData;

    if (!workflow) return;

    const { parties, signatures } = workflow;

    if (!signatures || !parties) {
      return;
    }

    // check if party is removed
    signatures.forEach((page: any[], index: number) => {
      if (page.length > 0) {
        signatures[index] = page.filter(signature =>
          parties.some(
            (party: any) => party.id === signature.id && party.role === 'SIGN'
          )
        );
      }
    });

    // check if parties' name is updated
    signatures.forEach((page: any[], index: number) => {
      if (page.length > 0) {
        signatures[index] = page.map(signature => {
          if (
            ['name', 'designation'].some(type => signature.type.includes(type))
          ) {
            const party = parties.find(
              (party: any) => party.id === signature.id
            );
            const nameConfig = signature.nameConfig;
            const desginationConfig = signature.desginationConfig;

            return {
              ...signature,
              nameConfig: {
                ...nameConfig,
                text: party.name,
              },
              desginationConfig: {
                ...desginationConfig,
                text: party.designation,
              },
            };
          } else {
            return signature;
          }
        });
      }
    });

    this.worflowData.next({
      ...currentData,
      workflow: { ...workflow, signatures: signatures },
    });
  }

  createWorkflow(signsecure: any) {
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/create`,
      this.formatData(signsecure)
    );
  }

  formatData(data: any) {
    let formData = new FormData();

    delete data.travelAuth;
    if (!!!data.type) {
      data.type = 'file';
    }

    if (data.file) {
      formData.append('file', data.file);
      delete data?.file;
      delete data?.fileData;
    }

    if (data?.workflow?.signOrder !== undefined) {
      formData.append(
        'workflow[signOrder]',
        data?.workflow?.signOrder ?? false
      );
    }

    // const workflow = data?.workflow ?? {}
    // // console.log(data)
    // // Parties
    // const parties = workflow.parties
    // for (let i = 0; i < parties.length; i++) {
    //   formData.append(`workflow[parties][${i}][id]`, parties[i].id)
    //   formData.append(`workflow[parties][${i}][email]`, parties[i].email)
    //   formData.append(`workflow[parties][${i}][name]`, parties[i].name)
    //   formData.append(`workflow[parties][${i}][role]`, parties[i].role)
    // }

    // const signatures = workflow.signatures
    // for (let i = 0; i < signatures.length; i++) {
    //   formData.append(`workflow[signatures][${i}][id]`, signatures[i].id)
    //   formData.append(`workflow[signatures][${i}][email]`, signatures[i].email)
    //   formData.append(`workflow[signatures][${i}][x]`, signatures[i].name)
    //   formData.append(`workflow[signatures][${i}][y]`, signatures[i].role)
    //   formData.append(`workflow[signatures][${i}][page]`, signatures[i].role)
    // }

    // const pages = workflow.signatures
    // for (let i = 0; i < pages.length; i++) {
    //   const signatures = pages[i]
    //   if (signatures?.length === 0) {
    //     formData.append(`workflow[signatures][${i}][0][trackId]`, '0')
    //   } else {
    //     for (let j = 0; j < signatures.length; j++) {
    //       const signature = signatures[j]
    //       formData.append(`workflow[signatures][${i}][id]`, signatures[i].id)
    //       formData.append(`workflow[signatures][${i}][email]`, signatures[i].email)
    //       formData.append(`workflow[signatures][${i}][x]`, signatures[i].name)
    //       formData.append(`workflow[signatures][${i}][y]`, signatures[i].role)
    //       formData.append(`workflow[signatures][${i}][page]`, signatures[i].role)
    //     }
    //   }
    // }

    // const emailContent = workflow.emailContent
    // formData.append('workflow[emailContent][subject]', emailContent.subject);
    // formData.append('workflow[emailContent][description]', emailContent.description);

    
    let signatures: any[] = []
    const workflow = data.workflow
    if (workflow?.signatures) {
      const pages = data['workflow']['signatures'] ?? [];
      signatures = [
        ...pages.map((sig: any[]) => {
          if (sig.length === 0) {
            sig.push({ trackId: 0 });
            return sig;
          } else {
            return sig.map(s => {
              const { image, x, y, viewport, scale, ...data } = s;

              if (data?.config) {
                const { x, y, width, height, ...config } = data.config;
                data.config = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                  width: width / scale,
                  height: height / scale,
                };
              }

              if (data?.textConfig) {
                const { x, y, ...config } = data.textConfig;
                data.textConfig = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                };
              }

              if (data?.documentConfig) {
                const { x, y, ...config } = data.documentConfig;
                data.documentConfig = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                };
              }

              if (data?.documentConfig) {
                const { x, y, ...config } = data.documentConfig;
                data.documentConfig = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                };
              }

              if (data?.nameConfig) {
                const { x, y, ...config } = data.nameConfig;
                data.nameConfig = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                };
              }

              if (data?.dateConfig) {
                const { x, y, ...config } = data.dateConfig;
                data.dateConfig = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                };
              }

              if (data?.designationConfig) {
                const { x, y, ...config } = data.designationConfig;
                data.designationConfig = {
                  ...config,
                  x: x / scale,
                  y: y / scale,
                };
              }

              return {
                ...data,
              };
            });
          }
        }),
      ];
      workflow.signatures = signatures
    }

    const updatedData = {...data, workflow: {...workflow, signatures}}
    formData = this.obj2FormData(updatedData, formData);
    return formData;
  }

  formatCompleteData(data: any) {
    let formData = new FormData();
    if (data.file) {
      formData.append('files', data.file);
      formData.append('files', data?.manifest);
      formData.append('files', data?.document);

      delete data?.manifest;
      delete data?.document;
      delete data?.file;
      delete data?.fileData;
    }

    formData = this.obj2FormData(data, formData);

    return formData;
  }

  obj2FormData(obj: any, formData = new FormData()) {
    const createFormData = (obj: any, subKeyStr = '') => {
      for (let i in obj) {
        let value = obj[i];
        let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;
        if (typeof value === 'string' || typeof value === 'number') {
          formData.append(subKeyStrTrans, value as any);
        } else if (
          subKeyStrTrans.includes('[signatures][') &&
          Array.isArray(value) &&
          value.length === 0
        ) {
          formData.append(`${subKeyStrTrans}[0][trackId]`, value as any);
        } else if (typeof value === 'object') {
          createFormData(value, subKeyStrTrans);
        }
      }
    };

    createFormData(obj);

    return formData;
  }

  setWorkFlowData(data: any) {
    this.worflowData.next(data);
  }

  getSignatoryOptions(signatory: any) {
    return this.signatureOptions.map((option: any) => {
      return {
        ...option,
        disabled:
          !signatory?.designation && option.type.includes('designation'),
        disabledAll: signatory?.role !== 'SIGN',
      };
    });
  }

  documentSigned(data: any) {
    console.log({ data });
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/sign/${data.id}`,
      this.formatData(data)
    );
  }

  documentDone(data: any) {
    console.log({ formData: data });
    return this._httpClient.post<any>(
      `${this.signsecureUrl}/complete/${data.id}`,
      this.formatCompleteData(data)
    );
  }

  bytesToFile(data: any, filename: string): File {
    return new File([data], this.getCorrectFilename(filename), {
      type: 'application/pdf',
    });
  }

  getCorrectFilename(name: string = '') {
    return name.match(/^.*\.[^\\]+$/g)?.length ?? 0 > 0 ? name : `${name}.pdf`;
  }

  setAddFieldPdfNumber(number: number) {
    this.addFieldPdfPageNumber.next(number);
  }

  setAddFieldZoom(number: number) {
    this.addFieldPdfZoom.next(number);
  }

  documentDecline(id: string, reason: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/decline/${id}`, {
      reason,
    });
  }

  documentReject(id: string, note: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/reject/${id}`, {
      reason: note,
    });
  }

  documentCancel(id: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/cancel/${id}`, {
      reason: '',
    });
  }

  documentView(id: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/view/${id}`, {});
  }

  documentApprove(id: string, note: string) {
    return this._httpClient.post<any>(`${this.signsecureUrl}/approve/${id}`, {
      reason: note,
    });
  }

  getReadableRole(role: string) {
    switch (role) {
      case 'SIGN':
        return 'Needs to Sign';
      case 'APPROVE':
        return 'Needs to Approve';
      case 'APPROVER':
        return 'Needs to Approve';
      case 'COPY':
        return 'Receives a Copy';
    }

    return ' ';
  }

  setPublicFile(file: string) {
    this.publicFile = file;
    this.isPublicView.next(true);
  }

  hidePublicView() {
    this.isPublicView.next(false);
  }

  updateReminders(data: any) {
    const value = this.worflowData.value;
    let formatData = data;
    if (data) {
      formatData.expiration = data.expiration.format('MM-DD-YYYY');
      formatData.reminderInXDays = data.reminderInXDays?.toString();
      formatData.reminderEveryXDays = data.reminderEveryXDays?.toString();
      formatData.reminderBeforeXDays = data.reminderBeforeXDays?.toString();
    }

    this.worflowData.next({
      ...value,
      workflow: { ...value.workflow, reminders: formatData },
    });
  }

  pendingSignature(directory: any) {
    localStorage.setItem('pendingData', JSON.stringify(directory));
  }

  doneSignature() {
    localStorage.removeItem('pendingData');
  }

  havePendingSignature() {
    return localStorage.getItem('pendingData');
  }

  async createManifest(
    pdf: PDFDocument,
    data: any,
    qrService: QrService,
    userService: UsersService
  ) {
    const pdfDoc = await PDFDocument.create({});
    // Add SC Image
    const arrayBuffer = await fetch('../../../assets/images/icon-sc.png').then(
      res => res.arrayBuffer()
    );
    const image = await pdfDoc.embedPng(arrayBuffer);

    let text = data.id;
    text = qrService.encrypt(text);
    const qrCode = await qrService.getQRCode(text);
    const qrImage = await pdfDoc.embedPng(qrCode);

    //Get font
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const fontItalicBytes = await fetch(
      '../../../assets/fonts/DMSans-Italic.ttf'
    ).then(res => res.arrayBuffer());
    const fontBoldBytes = await fetch(
      '../../../assets/fonts/DMSans-Bold.ttf'
    ).then(res => res.arrayBuffer());

    // Register Font
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);
    const customItalicFont = await pdfDoc.embedFont(fontItalicBytes);
    const customBoldFont = await pdfDoc.embedFont(fontBoldBytes);

    const events = [];
    const auditTrail = data?.workflow?.auditTrail;

    for (let trail of auditTrail) {
      const user = await lastValueFrom(
        userService.getUserInfo(trail?.author?.id)
      );
      const userData = user?.data;

      events.push({
        event: trail?.details?.toLowerCase() ?? '',
        user: `${trail?.author?.name} (${userData?.email})`,
        time: trail?.timestamp,
      });
    }

    events.push({
      event: 'completed',
      user: '',
      time: moment.now(),
    });

    const pageNum = pdf.getPageCount();
    const firstPage = events.slice(0, 8);

    const page = pdfDoc.addPage(PageSizes.Folio);
    page.drawImage(image, { x: 416, y: 851.31, width: 134, height: 28.84 });

    // Create "Manifest" Text
    page.drawText('Manifest', {
      size: 16,
      x: 62,
      y: 858,
      font: customBoldFont,
      color: rgb(0.13, 0.13, 0.13),
    });

    // Create lines
    page.drawLine({
      start: { x: 62, y: 828 },
      end: { x: 550, y: 828 },
      thickness: 1,
      color: rgb(0.13, 0.13, 0.13),
      opacity: 1,
    });

    const userData = await lastValueFrom(
      userService.getUserInfo(data.createdBy)
    );

    console.log({ signingData: data });
    this.createManifestInfo(page, customFont, {
      id: data.id,
      created: data?.date_created ?? data?.createdAtFormatted.split(',')[0],
      createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
      createdByEmail: userData?.data?.email,
    });

    for (const [index, party] of firstPage?.entries()) {
      await this.addSignatureData(
        party,
        index,
        page,
        customFont,
        customBoldFont
      );
    }

    if (firstPage.length !== 8) {
      this.addDocumentDetails(
        page,
        customItalicFont,
        {
          pages: pageNum,
          space: 85 * firstPage?.length ?? 0,
          createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
          createdByEmail: userData?.data?.email,
          ...data,
        },
        719 + (7.5 * firstPage?.length ?? 0)
      );
    }

    this.addFooter(page, customFont, {
      image: qrImage,
    });

    //suceeding pages
    const chunkSize = 10;
    for (let i = 8; i < events.length; i += chunkSize) {
      const chunk = events.slice(i, i + chunkSize);
      console.log({ chunk });
      const page = pdfDoc.addPage(PageSizes.Folio);
      page.drawImage(image, { x: 416, y: 851.31, width: 134, height: 28.84 });

      // Create "Manifest" Text
      page.drawText('Manifest', {
        size: 16,
        x: 62,
        y: 858,
        font: customBoldFont,
        color: rgb(0.13, 0.13, 0.13),
      });

      // Create lines
      page.drawLine({
        start: { x: 62, y: 828 },
        end: { x: 550, y: 828 },
        thickness: 1,
        color: rgb(0.13, 0.13, 0.13),
        opacity: 1,
      });

      for (const [index, party] of chunk?.entries()) {
        await this.addSignatureData(
          party,
          index,
          page,
          customFont,
          customBoldFont,
          788
        );
      }

      console.log({ i, length: events.length });
      if (i + chunkSize >= events.length && (events.length - 8) % 10 !== 0) {
        this.addDocumentDetails(
          page,
          customItalicFont,
          {
            pages: pageNum,
            space: 85 * chunk?.length ?? 0,
            createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
            createdByEmail: userData?.data?.email,
            ...data,
          },
          820 + (17 * (chunk?.length - 1) ?? 0)
        );
      }

      this.addFooter(page, customFont, {
        image: qrImage,
      });
    }

    if (events.length === 8 || (events.length - 8) % 10 === 0) {
      const page = pdfDoc.addPage(PageSizes.Folio);
      page.drawImage(image, { x: 416, y: 851.31, width: 134, height: 28.84 });

      // Create "Manifest" Text
      page.drawText('Manifest', {
        size: 16,
        x: 62,
        y: 858,
        font: customBoldFont,
        color: rgb(0.13, 0.13, 0.13),
      });

      // Create lines
      page.drawLine({
        start: { x: 62, y: 828 },
        end: { x: 550, y: 828 },
        thickness: 1,
        color: rgb(0.13, 0.13, 0.13),
        opacity: 1,
      });

      this.addDocumentDetails(
        page,
        customItalicFont,
        {
          pages: pageNum,
          space: 88,
          createdByName: `${userData?.data?.givenName} ${userData?.data?.lastName}`,
          createdByEmail: userData?.data?.email,
          ...data,
        },
        868
      );

      this.addFooter(page, customFont, {
        image: qrImage,
      });
    }

    const copyPages = await pdf.copyPages(
      pdfDoc,
      Array.from(Array(pdfDoc.getPageCount()).keys())
    );

    for (let copyPage of copyPages) {
      console.log({ copyPage });
      pdf.addPage(copyPage);
    }

    const manifestData = await pdfDoc.save();
    return this.bytesToFile(manifestData, 'manifest.pdf');
  }

  async addDocumentDetails(
    page: PDFPage,
    font: PDFFont,
    details: any,
    y: number = 575
  ) {
    console.log({ pageDetails: details });
    page.drawText(
      `Document created on ${
        details.createdAtFormatted ?? '03/22/2023 - 10:52 am'
      } by ${details.createdByName} | ${details.createdByEmail}` +
        `\nTotal no. of pages (excl. Manifest): ${details.pages} page/s\n\n` +
        `Signatures completed by all parties on ${moment().format(
          'MM/DD/YYYY - HH:mm a'
        )}`,
      {
        size: 7.5,
        x: 62,
        y: y - details?.space ?? 0,
        font: font,
        lineHeight: 10,
        color: rgb(0.64, 0.64, 0.64),
      }
    );
  }

  addFooter(page: PDFPage, font: PDFFont, details: any) {
    page.drawImage(details?.image, {
      width: 55,
      height: 55,
      x: 503,
      y: 31,
    });

    page.drawText(
      'This document was signed with SignSecure™ Scan the QR code to verify document',
      {
        font: font,
        lineHeight: 12,
        size: 9,
        maxWidth: 200,
        x: 62,
        y: 50,
        color: rgb(0.4, 0.4, 0.4),
      }
    );
  }

  createManifestInfo(page: PDFPage, font: PDFFont, details: any) {
    const rectLocation = { x: 62, y: 788 };
    // create rectangle
    page.drawRectangle({
      ...rectLocation,
      y: rectLocation.y - 102.5,
      width: 488,
      height: 116,
      color: rgb(0.5, 0.36, 0.6),
      opacity: 0.2,
      borderOpacity: 0,
    });

    const marginTop = 18;
    const marginLeft = 18;
    // create starting text lines
    page.drawText('Created:\n' + 'By:\n' + 'Status:\n' + 'Transaction ID:\n', {
      size: 10,
      lineHeight: 20,
      font,
      color: rgb(0.28, 0.28, 0.28),
      x: rectLocation.x + marginLeft,
      y: rectLocation.y - marginTop, // the page starts 0 at the bottom
    });

    const contentMarginLeft = 144;
    page.drawText(
      `${details?.created ?? '2023-04-01'}\n` +
        `${details?.createdByName ?? 'Juan Dela Cruz'} (${
          details?.createdByEmail ?? 'jdelacruz@email.com'
        })\n` +
        `Completed\n` +
        `${details?.id ?? 'OGOTKBKDCRMSCKFVMSL_564243'}\n`,
      {
        size: 10,
        lineHeight: 20,
        font,
        color: rgb(0.28, 0.28, 0.28),
        x: rectLocation.x + contentMarginLeft,
        y: rectLocation.y - marginTop, // the page starts 0 at the bottom
      }
    );
  }

  async addSignatureData(
    event: any,
    index: number,
    page: PDFPage,
    font: PDFFont,
    boldFont: PDFFont,
    y: number = 642
  ) {
    const arrayBuffer = await fetch(
      `../../../assets/images/manifest/${event.event}.png`
    ).then(res => res.arrayBuffer());
    const image = await page.doc.embedPng(arrayBuffer);

    const marginLeft = 35;
    const space = 62 * index;
    page.drawImage(image, { x: 62, y: y - 5 - space, width: 16, height: 16 });

    page.drawText(
      `Document ${event.event} ${event.user ? `by ${event.user}` : ''}`,
      {
        x: 62 + marginLeft,
        y: y - space,
        size: 12,
        lineHeight: 16,
        font: font,
        color: rgb(0.28, 0.28, 0.28),
      }
    );

    page.drawText(`${moment(event.time).format('MM/DD/YYYY - HH:mm:ss a')}`, {
      x: 62 + marginLeft,
      y: y - 20 - space,
      size: 12,
      lineHeight: 14,
      color: rgb(0.28, 0.28, 0.28),
      font: font,
    });
  }

  async checkIfAllSignatures(
    pdf: PDFDocument,
    data: any,
    qrService: QrService,
    userService: UsersService,
    fileService: FilesService
  ) {
    const newData = (await lastValueFrom(fileService.getFile(data.id)))?.data;

    const everyoneDone = newData?.workflow?.parties?.every(
      (party: any) => party?.role === 'COPY' || !!party?.executedFormatted
    );
    console.log({ data, everyoneDone, newData });
    if (everyoneDone) {
      await this.addQRCode(pdf, newData, qrService);
      const documentData = await pdf.save();
      const document = this.bytesToFile(documentData, 'document.pdf');
      const manifest = await this.createManifest(
        pdf,
        newData,
        qrService,
        userService
      );
      this.signPdfData = await pdf.save();
      const formatData = {
        id: data.id,
        file: this.bytesToFile(
          this.signPdfData,
          fileService.changeExtension(newData.name, 'pdf')
        ),
        document,
        manifest,
      };

      this.doneSignature();
      return this.documentDone(formatData);
    }
    return;
  }

  async addQRCode(pdf: PDFDocument, data: any, qrService: QrService) {
    const qrPosition = data.workflow?.qrPosition ?? 'bottom-right';
    let text = data.id;
    text = qrService.encrypt(text);
    const qrCode = await qrService.getQRCode(text);
    const fontBytes = await fetch('../../../assets/fonts/DMSans.ttf').then(
      res => res.arrayBuffer()
    );
    const pages = pdf.getPages();
    const pagesLength = pages?.length ?? 0;
    for (let page = 0; page < pagesLength; page++) {
      const image = await pages[page].doc.embedPng(qrCode);
      const size = pages[page].getSize();
      const pos = qrService.getQRCodeConfig(
        qrPosition,
        size.height,
        size.width
      );
      pages[page].drawImage(image, { ...pos, width: 55, height: 55 });
    }
  }

  async getSavedWorkflowData() {
    console.log('retrieve data ', localStorage.getItem('workflow'));

    const data = !!localStorage.getItem('workflow')
      ? JSON.parse(localStorage.getItem('workflow') ?? '')
      : '';

    if (!!!data) return data;

    if (data.name === 'Travel Authority.pdf') {
      this._loader.show();
      data.travelAuth = await this._safe.generateTravelAuthPdf(
        data.partiesLength
      );
      this._loader.hide();
    }
    return data;
  }

  saveWorkflowData(partiesLength: any = undefined) {
    console.log('saveworkflowdata', this.worflowData.value);
    localStorage.setItem(
      'workflow',
      JSON.stringify({ ...this.worflowData.value, partiesLength })
    );
  }

  clearSavedData() {
    localStorage.removeItem('workflow');
  }

  saveFileData(data: any) {
    const fileInput = document.getElementById('tempFile') ?? ({} as any);

    // Create a new File object
    const myFile = new File([data], 'temp.pdf');

    // Now let's create a FileList
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(myFile);
    fileInput.files = dataTransfer?.files;

    // Help Safari out
    if (fileInput?.webkitEntries.length) {
      fileInput.dataset.file = `${dataTransfer.files[0].name}`;
    }
  }

  async fileToArrayBuffer(file: File) {
    return new Uint8Array((await this.readFile(file)) as []);
  }

  async readFile(file: any) {
    return new Promise((resolve, reject) => {
      // Create file reader
      let reader = new FileReader();

      // Register event listeners
      reader.addEventListener('loadend', e => resolve(e.target?.result ?? []));
      reader.addEventListener('error', reject);

      // Read file
      reader.readAsArrayBuffer(file);
    });
  }

  getUntransformedPosition(
    transformedX: number,
    transformedY: number,
    transform: number[]
  ): { x: number; y: number } {
    // Extract the transformation matrix values
    const [a, b, c, d, e, f] = transform;

    // Calculate the inverse scaling factors
    const invScaleX = 1 / a;
    const invScaleY = 1 / d;

    // Since b and c are 0 in your matrix, we don't need to account for skewing
    // Calculate the untransformed coordinates
    const untransformedX = invScaleX * transformedX;
    const untransformedY = invScaleY * (transformedY - f);

    return { x: untransformedX, y: untransformedY };
  }
}
