<div class="header-container">
  <h4 class="title">How can we help you?</h4>
</div>
<div class="support-container">
  <div class="sub-header">
    Have any questions? Please fill out the form below to connect with us, and
    we will get back to you at the soonest possible time.
  </div>
  <div class="flex">
    <mat-label class="label">Email*</mat-label>
  </div>
  <div class="flex">
    <mat-form-field class="input-form" floatLabel="never" appearance="fill">
      <input
        matInput
        type="email"
        required
        [(ngModel)]="email"
        placeholder="{{ email }}"
        [disabled]="true"
      />
    </mat-form-field>
  </div>
  <div class="flex">
    <mat-label class="label">Name*</mat-label>
  </div>
  <div class="flex">
    <mat-form-field class="input-form" floatLabel="never" appearance="fill">
      <input
        matInput
        required
        [(ngModel)]="name"
        placeholder="{{ name }}"
        [disabled]="true"
      />
    </mat-form-field>
  </div>
  <div class="flex">
    <mat-label class="label">Message*</mat-label>
  </div>
  <div class="flex">
    <mat-form-field class="text-form" floatLabel="never" appearance="fill">
      <textarea
        class="textarea-resize"
        matInput
        [(ngModel)]="message"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="btnGrp">
    <div class="uploader">
      <div class="upload-label">
        <span>Upload attachment/s:</span>
        <span class="upload-label-description">Max size: 25mb</span>
      </div>
      <button class="upload-file" (click)="uploadAttachment()">
        Choose File/s
      </button>
      <div class="file-upload-label {{ !!fileNames.length ? '' : 'empty' }}">
        <div *ngIf="!!!fileNames.length">No file chosen</div>
        <div class="attached-files" *ngIf="!!fileNames.length">
          <span>Attached files:</span>
          <div
            *ngFor="let fileName of fileNames; let i = index"
            class="attached-file-container"
          >
            <span class="attached-file">{{ fileName }}</span>

            <img
              src="../../../../assets/images/delete-icon.svg"
              alt=""
              class="close-button"
              (click)="removeAttachment(i)"
            />
          </div>
        </div>
      </div>
    </div>

    <button class="submit" (click)="submitConcern()">Submit</button>
  </div>
</div>
