import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileActionService {
  file: any;
  pdfSrc: any = '';
  breadCrumbs: any = '';
  selectedTab: number = 0;


  private isModify = new BehaviorSubject<boolean>(false);
  isModify$ = this.isModify.asObservable();
  isModifyValue = this.isModify.getValue();

  private isViewFile = new BehaviorSubject<boolean>(false);
  isViewFile$ = this.isViewFile.asObservable();
  isViewFileValue = this.isViewFile.getValue();

  setIsModify(data: boolean) {
    this.isModify.next(data);
  }

  setIsViewFile(data: boolean) {
    this.isViewFile.next(data);
  }
}
