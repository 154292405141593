<div class="file-view-container">
  <div class="header-container">
    <h4 class="title">{{ title }}</h4>
  </div>
  <div class="file-navigator">
    <app-breadcrumb
      [data]="breadcrumbs"
      (onBack)="onBreadcrumbBack($event)"></app-breadcrumb>
  </div>
  <div
    [ngClass]="{
      'file-container': file.type == 'file',
      'folder-container': file.type == 'folder'
    }">
    <div class="title-container">
      <h4 class="file-view-title">
        <img [src]="getObjectImage(file.type)" />{{ file.name }}
      </h4>

      <mat-checkbox
        class="publish"
        *ngIf="file.type == 'file' && isPublishable"
        [checked]="isPublished"
        (change)="publishFile()"
        >Publish</mat-checkbox
      >
    </div>
    <div class="flex">
      <mat-label class="label">Name</mat-label>
    </div>
    <div class="flex">
      <mat-form-field class="input-form" floatLabel="never" appearance="fill">
        <input matInput [(ngModel)]="name" type="text" placeholder="Name" />
      </mat-form-field>
    </div>
    <div class="flex padding-top-20">
      <mat-label class="label padding-top-20">Tags</mat-label>
    </div>
    <div class="flex">
      <mat-form-field
        class="label input-form input-tags"
        floatLabel="never"
        appearance="fill">
        <div class="members width-100 height-fit-content">
          <mat-chip-list #chipList aria-label="Tags" [disabled]="isPublished">
            <mat-chip class="member-tag" *ngFor="let tag of tags">
              {{ tag.name || tag }}
            </mat-chip>
            <input
              placeholder="test"
              [matChipInputFor]="chipList"
              (keydown.enter)="addTags(tag)"
              #tag />
          </mat-chip-list>
        </div>
      </mat-form-field>
    </div>

    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>Date</mat-label>
        <mat-form-field
          appearance="fill"
          floatLabel="never"
          class="input-form-date">
          <input
            matInput
            [(ngModel)]="dateVal"
            [matDatepicker]="date"
            [min]="todayDate"
            disabled />
          <mat-datepicker-toggle matSuffix [for]="date">
            <mat-icon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-label>Due Date</mat-label>
        <mat-form-field
          appearance="fill"
          floatLabel="never"
          class="input-form-date">
          <input
            matInput
            [(ngModel)]="dueDateVal"
            [min]="todayDate"
            [matDatepicker]="dueDate"
            [disabled]="isPublished" />
          <mat-datepicker-toggle matSuffix [for]="dueDate">
            <mat-icon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div class="form-field-pair label padding-top-20">
      <div class="input-group">
        <mat-label>Document Number</mat-label>
        <mat-form-field
          class="input-form-date"
          floatLabel="never"
          appearance="fill">
          <input
            type="text"
            [(ngModel)]="documentNumber"
            matInput
            class="textfieldReg"
            [disabled]="isPublished" />
        </mat-form-field>
      </div>
      <div class="input-group">
        <mat-label class="label">Details</mat-label>
        <mat-form-field
          class="input-form-date"
          floatLabel="never"
          appearance="fill">
          <input
            type="text"
            [(ngModel)]="docDetails"
            matInput
            class="textfieldReg"
            [disabled]="isPublished" />
        </mat-form-field>
      </div>
    </div>

    <div class="padding-top-20" *ngIf="file.type === 'file'">
      <div>
        <mat-label class="label">Shared To</mat-label>
      </div>
      <mat-form-field
        class="label input-form-shared"
        floatLabel="never"
        appearance="fill">
        <div class="members width-100 height-fit-content">
          <mat-chip-list
            #permission
            aria-label="User Groups"
            [disabled]="isPublished">
            <mat-chip class="member-tag" *ngFor="let usergroup of userGroups">{{
              usergroup.group_name || usergroup.groupName || usergroup.name
            }}</mat-chip>
            <mat-chip class="member-tag" *ngFor="let usergroup of userList">{{
              usergroup.name
            }}</mat-chip>
            <input placeholder="" [matChipInputFor]="permission" disabled />
          </mat-chip-list>
        </div>
      </mat-form-field>
      <button
        [ngClass]="isPublished ? 'edit-disabled' : 'edit'"
        (click)="showModal()"
        [disabled]="isPublished">
        Edit sharing permissions
      </button>
    </div>

    <div class="label" *ngIf="file.type == 'file'">
      <hr />
      <button class="btnOcr" (click)="runOcr('run-ocr')">Run OCR</button>
    </div>

    <div *ngIf="ocrResult" class="ocr-preview">
      <mat-form-field class="text-form" floatLabel="never" appearance="fill">
        <textarea
          class="textarea-resize"
          matInput
          [(ngModel)]="ocrResult"
          disabled="true"></textarea>
      </mat-form-field>
      <span
        class="flex editOcr {{ isPublished ? 'disable' : '' }}"
        (click)="runOcr('edit-ocr')"
        >Edit OCR</span
      >
    </div>

    <div
      [ngClass]="{
        fileBtnGrp: file.type == 'file',
        btnGrp: file.type == 'folder'
      }">
      <button class="cancel" (click)="goBack()">Cancel</button>
      <button class="create" (click)="updateDirectory()">Save</button>
    </div>
  </div>
  <div style="padding-bottom: 20px"></div>
</div>
