import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { ModalComponent } from '../modal/modal.component';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public-view',
  templateUrl: './public-view.component.html',
  styleUrls: ['./public-view.component.sass']
})
export class PublicViewComponent implements OnInit {

  pdfSrc: string = "";
  zoom: number = 1;
  totalPages: number = 0;
  pageNumber: number = 1;
  showSignatureButton: boolean = false;
  showSignSecureButton: boolean = true;
  showShareButton: boolean = false;
  isPhoneWidth: boolean = false;

  file: any
  constructor(
    public dialog: MatDialog,
    private _fileActionService: FileActionService,
    private _fileService: FilesService,
    private _signsecure: SignSecureService,
    private _nav: NavigationService
  ) { }

  ngOnInit() {
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }

    this.file = this._signsecure.publicFile

    if (!environment.local) {
      this.pdfSrc = `./assets/${this.file.path || ""
        }`;
    } else {
      this.pdfSrc = "./assets/dms-documents/pdf-test.pdf";
    }

  }

  checkFile() { }

  zoomLevel(level: string) {
    switch (level) {
      case "in":
        if (this.zoom <= 2.75) {
          this.zoom = +this.zoom + +0.25;
        }
        break;
      case "out":
        if (this.zoom >= 0.5) {
          this.zoom = this.zoom - 0.25;
        }
        break;
      default:
        break;
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    console.log(pdf);
    this.totalPages = pdf.numPages;
  }

  pageNavi(navi: string) {
    switch (navi) {
      case "up":
        if (this.pageNumber > 1) this.pageNumber = this.pageNumber - 1;
        break;
      case "down":
        if (this.pageNumber < this.totalPages)
          this.pageNumber = this.pageNumber + 1;
        break;
      default:
        break;
    }
  }

  goBack() {
    this._signsecure.hidePublicView()
  }

  download(name?: string) {
    if (name) {
      this._fileService.downloadFile(name, this.pdfSrc);
    } else {
      this._fileService.downloadFile(this.file.name, this.pdfSrc);
    }
  }
}
