import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.sass'],
})
export class SearchResultsComponent implements OnInit {
  @Input() isSearch: boolean = false;
  @Input() searchResult: any;
  count: number = 12;
  keyword: string = 'Senate of the Phils';
  prevPosition: string = 'Dashboard';
  hasCheckBox: boolean = environment.flag.multiFileSelect;
  filesCols: string[] = [];
  filesData: Object[] = [];
  constructor(
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _navigationService: NavigationService
  ) {
    this.prevPosition = this._navigationService.getLastLocation();
  }

  ngOnInit(): void {
    this.filesCols = this._fileService.filesCols;
    this._fileService.searchResults$.subscribe((data) => {
      console.log({ data })
      this.filesData = data.data;
      this.count = this.filesData.length;
      this.keyword = data.searchQuery;
    });
  }
  backMenu() {
    this._navigationService.setSearchView(false);
  }

  openPdf(data: any) {
    if (data.file_type === 'folder' || data.file_type === 'back') return;

    let url =
      data?.action?.choices?.download?.path ||
      data?.actions?.choices?.download?.path ||
      data?.action?.download?.path ||
      data?.actions?.download?.path;

    url = `./assets/${url}`;

    this._fileActionService.pdfSrc = url;
    this._fileActionService.file = data;
    this._fileActionService.setIsViewFile(true);
  }
}
