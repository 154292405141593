import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FileTableModel } from 'src/app/@shared/models/file-table.model';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-recent-documents',
  templateUrl: './recent-documents.component.html',
  styleUrls: ['./recent-documents.component.sass'],
})
export class RecentDocumentsComponent implements OnInit, OnDestroy {
  recentDocmentTitle: string = 'Recent Documents';
  recentDocuemtCols: string[] = ['name', 'tags', 'date', 'actions'];
  recentDocumentData: any = this._fileService.recentfiles$;
  private _subs = new Subscription();
  isPhoneWidth: boolean = false;
  constructor(
    private dialog: MatDialog,
    private _fileService: FilesService,
    private _fileActionService: FileActionService
  ) { }

  ngOnInit(): void {
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }
    this._subs.add(
      this._fileService.getRecentFiles().subscribe(({ data }) => {
        this._fileService.setRecentFiles(
          this._fileService.formatRecentFile(data)
        );
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  recentDocumentModal(data: any) {
    const { data: mData } = data;
    const dialogRef = mData.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: mData.title,
        fileId: mData.fileId,
        data: mData.data,
        fileType: mData.type,
      },
    });

    dialogRef?.afterClosed()?.subscribe((data: any) => { });
  }

  openPdf(data: FileTableModel) {
    if (data.file_type === 'folder' || data.file_type === 'back') {
      return;
    }

    let url =
      data?.actions?.choices?.download?.path ||
      data?.actions?.download?.path ||
      data?.actions?.download_normal?.path;

    if (
      data.fileExtension == 'docx' ||
      data.fileExtension == 'doc' ||
      data.fileExtension == 'xls' ||
      data.fileExtension == 'xlsx' ||
      data.fileExtension == 'ppt' ||
      data.fileExtension == 'pptx'
    ) {
      let office = '';
      if (data.fileExtension == 'docx' || data.fileExtension == 'doc') {
        office = 'ms-word';
      }
      if (data.fileExtension == 'xls' || data.fileExtension == 'xlsx') {
        office = 'ms-excel';
      }
      if (data.fileExtension == 'ppt' || data.fileExtension == 'pptx') {
        office = 'ms-powerpoint';
      }
      window.location.assign(
        office + ':ofv|u|' + window.location.origin + '/assets/' + url
      );
      return;
    }

    url = `../../../assets/${url}`;
    this._fileActionService.pdfSrc = url;
    this._fileActionService.file = data;
    this._fileActionService.setIsViewFile(true);
  }
}
