import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { LoaderService } from 'src/app/@shared/services/loader.service';
import { UserGroupService } from 'src/app/@shared/services/user-group.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recent-doc-info',
  templateUrl: './recent-doc-info.component.html',
  styleUrls: ['./recent-doc-info.component.sass'],
})
export class RecentDocInfoComponent implements OnInit {
  @Input() data: ModalData = <ModalData>{};
  @Output() closeModalEvent = new EventEmitter<any>();

  pdfSrc: string = '../../../assets/dms-documents/pdf-test.pdf';
  datum: any;
  userGroups: any[] = [];
  users: any[] = [];
  canChangePermission: boolean = false;
  constructor(
    private _usergroupsService: UserGroupService,
    private _loaderService: LoaderService,
    private _userService: UsersService,
    public dialog: MatDialog
  ) {
    this._loaderService.show();
  }

  ngOnInit(): void {
    const { data } = this.data;
    this.datum = data;
    this.canChangePermission = this.datum.isViewerOnly;
    this._userService
      .getUserInfo(this.datum.createdBy)
      .subscribe(({ data }) => {
        this.datum.owner = `${data.givenName} ${data.lastName}`;
      });

    this._userService.getUserInfo(this.datum.openedBy).subscribe(({ data }) => {
      this.datum.openedByName = `${data.givenName} ${data.lastName}`;
    });

    this._userService
      .getUserInfo(this.datum.updatedBy)
      .subscribe(({ data }) => {
        this.datum.updatedByName = `${data.givenName} ${data.lastName}`;
      });

    if (!environment.local) {
      this.pdfSrc = `./assets/${data.path}`;
    } else {
      if (this.datum.fileExtension != 'pdf') {
        this.pdfSrc = '../../../assets/sample-image.png';
      }
    }

    if (this.datum.type === 'folder') {
      this.pdfSrc = '../../../assets/images/folder-info.svg';
    }

    this._usergroupsService.userGroups$.subscribe((data) => {
      this.userGroups = this.datum.userGroups.map((d: any) => d.name);
      this.users = this.datum.users;

      this._loaderService.hide();
    });
  }

  openSharingPermission() {
    const emitData = {
      action: 'share-permission',
      data: {
        title: this.datum.name,
        fileId: this.datum.id,
        type: this.datum.type,
        data: this.datum,
        dialog: this.dialog,
      },
    };
    this.closeModalEvent.emit(emitData);
  }

  close() {
    this.closeModalEvent.emit();
  }
}
