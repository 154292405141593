<ng-container>
  <form class="delete-scid">
    <!-- <h4 class="title">Delete SC ID</h4> -->
    <div class="content">
      <img
        class="logo"
        src="'../../../../../../assets/images/decline-document.svg"
        alt="logo" />
      <div class="input-group">
        <mat-label class="label-text"
          >Are you sure you want to delete this ID?
        </mat-label>
      </div>
      <div class="actions">
        <button class="cancel" (click)="closeModal()">Cancel</button>
        <button class="create" (click)="deleteMessage()">Delete</button>
      </div>
    </div>
  </form>
</ng-container>
