import { throwDialogContentAlreadyAttachedError } from '@angular/cdk/dialog';
import { HttpBackend, HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginResponseModel } from '../models/login-response.model';
import { UserGroup, UserResponseModel } from '../models/user-response.model';
import { FileActionService } from './file-action.service';
import { NavigationService } from './navigation.service';
import { InboxService } from './inbox.service';
import { UserUpdateModel } from '../models/user-update.model';
import { ResetPasswordModel } from '../models/reset-password.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private role = new BehaviorSubject('');
  role$ = this.role.asObservable();
  private readonly baseUrl = environment.apiConfig.baseUrl;
  private readonly authUrl = environment.apiConfig.auth.url;
  private readonly authenticateUserURL = environment.integration.authenticateUser;
  private isSessionActive = new BehaviorSubject(false);
  isSessionActive$: Observable<boolean> = this.isSessionActive.asObservable();
  userRole: string = '';
  token: string = '';
  userId: string = '';
  userGroups: UserGroup[] = [];
  name: string = '';
  email: string = '';
  designation: string = '';
  isAuthenticated: boolean = false;
  constructor(
    private _httpClient: HttpClient,
    private _httpClientNoIntereceptor: HttpClient,
    private _handler: HttpBackend,
    private _jwtService: JwtHelperService,
    private _fileActionService: FileActionService,
    private _navigationService: NavigationService,
    private _inboxService: InboxService
  ) {
    this._httpClientNoIntereceptor = new HttpClient(this._handler);
  }

  login(email: string, password: string): Observable<any> {
    return this._httpClient.post<LoginResponseModel>(
      `${this.baseUrl}${this.authUrl}/login`,
      { email, password }
    );
  }
  forgotPassowrd(email: string) {
    return this._httpClient.post(
      `${this.baseUrl}${this.authUrl}/forgot-password`,
      { email }
    );
  }

  authenticateSignSecureUser(userid: string, token: string) {
    let params = new HttpParams();
    params = params.append('userid', userid);
    params = params.append('token', token);
    return this._httpClient.get<any>(`${this.authenticateUserURL}`, { params });
  }


  authenticateUser(token: string) {
    this.token = token;
    console.log()
    let tokenPayload: UserResponseModel | null;
    tokenPayload = this._jwtService.decodeToken(token);

    this.userId = tokenPayload?.user.id ?? '';
    this.userRole = tokenPayload?.user?.role ?? '';
    this.userGroups = tokenPayload?.user.userGroup ?? [];
    this.email = tokenPayload?.user.email ?? '';
    this.name = tokenPayload?.user.givenName + ' ' + tokenPayload?.user.lastName ?? '';
    this.designation = tokenPayload?.user.position ?? ''

    localStorage.setItem('userID', this.userId)
    localStorage.setItem('token', token)
    this.setRole(this.userRole)
    // this.authenticateSignSecureUser(this.userId, this.token).subscribe((res) => {
    // });
  }

  getUserDataFromToken(token: string) {
    return this._jwtService.decodeToken(token);
  }

  setRole(data: string) {
    this.role.next(data);
  }

  rememberMe(username: string, password: string, remember: boolean) {
    if (remember == false) {
      localStorage.removeItem('username');
      localStorage.removeItem('rememeber');
    } else {
      localStorage.setItem('username', username);
      localStorage.setItem('remember', String(remember));
    }
  }
  refreshToken() {
    let accessToken = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    let userId = this.userId;
    return this._httpClient.post(
      `${this.baseUrl}${this.authUrl}/refresh-token`,
      {
        userId,
        accessToken,
        refreshToken,
      }
    );
  }

  isLogin() {
    const token = localStorage.getItem('token');
    if (!token) return false;
    this.authenticateUser(token as string);
    this.setRole(this.userRole);
    return true;
  }

  logout() {
    let userId = this.userId;
    if (localStorage.getItem('remember') == 'true') {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    } else localStorage.clear();
    this.token = '';
    this._inboxService.setViewMessage(false);
    this._fileActionService.setIsModify(false);
    this._navigationService.initLocation = 'report';
    this._navigationService.setLocation('report');
    this._fileActionService.setIsViewFile(false);
    localStorage.setItem('sTime', "0");
    this._navigationService.emptyParams()
    return this._httpClient.post(`${this.baseUrl}${this.authUrl}/logout`, {
      userId,
    });
  }
  isAuthorized() {
    let userId = localStorage.getItem('userID');
    if (userId) {
      return this._httpClient.post<any>(
        `${this.baseUrl}${this.authUrl}/is-authorized/${userId}`,
        {}
      );
    } else {
      return of(false)
    }

  }
  loginAzureAD(
    givenName: string,
    lastName: string,
    email: string,
    password: string,
    position: string
  ): Observable<any> {
    return this._httpClient.post<LoginResponseModel>(
      `${this.baseUrl}${this.authUrl}/azureAD`,
      { givenName, lastName, email, password, position }
    );
  }

  generateToken(email: string | null) {
    return this._httpClient.get(
      `${this.baseUrl}/auth/custom-token?email=${email}`
    );
  }

  updateUserPassword(user: UserUpdateModel) {
    return this._httpClient.post<any>(
      `${this.baseUrl}${this.authUrl}/change-password`,
      {
        ...user,
      }
    );
  }

  resetPassword(user: ResetPasswordModel) {
    return this._httpClient.put<any>(
      `${this.baseUrl}${this.authUrl}/reset-password`,
      {
        ...user,
      }
    );
  }
}
