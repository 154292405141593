import { Component, OnInit } from '@angular/core';
import { SafeformService } from 'src/app/@shared/services/safeform.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { LoaderService } from 'src/app/@shared/services/loader.service';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.sass'],
})
export class FormTemplateComponent implements OnInit {
  templates: any;
  selected: string = this._navigation.initLocation;

  constructor(
    private _safeFormService: SafeformService,
    private _navigation: NavigationService,
    private _auth: AuthenticationService,
    private _loader: LoaderService,
  ) {
    this._navigation.location$.subscribe(
      location => (this.selected = location)
    );
  }

  ngOnInit(): void {
    this._navigation.initLocation = this.selected;
    this._safeFormService.getSafeForms().subscribe(res => {
      const forms = res['data'] ?? [];
      console.log({forms})
      if (this._auth.userRole === 'admin') {
        this._safeFormService.templates = forms.filter(
          (template: any) =>
            template.documentTitle == 'Supreme Court ID' ||
            template.documentTitle == 'Certificate of Admission to the Bar' ||
            template.documentTitle == 'Travel Authority Form'
        );
      } else {
        this._safeFormService.templates = forms.filter(
          (template: any) => template.documentTitle == 'Travel Authority Form'
        );
      }

      this.templates = this._safeFormService.templates;

      this._safeFormService.forEditCertificateTemplate = forms.filter(
        (form: any) =>
          form.documentTitle == 'Supreme Court ID' ||
          form.documentTitle.toLowerCase().includes('edit')
      );

      this._loader.hideNow()
    });
  }

  useForm(templateTitle: string, formId: string, html: any) {
    this._safeFormService.formId = formId;
    this._safeFormService.formTemplateTitle = templateTitle;
    this._safeFormService.formTemplate = html;
    switch (templateTitle) {
      case this._safeFormService.certificateTitle:
        this._safeFormService.formTemplate =
          this._safeFormService.forEditCertificateTemplate.filter(
            (template: any) =>
              template.documentTitle ===
              this._safeFormService.editCertificateTitle
          )[0].html;
        this._navigation.updateLocation('certificates-table');
        break;
      case this._safeFormService.scId:
        this._navigation.updateLocation('sc-id-table');
        break;
      default:
        this._navigation.updateLocation('safe-form');
        break;
    }
  }
}
