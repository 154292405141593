import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  bufferCount,
  catchError,
  concatMap,
  delay,
  finalize,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  tap,
} from 'rxjs';
import {
  EMAIL_FORMAT,
  NAME_FORMAT,
} from 'src/app/@shared/constants/regex-paterns';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import * as XLSX from 'xlsx';
import { BulkFormsService } from 'src/app/@shared/services/bulk-forms.service';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { UserResponseModel } from 'src/app/@shared/models/user-response.model';
import moment from 'moment';
import { FileTableModel } from 'src/app/@shared/models/file-table.model';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-bulk-id-template',
  templateUrl: './bulk-id-template.component.html',
  styleUrls: ['./bulk-id-template.component.sass'],
})
export class BulkIdTemplateComponent implements OnInit {
  columns: string[] = [
    'batch name',
    'name of uploader',
    'no of id',
    'upload date',
  ];
  private bulkId = new BehaviorSubject<any>([]);
  bulkId$ = this.bulkId.asObservable();
  excelToJSON: any = [];
  //myFormGroup: FormGroup = null;
  formTemplate: any;
  template: string = ``;
  orientation: string = ``;
  blankFileUrl = '';
  source = '';
  documentTitle = '';
  totalPage: number = 1;
  totalStep: number = 1;
  isLoading = false;
  isFileEmpty: boolean = false;
  isClerkSigEmpty: boolean = false;
  isChiefSigEmpty: boolean = false;
  batchId: any;
  dialogRef?: MatDialogRef<any>;
  public author: any;

  total: number = 0;
  progress = 0;
  totalDataToUpload = 0;
  withQR: boolean = false;
  teamId: any;
  latestTeam: any;
  selectedTeam: any;
  teamOwnerId: any;
  teamStorage: any;
  showMyTeam = false;
  isFile: boolean = true;
  isFullname: boolean = true;
  isFileReady: boolean = true;
  isPhilHealth: boolean = false;
  isBirthDay: boolean = true;
  isContact: boolean = true;
  isPagibig: boolean = true;
  isEmail: boolean = true;
  isEmpNum: boolean = true;
  isStation: boolean = true;

  isEmailIndex: any = [];
  isFullnameIndex: any = [];
  isBirthDayIndex: any = [];
  isEmpNumIndex: any = [];
  isStationIndex: any = [];
  uploadFileName: any;

  fileHeader: any = [
    'name',
    'empnum',
    'position',
    'station',
    'tin',
    'gsis',
    'phealth',
    'PAGIBIG',
    'birthday',
    'BLOOD TYPE',
    'Comorbidity',
    'ALLERGY',
    'HEIGHT',
    'WEIGHT',
    'PERSON TO NOTIFY',
    'CONTACT',
    'EMAIL ADD', 'Court',
  ];
  checkFormat: boolean = false;
  teamSub: any;
  errorRowEmail: any;
  content: { [key: string]: any } = {};

  fileName: any;
  isSharedToTeam = false;
  userData: any;
  documentData = {
    title: '',
    type: 'application/pdf',
    ext: 'pdf',
  };

  BulkIdData: any = [];
  message: string = '';
  private ngUnsubscribe = new Subject<void>();

  @ViewChild('formFile', { static: false })
  fileInput!: ElementRef;

  constructor(
    public dialog: MatDialog,
    public bulkFormService: BulkFormsService,
    private authService: AuthenticationService,
    private _navigation: NavigationService
  ) { }

  onFileChange(event: any) {
    try {
      this.progress = 0;
      this.totalDataToUpload = 0;
      this.isFullname = true;
      //this.isBirthDay = true;
      this.isEmail = true;
      this.isEmpNum = true;
      // this.isStation = true;
      this.isFileReady = true;
      this.checkFormat = true;
      const reader: FileReader = new FileReader();
      const target: DataTransfer = <DataTransfer>event.target;
      this.uploadFileName = target.files[0].name;
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        for (var i = 0; i < wb.SheetNames.length; ++i) {
          const wsname: string = wb.SheetNames[i];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { raw: false, defval: '' }); // to get 2d array pass 2nd parameter as object {header: 1}
          this.excelToJSON[`sheet${i + 1}`] = data;
          console.log('json', data);
          let fileUploadHeader = Object.keys(this.excelToJSON[`sheet1`][0]);
          this.checkFormat = this.fileHeader.every(function (
            element: any,
            index: any
          ) {
            return element === fileUploadHeader[index];
          });
          console.log(fileUploadHeader);
          this.isFileReady = this.checkFormat;
          this.isFileEmpty = this.checkFormat;
        }

        if (!this.isFileEmpty) {
          this.resetInput()
        }



        console.log('finalOUT', this.excelToJSON);
      };
    } catch (error) {
      this.isFileEmpty = false;
      this.resetInput()
    }
  }
  initialize() {
    let group = {};
  }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    let tokenPayload: UserResponseModel;
    if (token != null) {
      tokenPayload = this.authService.getUserDataFromToken(token);
      this.userData = tokenPayload.user;
    }
    this.getBulkId();

    console.log('UserData', this.userData);
  }
  async bulkRegister() {
    this.isFileReady = true;
    // this.firebaseService.getUserTeamLatest().subscribe(res =>{
    //   this.teamId = res[0].teamId
    // })
    this.batchId = crypto.randomUUID(); //this.afs.createId()
    this.isLoading = true;
    const jsonData = this.excelToJSON['sheet1'];
    console.log('jsonData', jsonData);
    this.totalDataToUpload = jsonData.length;
    // this.dialogRef = this.dialog.open(ModalComponent, {
    //   minWidth: 600,
    //   maxWidth: '95vw',
    //   minHeight: 500,
    //   data: {
    //     type: 'creating-bulk-id',
    //     totalDataToUpload: jsonData.length,
    //     inprogressUpload: this.progress,
    //     user: 'test'//JSON.parse(localStorage.getItem('user'))
    //   },
    //   autoFocus: false,
    //   disableClose: true
    // });
    // this.dialogRef.afterClosed().subscribe(result => {
    //   console.log(result);
    //   for (let index = 0; index < this.totalDataToUpload; index++) {
    //   }

    //  });
    this.isEmailIndex = [];
    this.isFullnameIndex = [];
    this.isStationIndex = [];
    this.isEmpNumIndex = [];
    //this.isBirthDayIndex = [];
    let errCounter: number = 0;
    let checkData = jsonData.map((validate: any, index: number) => {
      if (this.isEmpNum == true) {
        if (validate['empnum'] == undefined || validate['empnum'] == '') {
          errCounter = index + 2;
          this.isEmpNumIndex.push(errCounter);
          this.isEmpNum = false;
          console.log('this.isEmpNumIndex', this.isEmpNumIndex);
        } else {
          this.isEmpNum = true;
          this.resetInput()
        }
      }
      // if (this.isStation == true) {
      //   if (validate['station'] == undefined || validate['station'] == '') {
      //     errCounter = index + 2;
      //     this.isStationIndex.push(errCounter);
      //     this.isStation = false;
      //     console.log('this.isStationIndex', this.isStationIndex);
      //   } else {
      //     this.isStation = true;
      //     this.resetInput()
      //   }
      // }
      if (this.isEmail == true) {
        if (EMAIL_FORMAT.test(validate['EMAIL ADD']) == false) {
          errCounter = index + 2;
          console.log('index', errCounter);
          this.isEmailIndex.push(errCounter);
          this.isEmail = false;
        } else {
          this.isEmail = true;
          this.resetInput()
        }
      }
      if (this.isFullname == true) {
        if (validate['name'] == undefined || validate['name'] == '') {
          errCounter = index + 2;
          this.isFullnameIndex.push(errCounter);
          this.isFullname = false;
        } else {
          if (NAME_FORMAT.test(validate['name']) == false) {
            errCounter = index + 2;
            this.isFullnameIndex.push(errCounter);
            this.isFullname = false;
          } else {
            this.isFullname = true;
            this.resetInput()
          }
        }
      }
    });
    console.log('this.isEmailIndex', this.isEmailIndex);
    if (
      this.isFullname == false ||
      this.isEmail == false ||
      this.isEmpNum == false
      // || this.isStation == false
    ) {
      this.isFile = false;
    } else {
      this.isFile = true;
    }

    if (this.isFile) {
      console.log({ jsonData })
      let data = jsonData.map((x: any) => {
        // if (x['QR'] == 'X' || x['QR'] == 'x') {
        this.withQR = true;
        // } else {
        //   this.withQR = false;
        // }
        return {
          fullName: x['name'],
          position: x['position'],
          branchStation: x['station'],
          employeeNumber: x['empnum'],
          emailAddress: x['EMAIL ADD'],
          comorbidity: x['Comorbidity'],
          allergies: x['ALLERGY'],
          bloodType: x['BLOOD TYPE'],
          emergencyContactNumber: x['CONTACT'],
          personToContact: x['PERSON TO NOTIFY'],
          height: x['HEIGHT'],
          weightSupremeCourtId: x['WEIGHT'],
          weight: x['WEIGHT'],
          pagibig: x['PAGIBIG'],
          dateOfBirth: x['birthday'], //moment(x['birthday']).format("MMMM DD, YYYY").toString(),
          gsis: x['gsis'],
          tinNumber: x['tin'],
          philHealth: x['phealth'],
          QR: '',
          Court: x['Court'],
          // consentCheckBox: '____________',
          isFileGenerated: false,
          filepath: null,
        };
      });

      console.log('data', data);
      let uuid = self.crypto.randomUUID();
      const batchInfo = {
        batchId: uuid,
        batchName: this.uploadFileName,
        uploaderName: this.userData.givenName + ' ' + this.userData.lastName,
        teamId: '',
        scId: data,
        createdAt: Date.now()
        //  uploadDate: new Date()
      };
      this.bulkFormService.bulkUploadSCID(batchInfo).subscribe({
        next: value => console.log(value),
        error: err => console.log(err),
        complete: () => {
          this.isLoading = false;
          console.log('bulkFormService complete ');
          this.getBulkId();
        },
      });
    } else {
      this.isLoading = false;
      setTimeout(() => {
        if (
          this.isFullname == false ||
          this.isEmail == false ||
          // this.isStation == false ||
          this.isEmpNum == false
        ) {
          this.isFileReady = false;
          this.dialog.closeAll();
        } else {
          this.isFileReady = true;
        }
      }, 3000);
    }

    this.isLoading = false;
  }

  submitDocument(contentData: any) {
    let filePath;
    let projectId = 'asdsad';
    this.documentTitle == 'Supreme Court ID';
    let dateNow = new Date().toLocaleString('en-GB');
    let ownerName = contentData.nameSupremeCourtId
      .toString()
      .replace(/ /, '')
      .toString();
    this.fileName = ownerName.concat(dateNow).replace(/[^A-Z0-9]+/gi, '');
  }
  openPdf(data: FileTableModel) {
    console.log('OPNPDF', data);
    if (data.file_type === 'folder' || data.file_type === 'back') {
      return;
    }
  }

  getBulkId(wait: boolean = false) {
    this.bulkFormService.getAllBulkSCID().subscribe((res: any) => {
      console.log('Bulk', res);
      if (res) {
        this.setBulkId(this.formatBulkData(res.data));
      }
      console.log('this.BulkIdData', this.BulkIdData);
      this.resetInput()
    });
  }

  setText() {
    this.progress = 0;
  }
  selectedRow(event: any, data: any) {
    if (event.target.className.includes('checkbox')) {
      return;
    }

    console.log('tabledata', data);

    // if (file_type === "folder") {
    // 	if (id === "signsecure") {
    // 		this._fileService.getWorkflowData(99999).subscribe(({ data }) => {
    // 			const format = this._fileService.formatFilesAndFolderData(data, true);
    // 			this._fileService.setFilesAndFolderAsync(format);
    // 		});
    // 	} else {
    // 		console.log({ id })
    // 		this._fileService.getAllFolderFiles(id).subscribe(({ data }) => {
    // 			const format = this._fileService.formatFilesAndFolderData(data);
    // 			this._fileService.setFilesAndFolderAsync(format);
    // 		});
    // 	}
    // }
    // this.bulkFormService.bulkId = data;
    // this._navigation.addParams("id", data.id);
    localStorage.setItem('bulkId', data.id);
    this._navigation.updateLocation('bulk-id-table');
  }

  formatBulkData(data: any) {
    return data
      .map((item: any) => {
        const formattedFile = {
          id: item._id,
          scId: item.scId,
          batch_name: item.batchName || '',
          name_of_uploader: item.uploaderName || item.createdBy || '',
          no_of_id: item.scId.length || 0,
          upload_date:
            moment(item.createdAt).format('yyyy-MM-DD HH:mm:ss') || '',
        };

        return formattedFile;
      })
      .reverse();
  }

  setBulkId(data: any[]) {
    this.total = data.length || 0;
    this.bulkId.next(data);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  bulkCancel() {
    //this.router.navigate(['/dashboard/supreme-court-id']);
  }

  updateLocation(location: string) {
    this._navigation.updateLocation(location);
  }

  resetInput() {
    this.fileInput.nativeElement.value = null;
  }
}
