<mat-card class="progress-card last-card" *ngIf="isPhoneWidth">
  <div class="content"  [class.active]="selected==title" (click)="filterData()">
    <div class="text">
      <p class="number-docs" style="color:{{ textColor }}">{{ number }}</p>
      <h5 class="title" style="color:{{ textColor }}">{{ title }}</h5>
    </div>
  </div>
</mat-card>

<mat-card class="big-card" *ngIf="!isPhoneWidth">
  <div [class.active]="selected=='ALL'" (click)="filterData('ALL')">
    <p class="margin-0">{{ actionData?.allDocuments }}</p>
    <p class="label">All Documents</p>
  </div>
  <hr />
  <div
    style="color: #e75151"
    [class.active]="selected=='FOR_MY_ACTION'"
    (click)="filterData('FOR_MY_ACTION')"
  >
    <p class="margin-0">{{ actionData?.forMyAction }}</p>
    <p class="label">For My Action</p>
  </div>
  <hr />
  <div  [class.active]="selected=='FOR_OTHER_ACTION'" (click)="filterData('FOR_OTHER_ACTION')">
    <p class="margin-0">{{ actionData?.forOtherAction }}</p>
    <p class="label">For Others' Action</p>
  </div>
  <hr />
  <div style="color: #23ac00" [class.active]="selected=='COMPLETED'"  (click)="filterData('COMPLETED')">
    <p class="margin-0">{{ actionData?.completed }}</p>
    <p class="label">Completed</p>
  </div>
</mat-card>
