<div class="workflow padding-top-50">
  <div class="header">
    <h4 class="title">WorkFlow</h4>
    <button class="header-button"  *ngIf="!isPhoneWidth" (click)="redirectUrl()">
      Use SignSecure
    </button>
  </div>
  <mat-tab-group class="" *ngIf="!isPhoneWidth; else phoneView">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/myDoc.svg" />
          <span>Default Workflow</span>
        </div>
      </ng-template>
      <app-table
        [title]="'workflow'"
        [columns]="filesCols"
        [type]="'recent-documents'"
        [data]="data"
        [hideTitle]="true"
        [rowFunction]="rowFunction"
        [paginate]="true"
      ></app-table>
    </mat-tab>
    <ng-container *ngIf="templateFlag">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <img src="../../../../assets/images/icons/teamDoc.svg" />
            <span>Workflow Templates</span>
          </div>
        </ng-template>
        <app-table
          [title]="'workflow'"
          [columns]="filesCols"
          [data]="data"
          [hideTitle]="true"
        ></app-table>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>

<ng-template #phoneView>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="label-container">
          <img src="../../../../assets/images/icons/myDoc.svg" />
          <span>Default Workflow</span>
        </div>
      </ng-template>
      <app-tablet-table
        [data]="data"
        [itemTemplate]="workflow_row"
      ></app-tablet-table>
    </mat-tab>
    <ng-container *ngIf="templateFlag">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="label-container">
            <img src="../../../../assets/images/icons/teamDoc.svg" />
            <span>Workflow Templates</span>
          </div>
        </ng-template>
        <app-table
          [title]="'workflow'"
          [columns]="filesCols"
          [data]="data"
          [hideTitle]="true"
        ></app-table>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</ng-template>

<ng-template #workflow_row let-data>
  <div class="workflow_row" (click)="rowFunction(data,data)">
    <div class="workflow-content">
      <p class="title">{{ data.name }}</p>
      <p class="subtitle">{{data.workflow.status}}</p>
      <p class="content"><a>Updated on: </a>{{ data.updatedAtFormatted }}</p>
    </div>
    <div class="workflow_row__img">
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>