import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { switchMap } from 'rxjs';
import { ModalData } from 'src/app/@shared/models/Modal.model';
import { BulkFormsService } from 'src/app/@shared/services/bulk-forms.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { InboxService } from 'src/app/@shared/services/inbox.service';
import { SafeformService } from 'src/app/@shared/services/safeform.service';

@Component({
  selector: 'app-delete-scid',
  templateUrl: './delete-scid.component.html',
  styleUrls: ['./delete-scid.component.sass'],
})
export class DeleteScidComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  @Input() data: ModalData = {
    type: '',
    action: '',
  };

  constructor(private _forms: SafeformService, private _bulkforms: BulkFormsService) {}

  ngOnInit(): void {}

  deleteMessage() {
      if(this.data.action == "bulkIdData-deleteScid"){
        this.closeModal(true);
        this._forms.deleteScId(this.data.data).pipe(
          switchMap(() => this._forms.getScIdById(this.data.data)), 
          switchMap(({data}) => this._bulkforms.getBulkSCID(data.batchId))
        ).subscribe(({ data }) => {
          this._bulkforms.setBulkIdRow(this._bulkforms.formatBulkScId(data.scId))
        });
      }else{
        this._forms.deleteScId(this.data.data).subscribe((i: any) => {
          this._forms.getScId().subscribe(({ data }) => {
            this._forms.setScId(this._forms.formatScId(data));
          });
          this.closeModal(true);
        });
    }
  }

  closeModal(confirm?: boolean) {
    this.closeModalEvent.emit(confirm);
  }
}
