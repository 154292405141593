import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Breadcrumb } from 'src/app/@shared/models/breadcrumb.model';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';

@Component({
  selector: 'app-file-and-folder-ipad',
  templateUrl: './file-and-folder-ipad.component.html',
  styleUrls: ['./file-and-folder-ipad.component.sass'],
})
export class FileAndFolderIpadComponent implements OnInit {
  @Input('data')
  data: any;

  @Input()
  rowFunction: Function = (event: any, element: any) => {};

  @Input()
  nameFunction: Function = (element: any) => {};

  @Input()
  breadcrumbBack: Function = (event: any) => {};

  @Input()
  modifyFile: boolean | null = false;

  @Input()
  type: string = '';

  @Output('modalCloses')
  callback: EventEmitter<any> = new EventEmitter<any>();

  @Output('modal')
  showModal: EventEmitter<any> = new EventEmitter<any>();

  dataSource = new MatTableDataSource();
  displayedColumns = ['name', 'ocr', 'actions'];

  breadcrumb: Breadcrumb[] = [];
  constructor(
    public dialog: MatDialog,
    private _fileActionService: FileActionService,
    private _breadcrumbs: BreadcrumbsService,
    private _fileService: FilesService
  ) {}

  ngAfterViewInit() {
    this._breadcrumbs.breadcrumbs$.subscribe((i) => {
      this.breadcrumb = i;
    });
  }

  ngOnChanges() {
    this.dataSource.data = this.data;
  }

  ngOnInit(): void {}

  modifyFileEvent(data: any): void {
    this._fileActionService.file = data;
    this._fileActionService.breadCrumbs;
    this.modifyFile = !this.modifyFile;
    this._fileActionService.setIsModify(this.modifyFile);
  }
}
