import {
	AfterViewChecked,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationService } from "src/app/@shared/services/navigation.service";
import { ModalComponent } from "../modal/modal.component";

@Component({
	selector: "app-signing-page-navbar",
	templateUrl: "./signing-page-navbar.component.html",
	styleUrls: ["./signing-page-navbar.component.sass"],
})
export class SigningPageNavbarComponent implements OnInit, AfterViewChecked {
	@Input()
	data: any;

	numberSignatures: number = 0;
	numberDoneSignatures: number = 0;
	signatures: any[] = [];
	message: string = "";

	@Input()
	done: boolean = false;

	@Input()
	cancel: boolean = false;

	@Input()
	role: string = "SIGN";

	@Input()
	createdByMe: boolean = false;

	@Input()
	status: string = "";

	@Input()
	documentStatus: string = "";

	@Input()
	documentTitle: string = ''

	@Input("id")
	documentId: string = "";

	@Input("signing")
	signingNow: boolean = false;

	@Input("userData")
	user: any;

	@Output()
	generate: EventEmitter<any> = new EventEmitter();

	@Output()
	action: EventEmitter<any> = new EventEmitter();

	isPhoneWidth: boolean = false;

	constructor(private _nav: NavigationService, public dialog: MatDialog) { }

	ngAfterViewChecked(): void {
		this.signatures = this.data?.flat() ?? [];
		this.numberSignatures = this.signatures.filter(
			(signature) =>
				signature?.type?.includes("signature") || signature?.type === "initials"
		).length;
		this.numberDoneSignatures = this.signatures.reduce(
			(total: number, signature: any) => {
				if (signature?.signature) {
					return ++total;
				} else {
					return total;
				}
			},
			0
		);

	}

	ngOnInit(): void {
		console.log({ role: this.role, status: this.status, done: this.done, sign: this.signingNow });

		if (screen.width >= 393 && screen.width <= 430) {
			this.isPhoneWidth = true;
		}

		if (!this.done && this.status !== 'COMPLETED' && this.status !== 'CANCELLED' && this.status !== 'EXPIRED' && this.signingNow) {
			if (this.role === 'SIGN') {
				this.message = 'Select a signature field and add your signature.'
			} else if (this.role === 'APPROVE') {
				this.message = 'Please review the document for approval.'
			} else if (this.role === 'COPY') {
				this.message = 'You are only allowed to view this document.'
			}
		} else if (!this.signingNow && this.user?.role !== 'COPY' && this.status !== 'COMPLETED') {
			this.message = `Waiting for ${this.user?.name ?? "Party 1"} to ${this.user?.role?.toLowerCase() ?? "sign"
				}`;
		} else if (this.createdByMe && this.status !== 'EXPIRED') {
			this.message = `You are viewing this document as the author`
		} else if (this.role === 'COPY' && this.status !== 'COMPLETED') {
			this.message = 'You are only allowed to view this document.'
		}





		this.signatures = this.data?.flat() ?? [];
		this.numberSignatures = this.signatures.filter((signature) =>
			signature?.type?.includes("signature")
		).length;
		this.numberDoneSignatures = this.signatures.reduce(
			(total: number, signature: any) => {
				if (signature?.signature) {
					return ++total;
				} else {
					return total;
				}
			},
			0
		);


	}

	back() {
		this._nav.updateSigningPage(false);
	}

	generateFile() {
		if (this.numberDoneSignatures === this.numberSignatures) {
			console.log("generate file");
			this.generate.emit(null);
		}
	}

	showModal(action: string = "workflow-reject-accept", size: string = "808px") {
		console.log({ sendData: this.data });
		if (screen.width >= 393 && screen.width <= 430) {
			this.isPhoneWidth = true;
		}
		const dialogRef = this.dialog.open(ModalComponent, {
			width: !this.isPhoneWidth ? size : '100vh',
			data: {
				action: action,
				data: this.documentId,
			},
		});

		dialogRef.afterClosed().subscribe((data) => {
			if (data?.action === "document-cancelled") {
				this.showModal(data.action, "512px");
			} else if (data?.action === "dashboard") {
				this.back();
			} else {
				this.action.emit(data);
			}
		});
	}

	doAction(action: string) {
		this.action.emit({ action });
	}
}
