<div class="complete">
	<img src="../../../../../assets/images/workflow-done.svg" alt="" srcset="" />
	<div class="text-content">
		<h4>Document sent</h4>
		<p>
			Recipients will be notified when it's their turn to sign or approve the
			document.
		</p>
	</div>

	<div class="buttons">
		<button class="return" (click)="goBack()">Return to dashboard</button>
	</div>
</div>
