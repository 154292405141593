import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, interval, Observable, of, throttle } from 'rxjs';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import {
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { KonvaComponent } from 'ng2-konva';
import Konva from 'konva';
import moment from 'moment';
import QRCode from 'qrcode';
import { NavigationService } from 'src/app/@shared/services/navigation.service';

@Component({
  selector: 'app-add-fields',
  templateUrl: './add-fields.component.html',
  styleUrls: ['./add-fields.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddFieldsComponent implements OnInit, OnDestroy {
  @Output('data')
  data: EventEmitter<any> = new EventEmitter<any>();

  @Output('pdfDetails')
  setPdfDetails: EventEmitter<any> = new EventEmitter<any>();

  @Output('allHaveSignatures')
  allAddedSignatures: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  height: number = 1056;
  width: number = 816;
  pdfSrc: any = '../../../../../assets/dms-documents/pdf-test.pdf';
  zoom: number = 1;
  totalPages: number = 1;
  pageNumber: number = 1;
  pageHeight: string = `${this.height * this.totalPages}px`;

  pageIndex: number = 0;

  signatures: any[][] = [[]];
  signatureObs: BehaviorSubject<any[][]> = new BehaviorSubject<any[][]>([]);
  signatures$ = this.signatureObs.asObservable();

  stages: Map<number, Konva.Stage> = new Map<number, Konva.Stage>();
  stageStatus: boolean[] = [];
  transformers: Konva.Transformer[] = [];

  optionTypes = [
    'signature',
    'signature-name',
    'signature-date',
    'signature-name-designation',
    'name',
    'initials',
    'designation',
    'date-time',
    'textbox',
  ];

  options: any;
  space: number = 0;

  layerConfig = {
    draggable: true,
  };

  @Input()
  qrPosition: string = 'bottom-right';

  @Output('qr')
  QRPosition: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('pdf')
  pdfContainer!: ElementRef;

  @ViewChild('scroll')
  scrollContainer!: ElementRef;

  @ViewChild('pages')
  pageElement!: CdkDropList;

  @ViewChild('viewer')
  pdfViewer!: PdfViewerComponent;

  public configStage = {
    width: this.width * this.zoom,
    height: this.height * this.zoom,
  };

  pdfDetails: PDFDocumentProxy | null = null;

  loading: boolean = true;

  signatories: any[] = [];
  fieldFor: any = 0;
  doneSignatories: any[] = [];

  cursorLocation: any[] = [];
  offset: number = 161;

  left = '0px';
  top = '0px';
  controlsDisplay = 'none';
  transformer: any;
  target: any;

  isDragged: boolean = false;

  message: any = {
    SIGN: '',
    APPROVE:
      "This assignee needs to approve and doesn't have to fill the document.",
    COPY: "This assignee receives a copy and doesn't have to fill the document.",
  };

  draggedField: any;

  pageConfigs: any[] = [];

  pagesRendered: number = 0;
  forceUpdate: any;
  qrData: any = null;
  prevPage: number = 0;
  scale: number = 1;
  constructor(
    private signSecureService: SignSecureService,
    private _nav: NavigationService,
    private changeDetection: ChangeDetectorRef
  ) {
    this.signSecureService.worflowData$
      .pipe(throttle(() => interval(1000)))
      .subscribe(data => {
        if (this.pdfSrc !== data.fileData) {
          this.pdfSrc = data.fileData;
        }

        console.log('workflow flowing')
        this.signatories = data.workflow?.parties ?? [];

        if (this.doneSignatories.length !== this.signatories.length) {
          this.doneSignatories = this.signatories.map(
            (signatory: any) =>
              signatory.role !== 'SIGN' ||
              data.workflow?.signatures?.some(ss =>
                ss?.some(
                  (s: any) =>
                    s?.id === signatory?.id && s.type.includes('signature')
                )
              )
          );
        }

        if (this.signatures !== data.workflow?.signatures) {
          this.signatures = data.workflow?.signatures ?? [];
          this.data.emit(this.signatures);
        }

        console.log({ done: this.doneSignatories });
        if (this.doneSignatories.every(signatories => signatories)) {
          this.allAddedSignatures.emit(true);
        } else {
          this.allAddedSignatures.emit(false);
        }

        this.options = this.signSecureService.getSignatoryOptions(
          this.signatories[this.fieldFor]
        );

        console.log({ updatedQRPosition: data?.workflow?.qrPosition });
        if (
          data?.workflow?.qrPosition &&
          data?.workflow?.qrPosition !== this.qrPosition
        ) {
          this.qrPosition = data?.workflow?.qrPosition;
        }
      });

    this.signSecureService.addFieldPdfPageNumber$.subscribe(pdfPage => {
      // this.scrollData(pdfPage)
      console.log({ pdfPage });
      this.pageNumber = pdfPage as number;
    });
  }

  ngOnInit(): void {
    console.log({ src: this.pdfSrc });
    this.generateQR().catch(err => console.log({ err }));
    this.signSecureService.addFieldPdfZoom$.subscribe(async value => {
      this.zoom = value;
      this.hideControls();
      if (this.pageConfigs.length === this.totalPages) {
        await this.getPageInfo(this.pdfViewer?.pdfViewer?.pdfDocument);
        this.signatures = [...this.signatures];
      }
    });
    this.fieldFor = 0;

    if (this.doneSignatories.every(signatories => signatories)) {
      this.allAddedSignatures.emit(true);
    } else {
      this.allAddedSignatures.emit(false);
    }
  }

  ngOnDestroy(): void {
    this.pdfViewer.ngOnDestroy();
  }

  drop(event: any) {
    const item = this.draggedField;
    this.hideControls();

    let position = { ...this.cursorLocation[this.pageIndex] };
    console.log({
      ...position,
      index: this.pageIndex,
      num: this.pageNumber,
      cursor: [...this.cursorLocation],
      item,
      event,
    });

    if (this._nav.getCurrentView() !== 'Desktop') {
      const sideDivRect =
        event.container.element.nativeElement.getBoundingClientRect();
      const pointerX = event.dropPoint.x - sideDivRect.left;
      const pointerY = event.dropPoint.y - sideDivRect.top;
      position = { x: pointerX, y: pointerY };
    }

    if (position.x === -1 && position.y === -1) return;

    const config = this.createConfig(item, position);
    const signatureField: any = {
      ...item,
      trackId: this.random(),
      ...config,
      layerConfig: this.layerConfig,
      ...this.signatories[this.fieldFor],
      scale: this.scale
    };

    console.log({ signatures: this.signatures });
    this.signatures[this.pageIndex] = [
      ...this.signatures[this.pageIndex],
      signatureField,
    ];

    this.changeDetection.detectChanges();
    this.signatureObs.next(this.signatures);
    this.data.emit(this.signatures);
    if (this.qrPosition) this.QRPosition.emit(this.qrPosition);
    this.pdfViewer.pdfViewerContainer.nativeElement.scrollTo({
      top: this.pdfViewer.pdfViewerContainer.nativeElement.scrollTop + 1,
    });
    this.pdfViewer.pdfViewerContainer.nativeElement.scrollTo({
      top: this.pdfViewer.pdfViewerContainer.nativeElement.scrollTop - 1,
    });
    this.changeDetection.detectChanges();
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdfDetails = pdf;
    this.totalPages = pdf?.numPages;

    this.setPdfDetails.emit({ total: pdf.numPages, page: 1 });
    if (this.signatures.length === 0)
      this.signatures = Array(this.totalPages).fill([]);
    this.signatureObs.next(this.signatures);
    if (this.pageConfigs.length !== this.totalPages) {
      this.pageConfigs = Array(this.totalPages).fill(null);
    }

    if (this.cursorLocation.length === 0) {
      this.cursorLocation = Array(this.totalPages).fill({ x: -1, y: -1 });
    }

    this.transformers = Array(this.totalPages).fill(null);
    this.stageStatus = Array(this.totalPages).fill(false);

    this.pageHeight = `${this.height * this.totalPages}px`;
    this.getPageInfo(pdf);
    this.pdfViewer.pdfViewerContainer.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );

    this.pageElement.element.nativeElement.addEventListener(
      'scroll',
      this.onScroll.bind(this)
    );
  }

  currentPage(event: any, index: number) {
    event.preventDefault();
  }

  trackItem(index: number, item: any) {
    return item.trackId;
  }

  random() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const { target } = event;
    this.pageElement.element.nativeElement.scrollTop = target.scrollTop;
    this.pdfViewer.pdfViewerContainer.nativeElement.scrollTop =
      target.scrollTop;
    this.setPdfDetails.emit({ total: this.totalPages, page: this.pageNumber });
  }

  handleDrag(ngComponent: KonvaComponent, page: number, signature: number) {
    console.log({ ngComponent, page, signature });
  }

  setCursorLocation(event: any, page: number) {
    this.pageIndex = page;
    console.log({page, event})
    this.cursorLocation[page] = event;
  }

  updateSignature(event: any) {
    console.log('UPDATE SIGNATURE: ', event);
    const newConfig = this.createConfig(event.detail, event.position);
    this.signatures[event.page][event.index] = {
      ...event.detail,
      ...newConfig,
    };
    this.data.emit(this.signatures);
    if (this.QRPosition) {
      this.QRPosition.emit(this.qrPosition);
    }

    this.changeDetection.detectChanges();
  }

  createConfig(item: any, position: { x: number; y: number }) {
    const signatory = this.signatories[this.fieldFor];

    console.log({ item, position, signatory, drag: this.draggedField });
    if (item.type.includes('signature')) {
      this.doneSignatories[this.fieldFor] = true;
      console.log({ doneSignatories: this.doneSignatories });
      if (this.doneSignatories.every(signatories => signatories)) {
        this.allAddedSignatures.emit(true);
      }
    }

    let config;
    if (
      item.type.includes('signature') ||
      item.type === 'initials' ||
      item.type === 'textbox'
    ) {
      config = {
        id: item?.config?.id ?? this.random(),
        x: position.x,
        y: position.y,
        width: 263 * this.scale,
        height: 72 * this.scale,
        fill: `${this.signatories[this.fieldFor].color}55`,
        stroke: 'transparent',
        strokeWidth: 0,
      };
    }

    let textConfig;
    if (item.type === 'initials') {
      textConfig = {
        id: item?.textConfig?.id ?? this.random(),
        x: position.x,
        y: position.y,
        text: '[Initial will be placed here]',
        fontSize: 8.5 * this.scale,
        fontFamily: 'DM Sans',
        width: 263 * this.scale,
        padding: 33 * this.scale,
        align: 'center',
        fill: this.signatories[this.fieldFor].color,
      };
    } else if (item.type.includes('signature')) {
      textConfig = {
        id: item?.textConfig?.id ?? this.random(),
        x: position.x,
        y: position.y,
        text: '[Signature will be placed here]',
        fontSize: 8.5 * this.scale,
        fontFamily: 'DM Sans',
        width: 263 * this.scale,
        padding: 33 * this.scale,
        align: 'center',
        fill: this.signatories[this.fieldFor].color,
      };
    } else if (item.type === 'textbox') {
      textConfig = {
        id: item?.textConfig?.id ?? this.random(),
        x: position.x,
        y: position.y,
        text: '[Text will be placed here]',
        fontSize: 8.5 * this.scale,
        fontFamily: 'DM Sans',
        width: 263 * this.scale,
        padding: 33 * this.scale,
        align: 'center',
        fill: this.signatories[this.fieldFor].color,
      };
    }

    let documentConfig;
    if (item.type.includes('signature') || item.type === 'initials') {
      documentConfig = {
        id: item?.documentConfig?.id ?? this.random(),
        x: position.x + 162 * this.scale,
        y: position.y,
        text: 'XXXXXXXXXXXXXXX',
        fontSize: 5.28533 * this.scale,
        fontFamily: 'DM Sans',
        width: 100 * this.scale,
        padding: 14,
        align: 'center',
        fill: 'black',
      };
    }

    let nameConfig;
    if (item.type === 'name') {
      nameConfig = {
        id: item?.nameConfig?.id ?? this.random(),
        x: position.x,
        y: position.y,
        text: signatory.name,
        fontSize: 12,
        fontFamily: 'DM Sans',
        fontWeight: '500',
        width: 200 * this.scale,
        fill: '#212221',
      };
    } else if (item.type.includes('name')) {
      nameConfig = {
        id: item?.nameConfig?.id ?? this.random(),
        x: position.x,
        y: position.y + 77 * this.scale,
        text: signatory.name,
        fontSize: 12 * this.scale,
        fontFamily: 'DM Sans',
        fontWeight: '500',
        width: 200 * this.scale,
        fill: '#212221',
      };
    }

    let dateConfig;
    if (item.type === 'date-time') {
      dateConfig = {
        id: item?.dateConfig?.id ?? this.random(),
        x: position.x,
        y: position.y,
        text: moment().format('DD/MM/YYYY hh:mm A'),
        fontSize: 12 * this.scale,
        fontFamily: 'DM Sans',
        fontWeight: '500',
        width: 150 * this.scale,
        fill: '#212221',
      };
    } else if (item.type.includes('date')) {
      dateConfig = {
        id: item?.dateConfig?.id ?? this.random(),
        x: position.x,
        y: position.y + 80 * this.scale,
        text: moment().format('DD/MM/YYYY hh:mm A'),
        fontSize: 12 * this.scale,
        fontFamily: 'DM Sans',
        fontWeight: '500',
        width: 150 * this.scale,
        fill: '#212221',
      };
    }

    let desginationConfig;
    if (item.type === 'signature-name-designation') {
      desginationConfig = {
        id: item?.desginationConfig?.id ?? this.random(),
        x: position.x,
        y: position.y + 95 * this.scale,
        text: signatory.designation,
        fontSize: 12 * this.scale,
        fontFamily: 'DM Sans',
        fontStyle: 'italic',
        width: 150 * this.scale,
        fill: '#212221',
      };
    } else if (item.type === 'designation') {
      desginationConfig = {
        id: item?.desginationConfig?.id ?? this.random(),
        x: position.x,
        y: position.y,
        text: signatory.designation,
        fontSize: 12 * this.scale,
        fontFamily: 'DM Sans',
        fontStyle: 'italic',
        width: 150 * this.scale,
        fill: '#212221',
      };
    }

    return {
      config: config,
      textConfig: textConfig,
      documentConfig: documentConfig,
      nameConfig: nameConfig,
      dateConfig: dateConfig,
      desginationConfig: desginationConfig,
	  viewport: this.pageConfigs[this.pageIndex].viewport
    };
  }

  changeSignatory(fieldFor: number) {
    console.log({ event: event });
    this.fieldFor = fieldFor;
    this.options = this.signSecureService.getSignatoryOptions(
      this.signatories[fieldFor]
    );
  }

  showControls(event: any) {
    if (event === 'hide') {
      this.controlsDisplay = 'none';
    } else {
      this.controlsDisplay = 'flex';
      this.left = `${event?.x}px` ?? 0;
      this.top = `${event?.y + this.getPreviousPageHeight()}px` ?? 0;
      this.transformer = event.transformer;
      this.target = event.target;
    }
  }

  getPreviousPageHeight() {
    let totalHeight = 0;

    for (let i = 0; i < this.pageNumber - 1; i++) {
      const { height } = this.pageConfigs[i];
      totalHeight += height + 10;
    }

    console.log({ totalHeight });
    return totalHeight;
  }

  deleteSignature() {
    const num = this.pageIndex;
    const signatures = this.signatures[num];
    this.signatures[num] = [];
    this.changeDetection.detectChanges();

    const { index } = this.target.attrs;
    console.log({ num, signatures });
    signatures.splice(index, 1);

    this.signatures[num] = signatures;
    this.signatures = [...this.signatures];
    this.data.emit(this.signatures);
    this.changeDetection.detectChanges();
    this.hideControls();

    this.checkIfAllSignatoryHaveField();
  }

  checkIfAllSignatoryHaveField() {
    const sigs = this.signatures.flat(2);
    this.doneSignatories = this.signatories.map(
      (s: any) =>
        s.role !== 'SIGN' ||
        sigs.some((sg: any) => sg.id === s.id && sg.type.includes('signature'))
    );

    if (!this.doneSignatories.every(signatory => signatory)) {
      this.allAddedSignatures.emit(false);
      this.changeDetection.detectChanges();
    }
  }

  hideControls() {
    this.transformer?.nodes([]);
    this.showControls('hide');
  }

  scrollData(page: Number) {
    const calcPage = +page - 1;
    const top = this.height * calcPage + 11 * calcPage;
    this.scrollContainer?.nativeElement?.scroll({
      top: top,
      left: 0,
      behavior: 'smooth',
    });
  }

  updateDrag(event: any) {
    this.isDragged = event;
    if (this.isDragged) {
      this.left = `${event?.x}px` ?? 0;
      this.top = `${event?.y + this.getPreviousPageHeight()}px` ?? 0;
    }
  }

  getMessage(role: string) {
    return this.message[role];
  }

  selectedField(field: any) {
    console.log({ field });
    this.draggedField = field;
  }

  async getPageInfo(pdf: PDFDocumentProxy | undefined) {
    if (!pdf) return;

    const pdfViewer = this.pdfViewer.pdfViewer;
    pdfViewer.scrollMode = 0;
    pdfViewer.spreadMode = 0;

    for (let i = 1; i <= this.totalPages; i++) {
      try {
        const pageView = await pdf.getPage(i);
        const viewPort = pageView.getViewport();
        const viewBox = viewPort?.viewBox ?? null;

        if (viewBox.length === 4) {
          let width = viewBox[2] * PdfViewerComponent.CSS_UNITS;
          let height = viewBox[3] * PdfViewerComponent.CSS_UNITS;
          this.height = height;
          this.pageConfigs[i - 1] = { width, height, viewPort };
          console.log({ pageConfigs: { width, height, i, viewBox, pageView } });
        }
      } catch (e) {
        console.log({ e });
        if (i !== 1) {
          this.pageConfigs[i - 1] = this.pageConfigs[i - 2];
        }
      }
    }

    console.log({ pageConfigs: this.pageConfigs });
    this.loading = false;
  }

  pageRendered(event: any) {
    console.log({ pageRender: event });
    const pdf = event?.source;
    const viewBox = pdf?.viewport?.viewBox ?? null;
    let config = this.pageConfigs[event?.pageNumber - 1];
    let width = viewBox[2] * PdfViewerComponent.CSS_UNITS * pdf.scale;
    let height = viewBox[3] * PdfViewerComponent.CSS_UNITS * pdf.scale;

    console.log({ viewBox });

    config = { width, height: Math.floor(height), viewport: pdf?.viewport };
    this.pageConfigs[event?.pageNumber - 1] = { ...config };
    this.pagesRendered = event?.pageNumber;
    this.scale = pdf.scale;
    this.changeDetection.detectChanges();
  }

  updateQRPostion(event: any) {
    if (this.QRPosition) this.QRPosition.emit(this.qrPosition);
    this.changeDetection.detectChanges();
    console.log({ qrPosition: this.qrPosition });
  }

  async generateQR() {
    this.qrData = await QRCode.toDataURL('example-qr-code...');
  }

  testing(event: any, key: string) {
    let obj: { [key: string]: any } = {};
    obj[key] = event;
    console.log({ obj });
  }
}
