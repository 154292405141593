<mat-card class="container">
  <img class="desktop" src="../../../assets/images/sc-logo-text.png" alt="" />
  <img class="tablet" src="../../../assets/images/side-logo.svg" alt="" />
  <img
    class="iphone"
    src="../../../assets/images/iphone-assets/sc-signin-logo.png"
    alt=""
  />
  <h4 class="header">Document Management System</h4>
</mat-card>
