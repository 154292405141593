import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, of } from 'rxjs';
import { SupportData } from 'src/app/@shared/models/support.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { SupportService } from 'src/app/@shared/services/support.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.sass'],
})
export class SupportPageComponent implements OnInit {
  email: string = '';
  name: string = '';
  message: string = '';
  attachment: File[] = [];
  fileNames: string[] = [];
  constructor(
    private _supportService: SupportService,
    private dialog: MatDialog,
    private _filesService: FilesService,
    private _authService: AuthenticationService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.name = this._authService.name;
    this.email = this._authService.email;
  }
  getFormData(object: any) {
    const formData = new FormData();

    Object.keys(object).forEach((key) => {
      if (typeof object[key] !== 'object') {
        formData.append(key, object[key]);
      } else {
        object[key].forEach((i: File) => {
          formData.append(key, i, object[key].name);
        });
      }
    });
    return formData;
  }
  submitConcern() {
    const epochNow = new Date().getTime();

    let concern = {
      files: this.attachment,
      email: this.email,
      name: this.name,
      message: this.message,
      //createdAt:epochNow.toString()
    };
    const concernForm = this.getFormData(concern);

    this._supportService
      .createSupportMsg(concernForm)
      .pipe(
        catchError((err) => {
          console.log('err', err)
          if (err.status == 400) {
            const errorMessage =
              this.message !== ''
                ? 'File type unsupported'
                : 'Message Required';
            this._snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { type: 'error', text: errorMessage },
            });
          }
          return of();
        })
      )
      .subscribe((i) => {
        this.clearContent();
        this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { type: 'TicketCreated' },
        });
      });
  }

  clearContent() {
    // this.attachment = undefined as unknown as File[];
    this.attachment = [] as File[];
    this.message = '';
    this.fileNames = [];
  }

  uploadAttachment() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: { action: 'upload-attachment' },
    });

    dialogRef?.afterClosed()?.subscribe((data) => {
      if (data) {
        Array.from(data).map((datum:any)=>{
          if(datum.size/1024/1024 <=25)
          {this.attachment.push(datum)}else{
            this._snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { type: 'error', text: 'File size exceeds 25MB' },
            });
          }          
        });
        
        
        this.fileNames = this.attachment.map((datum: any) =>  datum?.name);
      }
    });
  }

  removeAttachment(index: number) {
    this.attachment.splice(index, 1);
    this.fileNames.splice(index, 1);
  }
}
