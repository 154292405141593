import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  combineLatestWith,
  lastValueFrom,
  map,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DashboardCardModel } from 'src/app/@shared/models/dasboard.model';
import { FileTableModel } from 'src/app/@shared/models/file-table.model';
import { UserModel } from 'src/app/@shared/models/user.model';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { BreadcrumbsService } from 'src/app/@shared/services/breadcrumbs.service';
import { DashBoardServices } from 'src/app/@shared/services/dashboard.service';
import { FileActionService } from 'src/app/@shared/services/file-action.service';
import { FilesService } from 'src/app/@shared/services/files.service';
import { NavigationService } from 'src/app/@shared/services/navigation.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { WorkflowService } from 'src/app/@shared/services/workflow.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { SignSecureService } from 'src/app/@shared/services/signsecure.service';
import { MatDialog } from '@angular/material/dialog';
import { QrService } from 'src/app/@shared/services/qr.service';
import { PDFDocument } from 'pdf-lib';

@Component({
  selector: 'app-admin-dashboard-report',
  templateUrl: './admin-dashboard-report.component.html',
  styleUrls: ['./admin-dashboard-report.component.sass'],
})
export class AdminDashboardReportComponent implements OnInit, OnDestroy {
  searchQuery: string = '';
  recentDocmentTitle: string = 'Recent Documents';
  recentUsersTitle: string = 'Recent Users';
  signSecureDocumentTitle: string = 'All Documents';
  signSecureDocumentData: any[] = [];
  signSecureDocumentDataOrig: any[] = [];
  signSecureDocumentCols: string[] = [
    'title',
    'status',
    'Date Created',
    'Last Updated',
    'actions',
  ];

  recentDocuemtCols: string[] = ['name', 'tags', 'date', 'actions'];
  recentDocumentData: any = this._fileService.recentfiles$;
  dashData: any;
  signSecureDocumentDataAsync: any;
  recentFoldersCols: string[] = ['name', 'tags', 'date', 'actions'];
  recentFolders: any[] = [];
  isPhoneWidth: boolean = false;

  recentUsersCols: string[] = ['Name', 'Email', 'Date Added'];
  actionData: any;
  private _subs: Subscription = new Subscription();
  filterType: string = 'ALL';
  recentUsersData: Object[] = [];
  selected: string = '';
  userType = this._authService.userRole;
  isTabletWidth = false;
  readonly workflow = environment.flag.useLocalSign;
  constructor(
    private _usersService: UsersService,
    private _fileService: FilesService,
    private _dashboardService: DashBoardServices,
    private _authService: AuthenticationService,
    private _fileActionService: FileActionService,
    private _breadcrumbs: BreadcrumbsService,
    private _navigation: NavigationService,
    private _workflowService: WorkflowService,
    private _signSecureService: SignSecureService,
    private dialog: MatDialog,
    private _qr: QrService,
  ) { }

  ngOnInit(): void {

    this.checkIncompleteProcess()

    this.actionData = this._dashboardService.getDashboardData().pipe(
      map((data) => {
        return data.data;
      })
    );
    console.log(screen.width);
    this.isTabletWidth = (screen.width >= 481 && screen.width <= 1279) ? true : false;
    console.log(this.isTabletWidth);
    if (screen.width >= 393 && screen.width <= 490) {
      this.isPhoneWidth = true;
    } else {
      this.isPhoneWidth = false;
    }
    if (this.userType !== 'user') {
      this._subs.add(
        this._usersService.getUsers('desc').subscribe((users) => {
          this.recentUsersData = users.data.slice(0, 7).map((i: UserModel) => {
            return {
              name: `${i.givenName} ${i.lastName}`,
              email: i.email,
              date_added: i.lastAccessedFormatted?.split(',')[0],
            };
          });
        })
      );
    } else {
      this._subs.add(
        this._fileService.getAllFolder(false).subscribe(({ data }) => {
          const recentFoldersData = data.slice(0, 7);
          this.recentFolders = recentFoldersData.map((datum: any) => {
            return {
              name: datum.name || '',
              date: datum.createdAtFormatted?.split(',')[0],
              tags: datum.tags || [],
              actions: {
                download: datum,
                info: { data: datum, action: 'recent-doc-info' },
              },
            };
          });
        })
      );
    }

    // this._subs.add(
    //   this._fileService.getRecentFiles().subscribe(({ data }) => {
    //     this._fileService.setRecentFiles(
    //       this._fileService.formatRecentFile(data)
    //     );
    //   })
    // );

    if (!this.isPhoneWidth && !this.isTabletWidth) {
      this._subs.add(
        this._fileService.getAllFilesAndFolder(true).subscribe((data) => {
          // to add actions use file service format
          console.log(data, data.data.length);
          this.signSecureDocumentData = this._fileService.formatWorkflowData(
            data.data
          );
          this.signSecureDocumentDataOrig = this.signSecureDocumentData;
        })
      );
    } else {
      this._dashboardService.getDashboardData().subscribe((data) => {
        console.log("tablet")
        this.dashData = data.data;
      });
    }

    this.signSecureDocumentDataAsync = this._dashboardService
      .getFilteredDashboardData('FOR_MY_ACTION')
      .pipe(
        switchMap((data) => {
          let type = 'ALL';
          this.selected = 'All Documents';
          if (data.data.count !== 0) {
            this.signSecureDocumentTitle = 'For my action';
            this.selected = 'For My Action';
            type = 'FOR_MY_ACTION';
          }

          this.filterType = type;
          return this._dashboardService.getFilteredDashboardData(type);
        }),
        map((data) => {
          this.signSecureDocumentData = this._fileService.formatWorkflowData(
            data.data.items
          );
          this.signSecureDocumentDataOrig = this.signSecureDocumentData;
          return this._fileService.formatWorkflowData(data.data.items);
        })
      );

    console.log({ formattedData: this.signSecureDocumentDataAsync })
  }

  filterDashboardData(filter: string = this.filterType, title: string = this.selected) {
    this.filterType = filter;
    this.selected = title;
    switch (filter) {
      case 'ALL': {
        this.signSecureDocumentTitle = 'All Documents';

        break;
      }
      case 'FOR_MY_ACTION': {
        this.signSecureDocumentTitle = 'For my action';
        break;
      }
      case 'FOR_OTHER_ACTION': {
        this.signSecureDocumentTitle = 'For others\' action';
        break;
      }
      case 'COMPLETED': {
        this.signSecureDocumentTitle = 'Completed';
        break;
      }
      default: {
        break;
      }
    }
    this.signSecureDocumentDataAsync = this._dashboardService
      .getFilteredDashboardData(filter)
      .pipe(
        map((data) => {
          this.signSecureDocumentData = this._fileService.formatWorkflowData(
            data.data.items
          );
          this.signSecureDocumentDataOrig = this.signSecureDocumentData;
          return this._fileService.formatWorkflowData(data.data.items);
        })
      );
  }

  async checkIncompleteProcess() {
    const pending = this._signSecureService.havePendingSignature()

    if (!!pending) {
      let data = JSON.parse(pending)

      console.log({ pendingData: data })
      data = (await lastValueFrom(this._fileService.getFile(data.id))).data
      console.log({ retrievedData: data })
      let path = "../../../assets/dms-documents/pdf-test.pdf"
      if (!environment.local) {
        path = `./assets/${data.path}`
      }

      console.log('pending document:', path)
      const bytes = await fetch(path).then(res => res.arrayBuffer())
      const pdfdocument = await PDFDocument.load(bytes)
      const sub = await this._signSecureService.checkIfAllSignatures(pdfdocument, data, this._qr, this._usersService, this._fileService)
      if (sub) {
        sub.subscribe(() => this.filterDashboardData())
      }
    }
  }

  rowFunction(event: any, element: any) {
    if (this.workflow) {
      this._navigation.updateSigningPage(true);
      this._signSecureService.setWorkFlowData(element);
      return;
    }
    console.log(element, element.actions.delete_doc);
    if (element.actions.delete) {
      return;
    } else {
      const token = localStorage.getItem('token');
      const userID = localStorage.getItem('userID');
      window.location.href = `${environment.integration.signSecureWebBaseURL}/my-safeforms/${element.teams}/${element.id}?token=${token}&userID=${userID}`;
    }
  }

  dateConverter(date: string) {
    return new Date(date);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  showModal(action: string, size: string, data?: any) {
    action = 'recent-documents-' + action;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: size,
      data: { action, data },
    });

    dialogRef?.afterClosed()?.subscribe((result) => {
      location.reload();
    });
  }

  recentDocumentModal(data: any) {
    const { data: mData } = data;
    const dialogRef = mData.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: mData.title,
        fileId: mData.fileId,
        data: mData.data,
        fileType: mData.type,
      },
    });

    dialogRef?.afterClosed()?.subscribe((data: any) => { });
  }

  openPdf(data: FileTableModel) {
    if (data.file_type === 'folder' || data.file_type === 'back') {
      return;
    }

    let url =
      data?.actions?.choices?.download?.path || data?.actions?.download?.path;

    if (
      data.fileExtension == 'docx' ||
      data.fileExtension == 'doc' ||
      data.fileExtension == 'xls' ||
      data.fileExtension == 'xlsx' ||
      data.fileExtension == 'ppt' ||
      data.fileExtension == 'pptx'
    ) {
      let office = '';
      if (data.fileExtension == 'docx' || data.fileExtension == 'doc') {
        office = 'ms-word';
      }
      if (data.fileExtension == 'xls' || data.fileExtension == 'xlsx') {
        office = 'ms-excel';
      }
      if (data.fileExtension == 'ppt' || data.fileExtension == 'pptx') {
        office = 'ms-powerpoint';
      }
      window.location.assign(
        office + ':ofv|u|' + window.location.origin + '/assets/' + url
      );
      return;
    }

    url = `../../../assets/${url}`;
    this._fileActionService.pdfSrc = url;
    this._fileActionService.file = data;
    this._fileActionService.setIsViewFile(true);
  }

  async openFolder(event: any, data: any) {
    if (event.target.localName == 'img') {
      return;
    }

    const { download } = data.actions;

    let file = download;
    let files: any[] = [];

    files.push(file);
    await this.generateBreadcrumbs(files, file);

    const length = files.length;

    for (let index = 1; index <= length; index++) {
      const f = files[length - index];
      this._breadcrumbs.setBreadcrumbsData({
        title: f?.name,
        id: f?.id,
        file: f,
      });
    }

    this._fileService.getAllFolderFiles(download.id).subscribe(({ data }) => {
      const format = this._fileService.formatFilesAndFolderData(data);
      this._fileService.setFilesAndFolderAsync(format);
      this._navigation.setLocation('listMenu');
    });
  }

  openSignSecureDoc(event: any, element: any) {
    if (event.target.localName == 'img') {
      return;
    }

    if (element?.actions?.delete) {
      return;
    } else {
      this._navigation.updateSigningPage(true);
      this._signSecureService.setWorkFlowData(element);
    }
  }

  async generateBreadcrumbs(fileDatas: any[], file: any) { }

  useSignSecure() {
    this._navigation.showWorkflow();
    this._signSecureService.nextProgress(1);
  }

  search() {
    if (this.searchQuery.trim().length == 0) {
      this.signSecureDocumentDataAsync = this._dashboardService
        .getFilteredDashboardData(this.filterType)
        .pipe(
          map((data) => {
            return this._fileService.formatWorkflowData(data.data.items);
          })
        );
    } else {
      this.signSecureDocumentDataAsync = this._dashboardService
        .getFilteredDashboardData(this.filterType)
        .pipe(
          map((data) => {
            const origData = this._fileService.formatWorkflowData(
              data.data.items
            );
            const filteredData = origData.filter((file: any) => {
              if (
                file.title
                  .toLowerCase()
                  .includes(this.searchQuery.toLowerCase())
              ) {
                return file;
              } else {
                return;
              }
            });
            return filteredData;
          })
        );
    }
  }
}
