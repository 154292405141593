import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private requests: number = 0;
  private status: boolean = false;
  isLoading = new Subject<boolean>();

  constructor() { }

  show() {
    this.requests++;
    this.status = true;
    this.isLoading.next(true);
    console.log(this.requests)
  }

  hide() {
    if (this.requests === 1 || this.requests === 0) {
      this.status = false;
      this.isLoading.next(false);
      if (this.requests !== 0) {
        this.requests--;
      }
    } else {
      if (this.requests < 0) {
        if (this.status) {
          setTimeout(() => this.isLoading.next(false), 2000);
        }
      } else {
        this.requests--;
      }
    }
    console.log(this.requests)
  }

  hideNow() {
    this.requests = 0;
    this.status = false;
    this.isLoading.next(false)
  }

}
