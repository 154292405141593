<div class="container backCertificate">
  <button mat-button (click)="updateLocation('sc-id-table')">
    <span>
      Back <mat-icon inline="true" class="ml-3">arrow_back</mat-icon>
    </span>
  </button>
</div>
<div class="container" *ngIf="!isFileReady" style="margin-bottom: 2.5rem">
  <div class="alert alert-danger mt-3" role="alert">
    <ul style="margin-bottom: 0 !important">
      <li *ngIf="!isEmail">
        [row {{ isEmailIndex }}] a value in Email Address is invalid.
      </li>
      <li *ngIf="!isFullname">
        [row {{ isFullnameIndex }}] a value in name is invalid.
      </li>
      <!-- <li *ngIf="!isBirthDay"> [row {{isBirthDayIndex}}] a value in birthday is invalid. </li> -->
      <!-- <li *ngIf="!isStation">
        [row {{ isStationIndex }}] a value in station is invalid.
      </li> -->
      <li *ngIf="!isEmpNum">
        [row {{ isEmpNumIndex }}] a value in empnum is invalid.
      </li>
      <li *ngIf="!checkFormat">
        Invalid value. Please ensure that field names are correct and required
        fields are not blank.
      </li>
    </ul>
  </div>
</div>
<div
  class="container d-flex justify-content-center py-2"
  style="margin-bottom: 2.5rem">
  <mat-card class="card-table">
    <div class="col-12 sign-in-container">
      <mat-card-title>
        <div class="row">
          <div class="col-9">Bulk ID Creation</div>
          <div class="col-3" style="float: right">
            <button
              mat-raised-button
              class="button-auth"
              color="primary"
              type="submit"
              [disabled]="!isFileEmpty || isLoading"
              (click)="bulkRegister()">
              <span *ngIf="isLoading">
                <i class="fa fa-spinner fa-spin fa-fw"></i> Creating
              </span>
              <span *ngIf="!isLoading"> Generate ID </span>
            </button>
          </div>
        </div>
      </mat-card-title>
      <mat-error *ngIf="message">
        {{ message }}
      </mat-error>
      <!-- <div class="spinner-container mb-3" *ngIf="isFormLoading">
                <mat-progress-spinner mode="indeterminate">
                </mat-progress-spinner>
            </div> -->
      <div class="form-data">
        <label for="formFile" class="form-label mb-4">Upload template ID</label>
        <input
          class="form-control"
          type="file"
          id="formFile"
          #formFile
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="onFileChange($event)" />
      </div>
    </div>
  </mat-card>
</div>

<div
  class="container d-flex justify-content-center py-2"
  style="margin-top: 2.5rem; margin-bottom: 2.5rem">
  <mat-card class="card-table">
    <app-table
      [title]="'Uploaded Bulk IDs'"
      [columns]="columns"
      [type]="'bulkId$'"
      [data]="bulkId$ | async"
      [paginate]="true"
      [canOpenFile]="true"
      [rowFunction]="selectedRow"></app-table>
  </mat-card>
</div>
