import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SafeformService } from '../services/safeform.service';

export class RollNumberValidator {
  static createValidator(_forms: SafeformService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return _forms
        .checkRollNumber(control.value)
        .pipe(
          map((result: boolean) => (result ? {} : { rollNumberExists: true }))
        );
    };
  }
}
