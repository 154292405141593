import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionUserGroupModel } from 'src/app/@shared/models/user-group.model';
import { ActionUserModel } from 'src/app/@shared/models/user.model';
import { FileActionService } from 'src/app/@shared/services/file-action.service';

@Component({
  selector: 'app-members-file',
  templateUrl: './members-file.component.html',
  styleUrls: ['./members-file.component.sass'],
})
export class MembersFileComponent implements OnInit {
  @Input() data: any;
  users: ActionUserModel[] = [];
  userGroups: ActionUserGroupModel[] = [];
  parties: any[] = []
  @Output() closeModalEvent = new EventEmitter();
  constructor(private _fileActionService: FileActionService) { }

  ngOnInit(): void {
    this.users = this.data.data.users;
    this.userGroups = this.data.data.userGroups;
    this.parties = this.data.data?.workflow?.parties ?? []
    console.log({ data: this.parties })
  }
  closeModal() {
    this.closeModalEvent.emit();
  }
}
