import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, interval } from 'rxjs';
import { AuthenticationService } from 'src/app/@shared/services/authentication.service';
import { SignatureService } from 'src/app/@shared/services/signature.service';
import { UsersService } from 'src/app/@shared/services/users.service';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.sass'],
})
export class AddSignatureComponent implements OnInit {
  @Output() closeModalEvent = new EventEmitter();
  timer = new Subscription();
  time = 100;
  image: string = '';
  value: any;
  reader = new FileReader();
  otp: any = '';
  otpValue: string = '';
  otpError: string = '';
  constructor(
    private _signatureService: SignatureService,
    private _userService: UsersService,
    private _auth: AuthenticationService,
    private _snackbar: MatSnackBar,
  ) {
    this.reader.onload = (e: any) =>
      (this.image = this.reader.result as string);
  }

  ngOnInit(): void { }

  onChangeOption({ value }: any) { }

  dragover(data: FileList) {


    const regex = /.*\.(jpg|jpeg|png)$/i;
    const files = data;

    const supportedFiles: File[] = Array.from(files).filter(file => regex.test(file.name));

    if (supportedFiles.length === 0) {
      this._snackbar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: { type: 'error', text: 'Unsupported file type. Please select a valid file.' },
      });
      return;
    }

    this.value = data[0];
    this.image = data[0].name;
    this.reader.readAsDataURL(this.value);
  }

  checkIfContainsFolder(data: FileList) {
    for (let i = 0; i < data.length; i++) {
      if (data.item(i)?.type === '') {
        return true;
      }
    }
    return false;
  }

  onFileSelected(data: any) {
    const regex = /.*\.(jpg|jpeg|png)$/i;

    if (data.path) {
      const file = data.path[0].files[0]
      if (!regex.test(file.name)) {
        this._snackbar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { type: 'error', text: 'Unsupported file type. Please select a valid file.' },
        });
        return;
      }

      this.value = file;
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    } else if (data.target) {
      const file = data.target.files[0]
      if (!regex.test(file.name)) {
        this._snackbar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { type: 'error', text: 'Unsupported file type. Please select a valid file.' },
        });
        return;
      }

      this.value = file;
      this.image = this.value.name;
      this.reader.readAsDataURL(this.value);
    }
    // this.closeModalEvent.emit(files);
  }

  upload() {
    document.getElementById('fileUpload')?.click();
  }

  close() {
    this.closeModalEvent.emit();
  }

  chooseAnother() {
    this.image = '';
    this.value = {};
    this.upload();
  }

  sendOTP() {
    this.otp = 'otp';
    this._userService.generateOTP().subscribe(() => {
      this.startTimer();
    });
  }

  inputOtp(data: any) {

    this.otpValue = data?.target?.value || '';
  }

  verifyOtp() {
    this._userService.validateOTP(this.otpValue).subscribe(({ data }) => {
      const { valid } = data;
      if (valid) {
        this._signatureService
          .addSignature({
            name: `signature-${this._auth.userId}-${this._signatureService.total + 1
              }`,
            file: this.value,
          })
          .subscribe((i) => { });
        this.close();
      } else {
        this.otpError = 'Invalid OTP';
      }
    });
  }

  startTimer() {
    this.timer = interval(1000).subscribe((timeData) => {

      this.time = this.time - 1;
    });
    return this.timer;
  }
}
