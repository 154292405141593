<mat-card class="container {{ type }}">
  <app-reset-password
    *ngIf="type == 'reset-password'"
    (resetPassword)="changeType($event)"
  ></app-reset-password>
  <app-reset-password-success
    *ngIf="type == 'reset-password-success'"
    (returnEvent)="returnScreen($event)"
  ></app-reset-password-success>
  <app-verification-success
    *ngIf="type == 'verification-success'"
  ></app-verification-success>
  <app-verifitaion-fail
    *ngIf="type == 'verification-fail'"
  ></app-verifitaion-fail>
  <app-recover-password
    *ngIf="type == 'recoverPassword'"
    (emailSentEvent)="changeType($event)"
    (navigatePage)="returnScreen($event)"
  ></app-recover-password>
  <app-email-sent
    *ngIf="type == 'email-sent-reg' || type == 'email-resetpw'"
    [type]="type"
    (returnEvent)="returnScreen($event)"
  ></app-email-sent>
</mat-card>
