import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';

@Component({
  selector: 'app-support-upload',
  templateUrl: './support-upload.component.html',
  styleUrls: ['./support-upload.component.sass'],
})
export class SupportUploadComponent implements OnInit {
  buttonText: string = 'Add files';
  value: string = 'files';

  @Output() closeModalEvent = new EventEmitter();
  constructor(
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  onChangeOption({ value }: any) {
    this.buttonText = `Add ${value}`;
    this.value = value;
  }

  dragover(data: FileList) {
    console.log('datadata', data)

    const regex = /.*\.(jpg|jpeg|png|pdf|doc|docx|xls|xlsx|ppt|pptx|mp4|webm)$/i;
    const files = data;

    const supportedFiles: File[] = Array.from(files).filter(file => regex.test(file.name));

    if (supportedFiles.length === 0) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: { type: 'error', text: 'Unsupported file type. Please select a valid file.' },
      });
      return;
    } else {
      this.closeModalEvent.emit(data);
    }

  }

  onFileSelected(data: any) {
    const regex =
      /.*\.(jpg|JPG|png|PNG|pdf|PDF|doc|DOC|docx|DOCX|xls|XLS|xlsx|XLSX|ppt|PPT|pptx|PPTX|mp4|webm)$/g;
    let files = [];
    if (data.path) {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else if (data.target) {
      files = Array.from(data.target.files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    } else {
      files = Array.from(data.path[0].files)?.filter((file: any) => {
        const result = file?.name?.match(regex);
        return result && result.length !== 0;
      });
    }

    if (files.length === 0) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        data: { type: 'error', text: 'Unsupported file type. Please select a valid file.' },
      });
    }

    console.log('files1233', files)
    this.closeModalEvent.emit(files);
  }

  upload() {
    document.getElementById('fileUpload')?.click();
  }

  close() {
    this.closeModalEvent.emit();
  }
}
