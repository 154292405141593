<div class="header-container">
  <h4 class="title">FAQs</h4>
</div>
<div class="faq-container" *ngFor="let faq of faqs">

    <mat-expansion-panel class="faq-card">
      <mat-expansion-panel-header [collapsedHeight]="'75px'" [expandedHeight]="'75px'">
        <mat-panel-title class="faq-title"> {{faq.title}} </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="faq-desc"*ngFor="let description of faq.descriptions">{{description.text}}</p>
    </mat-expansion-panel>
</div>
<div style="padding-bottom: 20px;"> </div>