import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-parties',
  templateUrl: './document-parties.component.html',
  styleUrls: ['./document-parties.component.sass']
})
export class DocumentPartiesComponent implements OnInit {


  @Input('data')
  parties: any[] = []
  constructor() {
    console.log({ parties: this.parties })
  }

  ngOnInit(): void {
  }

}
